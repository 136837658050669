import Vue from "vue";
import VueRouter from "vue-router";

import AuthRoute from "@/router/AuthRoute";

import CanOnboarding from "@/router/CanOnboarding";
import EmpOnboarding from "@/router/EmpOnboarding";

import CanProfile from "@/router/CanProfile";
import EmpProfile from "@/router/EmpProfile";
import ZaajiraCandidate from "@/router/ZaajiraCandidate";
import store from '@/store'

Vue.use(VueRouter);

const routes = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  routes: [
    // ...AuthRoute,
    // ...CanOnboarding,
    // ...CanProfile,
    // ...EmpOnboarding,
    // ...EmpProfile,
    ...ZaajiraCandidate,
    {
      path: "*",
      name: "404",
      component: () => import("../views/404.vue"),
    },
  ],

});

routes.beforeEach((to, from, next) => {
  // console.log('Vue.store', store);
  const isLoggedIn = store.getters.getLoggedUser.isLoggedIn;
  if (to.matched.some(record => record.meta.applicantAuth) && !isLoggedIn) {

    // console.log('if::To', to);
    // console.log('if::from', from);
    next('/')
  } else {
    // console.log('else::To', to);
    // console.log('else::from', from);
    next()
  }
})


export default routes
import Vue from "vue";
import { BASEURL } from "@/config/index";
import constant from "@/helpers/constant";

const data = function () {
  return {
    AdminError: "Sorry! Something went wrong. kindly contact your administrator",
    NoRecords: "NoRecords",
    BASE_URL: constant.imageBaseURL,
    imageBaseURL: constant.imageBaseURL,
    imageS3BaseURL: constant.imageS3BaseURL,
    secureStore: {
      // set: authenticationService.set,
      // get: authenticationService.get,
      remove: function (key) {
        window.localStorage.removeItem(key);
      },
    },
  };
};
const computed = {
  GetRoleId() {
    const role_id = "";
    return role_id;
  },
};

const methods = {
  _CatchError: function (error) {
    Vue.toasted.show(error || "Sorry! Something went wrong. kindly contact your administrator");
  },
  _GetImagePath: function (imagePath) {
    if (imagePath.match(/S3\//g)) {
      return `${constant.imageS3BaseURL}${imagePath}`;
    } else {
      return `${constant.imageBaseURL}${imagePath}`;
    }
  },
};

const storeService = {
  data: () => data(),
  computed,
  methods,
};

export default storeService;

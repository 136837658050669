import Vue from "vue";

// Vue.directive('click-outside', {
//     priority: 700,
//     bind(el, binding, vnode) {
//         console.log('vnode', vnode)
//         // let self = this
//         // this['event'] = function (event) {
//         //     console.log('emitting event')
//         //     self.vm.$emit(self.expression, event)
//         // }
//         // this.el.addEventListener('click', this.stopProp)
//         // document.body.addEventListener('click', this.event)
//     },

//     unbind() {
//         console.log('unbind')
//         this.el.removeEventListener('click', this.stopProp)
//         document.body.removeEventListener('click', this.event)
//     },
//     stopProp(event) { event.stopPropagation() }
// })

let handleOutsideClick;
Vue.directive("closable", {
  bind(el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation();
      const { handler, exclude } = binding.value;
      // vnode.context[handler]();
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName];
          clickedOnExcludedEl = excludedEl ? excludedEl.contains(e.target) : false;
        }
      });
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]();
      }
    };
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  },

  unbind() {
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
  },
});

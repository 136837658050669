import Vue from "vue";
import router from "@/router";

import moment from "moment";
import _ from "lodash";
import store from "@/store";
import cookieType from "@/config/cookieType";
// import cookies from 'vue-cookies';
// import { authenticationService } from "@/services/AuthenticationService";

const userD = () => ({
  userName: null,
  userEmail: null,
  userId: null,
  userRole: null,
  userToken: null,
  userIndustry: null,
  userSkills: null,
});

function initialState() {
  return {
    signinForm: {
      userName: null,
      userEmail: null,
      userId: null,
      userRole: null,
      userToken: null,
      userIndustry: null,
      userSkills: null,
      isLoggedIn: false,
      currentLang: 'en',
      profileUrl: null
    },
  };
}
const state = initialState();

const mutations = {

  ON_RESET_SAMPLE_STORE_STATE: (state, payload) => {
    // state = initialState();
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },

  ON_SET_SELECTED_INDUSTRY: (state, payload) => {
    // console.log('state', state);
    // console.log('ON_SET_SELECTED_INDUSTRY', payload.userIndustry);
    state.signinForm.userIndustry = payload.userIndustry;
  },
  ON_RESET_SELECTED_INDUSTRY: (state, payload) => {
    state.userIndustry = null;
  },
  ON_SET_SELECTED_SKILLS: (state, payload) => {
    state.signinForm.userSkills = payload.userSkills;
  },
  ON_RESET_SELECTED_SKILLS: (state, payload) => {
    state.userSkills = null;
  },
  ON_SET_SELECTED_LANG: (state, payload) => {
    console.log('ON_SET_SELECTED_LANG', payload);
    state.currentLang = payload.currentLang;
  },
  ON_RESET_SELECTED_LANG: (state, payload) => {
    state.currentLang = 'en';
  },
  ON_SET_PROFILE_IMAGE: (state, payload) => {
    // console.log("payload", payload);
    // console.log("state", state);
    state.signinForm.profileUrl = payload;
  },
  ON_SET_LOCATION: (state, payload) => {
    state.location = payload.location;
  },
  ON_SET_COUNTRY: (state, payload) => {
    state.country = payload.country;
  },
  ON_RESET_COUNTRY: (state, payload) => {
    state.country = null;
  },
  ON_RESET_CITY: (state, payload) => {
    state.city = null;
  },
  ON_SET_CITY: (state, payload) => {
    state.city = payload.city;
  },
  ON_RESET_PROFILE_IMAGE: (state, payload) => {
    state.image = null;
  },
  ON_SET_USER_SIGNIN_VALUES: (state, payload) => {
    state.signinForm = {
      ...userD(),
      ...payload,
    };
    state.signinForm = payload;
  },
  ON_SET_USER_NAME: (state, payload) => {
    state.signinForm = { ...state.signinForm, userName: payload.userName };
  },
  ON_RESET_USER_SIGNIN_VALUES: (state, payload) => {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  ON_LOGOUT: (state, payload) => {
    state.signinForm = {
      ...userD(),
    };
  },
  SET_LOCATION: (state, payload) => {
    state.city_id = payload.city_id;
    state.city_name = payload.city_name;
  },
};

const actions = {
  onResetSampleStoreState: async ({ dispatch, commit, state }, { ...payload }) => {
    await commit("ON_RESET_SAMPLE_STORE_STATE");
  },
  onLogout: async ({ dispatch, commit, state }) => {
    Vue.$cookies.remove("AIAPADHASHID");
    Vue.$cookies.remove("username");
    Vue.$cookies.remove("user_id");
    Vue.$cookies.remove("company_id");
    Vue.$cookies.remove("role_id");
    Vue.$cookies.remove("admin");
    Vue.$cookies.remove("residence");
    Vue.$cookies.remove("google_login");
    Vue.$cookies.remove("job_title");
    Vue.$cookies.remove("country_id");
    Vue.$cookies.remove("city_id");
    localStorage.removeItem("image");
    //store.dispatch("onLogout");
    store.commit("ON_RESET_USER_SIGNIN_VALUES");
    store.commit("ON_RESET_JOB_TITLE");
    store.dispatch("onResetProfileImage");
    store.dispatch("onResetApplicantCv");
    store.dispatch("onResetApplicantExperiance");
    store.dispatch("onResetApplicantEducation");
    store.dispatch("onResetApplicantSocialLink");
    store.dispatch("onResetApplicantPersonalDetails");
    store.dispatch("onResetApplicantCurrentJob");
    store.commit("RESET_PREFILLED_FLAG");
    store.commit("RESET_PREFILLED_FLAG2");
    store.commit("ON_RESET_RECENT_APPLICANTS");
    store.commit("ON_RESET_INDUSTRY_APPLICANTS");
    store.commit("ON_RESET_APPLICANT_MATCH_JOBS");
    store.commit("ON_RESET_COUNTRY");
    store.commit("ON_RESET_CITY");

    router.push({ path: "/candidate/candidate-login" });
  },
  // set Candidate selected industry
  onSetSelectedIndustry: async ({ dispatch, commit }, { ...payload }) => {
    await commit("ON_SET_SELECTED_INDUSTRY", payload);
  },
  onResetSelectedIndustry: async ({ dispatch, commit }) => {
    await commit("ON_RESET_SELECTED_INDUSTRY");
  },
  // set Candidate selected Skill
  onSetSelectedSkills: async ({ dispatch, commit }, { ...payload }) => {
    await commit("ON_SET_SELECTED_SKILLS", payload);
  },
  onResetSelectedSkills: async ({ dispatch, commit }) => {
    await commit("ON_RESET_SELECTED_SKILLS");
  },
  // set Candidate Lang
  onSetSelectedLang: async ({ dispatch, commit }, payload) => {
    await commit("ON_SET_SELECTED_LANG", payload);
  },
  onResetSelectedLang: async ({ dispatch, commit }) => {
    await commit("ON_RESET_SELECTED_LANG");
  },


  onSetProfileImage: async ({ dispatch, commit }, payload) => {
    console.log('onSetProfileImage', payload);
    await commit("ON_SET_PROFILE_IMAGE", payload);

  },
  OnSetLocation: async ({ dispatch, commit }, { ...payload }) => {
    await commit("ON_SET_LOCATION", payload);
  },
  onResetProfileImage: async ({ dispatch, commit }) => {
    await commit("ON_RESET_PROFILE_IMAGE");
  },
  onSetUserSigninValues: async ({ dispatch, commit }, { ...payload }) => {
    await commit("ON_SET_USER_SIGNIN_VALUES", payload);
  },
  // onLogout: async ({ dispatch, commit, state }) => {
  //   await commit("ON_LOGOUT");
  // },
  onResetUserSigninValues: async ({ dispatch, commit }) => {
    await commit("ON_RESET_USER_SIGNIN_VALUES");
  },
  setUserLoggedUser: async ({ dispatch, commit, state }, { ...payload }) => {
    await commit("ON_SET_USER_DETAILS", payload);
  },
  // logoutUser: async ({ dispatch, commit, state }, { ...payload }) => {
  //     Vue.cookie.remove(cookieType.MCHASHID)
  //     await commit('ON_LOGOUT', payload);
  //     await store.dispatch('setUserLoggedStatus', false);
  // },
  setLocation: async ({ dispatch, commit, state }, { ...payload }) => {
    await commit("SET_LOCATION", payload);
  },
};

const getters = {
  getUserProfile: (state) => state.signinForm.profileUrl,
  getLoggedUser: (state) => state.signinForm,
  getUsersIndustry: (state) => state.signinForm.userIndustry,
  getUsersSkills: (state) => state.signinForm.userSkills,
  getCurrentLang: (state) => state.signinForm.currentLang,
  getPersistItems: (state) => state.items,
  getLocation: (state) => state.city_name,
  getProfileImage: (state) => state.image,
  getCountryLocation: (state) => state.location,
  getCountry: (state) => state.country,
  getCity: (state) => state.city,
  getJobTitle: (state) => state.job_title,
};

export default {
  state,
  mutations,
  actions,
  getters,
};

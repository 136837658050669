<template>
  <b-col class="footer-section pb-4">
    <b-row class="">
      <b-col cols="12" sm="12" md="12" class="left-section">
        <h1 class="text-content">
          {{ $gettext("Trusted_by_leading_companies_of_the_world") }}
        </h1>
      </b-col>
      <b-col cols="12" sm="12" md="12"
        class="d-flex flex-column align-items-center justify-content-center client-logo d-md-none">
        <img src="@/assets/job-post.png" class="img-fluid py-3" />
      </b-col>
      <b-col cols="12" sm="12" md="12"
        class="d-flex flex-column align-items-center justify-content-center client-logo hide4-mob">
        <img src="@/assets/job-post.png" class="img-fluid py-3" />
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  name: "AuthFooter",
};
</script>

<style scoped>
.footer-section {
  min-height: 20vh;
  padding: 20px 40px;
}

@media screen and (max-width:768px) {
  .footer-section {
    min-height: 20vh;
    padding: 20px;
  }

}

.footer-section .left-section .text-content {
  font-weight: 700;
  color: #000000;
  font-size: 40px;
  padding-top: 20px;
}

.text-content {
  color: rgba(255, 255, 255, 1);
  font-size: clamp(2rem, 5vw, 3.5rem);
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.client-logo img {
  padding-left: 3rem;
  padding-right: 3rem;
}

.hide4-mob {
  display: block;
}

@media screen and (max-width: 768px) {
  .hide4-mob {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .client-logo img {
    padding-left: 0;
    padding-right: 0;
  }

  .footer-section .left-section .text-content {
    font-size: 25px;
  }
}
</style>

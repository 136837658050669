<template>
  <b-container fluid>
    <b-row v-if="!this.$cookies.get('company_id')">
      <b-col col md="12">
        <NavbarCompNew />
      </b-col>
    </b-row>

    <b-row class="main py-5">
      <!-- left :: start-->
      <b-col cols="12" md="8" class="mx-auto main__left p-5 d-flex align-items-center justify-content-start">
        <div>
          <h1 class="text-main my-auto">
            Connect with the best <br />
            candidates directly, <br />
            with ease!
          </h1>
        </div>
      </b-col>
      <!-- left :: end -->
      <!-- right :: start -->
      <b-col cols="12" md="4" class="mx-auto main__right p-4">
        <div class="mycard p-4 mx-auto">
          <h5 class="heading">RESET PASSWORD</h5>

          <ValidationObserver ref="employerForm">
            <b-form class="customized-form mt-4">
              <ValidationProvider name="otp" rules="required" v-slot="{ errors, classes }">
                <b-form-input :class="classes" placeholder="Enter OTP" v-model="otp" type="number"> </b-form-input>
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider name="email" rules="" v-slot="{ errors, classes }">
                <b-form-input v-show="false" :class="classes" :disabled="true" placeholder="E-mail" v-model="email">
                </b-form-input>
                <div v-show="false" class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider name="password" rules="required|max:15|min:8" vid="confirmation"
                v-slot="{ errors, classes }">
                <b-form-input :class="classes" placeholder="Password" type="password" v-model="password"> </b-form-input>
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ errors, classes }">
                <b-form-input :class="classes" placeholder="Confirm Password" type="password" v-model="confirmPassword">
                </b-form-input>
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <b-button block @click.prevent.stop="ValidateInput" class="main__forgotpassword--button py-3"> Change
                Password </b-button>
            </b-form>
          </ValidationObserver>
        </div>
      </b-col>
      <!-- right :: end -->
    </b-row>
    <b-row>
      <AuthFooter />
    </b-row>
  </b-container>
</template>
<script>
import UserService from "@/apiServices/UserService";
import { validate } from "vee-validate";
import GoogleLogin from "vue-google-login";

import NavbarComp from "@/components/shared/NavbarComp.vue";
import NavbarCompNew from "../components/shared/NavbarCompNew.vue";
import AuthFooter from "@/components/shared/AuthFooter.vue";

export default {
  components: { GoogleLogin, NavbarComp, NavbarCompNew, AuthFooter },
  data() {
    return {
      otp: null,
      password: null,
      email: this.$store.getters.getEmail,

      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      confirmPassword: null,

      sectionSlider: {
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    };
  },
  methods: {
    ValidateInput() {
      this.$refs.employerForm.validate().then((result) => {
        if (result) {
          if (this.email) {
            UserService.resetPassword({
              otp: this.otp,
              password: this.password,
              user_email: this.email,
            })
              .then((res) => {
                if (res.data.data.status) {
                  this.$toasted.show("Password changed successfully!", {
                    duration: 6000,
                    type: "success",
                    icon: "check",
                  });
                  this.$router.push({ name: "Login" });
                } else {
                  this.$toasted.show(`${res.data.data.msg}`, {
                    duration: 6000,
                    type: "error",
                    icon: "clear",
                  });
                }
              })
              .catch((err) => {
                this.$toasted.show("Password not changed!", {
                  duration: 6000,
                  type: "error",
                  icon: "clear",
                });
              });
          } else {
            this.$toasted.show("Email id not found", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          }
          return;
        } else {
          return;
        }
      });
    },
    async onSuccessEmployee(googleUser) {
      const profile = googleUser.getBasicProfile();
      const c = await googleUser.getAuthResponse();

      const token = { token: c.id_token, role_id: 2 };
      try {
        const login = await UserService.socialLogin(token);
        if (login.data.data.role_id == 2) {
          const payload = {
            userName: login.data.data.user_username,
            userEmail: login.data.data.user_email,
            userRole: login.data.data.role_id,
            userToken: token.token,
          };

          this.$store.dispatch("onSetUserSigninValues", payload);
          this.$cookies.set("AIAPADHASHID", c.id_token);
          this.$cookies.set("role_id", login.data.data.role_id);
          this.$cookies.set("user_id", login.data.data.user_id);
          this.$cookies.set("company_id", login.data.data.company_id);
          this.$cookies.set("google_login", true);
          this.$toasted.show("Login Success", {
            duration: 6000,
            type: "success",
            icon: "check",
          });
          this.$router.push({ name: "Employer-Profile" });
        } else {
          this.$toasted.show("This email id already exists with applicant", {
            duration: 6000,
            type: "info",
            icon: "clear",
          });
        }
      } catch (error) {
        this.$toasted.show("Login Failed", { duration: 6000, type: "error", icon: "clear" });
      }
    },
    onFailureEmployee(failed) {
      this.$toasted.show("Login Failed", { duration: 6000, type: "error", icon: "clear" });
    },
    async onSuccessApplicant(googleUser) {
      const profile = googleUser.getBasicProfile();
      const c = await googleUser.getAuthResponse();

      const token = { token: c.id_token, role_id: 3 };
      try {
        const login = await UserService.socialLogin(token);
        if (login.data.data.role_id == 3) {
          const payload = {
            userName: login.data.data.user_username,
            userEmail: login.data.data.user_email,
            userRole: login.data.data.role_id,
            userToken: token.token,
          };

          this.$store.dispatch("onSetUserSigninValues", payload);
          this.$cookies.set("AIAPADHASHID", c.id_token);
          this.$cookies.set("role_id", 3);
          this.$cookies.set("user_id", login.data.data.user_id);
          this.$cookies.set("google_login", true);
          this.$toasted.show("Login Success", {
            duration: 6000,
            type: "success",
            icon: "check",
          });
          this.$router.push({ name: "Applicant-Profile" });
        } else {
          this.$toasted.show("This email is already registered with company", {
            duration: 6000,
            type: "info",
            icon: "clear",
          });
        }
      } catch (error) {
        this.$toasted.show("Login Failed", { duration: 6000, type: "error", icon: "clear" });
      }
    },
    onFailureApplicant(failed) {
      this.$toasted.show("Login Failed", { duration: 6000, type: "error", icon: "clear" });
    },
    ValidateApplicantInput() {
      this.$refs.applicantForm.validate().then((result) => {
        if (result) {
          this.signUp(3, null);
          return;
        } else {
          return;
        }
      });
    },
    ValidatePassword() {
      let password = this.userData.user_password;
      let confirmPass = this.userData.confirmPassword;
      validate(password, "required|confirmed:@confirmation", {
        name: "Password",
        values: {
          confirmPass,
        },
      })
        .then((result) => {
          if (result.valid) {
            return true;
          } else {
            return false;
          }
        })
        .catch((err) => {
          return err.message;
        });
    },
    signUp(role, admin) {
      if (this.userData) {
        let payload = {
          admin: admin,
          role: role,
          user_username: this.userData.user_username,
          user_email: this.userData.user_email,
          referal_code: "",
          user_password: this.userData.user_password,
        };
        if (this.$route.query && this.$route.query.referal_code) {
          const referal_code = this.$route.query.referal_code;
          payload.referal_code = referal_code;
        }

        if (payload.role == 2 && payload.referal_code) {
          this.$toasted.show("Employer not allowed to use referal code");
          return;
        }

        UserService.Register(payload)
          .then((data) => {
            this.$toasted.show("Registeration Success!", {
              duration: 6000,
              type: "success",
              icon: "check",
            });
            this.$router.push({ path: "/login" });
          })
          .catch((err) => {
            this.$toasted.show("Registration Failed!", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          });
      }
    },
    singUpByReferal() { },
  },
};
</script>

<style scoped>
.main {
  background-color: #6244e5;
}

.text-main {
  font-weight: 600;
  font-size: 5vw;
  color: #ffffff;
}

.main__forgotpassword--button {
  background-color: #6244e5;
}

.mycard {
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}
</style>

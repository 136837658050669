export const numberWithCommas = (x) => {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return x;
};

export const convertTime = (time) => {
  if (time < 60) {
    return time + String(time == 1 ? " min" : " mins");
  } else if (time >= 60 && time < 1440) {
    return parseInt(time / 60) + String(parseInt(time / 60) == 1 ? " hr" : " hrs");
  } else {
    return parseInt(time / 1440) + String(parseInt(time / 1440) == 1 ? " day" : " days");
  }
};
export const convertMinsToHrsMins = (minutes) => {
  var h = Math.floor(minutes / 60);
  var m = minutes % 60;
  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  return h + ":" + m;
};

import { BASEURL } from "@/config/index";

export default {
  imageBaseURL: BASEURL,
  imageS3BaseURL: "https://img-dev.brij.tech/",

  movieImages: {
    moviesUploadThumbnailImage: "280*350",
  },
  dateFormat: "dd MMM yyyy",
  cookiekeys: {
    BRHSID: "BRHSID",
  },
};

export const SupportTeamEmail = "reach@zaajira.com";

<template>
  <b-container fluid>
    <!-- Navbar :: start -->
    <b-row v-if="!this.$cookies.get('company_id')">
      <b-col col md="12">
        <NavbarCompNew />
      </b-col>
    </b-row>
    <MainHeader v-if="this.$cookies.get('company_id')" />
    <!-- Navbar :: end -->
    <b-row class="py-5 mt-5">
      <b-col md="6" sm="12">
        <div class="p-2 my-1 d-flex justify-content-center">
          <!-- <b-img width="500" height="500" fluid
            src="https://cdn.apna.co/cloudinary/download_page_images/desktop-banner.png?w=1920&q=75"
            alt="playstore-img" /> -->
          <img fluid class="img-responsive img-size" src="@/assets/app-screenshot.png" alt="qrcode" />
        </div>
      </b-col>
      <b-col md="6" sm="12" class="py-5">
        <div class="p-2 my-1 d-flex flex-column">
          <h1 class="text-bold">Download The Zaajira App !</h1>
          <h4 class="text-bold">Search and apply for jobs within a few seconds using the app. You can download it for
            either Apple or Android Phones</h4>
          <div class="d-flex justify-content-left my-5">
            <!-- <img width="200" height="200" class="img-responsive"
              src="https://cdn.apna.co/cloudinary/download_page_images/Download-App-QR-code%402x.png?w=1920&q=75"
              alt="qrcode" /> -->
          </div>
          <div class="d-flex justify-content-left px-2">
            <div class="pr-2">
              <a href="https://play.google.com/store/apps/details?id=com.zaajira" target="_blank"
                rel="noopener noreferrer">
                <img class="img-responsive rounded" width="150" src="@/assets/google.png" alt="playstore-link" />
              </a>
            </div>
            <div class="pl-2">
              <a href="https://apps.apple.com/app/zaajira/id6449016993?platform=iphone" target="_blank"
                rel="noopener noreferrer">
                <img class="img-responsive rounded" width="150" src="@/assets/apple.png" alt="appstore-link" />
              </a>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import NavbarCompNew from "../../components/shared/NavbarCompNew.vue";
import MainHeader from "@/views/partials/MainHeader.vue";
export default {
  name: "DownloadApp",
  components: {
    NavbarCompNew,
    MainHeader
  },
  data() {
    return {};
  },
};
</script>

<style>
.img-size {
  width: 70%;
  height: 70%;
}

@media screen and (max-width: 1129px) {
  .img-size {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .img-size {
    width: 100%;
    height: 100%;
  }
}
</style>
<template>
  <div class="mb-3" style="position: relative; width:100% ;background-color: var(--ai-pplicant-logo);">
    <!-- DownloadNow :: start -->
    <!-- <b-row style="border-bottom: 1px solid #ffffff26;width: 100%;"
      class="bg-primary py-2 d-flex align-items-center d-block d-md-none">
      <b-col class="mx-auto">
        <h6 style="padding-left: 25px; text-wrap: nowrap;    font-size: clamp(.7rem, 3vw, 1rem);"
          class="text-white fw-600 fz-40 mt-2">{{ $gettext("DOWNLOAD NOW") }}</h6>
      </b-col>
      <b-col class="mx-auto">
        <a href="https://play.google.com/store/apps/details?id=com.zaajira" target="_blank" rel="noopener noreferrer">
          <img class="img-fluid" width="150" src="@/assets/google.png" alt="google-play" />
        </a>
      </b-col>
      <b-col class="mx-auto">
        <a href="https://apps.apple.com/app/zaajira/id6449016993?platform=iphone" target="_blank"
          rel="noopener noreferrer">
          <img class="img-fluid" width="150" src="@/assets/apple.png" alt="google-play" />
        </a>
      </b-col>
    </b-row> -->
    <div style="gap:1rem; padding-inline: .875rem;"
      class="d-flex align-items-center justify-content-around  py-2 d-block d-md-none">

      <h6 style="text-wrap: nowrap;    font-size: clamp(.7rem, 4vw, 1rem);" class="text-white fw-600 fz-40 mt-2">{{
        $gettext("DOWNLOAD NOW") }}</h6>
      <a href="https://play.google.com/store/apps/details?id=com.zaajira" target="_blank" rel="noopener noreferrer">
        <img class="img-fluid" width="150" src="@/assets/google.png" alt="google-play" />
      </a>
      <a href="https://apps.apple.com/app/zaajira/id6449016993?platform=iphone" target="_blank" rel="noopener noreferrer">
        <img class="img-fluid" width="150" src="@/assets/apple.png" alt="google-play" />
      </a>

    </div>
    <!-- DownloadNow :: end -->

    <div class="footer pt-4">
      <b-container fluid>
        <b-row>
          <b-col cols="12" lg="3">
            <router-link v-if="isLoggedIn" :to="{ name: 'Home-Page' }">
              <img src="../../assets/logos/Logo-Rectangle-White.svg" class="img-fluid logo-black" style="height: 45px" />
            </router-link>
            <router-link v-else to="/">
              <img src="../../assets/logos/Logo-Rectangle-White.svg" class="img-fluid logo-black" style="height: 45px" />
            </router-link>
            <p class="logo-para py-2">{{ $gettext("An_integrated_AI_talent_acquisition_platform") }}</p>
          </b-col>
          <b-col>
            <h5 class="text-dark fw-bold pb-2" style="font-size: 1.125rem;">{{ $gettext("Employer") }}</h5>
            <ul class="list-unstyled footer-link">
              <!-- <li @click="$router.push({ path: '/employer-login' })">{{ $gettext("Login_Register") }}</li>
              <li @click="$router.push({ path: '/how-it-works' })">{{ $gettext("How_it_works") }}</li>
              <li @click="$router.push({ path: '/how-it-works' })">{{ $gettext("Pricing") }}</li> -->
              <li>
                <router-link class="nav-link" to="/employer-login" style="padding:0;">
                  {{ $gettext("Login_Register") }}
                </router-link>
              </li>
              <li>
                <router-link class="nav-link" to="/how-it-works" style="padding:0;">
                  {{ $gettext("How_it_works") }}
                </router-link>
              </li>
              <li>
                <router-link class="nav-link" to="/pricing" style="padding:0;">
                  {{ $gettext("Pricing") }}
                </router-link>
              </li>
            </ul>
          </b-col>
          <b-col>
            <h5 class="text-dark fw-bold pb-2" style="font-size: 1.125rem;">{{ $gettext("Candidate") }}</h5>
            <ul v-if="!isLoggedIn" class="list-unstyled footer-link">
              <li>
                <router-link class="nav-link" to="/candidate/candidate-login" style="padding:0;">
                  {{ $gettext("Login_Register") }}
                </router-link>
              </li>
              <li>
                <router-link class="nav-link" to="/" style="padding:0;">
                  {{ $gettext("Find_Jobs") }}
                </router-link>
              </li>
              <!-- <li @click="$router.push({ path: '/download-zaajira-app' })">Download Mobile App </li> -->
            </ul>
            <ul v-else class="list-unstyled footer-link">
              <li> <router-link class="nav-link" to="/" style="padding:0;">{{
                $gettext("Find_Jobs")
              }}</router-link></li>
              <li> <router-link class="nav-link" to="/candidate/save-job" style="padding:0;">{{
                $gettext("Saved_Jobs")
              }}</router-link></li>
              <li> <router-link class="nav-link" to="/candidate/user-profile" style="padding:0;">{{
                $gettext("Profile")
              }}</router-link> </li>
              <li><router-link class="nav-link" to="/candidate/chat" style="padding:0;">{{
                $gettext("Chat")
              }}</router-link> </li>
              <li> <router-link class="nav-link" to="/candidate/settings" style="padding:0;">{{
                $gettext("Settings")
              }}</router-link> </li>
              <!-- <li @click="$router.push({ path: '/download-zaajira-app' })">Download Mobile App </li> -->
            </ul>
          </b-col>
          <b-col cols="6" lg="3">
            <!-- <h5 class="text-dark pb-2 ext-link" @click="$router.push({ path: '/support' })">{{ $gettext("Support") }}</h5>
            <h5 class="text-dark pb-2 ext-link" @click="$router.push({ path: '/terms-and-conditions' })">
              {{ $gettext("Terms_and_Conditions") }}
            </h5>
            <h5 class="text-dark pb-2 ext-link" @click="$router.push({ path: '/privacy-policy' })">
              {{ $gettext("Privacy_Policy") }}
            </h5> -->

            <h5 class="text-dark pb-2 ext-link">
              <router-link to="/support" class="nav-link" style="padding:0;">
                {{ $gettext("Support") }}
              </router-link>
            </h5>
            <h5 class="text-dark pb-2 ext-link">
              <router-link to="/terms-and-conditions" class="nav-link" style="padding:0;">
                {{ $gettext("Terms_and_Conditions") }}
              </router-link>
            </h5>
            <h5 class="text-dark pb-2 ext-link">
              <router-link to="/privacy-policy" class="nav-link" style="padding:0;">
                {{ $gettext("Privacy_Policy") }}
              </router-link>
            </h5>
          </b-col>
          <b-col class="customized-list">
            <h5 class="text-dark fw-bold pb-2" style="font-size: 1.125rem;">{{ $gettext("Contact") }}</h5>
            <ul class="list-unstyled">
              <li class="logo-para">reach@zaajira.com</li>
              <li>
                <div class="flex-between flex-wrap">
                  <div class="social">
                    <!-- <a
                      class="fa fa-facebook"
                      title=""
                      target="_blank"
                      href="https://www.facebook.com/aipplicant"
                    ></a> -->

                    <a class="fa fa-brands fa-x-twitter" title="" target="_blank" href="https://twitter.com/zaajira">
                    </a>
                    <a class="fa fa-instagram" title="" target="_blank" href="https://www.instagram.com/zaajira.tz"></a>
                    <a class="fa fa-linkedin" title="" target="_blank"
                      href="https://www.linkedin.com/company/zaajira"></a>
                  </div>
                </div>
              </li>
              <!-- <li>svgs</li> -->
            </ul>
          </b-col>

        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>


export default {
  name: "Footer",
  components: {},
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // isLoggedIn: true
      // isLoggedIn: this.$store.getters.getLoggedUser.isLoggedIn
    }
  },
  beforeMount() {
    this.isLoggedIn = this.$store.getters.getLoggedUser.isLoggedIn
  },
  created() {
    console.log('Component updated');
    this.isLoggedIn = this.$store.getters.getLoggedUser.isLoggedIn
  },
  // mounted() {
  //   this.isLoggedIn = this.$store.getters.getLoggedUser.isLoggedIn
  // },
  watch: {
    isLoggedIn: function (newItems, oldItems) {
      console.log('newItems', newItems);
      console.log('oldItems', oldItems);
      this.isLoggedIn = this.$store.getters.getLoggedUser.isLoggedIn
      // if (newItems.length !== oldItems.length) {
      //   this.changeSlick = true;
      // }
    },
  },
}
</script>

<style>
.footer {
  margin-bottom: -15px;
  padding-inline: 1rem;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 18%) !important;
}

.footer .logo-para {
  color: #000;
  opacity: .8;
  font-family: var(--font-family-sans-serif);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.footer-link li {
  color: #000;
  opacity: .5;
  font-family: var(--font-family-sans-serif);
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.footer-link li a {
  text-transform: uppercase;
}

.ext-link a,
.footer-link li a {
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  font-size: .9rem;
}

.social {
  padding-top: 10px;
}

.social [class*="fa fa-"] {
  background-color: #f36f21;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin: auto 3px;
  width: 30px;
  font-size: 15px;
  text-align: center;
}

.text-dark {
  color: #343a40 !important;
  text-transform: uppercase;
}
</style>
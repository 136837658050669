import Api from "./Api";
import { API_VERSION } from "@/config/index";

export default {
  CreateOrder(payload) {
    return Api().post(`/${API_VERSION}/webapp/createorder`, payload);
  },
  DeActivatePlan(payload) {
    return Api().post(`/${API_VERSION}/webapp/de-activate-plan`, payload);
  },
};

<template>
  <div>
    <b-container fluid class="my-5 pt-5 user-onboard">
      <h4 class="user-welcome">Welcome, User</h4>
      <div class="user-step-heading">
        <div class="user-step-left">
          <img src="@/assets/app-icons/cv.svg" class="img-fluid pr-3" alt="cv-icon" width="50px" />
          <div class="pt-2">
            <h5 class="user-step">STEP 2</h5>
            <h4 class="user-step-name">Choose your plan</h4>
          </div>
        </div>
      </div>
      <b-tabs pills align="right" class="pills-primary pricing-tabs mt-md-n5">
        <b-tab title="YEARLY">
          <b-row class="pt-5 justify-content-center">
            <b-col class="col-md-4 w-lg-2p8 col-12" v-for="sub in subscriptions" :key="sub.subs_pkg_id">
              <div class="pricing-card">
                <div class="pricing-header">
                  <h5 class="pricing-title">
                    {{ sub.subs_pkg_title }}
                  </h5>
                  <div class="pricing-status">
                    <h4 class="pricing-cost">${{ sub.subs_pkg_annually_price }}/year</h4>
                    <h6 class="pricing-plan">paid annually</h6>
                  </div>
                </div>
                <div class="pricing-content">
                  <ul class="pricing-content-list">
                    <li>{{ sub.subs_pkg_no_of_jobs }} jobs/month</li>
                    <li>{{ sub.subs_pkg_no_of_users }} users</li>
                    <li>{{ sub.subs_pkg_description }}</li>
                    <li>Unlimited Applicants</li>
                  </ul>
                </div>
                <div class="pricing-footer">
                  <button class="btn pricing-btn" @click="onSelect(sub.subs_pkg_id)">SELECT</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="MONTHLY"></b-tab>
      </b-tabs>
      <b-row class="my-5">
        <b-col class="col-md-4">
          <button class="btn btn-tip">TIPS</button>
        </b-col>
        <b-col class="col-md-4 user-step-footer">
          <button class="btn btn-previous" @click="$router.push({ name: 'Employer-onboarding-1' })">BACK</button>
          <button class="btn btn-next" @click.prevent.stop="onSubmit">SUBMIT</button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import CompanyServices from "@/apiServices/CompanyServices";

export default {
  data() {
    return {
      subscriptions: [],
      selectedSub: null,
    };
  },
  beforeMount() {
    this.getAllSubscriptions();
  },
  methods: {
    getAllSubscriptions() {
      CompanyServices.getSubscription().then((res) => {
        console.log(res);
        res.data.data.data.forEach((s) => {
          if (s.subs_pkg_type === "Employer") {
            this.subscriptions.push(s);
            console.log("this.subscriptions :>> ", this.subscriptions);
          }
        });
      });
    },
    onSelect(id) {
      this.selectedSub = id;
      console.log("id :>> ", id);
    },
    getEmployerDetails() {
      const payload = { company_id: this.$cookies.get("company_id") };
      console.log("payload.company_id :>> ", payload.company_id);
      CompanyServices.GetSingleCompany(payload).then((result) => {
        this.profile = { ...result.data.data[0] };
        
        const pay = { image: this.profile.company_logo_path };
        this.$store.dispatch("onSetProfileImage", pay);
      });
    },

    async onSubmit() {
      const payload = this.$store.getters.getCompanyProfile;
      const postData = new FormData();
      const company_id = this.$cookies.get("company_id");
      console.log("company_id :>> ", company_id);
      //console.log("payload :>> ", Object.keys(payload.social_link));

      if (payload.image) {
        postData.append("image", payload.image);
        postData.append("industry_id", payload.industry_id);
        postData.append("admin_name", payload.admin_name);
        postData.append("admin_email", payload.admin_email);
        postData.append("company_profile", payload.company_profile);
        postData.append("employer_size", payload.employer_size);
        postData.append("social_link", JSON.stringify(payload.social_link));
        postData.append("company_official_phone", payload.company_official_phone);
        postData.append("company_official_email", payload.company_official_email);
        postData.append("company_website_url", payload.company_website_url);

        postData.append("company_office_address", payload.company_office_address);
      } else {
        postData.append("industry_id", payload.industry_id);
        postData.append("admin_name", payload.admin_name);
        postData.append("admin_email", payload.admin_email);
        postData.append("company_profile", payload.company_profile);
        postData.append("employer_size", payload.employer_size);
        postData.append("social_link", JSON.stringify(payload.social_link));
        postData.append("company_official_phone", payload.company_official_phone);
        postData.append("company_official_email", payload.company_official_email);
        postData.append("company_website_url", payload.company_website_url);

        postData.append("company_office_address", payload.company_office_address);
      }

      try {
        const updateCompany = await CompanyServices.UpdateCompany(postData, company_id);

        const payload = {
          user_id: this.$cookies.get("user_id"),
          subs_pkg_id: this.selectedSub,
        };
        
        this.getEmployerDetails();
        const updateSubs = await CompanyServices.updateUserSubscription(payload);

        this.$toasted.show("Company and Subscription successfully added", {
          position: "top-right",
          duration: 6000,
          icon: "check",
        });
        this.$router.push({ name: "Employer-onboarding-3" });
      } catch (error) {
        
      }
    },
  },
};
</script>
<style scoped></style>

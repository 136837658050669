import Api from "./Api";
import { API_VERSION } from "@/config/index";

export default {
  AddAlertSetting(payload) {
    return Api().post(`/${API_VERSION}/webapp/alert`, payload);
  },
  GetAlertSetting(payload) {
    return Api().get(`/${API_VERSION}/webapp/alert`);
  },
  UpdateAlertSetting(payload) {
    return Api().put(`/${API_VERSION}/webapp/alert`, payload);
  },
};

<template>
  <div class="">
    <b-container fluid class="auth-page">
      <!-- navbar :: start -->
      <b-row>
        <b-col col md="12">
          <NavbarCompNew />
        </b-col>
      </b-row>
      <!-- navbar :: end -->
      <!-- container :: start -->
      <b-row class="main pt-6 py-0">
        <!-- left :: start-->
        <b-col cols="12" md="8" class="mx-auto main__left p-5 d-flex align-items-center justify-content-start">
          <div>
            <h2 class="text-main my-auto">
              Connect with the best <br />
              candidates directly, <br />
              with ease!
            </h2>
          </div>
        </b-col>
        <!-- left :: end -->
        <!-- register :: start -->
        <b-col cols="12" md="4" class="mx-auto main__right p-4" v-show="!showOtp">
          <div class="mycard main__login p-4 mx-auto">
            <h5 class="heading">REGISTER</h5>

            <ValidationObserver ref="employerForm">
              <b-form class="customized-form mt-4">
                <ValidationProvider name="userName" rules="required" v-slot="{ errors, classes }">
                  <b-form-input :class="classes" placeholder="Company Name" v-model="userData.user_username" type="text"
                    description="We will convert your text to lowercase instantly"> </b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="user_phoneOrEmail" rules="required|phoneOrEmail" v-slot="{ errors, classes }">
                  <b-form-input :class="classes" placeholder="E-mail or Phone" v-model="userData.user_phoneOrEmail">
                  </b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="password" rules="required|max:15|min:8" vid="confirmation"
                  v-slot="{ errors, classes }">
                  <b-form-input :class="classes" placeholder="Password" type="password" v-model="userData.user_password">
                  </b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ errors, classes }">
                  <b-form-input :class="classes" placeholder="Confirm Password" type="password" v-model="confirmPassword">
                  </b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <div class="d-flex justify-content-center">
                  <vue-recaptcha :sitekey="reCaptchaSiteKey" @verify="captchaVerify" @expired="captchaExpired"
                    @error="captchaError" ref="recaptcha-1"></vue-recaptcha>
                </div>
                <b-button block class="main__login--button px-2 py-3 mt-4 mx-auto" @click.prevent.stop="ValidateInput">
                  REGISTER </b-button>
              </b-form>
            </ValidationObserver>
            <!-- <h5 class="heading my-3" v-show="!this.$store.getters.getName">
                  OR
                </h5> -->
            <!-- <div class="flex-between">
                  <button class="btn btn-social btn-linkedin">
                    <i class="fa fa-linkedin" aria-hidden="true"></i> Sign in
                    with Linkedin
                  </button>
                  <button class="btn btn-social btn-google">
                    <i class="fa fa-google" aria-hidden="true"></i> Sign in with
                    Google
                  </button>
                </div> -->
            <b-row>
              <!-- <b-col class="col-12 col-lg-6 mb-3 mb-lg-0 pr-lg-2">
                    <button class="btn btn-social btn-linkedin w-100">
                      <i
                        class="fa fa-linkedin border-right float-left pr-1"
                        aria-hidden="true"
                      ></i>
                      Sign in with Linkedin
                    </button>
                  </b-col> -->
              <b-col class="mx-auto col-12 col-lg-6 mb-3 mb-lg-0 pl-lg-2">
                <!-- <GoogleLogin
                      v-show="!this.$store.getters.getName"
                      class="btn btn-social btn-google w-100"
                      :params="params"
                      :onSuccess="onSuccessEmployee"
                      :onFailure="onFailureEmployee"
                      ><i
                        class="fa fa-google border-right float-left pr-1"
                        aria-hidden="true"
                      ></i>
                      Sign in with Google</GoogleLogin
                    > -->
              </b-col>
            </b-row>
            <div class="border-top py-3 mt-4">
              <h6 class="f-light text-center">Already have an account?</h6>
              <h6 class="link-color text-center text-color font-weight-bold py-3"
                @click="$router.push({ path: '/login' })">Log In</h6>
            </div>
          </div>
        </b-col>
        <!-- register :: end -->
        <!-- otp :: start -->
        <b-col cols="12" md="4" class="mx-auto main__right p-4" v-show="showOtp">
          <div class="mycard main__login p-4 mx-auto">
            <h5 class="heading py-3">Thank you for registering</h5>
            <p class="mb-2 py-3" v-if="dynamicValue && !otp">
              We have sent you an email with an OTP, kindly enter the number below to complete your registration process.
              <br />
              <strong>Please check your notification/newsletter/junk or spam folders, since the email might sometimes land
                in these folders.</strong>
            </p>
            <p class="mb-2 py-3" v-if="!dynamicValue && !otp">We have sent you an OTP to the registered phone number,
              kindly enter the number below to complete your registration process.</p>
            <p v-if="!otp">If you haven’t received the OTP, please click the button below to resend it to you</p>
            <b-button block disabled size="lg">Enter OTP</b-button>
            <ValidationObserver ref="otpForm">
              <b-form class="customized-form mt-4">
                <ValidationProvider name="userOtp" rules="required" v-slot="{ errors, classes }">
                  <b-form-input type="text" placeholder="Enter OTP" v-model="otp" :class="classes"> </b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <div class="d-flex justify-content-center">
                  <b-button :id="otp" size="sm" class="main__login--button p-3 mx-auto"
                    @click.prevent.stop="SubmitOtp">Submit
                    OTP</b-button>
                  <b-button v-if="!otp" size="sm" :disabled="disableResend" class="main__login--button p-3 mx-auto"
                    @click.prevent.stop="resendOtp">Resend&nbsp;{{ disableResend ? `in ${countDown}` : "" }}</b-button>
                </div>
              </b-form>
            </ValidationObserver>
          </div>
        </b-col>
        <!-- otp :: end -->
      </b-row>
      <!-- container :: end -->
      <!-- footer :: start -->
      <b-row>
        <AuthFooter />
      </b-row>
      <!-- footer :: end -->
    </b-container>
  </div>
</template>
<script>
import UserService from "@/apiServices/UserService";
import { validate } from "vee-validate";
import GoogleLogin from "vue-google-login";
import { socialLoginClientId, reCaptchaSiteKey } from "@/config";
import CompanyServices from "@/apiServices/CompanyServices";

import VueRecaptcha from "vue-recaptcha";
import NavbarComp from "../components/shared/NavbarComp.vue";
import NavbarCompNew from "../components/shared/NavbarCompNew.vue";
import AuthFooter from "../components/shared/AuthFooter.vue";

export default {
  name: "Register",
  components: { GoogleLogin, VueRecaptcha, NavbarComp, NavbarCompNew, AuthFooter },
  data() {
    return {
      countDown: 120,
      disableResend: true,
      captchaValue: null,
      reCaptchaSiteKey: reCaptchaSiteKey,
      params: {
        client_id: socialLoginClientId,
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      role: null,
      confirmPassword: null,
      referal_code: null,
      userData: {
        user_username: null,
        user_phoneOrEmail: null,
        user_password: null,
      },
      sectionSlider: {
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      openTab: null,
      otp: null,
      showOtp: false,
      dynamicValue: false,
    };
  },
  beforeMount() {
    // console.log(
    //   "this.$store.getters.getEmail :>> ",
    //   this.$store.getters.getEmail
    // );
    // console.log(
    //   "this.$store.getters.getName :>> ",
    //   this.$store.getters.getName
    // );
    console.log("this.$route.query.page", this.$route.query);
    const { role, user_email } = this.$route.query;
    let payload = {};
    payload.user_email = user_email;
    payload.role = role;

    UserService.verifyByLink(payload)
      .then((res) => {
        console.log("verifyByLink", res.data.data);
        if (res.data.data.otp) {
          this.otp = res.data.data.otp;
          this.userData.user_username = res.data.data.user_username;
          this.userData.user_phoneOrEmail = res.data.data.user_email;
          this.userData.user_password = res.data.data.user_password;
          // Init loader
          let loader = this.$loading.show();

          // lets check how the payload looks before registering
          console.log("Payload to verify-by-link: ", res);

          //
          if (res.data.status) {
            this.showOtp = true;
            this.countDownTimer();
          } else {
            this.$refs["recaptcha-1"] && this.$refs["recaptcha-1"].reset();
          }
          console.log(`${res.data.data}`);
          // this.$toasted.show(`${res.data.data}`, { duration: 6000 });
          loader.hide();
          //
        }
      })
      .catch((err) => {
        console.log("[Backend] Verify email error: ", err);
        loader.hide();
        this.$toasted.show("Server Error", {
          duration: 6000,
          icon: "clear",
          type: "error",
        });
      });
    this.userData.user_username = this.$store.getters.getName;
    this.userData.user_phoneOrEmail = this.$store.getters.getEmail;
  },
  // watch: {
  //   tab: function() {
  //     if (tab == 0) {
  //       this.role = 2;
  //     } else {
  //       this.role = 3;
  //     }
  //   },
  // },
  methods: {
    captchaVerify(event) {
      console.log(`verify event`, event);
      this.captchaValue = event;
    },
    captchaExpired() {
      this.captchaValue = null;
    },
    captchaError() {
      this.captchaValue = null;
    },
    ValidateInput() {
      this.$refs.employerForm.validate().then((result) => {
        console.log("aman");
        console.log(result);
        if (result) {
          if (this.captchaValue) {
            this.signUp(2, "Y");
          } else {
            this.$toasted.show("Please verify captcha", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          }
          return;
        } else {
          return;
        }
      });
    },
    async onSuccessEmployee(googleUser) {
      console.log(googleUser);

      const profile = googleUser.getBasicProfile();
      const c = await googleUser.getAuthResponse();
      // This only gets the user information: id, name, imageUrl and email
      console.log(googleUser.getBasicProfile());
      console.log("profile.getId() :>> ", profile.getId());
      console.log("profile.getName :>> ", profile.getName());
      console.log("profile.getEmail() :>> ", profile.getEmail());
      console.log("c :>> ", c);
      const user = await UserService.getUser({
        email: profile.getEmail(),
      });
      if (user.data.data.length > 0) {
        const token = { token: c.id_token, role_id: 2 };
        try {
          const login = await UserService.socialLogin(token);
          if (login.data.data.role_id == 2) {
            console.log("login :>> ", login.data.data);
            // const payload = {
            //   userName: login.data.data.user_username,
            //   userEmail: login.data.data.user_email,
            //   userRole: login.data.data.role_id,
            //   userToken: token.token,
            // };
            //
            let company_id = login.data.data.company_id;
            const company = await CompanyServices.GetSingleCompany({
              company_id,
            });
            console.log("company.data.data :>> ", company.data.data);
            if (company.data.data.length > 0) {
              const p = {
                userName: company.data.data[0].company_long_name,
                userEmail: login.data.data.user_email,
                userRole: login.data.data.role_id,
                userToken: c.id_token,
              };
              this.$store.dispatch("onSetUserSigninValues", p);
            }

            // this.$store.dispatch("onSetUserSigninValues", payload);
            this.$cookies.set("AIAPADHASHID", c.id_token);
            this.$cookies.set("role_id", login.data.data.role_id);
            this.$cookies.set("user_id", login.data.data.user_id);
            this.$cookies.set("company_id", login.data.data.company_id);
            this.$cookies.set("google_login", true);
            this.$toasted.show("Login Success", {
              duration: 6000,
              type: "success",
              icon: "check",
            });
            this.$router.push({ name: "Employer-Profile" });
          } else {
            this.$toasted.show("This email id already exists with applicant", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          }
        } catch (error) {
          this.$toasted.show("Login Failed", {
            duration: 6000,
            type: "error",
            icon: "clear",
          });
        }
      } else {
        this.userData.user_username = profile.getName();
        this.userData.user_phoneOrEmail = profile.getEmail();
      }
    },
    onFailureEmployee(failed) {
      console.log("failed :>> ", failed);
      this.$toasted.show("Login Failed", {
        duration: 6000,
        type: "error",
        icon: "clear",
      });
    },
    async onSuccessApplicant(googleUser) {
      console.log("google user: ", googleUser);

      const profile = googleUser.getBasicProfile();
      const c = await googleUser.getAuthResponse();
      // This only gets the user information: id, name, imageUrl and email
      console.log(googleUser.getBasicProfile());
      console.log("profile.getId() :>> ", profile.getId());
      console.log("profile.getName :>> ", profile.getName());
      console.log("profile.getEmail() :>> ", profile.getEmail());
      console.log("c :>> ", c);
      const user = await UserService.getUser({
        email: profile.getEmail(),
      });
      if (user.data.data.length > 0) {
        const token = { token: c.id_token, role_id: 3 };
        try {
          const login = await UserService.socialLogin(token);
          if (login.data.data.role_id == 3) {
            console.log("login :>> ", login.data.data);
            const payload = {
              userName: login.data.data.user_username,
              userEmail: login.data.data.user_email,
              userRole: login.data.data.role_id,
              userToken: token.token,
            };

            this.$store.dispatch("onSetUserSigninValues", payload);
            this.$cookies.set("AIAPADHASHID", c.id_token);
            this.$cookies.set("role_id", 3);
            this.$cookies.set("user_id", login.data.data.user_id);
            this.$cookies.set("google_login", true);
            this.$toasted.show("Login Success", {
              duration: 6000,
              type: "success",
              icon: "check",
            });
            this.$router.push({ name: "Applicant-Profile" });
          } else {
            this.$toasted.show("This email is already registered with company", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          }
        } catch (error) {
          this.$toasted.show("Login Failed", {
            duration: 6000,
            type: "error",
            icon: "clear",
          });
        }
      } else {
        this.userData.user_username = profile.getName();
        this.userData.user_phoneOrEmail = profile.getEmail();
      }
    },
    onFailureApplicant(failed) {
      console.log("failed :>> ", failed);
      this.$toasted.show("Login Failed", {
        duration: 6000,
        type: "error",
        icon: "clear",
      });
    },
    ValidateApplicantInput() {
      this.$refs.applicantForm.validate().then((result) => {
        if (result) {
          // if (this.captchaValue) {
          //   this.signUp(3, null);
          // } else {
          //   this.$toasted.show("Please verify captcha", { duration: 6000 });
          // }
          this.signUp(3, null);
          return;
        } else {
          return;
        }
      });
    },
    ValidatePassword() {
      let password = this.userData.user_password;
      let confirmPass = this.userData.confirmPassword;
      validate(password, "required|confirmed:@confirmation", {
        name: "Password",
        values: {
          confirmPass,
        },
      })
        .then((result) => {
          if (result.valid) {
            console.log("succes :>> ", this.submitted);
            return true;
          } else {
            console.log("this.submitted :>> ", this.submitted);
            return false;
          }
        })
        .catch((err) => { });
    },
    SubmitOtp() {
      let payload = {};

      const isForEmail = (txt) => {
        return /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(txt);
      };
      if (isForEmail(this.userData.user_phoneOrEmail)) {
        payload = {
          // admin: this.openTab === 0 ? "Y" : null,
          // role: this.openTab === 0 ? 2 : 3,
          admin: "Y",
          role: 2,
          user_username: this.userData.user_username,
          user_email: this.userData.user_phoneOrEmail,
          otp: +this.otp,
          referal_code: "",
          user_password: this.userData.user_password,
        };
      } else {
        payload = {
          // admin: this.openTab === 0 ? "Y" : null,
          // role: this.openTab === 0 ? 2 : 3,
          admin: "Y",
          role: 2,
          user_username: this.userData.user_username,
          user_mobile_number: this.userData.user_phoneOrEmail,
          otp: +this.otp,
          referal_code: "",
          user_password: this.userData.user_password,
        };
      }

      if (this.$route.query && this.$route.query.referal_code) {
        payload.referal_code = this.$route.query.referal_code;
      }

      // if (payload.role == 2 && payload.referal_code) {
      //   this.$toasted.show("Employer not allowed to use referal code");
      //   return;
      // }

      // Call Register Service
      UserService.Register(payload)
        .then((res) => {
          if (res.data.status !== true) {
            this.$toasted.show(`${res.data.data}`, {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          } else {
            this.$toasted.show(`${res.data.data}`, {
              duration: 6000,
              type: "success",
              icon: "check",
            });
            this.$router.push({ path: "/login" });
          }
        })
        .catch((err) => {
          this.$toasted.show("Registration Failed!", {
            duration: 6000,
            type: "error",
            icon: "clear",
          });
        });
    },
    signUp(role, admin) {
      if (this.userData) {
        // logic to verify email or phone
        let payload = {};

        const isItEmail = (txt) => {
          return /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(txt);
        };

        if (isItEmail(this.userData.user_phoneOrEmail)) {
          this.dynamicValue = true;
          payload = {
            admin: admin,
            role: role,
            user_username: this.userData.user_username,
            user_email: this.userData.user_phoneOrEmail,
            referal_code: "",
            user_password: this.userData.user_password,
            captcha: this.captchaValue,
          };
        } else {
          this.dynamicValue = false;
          payload = {
            admin: admin,
            role: role,
            user_username: this.userData.user_username,
            user_mobile_number: this.userData.user_phoneOrEmail,
            referal_code: "",
            user_password: this.userData.user_password,
            captcha: this.captchaValue,
          };
        }

        // append referal code and role with 2
        if (this.$route.query && this.$route.query.referal_code) {
          payload.referal_code = this.$route.query.referal_code;
          payload.role = 2;
        }

        // If role is 2 with referal code
        // if (payload.role == 2 && payload.referal_code) {
        //   this.$toasted.show("Employer not allowed to use referal code");
        //   return;
        // }

        // Init loader
        let loader = this.$loading.show();

        // lets check how the payload looks before registering
        console.log("Payload to verify email : ", payload);

        // Call Verify Email Service
        UserService.verifyEmail(payload)
          .then((res) => {
            console.log("Verify email response: ", res);
            if (res.data.status) {
              this.showOtp = true;
              this.countDownTimer();
            } else {
              this.$refs["recaptcha-1"] && this.$refs["recaptcha-1"].reset();
            }
            this.$toasted.show(`${res.data.data}`, { duration: 6000 });
            loader.hide();
          })
          .catch((err) => {
            console.log("[Backend] Verify email error: ", err);
            loader.hide();
            this.$toasted.show("Server Error", {
              duration: 6000,
              icon: "clear",
              type: "error",
            });
          });
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.disableResend = false;
      }
    },
    resendOtp() {
      console.log("resend otp called");
      let payload = {};

      const isItEmail = (txt) => {
        return /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(txt);
      };

      if (isItEmail(this.userData.user_phoneOrEmail)) {
        payload = {
          user_username: this.userData.user_username,
          user_email: this.userData.user_phoneOrEmail,
        };
      } else {
        payload = {
          user_username: this.userData.user_username,
          user_mobile_number: this.userData.user_phoneOrEmail,
        };
      }

      let loader = this.$loading.show();

      UserService.verifyEmail(payload)
        .then((res) => {
          console.log("Resend OTP response:", res);
          if (res.data.status) {
            // this.showOtp = true;
            this.disableResend = true;
            this.countDown = 120;
            this.countDownTimer();
          }
          this.$toasted.show(`${res.data.data}`, { duration: 6000 });
          loader.hide();
        })
        .catch((err) => {
          console.log("err.data :>> ", err.data);
          loader.hide();
          this.$toasted.show("Server Error", {
            duration: 6000,
            icon: "clear",
            type: "error",
          });
        });
    },
    singUpByReferal() { },
  },
};
</script>
<style scoped>
.main {
  background-color: #6244e5;
}

.text-main {
  color: rgba(255, 255, 255, 1);
  font-size: clamp(2rem, 5vw, 3.5rem);
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.main__login--button {
  background-color: #6244e5;
}

.mycard {
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}

.link-color {
  color: #6244e5;
  cursor: pointer;
  font-size: 14px;
}
</style>

import Api from "./Api";
import { API_VERSION } from "@/config/index";

export default {
  Login(payload) {
    return Api().post(`/${API_VERSION}/webapp/login`, payload);
  },
  generateOTP(payload) {
    return Api().post(`/${API_VERSION}/webapp/generate-otp`, payload);
  },
  verifyOTP(payload) {
    return Api().post(`/${API_VERSION}/webapp/verify-otp`, payload);
  },

  Register(payload) {
    return Api().post(`/${API_VERSION}/webapp/register?referal_code=${payload.referal_code}`, payload);
  },
  createEmployee(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/createEmployee`, payload);
  },
  employeeLogin(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/employeeLogin`, payload);
  },
  updatePassword(payload) {
    return Api().post(`/${API_VERSION}/webapp/update-user`, payload);
  },

  formSubmit(payload) {
    return Api().post(`/${API_VERSION}/webapp/form-submit`, payload);
  },
  SetLoginFlag() {
    return Api().patch(`/${API_VERSION}/webapp/setloginflag`);
  },
  socialLogin(payload) {
    return Api().post(`/${API_VERSION}/webapp/social-login`, payload);
  },
  getRegisteredUser(payload) {
    return Api().get(`/${API_VERSION}/webapp/get-registered-user`);
  },
  getUser(payload) {
    return Api().get(`/${API_VERSION}/webapp/get-user?email=${payload.email}`);
  },
  getUserByUserID(payload) {
    return Api().get(`/${API_VERSION}/webapp/get-user/${payload}`);
  },
  forgotPassword(payload) {
    return Api().post(`/${API_VERSION}/webapp/forgot-password`, payload);
  },
  resetPassword(payload) {
    return Api().post(`/${API_VERSION}/webapp/reset-password`, payload);
  },
  verifyEmail(payload) {
    return Api().post(`/${API_VERSION}/webapp/verify-email?referal_code=${payload.referal_code}`, payload);
  },
  verifyByLink(payload) {
    return Api().post(`/${API_VERSION}/webapp/varify-by-link?referal_code=${payload.referal_code}`, payload);
  },
  resetInvalidPassAttempts() {
    return Api().post(`/${API_VERSION}/webapp/reset-invalid_pass_attempt`);
  },
  supportRequest(payload) {
    return Api().post(`/${API_VERSION}/webapp/support-ticket`, payload);
  },
  contactUs(payload) {
    return Api().post(`/${API_VERSION}/webapp/contact-us`, payload);
  },
  // user's api
  uploadProfileImage(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/uload-profile-image`, payload);
  },

  uploadCertificate(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/upload-certificate`, payload);
  },
  getCertificate(user_id) {
    return Api().get(`/${API_VERSION}/webapp/applicant/get-certificate/${user_id}`);
  },
  updateUser(payload) {
    return Api().post(`/${API_VERSION}/webapp/update-user`, payload);
  },
  // get selected user's Indusry
  GetselectedIndustry(payload) {
    //  user_id=payload
    return Api().get(`/${API_VERSION}/webapp/applicant/get-industry-types/${payload}`);
  },
  // get selected user's skills
  GetSelectedSkills(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/get-job-types/${payload}`);
  },
  // Get all jobs and filter(country,city,industry) jobs api
  GetAllJobsOrFilter(payload) {
    // /api/v1.0/webapp/applicant/search-job/null/null?currentPage=1&perPage=10000 200 40.168 ms - 2319
    return Api().post(`/${API_VERSION}/webapp/applicant/search-job/${payload.role_id}/${payload.user_id}?currentPage=${payload.currentpage}&perPage=${payload.perPage}&search=${payload.search}&sort=${payload.sort ? payload.sort : ''}`, payload.filter);
  },
  // get All saved job
  GetAllSavedJobs(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/bookmark`);
  },
  // get All saved job
  SaveJob(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/bookmark`, payload);
  },
  // get job by id
  GetJobsByID(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/job/${payload.job_id}/${payload.user_id}/${payload.role_id}`);
  },

  // get applied job
  GetAppliedJobs(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/get-apply-job?currentpage=${payload.currentpage}&perpage=${payload.perpage}`, { user_id: payload.user_id });
  },
  // get search job
  GetSearchJobs(payload) {
    //  payload= { 
    //     "showSizeChanger": true, 
    //     "current": 1, 
    //     "pageSize": 10, 
    //     "total": 10, 
    //     "city_id": null, 
    //     "feature": "Y", 
    //     "user_id": user_id.toString(), 
    //     "role_id": role_id.toString() 

    //   }
    return Api().post(`/${API_VERSION}/webapp/applicant/get-apply-job?currentpage=${payload.currentpage}&perpage=${payload.perpage}&search=${payload.search}`, payload);
  },
  // get search job
  GetInterviewQuestion(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/job-application/${payload}`,);
  },
  // get search job
  AnswerInterview(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/job-answer`, payload);
  },
  getScore(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/get-iv-score`, payload);
  },
  // Delete User Account
  DeleteUserAccount() {
    return Api().get(`/${API_VERSION}/webapp/delete-user`);
  },
  // Delete User Account
  Support(payload) {
    return Api().post(`/${API_VERSION}/webapp/contact-us`, payload);
  },
  //get Chat list
  GetChatList() {
    return Api().get(`/${API_VERSION}/webapp/get-employer-list`);
  },
  //get chat Messages
  GetChatMessages(payload) {
    console.log(payload);
    return Api().get(`/${API_VERSION}/webapp/get-message/${payload}`,);
  },
  //Send chat Messages
  SendChatMessages(payload) {
    console.log(payload);
    return Api().post(`/${API_VERSION}/webapp/send-message`, payload);
  },
  //Send chat Messages
  SendDoc(payload, config) {
    console.log(payload);
    return Api().post(`/${API_VERSION}/webapp/applicant/upload-doc`, payload, config);
  },


};

<template>
  <div>
    <div v-if="isNeedTopSpace" class="section-space-50"></div>
    <section :class="['section-empty-state ', isClass && isClass.length > 0 ? isClass : '']">
      <div class="text-muted text-center">
        <img height="100" width="100" class="d-table mx-auto mb-3 opacity-50" :src="emptyImage" />
        <p v-html="emptyMessage"></p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    isClass: {
      type: String,
      default: "",
    },
    emptyMessage: {
      type: String,
      default: "",
    },
    isNeedTopSpace: {
      type: Boolean,
      default: false,
    },
    emptyImage: {
      type: String,
      default: require("@/assets/logos/popcorn.svg"),
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style>
</style>
<template>
  <div class="bg-grey pt-1 mt-1">
    <b-container fluid class="py-3 mt-5 job-listings">

      <b-row class="bg-primary for-desktop" style="position: relative; top: -18px;">
        <!-- tabs :: start -->
        <b-col md="12" class="py-5 bg-primary mx-auto">
          <div class="d-flex flex-wrap justify-content-around pt-5 my-5">
            <!-- basicDetails :: start -->
            <div class="myTab mt-3 d-flex justify-content-left">
              <div class="myTabText p-4 w-25 px-auto text-center font-weight-bold">1</div>
              <span class="text-muted font-weight-bold my-auto mx-auto">{{ $gettext("Basic_details") }}</span>
            </div>
            <!-- basicDetails :: end -->

            <!-- candidateDetails :: start -->
            <div class="myTab mt-3 d-flex justify-content-left">
              <div class="myTabText p-4 w-25 px-auto text-center font-weight-bold">2</div>
              <span class="text-muted font-weight-bold my-auto mx-auto">{{ $gettext("Candidate_details") }}</span>
            </div>
            <!-- candidateDetails :: end -->

            <!-- interviewQuestions :: start -->
            <div class="myTab mt-3 d-flex justify-content-left">
              <div class="myTabText p-4 w-25 px-auto text-center font-weight-bold">3</div>
              <span class="text-white font-weight-bold my-auto mx-auto">{{ $gettext("Interview_Questions") }}</span>
            </div>
            <!-- interviewQuestions :: end -->

            <!-- reviewSuggestions :: start -->
            <div class="myTab mt-3 d-flex justify-content-left">
              <div class="myTabText p-4 w-25 px-auto text-center font-weight-bold">4</div>
              <span class="text-muted font-weight-bold my-auto mx-auto">{{ $gettext("Review_Suggestions") }}</span>
            </div>
            <!-- reviewSuggestions :: end -->
          </div>
        </b-col>
        <!-- tabs :: end -->
      </b-row>

      <b-row class="pt-4 bg-primary for-mob" style="position: relative;top: -21px;">
        <b-col md="12" class="py-5 bg-primary mx-auto">

          <div class="">
            <div class="BasicDetails" style="margin-bottom: 20px;
            text-align: center; 
            color: white; 
            font-size: 18px; 
            font-weight: 700; 
            word-wrap: break-word">
              {{ isVIPage ? `3.Interview Questions` : `` }}
            </div>
            <div class="d-flex flex-wrap justify-content-around">
              <div className="Rectangle24" :style="isVIPage ? 'opacity: 1.0' : 'opacity: 0.5'"
                style="width: 84px;height: 6px;background: white;border-radius: 7px;"></div>
              <div className="Rectangle24" :style="isVIPage ? 'opacity: 1.0' : 'opacity: 0.5'"
                style="width: 84px;height: 6px;background: white;border-radius: 7px;opacity: 0.50"></div>
              <div className="Rectangle24" :style="isVIPage ? 'opacity: 1.0' : 'opacity: 0.5'"
                style="width: 84px;height: 6px;background: white;border-radius: 7px;opacity: 0.50"></div>
              <div className="Rectangle24"
                style="width: 84px;height: 6px;background: white;border-radius: 7px;opacity: 0.50"></div>
            </div>
          </div>
        </b-col>
      </b-row>


      <div class="container-fluid profile-user job-create customized-form px-1 px-md-3 pt-4 pb-3">
        <div class="row">
          <div class="col-md-12">
            <!-- responsibilities :: start -->
            <div>
              <h4 class="heading">{{ $gettext("Responsibilities") }}</h4>
              <div class="box pb-1 pt-4 px-3 mt-3">
                <div class="question-name" v-for="(res, index) in responsibilities" :key="index">
                  <div class="left">
                    <b-form-input class="form-control" v-model="responsibilities[index].responsibility"
                      :placeholder="$gettext('Add_Responsibilities')"> </b-form-input>
                  </div>
                  <div style="text-align: right;">
                    <button class="btn btn-add" @click="removeResponsibility(index)">
                      <img src="@/assets/app-icons/delete.svg" width="25px" />
                    </button>
                    <button class="btn btn-add ml-5" v-show="index == responsibilities.length - 1"
                      @click="addResponsibility(index)">+</button>
                  </div>
                </div>
                <br />
                <div v-if="submitted && !responsibilities.some((m) => m != '')" class="validate-error">
                  <span> {{ "This field is required" }}</span>
                </div>
              </div>
            </div>
            <!-- responsibilities :: end -->

            <!-- education :: start -->
            <!-- <div class="mt-3 mt-md-5">
            <h4 class="heading">Education</h4>
            <div class="box pt-3 px-3 mt-3">
              <div class="question-name" v-for="(e, index) in education" :key="index">
                <div class="left">
                  <b-form-input class="form-control" v-model="education[index].job_education" placeholder="Add Education..."> </b-form-input>

                  <div v-if="submitted && String(e).length == 0" class="validate-error">
                    <span>{{ "This field is required" }}</span>
                  </div>
                </div>
                <div>
                  <button class="btn btn-add" @click="removeEdu(index)">
                    <img src="@/assets/app-icons/delete.svg" width="25px" />
                  </button>
                  <button class="btn btn-add ml-5" v-show="index == education.length - 1" @click="addEdu(index)">+</button>
                </div>
              </div>
            </div>
          </div> -->
            <!-- education :: end -->
            <div class="my-3">
              <button type="button" @click="handleAddQuestions()" class="btn btn-primary btn-lg my-col-6 col-3 py-3">{{
                $gettext("Add_Questions") }}</button>
            </div>
            <div class="my-3" style="display: flex;flex-direction: row;justify-content: space-evenly;">
              <button type="button" @click="toggleVI()" class="for-mob vi-btn">
                {{ $gettext("Suggested_Virtual_Interview_Questions") }}
              </button>
            </div>


            <!-- gridBox :: start -->
            <div class="mt-3 mt-md-5 my-grid-box">
              <!-- VirtualInterview :: start -->

              <div>
                <div class="grid-box">
                  <h4 class="heading">{{ $gettext("Virtual_Interview") }}</h4>
                </div>
                <span><small>(Minimum 3 questions, maximum 10. The
                    score should total to 100 for all questions)</small></span>
                <!-- old -->
                <!-- <div class="box pt-3 px-3 mt-3" v-for="(que, index) in q" :key="index">
                <div>
                  <div class="question-name">
                    <div class="left">
                      <ValidationProvider>
                        <b-form-input class="form-control" :readonly="q[index].universal_question_id ? true : false" v-model="q[index].que" :placeholder="selectedQueType[index] === 'Multiple Choice' ? 'Q1. Sample MCQ Question goes here?' : 'Q1. Sample Text Based Question goes here?'">
                        </b-form-input>
                        <div v-if="submitted && (!q[index].que || String(q[index].que).length == 0)" class="validate-error">
                          <span>{{ "This field is required" }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="right ml-auto">
                      <a @click="deleteQue(index)" style="cursor: pointer">
                        <img src="@/assets/app-icons/delete.svg" width="25px" />
                      </a>
                    </div>
                  </div>

                  <div class="question-input">
                    <div class="question-input-inner" v-for="(opt, i) in que.options" :key="i">
                      <div class="left">
                        <b-form-input :readonly="q[index].options[i].universal_question_option_id ? true : false" class="form-control" v-model="q[index].options[i].option" :placeholder="'Enter Option ' + (i + 1)"></b-form-input>
                        <div v-if="submitted && (!q[index].options[i].option || String(q[index].options[i].option).length == 0)" class="validate-error">
                          <span>{{ "This field is required" }}</span>
                        </div>
                      </div>
                      <div class="right d-flex">
                        <b-form-checkbox class="radio-square text-dark" v-model="q[index].options[i].status" @input="correctAns(index, i)" :unchecked-value="false">Mark As Correct</b-form-checkbox>
                        <a v-if="!q[index].options[i].universal_question_option_id ? true : false" class="delete-option pl-3" @click="deleteOption(index, i)">
                          <img src="@/assets/app-icons/delete.svg" width="20px" />
                        </a>
                      </div>
                    </div>
                    <button class="btn btn-add-options" :disabled="!virtualInterview" v-if="selectedQueType[index] === 'Multiple Choice'" @click="addOption(index)">ADD OPTIONS +</button>
                  </div>
                  <div class="question-status pt-4">
                    <div class="score">
                      <h6 class="fmp-b">Score</h6>
                      <ValidationProvider name="score" :rules="virtualInterview ? 'required' : ''" v-slot="{ errors, classes }">
                        <b-form-input :disabled="!virtualInterview" type="number" v-model="q[index].score"> </b-form-input>
                        <div v-if="submitted && (!q[index].score || String(q[index].score).length == 0)" class="validate-error">
                          <span>{{ "This field is required" }}</span>
                        </div>
                        <div class="error-msg" :class="classes">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="mandatory">
                      <h6 class="fmp-b">Mandatory</h6>
                      <b-form-checkbox selected size="lg" switch v-model="q[index].mandatory"> </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div> -->

                <!-- new -->
                <div class="box pt-3 px-3 mt-3" v-for="(que, index) in q" :key="index">
                  <div>
                    <!--End Question Name-->
                    <div class="question-name">
                      <div class="left">
                        <ValidationProvider>
                          <b-form-input class="form-control" v-model="q[index].question"
                            :placeholder="selectedQueType[index] === 'Multiple Choice' ? $gettext('Sample_MCQ_Question_goes_here') : $gettext('Sample_Text_Based_Question_goes_here')">
                          </b-form-input>
                          <div v-if="submitted && (!q[index].question || String(q[index].question).length == 0)"
                            class="validate-error">
                            <span>{{ "This field is required" }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="right ml-auto">
                        <a @click="deleteQue(index)" style="cursor: pointer">
                          <img src="@/assets/app-icons/delete.svg" width="25px" />
                        </a>
                      </div>
                    </div>
                    <!--End Question Name-->

                    <!--Start Question Input-->
                    <div class="question-input">
                      <div class="question-input-inner" v-for="(opt, i) in que.options" :key="i">
                        <div class="left">
                          <b-form-input class="form-control" v-model="q[index].options[i].optionTitle"
                            :placeholder="$gettext('Enter_Option') + (i + 1)"></b-form-input>
                          <div
                            v-if="submitted && (!q[index].options[i].optionTitle || String(q[index].options[i].optionTitle).length == 0)"
                            class="validate-error">
                            <span>{{ "This field is required" }}</span>
                          </div>
                        </div>
                        <div class="right d-flex">
                          <b-form-checkbox class="radio-square text-dark" v-model="q[index].options[i].answer"
                            @input="correctAns(index, i)" :unchecked-value="false">{{ $gettext("Mark_As_Correct")
                            }}</b-form-checkbox>
                          <!-- Edited By Aman -->
                          <div v-if="submitted && !q[index].answer" class="validate-error">
                            <span>{{ "This field is required" }}</span>
                          </div>
                          <!-- End Edited By Aman -->
                          <a class="delete-option pl-3" @click="deleteOption(index, i)">
                            <img src="@/assets/app-icons/delete.svg" width="20px" />
                          </a>
                        </div>
                      </div>
                      <button class="btn btn-add-options" v-if="selectedQueType[index] === 'Multiple Choice'"
                        @click="addOption(index)">{{ $gettext("ADD_OPTIONS") }} +</button>
                    </div>
                    <!--End Question Input-->

                    <!--Start Question status-->
                    <div class="question-status pt-4">
                      <div class="score">
                        <h6 class="fmp-b">{{ $gettext("Score") }}</h6>
                        <ValidationProvider name="score" :rules="virtualInterview ? 'required' : ''"
                          v-slot="{ errors, classes }">
                          <b-form-input type="number" v-model="q[index].question_score"> </b-form-input>
                          <div
                            v-if="submitted && (!q[index].question_score || String(q[index].question_score).length == 0)"
                            class="validate-error">
                            <span>{{ "This field is required" }}</span>
                          </div>
                          <div class="error-msg" :class="classes">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <!-- <div class="mandatory">
                      <h6 class="fmp-b">Mandatory</h6>
                      <b-form-checkbox selected size="lg" switch v-model="q[index].mandatory"> </b-form-checkbox>
                    </div> -->
                    </div>
                    <!--End Question Status-->

                  </div>
                </div>
              </div>
              <!-- VirtualInterview :: end -->

              <!-- SuggestedVirtualInterview :: start -->
              <div class="for-desktop">
                <div class="">
                  <h4 class="heading">{{ $gettext("Suggested_Virtual_Interview_Questions") }}</h4>
                </div>
                <br>
                <div class="my-2 border-left pl-3 mt-3 question-list">
                  <!-- <div class="d-flex align-items-center"  style="cursor: pointer">No suggestions available </div> -->
                  <div v-if="suggestedQues.length == 0">
                    <h2 class="text-center mt-5">No Suggested Questions Found</h2>
                  </div>
                  <div>
                    <div class="pb-2 border-bottom" v-for="(data, index) in suggestedQues" :key="index"
                      @click="onSelectSuggestedQues(data)" style="cursor: pointer">
                      <div class="box p-2">
                        <div class="d-flex align-items-center" style="cursor: pointer">
                          <!-- <b-form-checkbox
                        class="radio-square text-dark"
                      ></b-form-checkbox> -->
                          <span>Q{{ index + 1 }}.</span>
                          <b-form-input v-model="data.question" :disabled="true" class="no-form-control"
                            placeholder="Question" style="cursor: pointer"> </b-form-input>
                        </div>
                        <!--End Question Name-->

                        <div class="question-input">
                          <div>
                            <div class="left" v-for="(opt, i) in data.options" :key="opt.question_option_id"
                              style="cursor: pointer">
                              <b-form-input class="form-control" v-model="opt.question_option_title" :disabled="true"
                                :placeholder="'Enter Option ' + (i + 1)" style="cursor: pointer"></b-form-input>
                            </div>
                          </div>
                        </div>
                        <!--End Question Input-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- SuggestedVirtualInterview :: end -->
            </div>
            <!-- gridBox :: end -->

            <!-- addQuestionsSection :: start -->
            <div class="mt-3 mt-md-5">
              <div>
                <b-button class="btn btn-add-question" @click="addQue">
                  <!-- v-b-modal.type-question-modal -->
                  {{ $gettext("ADD_QUESTIONS") }} +
                </b-button>
                <span class="f-light fs-14"> ({{ $gettext("upto") }} {{ 10 - q.length }}) </span>
              </div>
              <b-modal id="modal-scoped">
                <template #modal-header="{ close }">
                  <b-button size="sm" @click="close()"> x </b-button>
                </template>

                <template>
                  <b-form-group label="Choose Type Of Question">
                    <b-form-radio-group v-model="qType" @ok="handleOk" :options="['Multiple Choice', 'Text Based']">
                    </b-form-radio-group>
                  </b-form-group>
                </template>

                <template #modal-footer>
                  <!-- Emulate built in modal footer ok and cancel button actions -->
                  <b-button class="text-center" size="sm" variant="success" @click="chooseQueType"> {{ $gettext("Confirm")
                  }} </b-button>
                </template>
              </b-modal>
            </div>
            <!-- addQuestionsSection :: end -->

            <ValidationObserver ref="videoInterview" tag="div" class="mt-3 mt-md-5">
              <div v-if="virtualVideoInterview">
                <h4 class="heading">Virtual Video Interview</h4>
                <div class="box py-3 mt-3">
                  <ValidationProvider name="videoInt" :rules="virtualVideoInterview ? 'required' : ''"
                    v-slot="{ errors, classes }" tag="div" class="col-md-6">
                    <b-form-input :disabled="!virtualVideoInterview" v-model="vedioInterviewQues.que" class="form-control"
                      :class="classes" placeholder="Q1. Enter Virtual Video Question Here?"> </b-form-input>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider name="videoDesc" :rules="virtualVideoInterview ? '' : ''"
                    v-slot="{ errors, classes }" tag="div" class="col-md-6">
                    <b-form-textarea v-model="vedioInterviewQues.question_tips" :disabled="!virtualVideoInterview"
                      :class="classes" class="form-control" rows="6" max-rows="10"
                      placeholder="Enter some tips to help the candidate upload the appropriate video…">
                    </b-form-textarea>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </ValidationObserver>

            <!--Start EQ Template-->
            <div v-if="EQTemplate" class="mt-3 mt-md-5">
              <h4 class="heading">EI Assessment</h4>
              <p>This test is based on the 5 competency model as outlined by Daniel Goleman in Emotional Intelligence The
                results of the test are automatically scored on the basis of the above model and cannot be altered by
                Employers. Please note candidates will take at least 15 minutes to answer this questionnaire and once
                selected by the employer it will be mandatory for all candidates to fill it out.</p>
              <div class="box py-3 px-3 mt-3">
                <div class="template-header">
                  <div class="left">
                    <!-- <ValidationProvider
                    name="videoInt"
                    :rules="EQTemplate ? 'required' : ''"
                    v-slot="{ errors, classes }"
                  >
                    <v-select
                      :disabled="!EQTemplate"
                      v-model="selectedTemp"
                      class="form-control w-25"
                      :class="classes"
                      :options="templates"
                      placeholder="Select Template"
                      @input="onSelectTemplate"
                    >
                    </v-select>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider> -->
                  </div>
                  <!-- <div class="right">
                  <img src="@/assets/app-icons/delete.svg" width="25px" />
                </div> -->
                </div>
                <div class="border-bottom py-3" v-for="(que, index) in selectedTempQues" :key="index">
                  <h5 class="font-weight-bolder">{{ que.ei_type_name }}</h5>
                  <div class="" v-for="(o, i) in que.question" :key="i">
                    <p>Q{{ i + 1 }}. {{ o.eiq_name }}</p>
                    <!-- <b-form-radio-group
                  
                    :disabled="false"
                    class="radio-square option-center"
                    :options="que.options"
                  >
                  </b-form-radio-group> -->
                    <!-- <b-form-checkbox
                    class="radio-square text-dark"
                    :disabled="true"
                    v-model="o.correct"
                    :unchecked-value="'N'"
                    :value="'Y'"
                    >{{ o.text }}</b-form-checkbox
                  > -->
                  </div>
                </div>
              </div>
            </div>
            <!--End EQ Template-->

            <div class="offset-md-4 col-md-4 d-flex justify-content-between text-center pt-4">
              <button class="btn btn-light btn-md" style="min-width: 90px" @click="goBack">{{ $gettext("Back") }}</button>
              <button class="btn btn-primary mx-3 btn-md" style="min-width: 100px" @click="saveDraft">{{
                $gettext("SAVE_DRAFT") }}</button>
              <button class="btn btn-primary btn-md" style="min-width: 100px" @click.prevent.stop="ValidateInput">{{
                $gettext("Submit") }}</button>
            </div>
          </div>
        </div>
      </div>

      <!--Select Template Modal-->
      <b-modal hide-footer id="template-modal" title="Select EI Template" class="template-modal" size="lg"
        :scrollable="true" :centered="true">
        <div v-for="(data, index) in tempQue" :key="index">
          <div class="py-3 px-3 collapse-card">
            <div class="template-modal-title" v-b-toggle="'collapse-' + index">
              <h4 class="fw-bold text-capitalize" v-if="data[0]">
                {{ data[0].template_name }}
              </h4>
              <p class="light-text"></p>
            </div>
            <b-collapse :id="'collapse-' + index">
              <div class="template-question border-bottom" v-for="(q, i) in data" :key="i">
                <h6>Q{{ i + 1 }}. {{ q.question }}</h6>
                <div class="template-options">
                  <b-form-radio-group class="radio-square option-center" :options="q.options"> </b-form-radio-group>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </b-modal>
      <!--Close Modal-->

      <!--Choose Question-->
      <b-modal hide-footer id="type-question-modal" title="Choose type of question" :centered="true">
        <b-row>
          <b-col class="col-md-12 radio-square option-center">
            <div class="text-center">
              <b-form-group>
                <b-form-radio-group v-model="qType" @ok="handleOk" :options="['Multiple Choice', 'Text Based']"
                  class="type-question"> </b-form-radio-group>
              </b-form-group>
            </div>
          </b-col>
          <b-button class="btn-center btn-confirm my-4" size="sm" @click="chooseQueType"> CONFIRM </b-button>
        </b-row>
      </b-modal>

      <b-modal ref="Suggest_VI" id="Suggest_VI" class="modal-bottom" hide-footer hide-header>
        <div style="margin-bottom: 23px;">
          <p style="    color: #000;    font-size: 18px;   font-style: normal;    font-weight: 600;    line-height: 28.8px;"
            @click="toggleVI()"> &lt; Back</p>
          <p style="margin-bottom:5px;font-size: 18px;font-weight: 700;text-align: center;">Suggested Virtual Interview
            Questions</p>
        </div>
        <div style="width: 100%;height: 83vh;overflow: auto;">
          <div v-if="suggestedQues.length == 0">
            <h2 class="text-center mt-5">No Suggested Questions Found</h2>
          </div>
          <div>
            <div class="pb-2 border-bottom" v-for="(data, index) in suggestedQues" :key="index" style="cursor: pointer">
              <div class="box p-2">
                <div class="d-flex align-items-center" style="cursor: pointer">
                  <!-- <b-form-checkbox
                        class="radio-square text-dark"
                      ></b-form-checkbox> -->
                  <span>Q{{ index + 1 }}.</span>
                  <b-form-input v-model="data.question" :disabled="true" class="no-form-control" placeholder="Question"
                    style="cursor: pointer"> </b-form-input>
                  <button class="btn btn-primary" @click="onSelectSuggestedQues(data)">+</button>
                </div>

                <!--End Question Name-->

                <div class="question-input">
                  <div>
                    <div class="left py-1" v-for="(opt, i) in data.options" :key="opt.question_option_id"
                      style="cursor: pointer">
                      <b-form-input class="form-control" v-model="opt.question_option_title" :disabled="true"
                        :placeholder="'Enter Option ' + (i + 1)" style="cursor: pointer"></b-form-input>
                    </div>
                  </div>
                </div>
                <!--End Question Input-->
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import JobService from "@/apiServices/RecruiterJobService";
import EmployerHeader from "@/views/Employer-Profile-Screens/Employer.vue";
import QuestionServices from "@/apiServices/QuestionServices";
import JobServices from "@/apiServices/RecruiterJobService";
import RecruiterJobService from "@/apiServices/RecruiterJobService";
import CompanyServices from "@/apiServices/CompanyServices";
import moment from "moment";

export default {
  name: "Employer-JobCreation-2",
  components: { EmployerHeader },
  data() {
    return {
      isSuggestVI: false,
      isVIPage: true,
      nxpId: null,
      submitted: false,
      status: false,
      qType: "",
      count: 0,
      job_id: null,
      responsibilities: [{ responsibility: "" }],
      education: [{ jem_id: null, job_education: "" }],
      delResp: [],
      delEdu: [],
      selectedQueType: ["Multiple Choice", "Multiple Choice", "Multiple Choice"],
      suggestedQues: [],
      selectedSub: null,
      selectedTempQues: [],
      selectedTemp: null,
      lastJob: {},
      delQ: [],
      selectTemplate: {},
      templates: [],
      temp_id: null,
      tempQue: [],
      isSuggestQuestionsEmpty: null,
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: "",
        feature: "Y",
      },
      // textBasedQuestion: [],
      // textQ: [
      //   {
      //     que: "",
      //     ans: "",
      //     score: null,
      //     mandatory: false,
      //     wfc_id: 2,
      //     eq: {},
      //   },
      // ],
      MCQquestions: [],
      old_q: [
        {
          que: "",
          options: [
            { option: "", status: true },
            { option: "", status: false },
          ],
          score: 0,
          mandatory: true,
          wfc_id: 2,
        },
        {
          que: "",
          options: [
            { option: "", status: true },
            { option: "", status: false },
          ],
          score: 0,
          mandatory: true,
          wfc_id: 2,
        },
        {
          que: "",
          options: [
            { option: "", status: true },
            { option: "", status: false },
          ],
          score: 0,
          mandatory: true,
          wfc_id: 2,
        },
      ],

      //   q: [
      //     {
      //       question_id: 1,
      //       quest_title: "",
      //       question_score: 0,
      //       wfc_id: 2,
      //       options: [
      //         {
      //           optionTitle: "",
      //           answer: false,
      //         },
      //         {
      //           optionTitle: "",
      //           answer: false,
      //         },
      //       ],
      //     },
      //     {
      //       question_id: 2,
      //       quest_title: "",
      //       question_score: 0,
      //       wfc_id: 2,
      //       options: [
      //         {
      //           optionTitle: "",
      //           answer: false,
      //         },
      //         {
      //           optionTitle: "",
      //           answer: false,
      //         },
      //       ],
      //     },
      //     {
      //       question_id: 3,
      //       quest_title: "",
      //       question_score: 0,
      //       wfc_id: 2,
      //       options: [
      //         {
      //           optionTitle: "",
      //           answer: false,
      //         },
      //         {
      //           optionTitle: "",
      //           answer: false,
      //         },
      //       ],
      //    },
      //    ],

      questionCount: 0,

      q: [
        {
          question_id: Date.now(),
          question: "",
          question_score: 0,
          wfc_id: 2,
          options: [
            {
              optionTitle: "",
              answer: false,
            },
            {
              optionTitle: "",
              answer: false,
            },
          ],
        },
        {
          question_id: Date.now(),
          question: "",
          question_score: 0,
          wfc_id: 2,
          options: [
            {
              optionTitle: "",
              answer: false,
            },
            {
              optionTitle: "",
              answer: false,
            },
          ],
        },
        {
          question_id: Date.now(),
          question: "",
          question_score: 0,
          wfc_id: 2,
          options: [
            {
              optionTitle: "",
              answer: false,
            },
            {
              optionTitle: "",
              answer: false,
            },
          ],
        },
        // {
        //   question_id: Date.now(),
        //   question: "",
        //   question_score: 0,
        //   wfc_id: 2,
        //   options: [
        //     {
        //       optionTitle: "",
        //       answer: false,
        //     },
        //     {
        //       optionTitle: "",
        //       answer: false,
        //     },
        //   ],
        // },
      ],
      vedioInterviewQues: {
        que: "",
        question_tips: "",
        score: 0,
        wfc_id: 3,
      },
    };
  },
  mounted() {
    this.nxpId = this.$store.getters.getNxpId;
    console.log('mounted()', this.nxpId);
  },
  beforeMount() {
    // this.getEQTempQuestions();
    this.getSelectedSubscription();
    this.getQuesFromStore();
    this.getSuggestedQues();
    this.prefilledData();
    if (!this.$route.query.d_job_id) {
      this.getSuggestedQues();
    }
    // if (this.$store.getters.getWfc) {
    //   if (this.$store.getters.getWfc.wfc?.includes("4")) {
    //     this.getAllEQTemplates();
    //   }
    // } else {
    //   this.$router.push("/employer-jobcreation-1");
    // }
    if (this.$route.query.d_job_id) {
      this.getAllDraftQuest();
      this.getRespEdu();
    }
    //this.getLastJob(this.pagination);
  },
  computed: {
    virtualInterview() {
      if (this.$store.getters.getWfc && this.$store.getters.getWfc.wfc?.includes("2")) {
        return true;
      } else {
        return false;
      }
    },
    virtualVideoInterview() {
      if (this.$store.getters.getWfc && this.$store.getters.getWfc.wfc?.includes("3")) {
        return true;
      } else {
        return false;
      }
    },
    EQTemplate() {
      if (this.$store.getters.getWfc && this.$store.getters.getWfc.wfc?.includes("4")) {
        this.onSelectTemplate({ template_id: 1 });
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    toggleVI() {
      this.isSuggestVI = !this.isSuggestVI;
      if (this.isSuggestVI) {
        this.$bvModal.show("Suggest_VI")
      }
      else {
        this.$bvModal.hide("Suggest_VI")
      }
    },
    handleAddQuestions() {
      if (!this.suggestedQues?.length) return;
      let currentCount = this.questionCount;

      if (currentCount < this.suggestedQues?.length) {
        const currentQuestion = this.suggestedQues[currentCount];
        this.onSelectSuggestedQues(currentQuestion);
        this.questionCount = this.questionCount + 1;
      }
    },
    getSelectedSubscription() {
      const user_id = this.$cookies.get("user_id");
      let loader = this.$loading.show();
      CompanyServices.getUserSubscription(user_id).then((res) => {
        this.selectedSub = res.data.data[0];
        this.selectedSub.subscription_type_id == 1 ? (this.activesub = "Monthly") : (this.activesub = "Yearly");
        if (this.selectedSub) {
          this.selectedSub.subscription_end_date_timestamp = moment.unix(this.selectedSub.subscription_end_date_timestamp).format("YYYY-MM-DD HH:mm:ss");
        }
      });
      loader.hide();
    },

    onSelectSuggestedQues(data) {
      console.log("data", data);
      // const ques = {
      //   mandatory: true,
      //   options: data.options.map((m) => {
      //     return {
      //       option: m.question_option_title,
      //       status: m.question_option_correct == "Y" ? true : false,
      //       universal_question_option_id: m.universal_question_option_id,
      //     };
      //   }),
      //   que: data.question,
      //   universal_question_id: data.universal_question_id,
      //   score: 0,
      //   wfc_id: 2,
      // };
      // console.log('old que', ques);

      const ques = {
        options: data.options.map((m) => {
          return {
            optionTitle: m.question_option_title,
            answer: false,
          };
        }),
        question: data.question,
        wfc_id: 2,
        question_score: 0,
        question_id: data.question_id,
      };

      if (this.q.length > 9) {
        this.$toasted.show("You have added max question.", {
          duration: 6000,
          icon: "clear",
        });
        this.questionCount = 0;
        return;
      }
      const getDuplicate = this.q.filter((f) => f.question_id == ques.question_id);
      if (getDuplicate.length) {
        this.$toasted.show("this question already added", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }
      //   if (this.count <= 3) {
      //     this.q.splice(this.count, 1, ques);
      //     this.q.unshift(ques);
      //     this.q.splice(this.count, 1);
      //     this.count++;
      //   } else {
      //     this.q.unshift(ques);
      //   }
      this.q = [ques, ...this.q];
      console.log(this.q);
      const filtered = this.suggestedQues.filter((a) => !this.q.some((b) => a.question_id === b.question_id));
      this.suggestedQues = filtered;
      console.log(this.suggestedQues);
    },
    getSuggestedQues() {
      // const job_title = this.$store.getters.getJobDetails ? this.$store.getters.getJobDetails.job_title : "";
      const job_title = this.$store.getters.getTheJobTitle;
      console.log(job_title);
      QuestionServices.SuggestedQues({
        job_title: encodeURIComponent(job_title),
      }).then((res) => {
        // console.log("this.$store.getters.getJobDetailsWithVI", this.$store.getters.getJobDetailsWithVI);
        const VIData = this.$store.getters.getJobDetailsWithVI.questions ? this.$store.getters.getJobDetailsWithVI : null
        // console.log("VIData.length()", VIData)
        if (VIData !== null) {
          this.prefilledData();
          const suggestedQuestions = [...res.data.data].reduce((acc, curr) => {
            const isFound = acc.find((que) => que.question === curr.question);
            return isFound ? acc : [...acc, curr];
          }, []);

          // const filtered = suggestedQuestions.filter((a) => !this.q.some((b) => a.question_id === b.question_id));
          const filtered = suggestedQuestions.filter((a) => !this.q.some((b) => a.question === b.question));
          this.suggestedQues = filtered;
          console.log(this.suggestedQues);
        }
        else {
          this.prefilledData();
          const suggestedQuestions = [...res.data.data].reduce((acc, curr) => {
            const isFound = acc.find((que) => que.question === curr.question);
            return isFound ? acc : [...acc, curr];
          }, []);
          const filtered = suggestedQuestions.filter((a) => !this.q.some((b) => a.question === b.question));
          this.suggestedQues = filtered;
          console.log(this.suggestedQues);
          // this.suggestedQues = suggestedQuestions;
        }
      });
    },
    getRespEdu() {
      let d_job_id = null;
      if (this.$route.query.d_job_id && this.$route.query.d_job_id != "") {
        d_job_id = this.$route.query.d_job_id;
      } else {
        d_job_id = this.job_id;
      }

      JobService.GetDraftResponsiblity({ d_job_id }).then((res) => {
        if (res.data.data.length) {
          this.responsibilities = res.data.data.map((m) => {
            return {
              responsibility: m.responsibility,
              jrm_id: m.jrm_id,
            };
          });
        } else {
          this.responsibilities = [{ responsibility: "" }];
        }
      });
      JobService.GetDraftEducation({ d_job_id }).then((res) => {
        if (res.data.data.length) {
          this.education = res.data.data.map((m) => {
            return {
              jem_id: m.jem_id,
              job_education: m.job_education,
            };
          });
        } else {
          this.education = [{ jem_id: null, job_education: "" }];
        }
      });
    },
    getAllEQTemplates() {
      QuestionServices.GetAllEQTemplates().then((res) => {
        this.templates = res.data.data.map((t) => {
          return {
            disabled: !this.EQTemplate,
            label: t.template_name,
            template_id: t.template_id,
          };
        });

        this.getEQTemplateQue();
      });
    },
    addDraftJob() {
      const j = this.$store.getters.getJobDetails;
      const wfc = this.$store.getters.getWfc ? this.$store.getters.getWfc.wfc : [];
      const payload = {
        job: { ...j, draft: "Y", wfc: wfc },
      };

      return JobService.AddDraftJob(payload);
      // .then((res) => {
      //
      //   this.job_id = res.data.data[0];
      //   // this.updateVideoQ();
      //   this.$toasted.show("Job saved to draft", {
      //     duration: 6000,
      //     icon: "check",
      //   });
      // })
      // .catch((err) => {
      //
      //   this.$toasted.show("Error occurred", {
      //     duration: 6000,
      //     icon: "clear",
      //   });
      // });
    },
    async saveDraft() {
      // try {
      //   const draftV1 = await this.addDraftJob();
      //   console.log("draftV1 :>> ", draftV1);

      //   this.job_id = draftV1.data.data[0];
      //   const payload = {
      //     resp: this.responsibilities,
      //     edu: this.education,
      //     ques: this.q,
      //     d_job_id: this.job_id,
      //     delEdu: this.delEdu,
      //     delResp: this.delResp,
      //     temp_id: this.temp_id,
      //     job_id: this.job_id,
      //   };

      //   JobService.AddDraftJobV2(payload).then((res) => {
      //     this.getRespEdu();
      //     this.getAllDraftQuest();
      //   });
      //   // this.q.push(this.videoQ);

      //   let p = {
      //     draft: true,
      //     d_job_id: this.job_id,
      //     q: this.q,
      //     removed_questions: this.removed_questions,
      //     job_id: +this.job_id,
      //     delQ: this.delQ,
      //   };
      //   let score = 0;
      //   this.q.map((m) => {
      //     if (m.score) {
      //       score = score + parseInt(m.score);
      //     } else {
      //       score = score + 0;
      //     }
      //   });
      //   if (score != 100) {
      //     return this.$toasted.show(`Sum of score is ${score}. It should be 100`, { duration: 6000, icon: "clear" });
      //   }
      //   this.updateVideoQ(p.d_job_id);
      //   //       mandatory: true
      //   // options: [{option_id: 576, option: "jghj", status: true}, {option_id: 577, option: "ghjhg", status: false}]
      //   // que: "hgjhg"
      //   // question_id: 319
      //   // question_tips: null
      //   // removed_options: []
      //   // score: 50
      //   // wfc_id: 2
      //   //       mandatory:true
      //   // options:Array[0]
      //   // que:"ytjggh"
      //   // question_id:322
      //   // question_tips:"gkjhljkl"
      //   // removed_options:Array[0]
      //   // score:null
      //   // wfc_id:3

      //   QuestionServices.UpdateQuestion(p)
      //     .then((res) => {
      //       this.$toasted.show("Questions updated successfully!", {
      //         position: "top-right",
      //         duration: 6000,
      //       });

      //       // this.$store.dispatch("onResetJobDetail");
      //     })
      //     .catch((err) => {
      //       this.$toasted.show("Questions not Submitted!", {
      //         position: "top-right",
      //         duration: 6000,
      //       });
      //     });
      // } catch (err) {}
      try {

        this.submitted = true;

        const p = await Promise.all([
          this.ValidateResp(),
          //    this.ValidateEdu(),
          this.validateVirtualInt(),
          this.validateVirtualVideoInt(),
          // this.validateEQ(),
        ]);

        let score = 0;
        this.q.map((m) => {
          console.log("kuch b", m);
          if (m.question_score) {
            score = score + parseInt(m.question_score);
          } else {
            score = score + 0;
          }
        });
        console.log("score :>> ", score);
        if (score != 100) {
          this.$toasted.show(`Score sum is ${score}. It must be equal to 100`, {
            duration: 6000,
            icon: "clear",
          });
          return;
        }

        if (p.includes(false)) {
          console.log("There is some missing fields!!");
          return;
        } else {
          console.log("Form success!");
          console.log(this.q);
          JobServices.InsertVirtualQuestions({
            responsibilities: this.responsibilities,
            draft: true,
            questions: this.q.map((m) => {
              return {
                quest_title: m.question,
                score: parseInt(m.question_score),
                wfc_id: m.wfc_id,
                ques_option: m.options,
              };
            }),
            nxpId: this.$store.getters.getNxpId,
          })
            .then((res) => {
              this.$toasted.show("Job Added To Draft", {
                position: "top-right",
                duration: 6000,
                icon: "check",
              });
              this.$store.dispatch("onSetJob_Details_With_VI", payload);
              console.log("this.$route.query.d_job_id", this.$route.query.d_job_id);
              if (this.$route.query.d_job_id) {
                const j_id = this.$route.query.d_job_id;
                this.$router.push({
                  name: "employer-job-review",
                  query: { d_job_id: j_id },
                });
              } else {
                const path = this.$router.path;
                this.$router.push({ name: "employer-job-review" });
              }

            })
            .catch((e) => console.log(e));
        }
      } catch (err) {
        console.log(err);
      }
    },
    updateVideoQ(job_id) {
      const v = {
        question_id: this.vedioInterviewQues.question_id,
        que: this.vedioInterviewQues.que,
        question_tips: this.vedioInterviewQues.question_tips,
        wfc_id: this.vedioInterviewQues.wfc_id,
        job_id: job_id,
        mandatory: true,
        options: [],
        removed_options: [],
        score: 0,
        wfc_id: 3,
      };
      QuestionServices.UpdateDraftQues(v).then((res) => {
        this.vedioInterviewQues.question_id = res.data.data[0];
      });
    },
    getAllDraftQuest() {
      let d_job_id = null;
      if (this.$route.query.d_job_id && this.$route.query.d_job_id != "") {
        d_job_id = this.$route.query.d_job_id;
      } else {
        d_job_id = this.job_id;
      }

      QuestionServices.GetAllDraftQues({ d_job_id }).then((res) => {
        if (res.data.data.ques.length > 0) {
          this.q = res.data.data.ques.map((m) => {
            return {
              question_id: m.question_id,
              que: m.question,
              options:
                m.options && m.options.length > 0
                  ? m.options.map((o) => {
                    return {
                      option_id: o.option_id,
                      option: o.option_title,
                      status: o.correct == "Y" ? true : false,
                    };
                  })
                  : [],
              removed_options: [],
              score: m.question_score,
              question_tips: m.question_tips,
              mandatory: m.question_is_mandatory == "Y" ? true : false,
              wfc_id: m.wfc_id,
            };
          });
          const videoQ = this.q.filter((f) => f.wfc_id == 3);

          if (videoQ.length) {
            this.vedioInterviewQues = {
              que: videoQ[0].que,
              question_tips: videoQ[0].question_tips,
              // score: null,
              question_id: videoQ[0].question_id,
              wfc_id: 3,
            };
            // this.videoInterviewQues = videoQ[0];
          }
          // if (videoQ.length > 0) {
          //   this.videoInterviewQues = {
          //     que: videoQ[0].que,
          //     question_id: videoQ[0].question_id,
          //     question_tips: videoQ[0].question_tips,
          //     wfc_id: videoQ[0].wfc_id,
          //   };
          //   console.log(
          //     "this.videoInterviewQues :>> ",
          //     this.videoInterviewQues
          //   );
          // }
          this.q = this.q.filter((f) => {
            if (f.wfc_id == 2) {
              return true;
            }
          });
        }
        if (res.data.data.template_id && res.data.data.template_id.length > 0) {
          const template_id = res.data.data.template_id[0].template_id;
          this.selectedTemp = res.data.data.template_id.map((m) => {
            return {
              template_id: m.template_id,
              label: m.template_name,
            };
          })[0];
          // this.onSelectTemplate({ template_id });
        }
        this.getSuggestedQues();
      });
    },
    prefilledData() {

      // const queryId = this.$route?.query?.d_job_id ? this.$route?.query?.d_job_id : this.job_id;
      const dJob_id = this.$route?.query?.d_job_id
      console.log("dJob_id", dJob_id);
      const dJobBoolVal = dJob_id !== undefined && dJob_id !== null ? true : false
      console.log("dJobBoolVal", dJobBoolVal);

      if (dJobBoolVal) {
        console.log("DraftJOb Section");
        let loader = this.$loading.show();
        RecruiterJobService.GetSingleDraftJob({ d_job_id: dJob_id })
          .then((res) => {
            console.log("res::aman", res.data.data[0].questions);
            const { responsibility } = res.data.data[0]
            if (responsibility.length) {
              const data = res.data.data.length ? res.data.data[0] : null;
              console.log(data);
              this.responsibilities = data.responsibility.map((responsibility) => ({
                responsibility: responsibility.job_responsibility
              }))
              this.q = data.questions.map((ques) => ({
                question_id: Date.now(),
                question: ques.question,
                question_score: ques.question_score,
                wfc_id: ques.wfc_id,
                options: ques.options.map((op) => ({
                  optionTitle: op.question_option_title,
                  answer: op.question_option_correct == "Y" ? true : false,
                })),
              }));
              ;
            }
            loader.hide();

          })
          .catch((err) => {
            console.log("GetDraftJob Error", err.message);
            loader.hide();
          });
      }
      else {
        console.log("Create Section");

        const data = this.$store.getters.getJobDetailsWithVI;
        console.log("data==>", data);
        if (data.responsibilities) {
          this.responsibilities = data.responsibilities.map((responsibility) => ({
            responsibility: responsibility.responsibility
          }))
          console.log("this.responsibilities", this.responsibilities);
          this.q = data.questions.map((question) => ({
            question_id: Date.now(),
            question: question.quest_title,
            question_score: question.score,
            wfc_id: question.wfc_id,
            options: question.ques_option.map((option) => ({
              optionTitle: option.optionTitle,
              answer: option.answer,
            })),
          }));
          console.log("que", this.q);
        }
      }

    },

    goBack() {
      if (this.$route.query.d_job_id) {
        const j_id = this.$route.query.d_job_id;
        console.log("j_id", j_id);
        this.$router.push({
          name: "Employer-JobCreation",
          query: { d_job_id: j_id, page: 2 },
        });
      } else {

        const q = this.q;
        const temp = this.selectedTemp;
        const edu = this.education;
        const resp = this.responsibilities;
        const video = this.vedioInterviewQues;
        this.$store.commit("ON_SET_VQ", q);
        this.$store.commit("ON_SET_EQQ", temp);
        this.$store.commit("ON_SET_RESP", resp);
        this.$store.commit("ON_SET_EDU", edu);
        this.$store.commit("ON_SET_PAGE2", 2);
        this.$store.commit("ON_SET_VIDEOQ", video);

        const path = this.$router.path;
        this.nxpId = this.$store.getters.getNxpId;
        this.$router.push({ name: "Employer-JobCreation", query: { page: 2 } });
      }
    },
    getQuesFromStore() {
      if (this.$store.getters.getPage2 == 2) {
        const qu = JSON.stringify(this.$store.getters.getVQ);
        const resp = JSON.stringify(this.$store.getters.getResp);
        const edu = JSON.stringify(this.$store.getters.getEdu);
        const videoQ = JSON.stringify(this.$store.getters.getVideoQ);

        let tempQ = JSON.stringify(this.$store.getters.getEq);
        tempQ = JSON.parse(tempQ);
        this.q = JSON.parse(qu);
        this.responsibilities = JSON.parse(resp);
        this.education = JSON.parse(edu);
        this.vedioInterviewQues = JSON.parse(videoQ);
        // this.templateSelected = qu.data.data.filter((f) => {
        //   if (f.wfc_id == 4) {
        //     return true;
        //   }
        // });
        // if (this.templateSelected.length > 0) {
        //   this.templateSelected = {
        //     label: this.templateSelected[0].template_name,
        //     template_id: this.templateSelected[0].template_id,
        //   };
        // }
        this.selectedTemp = tempQ;
        // this.onSelectTemplate(tempQ);
      }
    },
    validateVirtualInt() {
      let flag = true;
      let ansError = false;
      console.log("this.q", this.q);
      this.q.map((ques) => {
        if (!ques.question || String(ques.question).length == 0) {
          flag = false;
        } else if (!ques.question_score || String(ques.question_score).length == 0) {
          flag = false;
        }
        let correctFlag = 0;
        console.log("this.q", this.q);

        ques.options.map((op, opIndex) => {
          if (!op.optionTitle || String(op.optionTitle).length == 0) {
            flag = false;
          }
          if (op.answer) {
            correctFlag = 1;
          }

          if (opIndex == ques.options.length - 1 && correctFlag == 0) {
            console.log("correctFlag in error :>> ", correctFlag);
            flag = false;
            ansError = true;
          } else if (opIndex == ques.options.length - 1 && correctFlag == 1) {
            console.log("correctFlag in success :>> ", correctFlag);
            correctFlag = 0;
          }
        });
      });
      console.log("flag :>> ", flag);
      if (ansError) {
        this.$toasted.show("Please select 1 correct answer for MCQ questions", {
          duration: 6000,
          icon: "clear",
        });
      }
      if (flag) {
        return true;
      } else {
        return false;
      }
    },
    validateVirtualVideoInt() {
      return this.$refs.videoInterview
        .validate()
        .then((result) => {
          if (result) {
            return true;
          } else {
            this.$toasted.show("Somefields are missing in Virtual Interview!", {
              duration: 6000,
              icon: "clear",
            });
            return false;
          }
        })
        .catch((err) => { });
    },
    ValidateResp() {
      let valid = false;
      console.log("RESPONSIBILITY ", this.responsibilities);

      return new Promise((resolve, reject) => {
        if (this.responsibilities.some((m) => m.responsibility != "")) {
          valid = true;
          //reject(valid);
        } else {
          valid = false;
        }

        if (!valid) {
          this.$toasted.show("There are some fields are missing in Responsibilities", { duration: 6000, icon: "clear" });
        }
        resolve(valid);
      });
      // return this.$refs.resp
      //   .validate()
      //   .then((result) => {
      //     if (result) {
      //       console.log("result :>> ", result);
      //       return true;
      //     } else {
      //       this.$toasted.show(
      //         "Some fields are missing in Responsibility or Education!",
      //         {
      //           position: "top-right",
      //           duration: 6000,
      //           icon: "clear",
      //         }
      //       );
      //       return false;
      //       throw new Error("invalid form");
      //     }
      //   })
      //   .catch((err) => {
      //
      //   });
    },
    ValidateEdu() {
      let valid = false;

      return new Promise((resolve, reject) => {
        if (this.education.includes("")) {
          valid = false;
          //reject(valid);
        } else {
          valid = true;
        }
        if (!valid) {
          this.$toasted.show("There are some fields are missing in Education", {
            duration: 6000,
            icon: "clear",
          });
        }
        resolve(valid);
      });
    },
    async validateEQ() {
      if (this.$store.getters.getWfc.wfc?.includes("4")) {
        if (this.EQTemplate && this.temp_id && String(this.temp_id).length > 0) {
          return true;
        } else {
          this.$toasted.show("Select EI Template", {
            duration: 6000,
            icon: "clear",
          });
          return false;
        }
      } else {
        return true;
      }
    },

    async ValidateInput() {

      // return this.$refs.ques.validate().then((result) => {
      //   if (result) {
      //     console.log("result :>> ", result);
      //     this.updateJob();
      //   } else {
      //     this.$toasted.show("Some fields are missing!", {
      //       position: "top-right",
      //       duration: 6000,
      //     });
      //   }
      // });

      try {
        this.submitted = true;

        const p = await Promise.all([
          this.ValidateResp(),
          //    this.ValidateEdu(),
          this.validateVirtualInt(),
          this.validateVirtualVideoInt(),
          // this.validateEQ(),
        ]);

        let score = 0;
        this.q.map((m) => {
          console.log("kuch b", m);
          if (m.question_score) {
            score = score + parseInt(m.question_score);
          } else {
            score = score + 0;
          }
        });
        console.log("score :>> ", score);
        if (score != 100) {
          this.$toasted.show(`Score sum is ${score}. It must be equal to 100`, {
            duration: 6000,
            icon: "clear",
          });
          return;
        }

        if (p.includes(false)) {
          console.log("There is some missing fields!!");
          return;
        } else {
          console.log("Form success!");
          // this.updateJob();
          // this.submitQue();
          this.submitJob();
        }
      } catch (error) {
        return false;
      }
    },
    addResponsibility(index) {
      this.responsibilities.push({ responsibility: "" });
    },
    removeResponsibility(index) {
      if (this.responsibilities.length != 1) {
        const resp = this.responsibilities[index];

        this.responsibilities.splice(index, 1);
        this.delResp = [...this.delResp, resp];
      } else {
        return;
      }
    },
    addEdu(index) {
      this.education.push({ jem_id: null, job_education: "" });
    },
    removeEdu(index) {
      if (this.education.length > 1) {
        const edu = this.education[index];
        this.education.splice(index, 1);
        this.delEdu = [...this.delEdu, edu];
      } else {
        return;
      }
    },
    onSelectTemplate(e) {
      console.log("e :>> ", e);
      if (e?.template_id) {
        this.temp_id = e?.template_id;
        QuestionServices.GetEQTemplateQue(e?.template_id).then((res) => {
          this.selectedTempQues = res.data.data;
          this.selectedTempQues.forEach(async (q, i) => {
            if (q.options && q.options.length > 0) {
              this.selectedTempQues[i].options = await q.options.map((option) => {
                return {
                  text: option.question_option_title,
                  value: option.question_option_id,
                  correct: option.question_option_correct,
                };
              });
            }
          });
        });
      } else {
        this.temp_id = null;
        this.selectedTempQues = [];
      }
    },
    async getEQTemplateQue() {
      this.templates.forEach(async (t, index) => {
        const q = await QuestionServices.GetEQTemplateQue(t.template_id);
        q.data.data.forEach((que) => {
          que.options = que.options
            ? que.options.map((o) => {
              return {
                text: o.question_option_title,
                value: o.question_option_id,
              };
            })
            : [];
        });
        this.tempQue.push(q.data.data);

        this.tempQue = [...new Set(this.tempQue)];
      });

      // this.tempQue.forEach((q, index) => {

      //   q.options.map((o) => {
      //     return {
      //       text: o.question_option_title,
      //       value: o.question_option_id,
      //     };
      //   });

      // });
    },
    addOption(index) {
      this.q[index].options.push({ optionTitle: "", answer: false });
    },
    deleteOption(index, i) {
      if (this.q[index].options.length > 1) {
        this.q[index].options.splice(i, 1);
      }
    },
    handleOk(e) {
      e.preventDefault();
      this.chooseQueType();
    },
    chooseQueType() {
      this.selectedQueType.push(this.qType);

      let len = this.selectedQueType.length - 1;
      if (this.selectedQueType[len] === "Multiple Choice") {
        this.addQue();
      } else {
        this.addTextBasedQue();
      }
      this.$nextTick(() => {
        this.$bvModal.hide("type-question-modal");
      });
    },
    addTextBasedQue() {
      if (this.q.length < 10) {
        this.q.push({ q: "", score: null, mandatory: false, wfc_id: 2 });
      } else {
        return;
      }
    },
    addQue() {
      this.selectedQueType.push("Multiple Choice");
      if (this.q.length < 10) {
        console.log("add ques", this.q.length);
        this.q.push({
          quest_title: "",
          options: [
            { optionTitle: "", answer: false },
            { optionTitle: "", answer: false },
          ],

          score: null,
          wfc_id: 2,
        });
      } else {
        console.log("else ques");

        return;
      }
    },
    deleteQue(index) {
      const foundQuestion = this.q[index];

      if (foundQuestion && foundQuestion?.question) {
        const shiftedQuestion = Object.assign(
          {},
          {
            answer: foundQuestion["answer"] || null,
            question: foundQuestion["question"],
            question_score: foundQuestion["question_score"],
            question_id: foundQuestion["question_id"],
            options: foundQuestion["options"].map((option) => ({
              ...option,
              answer: option["answer"],
              question_option_title: option["optionTitle"],
            })),
          }
        );
        if (this.questionCount > 0) {
          --this.questionCount;
        }
        this.q.splice(index, 1);
        this.suggestedQues = [shiftedQuestion, ...this.suggestedQues];
      } else {
        this.q.splice(index, 1);
      }

      //  if (this.q.length > 3) {
      //    this.delQ = [...this.delQ, this.q[index]];
      //    if (this.q[index].quest_title && String(this.q[index].quest_title).length > 0) {
      //      this.suggestedQues.unshift({
      //        question: this.q[index].quest_title,
      //        question_id: this.q[index].question_id || null,
      //        options: this.q[index].options.map((m) => {
      //          return {
      //            question_option_title: m.question_option_title,
      //            question_option_id: m.question_option_id,
      //            question_id: m.question_id,
      //          };
      //        }),
      //      });
      //    }
      //    this.q.splice(index, 1);
      //  } else {
      //    if (this.q[index].quest_title && String(this.q[index].quest_title).length > 0) {
      //      this.suggestedQues.unshift({
      //        question: this.q[index].quest_title,
      //        question_id: this.q[index].question_id || null,
      //        options: this.q[index].options.map((m) => {
      //          return {
      //            question_option_title: m.question_option_title,
      //            question_option_id: m.question_option_id,
      //            question_id: m.question_id,
      //          };
      //        }),
      //      });
      //    }
      //    this.q[index].quest_title = "";
      //    this.q[index].options = this.q[index].options.map((m) => ({
      //      ...m,
      //      option: "",
      //      status: false,
      //    }));
      //    this.q[index].score = 0;
      //  }
    },

    // {
    //   que: "",
    //   options: [
    //     { option: "", status: true },
    //     { option: "", status: false },
    //   ],
    //   score: 0,
    //   mandatory: true,
    //   wfc_id: 2,
    // },

    // {
    //   quest_title: "",
    //   score: 0,
    //   wfc_id: 2,
    //   ques_option: [
    //     {
    //       optionTitle: "",
    //       answer: false,
    //     },
    //     {
    //       optionTitle: "",
    //       answer: false,
    //     },
    //   ],
    // },

    correctAns(index, i) {
      if (this.q[index].options[i].answer) {
        this.q[index].answer = this.q[index].options[i].answer;
        this.q[index].options.map((op, opIndex) => {
          if (opIndex != i) {
            op.answer = false;
          }
        });
      }
    },
    getLastJob(payload) {
      payload.company_id = this.$cookies.get("company_id");
      JobService.GetAllJobs(payload).then((res) => {
        let len = res.data.data.alljobs.length - 1;
        this.lastJob = res.data.data.alljobs[len];
      });
    },
    submitJob() {
      console.log(this.selectedSub.subs_pkg_no_of_jobs);

      if (this.selectedSub.subs_pkg_no_of_jobs == 0) {
        this.$toasted.show("You dont have plan to create this job", {
          duration: 6000,
          icon: "clear",
        });
        this.$router.push({ name: "Employer-jobs" });
      }
      const job = this.$store.getters.getJobDetails;
      const wfc = this.$store.getters.getWfc;

      const resp = this.responsibilities.filter((f) => f != "");
      const edu = this.education.filter((f) => f != "");
      const job_id = this.$route.query.d_job_id && this.$route.query.d_job_id != "" ? this.$route.query.d_job_id : this.$store.getters.getNxpId[0];
      console.log("job_id", job_id);
      // const payload = {
      //   job,
      //   wfc,
      //   resp,
      //   edu,
      //   job_id: job_id,
      // };

      let payload = {
        responsibilities: this.responsibilities,
        draft: true,
        questions: this.q.map((m) => {
          console.log("-------------0000000-------------", m);
          return {
            quest_title: m.question,
            score: parseInt(m.question_score),
            wfc_id: m.wfc_id,
            ques_option: m.options,
          };
        }),
        nxpId: job_id,
      };

      console.log("Interview Question", payload);

      // JobServices.InsertJob(payload)
      //   .then((res) => {

      //     if (!res.data.status) {
      //       this.$toasted.show("You have reached max limit of job creation", {
      //         duration: 6000,
      //         icon: "clear",
      //       });
      //       this.$toasted.show("Or You dont have plan", {
      //         duration: 6000,
      //         icon: "clear",
      //       });
      //       this.$router.push({ name: "Employer-jobs" });
      //       return;
      //     }

      //     if ((res.data && res.data.data && res.data.data.length) || (res.data && res.data.data && res.data.data.insertedJob && res.data.data.insertedJob.length)) {
      //       this.$toasted.show("Job Created Successfully!", {
      //         position: "top-right",
      //         duration: 6000,
      //         icon: "check",
      //       });
      //       this.submitQue(res.data.data[0] || res.data.data.insertedJob[0]);
      //     }
      //   })
      //   .catch((err) => {
      //     this.$toasted.show("Job Creation Failed!", {
      //       position: "top-right",
      //       duration: 6000,
      //       icon: "clear",
      //     });
      //   });

      JobServices.InsertVirtualQuestions(payload)
        .then((res) => {
          console.log("res", res);
          this.$toasted.show("Job Added To Draft", {
            position: "top-right",
            duration: 6000,
            icon: "check",
          });
          this.$store.dispatch("onSetJob_Details_With_VI", payload);
          console.log("this.$route.query.d_job_id", this.$route.query.d_job_id);
          if (this.$route.query.d_job_id) {
            const j_id = this.$route.query.d_job_id;
            this.$router.push({
              name: "employer-job-review",
              query: { d_job_id: j_id },
            });
          } else {
            const path = this.$router.path;
            this.$router.push({ name: "employer-job-review" });
          }
        })
        .catch((e) => console.log(e));

      // JobServices.InsertVirtualQuestions(payload)
      //   .then((res) => {
      //     this.$toasted.show("Job Created Successfully!", {
      //       position: "top-right",
      //       duration: 6000,
      //       icon: "check",
      //     });
      //     this.$router.push({
      //       name: "Emp-Job-Success",
      //       params: { job_id: this.$store.getters.getNxpId },
      //     });
      //   })
      //   .catch((e) => console.log(e));
    },
    submitQue(job_id) {
      let ques = [...this.q];
      if (this.virtualVideoInterview) ques.push(this.vedioInterviewQues);

      if (!this.virtualInterview && !this.virtualVideoInterview) {
        this.q = [
          {
            que: "",
            options: [
              { option: "", status: true },
              { option: "", status: false },
            ],
            score: 0,
            mandatory: true,
            wfc_id: 2,
          },
          {
            que: "",
            options: [
              { option: "", status: true },
              { option: "", status: false },
            ],
            score: 0,
            mandatory: true,
            wfc_id: 2,
          },
          {
            que: "",
            options: [
              { option: "", status: true },
              { option: "", status: false },
            ],
            score: 0,
            mandatory: true,
            wfc_id: 2,
          },
        ];
      }
      const payload = { ques: ques, temp_id: this.temp_id };
      console.log("payload========>", payload);
      QuestionServices.InsertQuestion(payload, job_id)
        .then((res) => {
          this.$toasted.show("Questions added successfully!", {
            position: "top-right",
            duration: 6000,
            icon: "check",
          });
          // this.q = [
          //   {
          //     que: "",
          //     options: [],
          //     ans: "",
          //     score: null,
          //     mandatory: false,
          //     wfc_id: 2,
          //     eq: null,
          //   },
          // ];
          this.$store.dispatch("onResetJobDetail");
          this.$router.push({
            name: "Job-Success",
            params: { job_id: job_id },
          });
        })
        .catch((err) => {
          this.$toasted.show("Questions not Submitted!", {
            position: "top-right",
            duration: 6000,
            icon: "clear",
          });
        });
    },
  },
};
</script>

<style scoped>
.validate-error {
  color: #ff4b4b;
  font-size: 13px;
  padding: 4px;
  font-family: "pn-regular";
  margin-top: -15px;
}

.grid-box {
  display: grid;
  grid-template-columns: 60% 40%;
}

.question-list {
  height: 100vh;
  overflow-y: scroll;
  background: white;
}

.no-form-control {
  background: white !important;
  border: 1px solid white !important;
  height: 30px !important;
  margin-bottom: 0px !important;
}

/* tab styles */
.myTab {
  width: 350px;
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(57, 39, 142, 1);
}

.myTabText {
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}


.Rectangle24 {
  width: 84px;
  height: 6px;
  background: white;
  border-radius: 7px;
}

.for-mob {
  display: none !important;
}

.btn-fs-8 {
  font-size: 12px;
}

.my-grid-box {
  display: grid;
  grid-template-columns: 60% 40%;
}



@media only screen and (max-width: 768px) {
  .my-col-6 {
    max-width: 50%;
    font-size: large;
    padding: 10px 10px !important;
    margin-block: 10px;
  }

  .vi-btn {
    background: #6244e5;
    border: none;
    padding: 10px;
    width: 100%;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    color: white;
  }

  .for-desktop {
    display: none !important;
    ;
  }

  .for-mob {
    display: block !important;
    ;
  }

  .job-create .question-name {
    display: grid;
    grid-template-columns: 100% !important;
  }

  .btn-fs-8 {
    font-size: 8px;
  }

  .my-grid-box {
    display: grid;
    grid-template-columns: 100%;
  }
}
</style>

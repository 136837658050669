import Api from "./Api";
import { API_VERSION } from "@/config/index";

export default {
  GetAllIndustry() {
    return Api().get(`/${API_VERSION}/webapp/industry-for-filter`);
  },
  GetAllCountries(payload) {
    return Api().get(`/${API_VERSION}/webapp/country-for-filter?role_id=${payload.role_id ? payload.role_id : ""}`);
  },
  GetAllCities() {
    return Api().get(`/${API_VERSION}/webapp/city-for-filter`);
  },
};

<template>
  <div>
    <!-- {{ toggleMenu() }} -->
    <nav class="navbar navbar-expand-md custom-nav-bg fixed-top">
      <!-- logo :: start -->
      <router-link class="navbar-brand" :to="{ name: 'Search-Candidate' }">
        <img src="../../assets/logos/Logo-Rectangle-White.svg" width="150" class="img-fluid" />
      </router-link>
      <!-- logo :: end -->

      <!-- hambergurMenu :: start -->
      <div class="mobile-menu" v-if="isMobileMenu">
        <!-- <button class="btn btn-mobile-menu" @click="showMenu">{{ $gettext("Menu") }}</button> -->
        <button class="btn btn-mobile-menu" @click="showMenu"><span class="material-icons"
            style="color: aliceblue;">menu</span></button>
      </div>
      <!-- hambergurMenu :: end -->

      <!-- collapsibleMenu :: start -->
      <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->
      <div class="navbar-collapse" :class="{ isMobileMenu }">
        <div class="text-right" v-if="isMobileMenu">
          <svg @click="hideMenu" width="3rem" height="3rem" viewBox="0 0 16 16" class="text-body bi bi-x" fill="#ffffff"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
        <ul class="navbar-nav mr-auto"></ul>
        <!-- desktop start -->
        <ul v-if="!isMobileMenu" class="navbar-nav justify-content-center align-items-center">
          <li class="nav-item" v-if="this.$store.getters.getLoggedUser.userToken">
            <b-dropdown style="padding-right: 27px;" text="Jobs" class="nav-dropdown" variant="transparent">
              <template #button-content>
                <span class="text-uppercase mr-1">{{ $gettext("Jobs") }}</span>
                <img src="../../assets/app-icons/arrow-white-down.svg" width="12px" class="ml-1" />
              </template>
              <b-dropdown-item :to="{ name: 'Employer-JobCreation', query: { create_job: true } }" tag="a">{{
                $gettext("Create") }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'Employer-jobs' }" tag="a">{{ $gettext("View") }}</b-dropdown-item>
            </b-dropdown>
          </li>
          <li class="nav-item" v-if="this.$store.getters.getLoggedUser.userToken">
            <router-link class="nav-link" to="/employer-message">{{ $gettext("Messages") }}</router-link>
          </li>

          <li class="nav-item mr-3" v-if="!$store.getters.getLoggedUser.userToken">
            <a class="nav-link">
              <button class="btn bg-white btn-custom__one btn-color py-2" @click="$router.push({ path: '/login' })">{{
                $gettext("Login_Register") }}</button>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link nav-profile-img" v-if="this.$store.getters.getLoggedUser.userToken">
              <b-dropdown style="padding-right: 29px;" right text="Setting" class="nav-dropdown" variant="transparent">
                <template #button-content>
                  <span class="text-uppercase mr-1">{{ $gettext("Profile") }}</span>
                  <img src="../../assets/app-icons/arrow-white-down.svg" width="12px" />
                </template>
                <b-dropdown-item to="/employer-onboarding-1">{{ $gettext("Edit") }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'Create-User' }">{{ $gettext("New_user") }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'Users-List' }">{{ $gettext("View_users") }}</b-dropdown-item>
              </b-dropdown>
            </a>
          </li>
          <li class="nav-item ml-n4">
            <a class="nav-link nav-profile-img" v-if="this.$store.getters.getLoggedUser.userToken">
              <b-dropdown right text="Setting" class="nav-dropdown" variant="transparent">
                <template #button-content>
                  <span class="text-uppercase mr-1"> {{ $gettext("Settings") }}</span>
                  <img src="../../assets/app-icons/arrow-white-down.svg" width="12px" />
                </template>
                <b-dropdown-item :to="{ name: 'Change-Password' }">{{ $gettext("Change_password") }}</b-dropdown-item>
                <b-dropdown-item @click.prevent.stop="logout">{{ $gettext("Logout") }}</b-dropdown-item>
              </b-dropdown>
            </a>
          </li>
          <li style="padding-left: 8px;">

            <LanguageSelector btnBgColor="#ffffff" />

          </li>
        </ul>
        <!-- desktop end -->
        <!-- mobile start -->
        <ul v-if="isMobileMenu" class="navbar-nav" style="align-items: flex-end;padding-right: 24px;">
          <li class="nav-item" v-if="this.$store.getters.getLoggedUser.userToken">
            <b-dropdown text="Jobs" class="nav-dropdown" variant="transparent">
              <template #button-content>
                <span style="padding:0;" class="text-uppercase mr-1">{{ $gettext("Jobs") }}</span>
                <img src="../../assets/app-icons/arrow-white-down.svg" width="12px" class="ml-1" />
              </template>
              <b-dropdown-item :to="{ name: 'Employer-JobCreation', query: { create_job: true } }" tag="a">{{
                $gettext("Create") }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'Employer-jobs' }" tag="a">{{ $gettext("View") }}</b-dropdown-item>
            </b-dropdown>
          </li>
          <li style="padding-right: 10px;" class="nav-item" v-if="this.$store.getters.getLoggedUser.userToken">
            <router-link style="padding: 5px 2px 4px 0px;" class="nav-link" to="/employer-message">{{
              $gettext("Messages")
            }}</router-link>
          </li>

          <li class="nav-item mr-3" v-if="!$store.getters.getLoggedUser.userToken">
            <a class="nav-link">
              <button class="btn bg-white btn-custom__one btn-color py-2" @click="$router.push({ path: '/login' })">{{
                $gettext("Login_Register") }}</button>
            </a>
          </li>

          <li class="nav-item">
            <a style="padding: 0;" class="nav-link nav-profile-img" v-if="this.$store.getters.getLoggedUser.userToken">
              <b-dropdown right text="Setting" class="nav-dropdown" variant="transparent">
                <template #button-content>
                  <span class="text-uppercase mr-1">{{ $gettext("Profile") }}</span>
                  <img src="../../assets/app-icons/arrow-white-down.svg" width="12px" />
                </template>
                <b-dropdown-item to="/employer-onboarding-1">{{ $gettext("Edit") }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'Create-User' }">{{ $gettext("New_user") }}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'Users-List' }">{{ $gettext("View_users") }}</b-dropdown-item>
              </b-dropdown>
            </a>
          </li>
          <li class="nav-item ml-n4">
            <a style="padding: 0;" class="nav-link nav-profile-img" v-if="this.$store.getters.getLoggedUser.userToken">
              <b-dropdown right text="Setting" class="nav-dropdown" variant="transparent">
                <template #button-content>
                  <span style="padding: 0;" class="text-uppercase mr-1"> {{ $gettext("Settings") }}</span>
                  <img src="../../assets/app-icons/arrow-white-down.svg" width="12px" />
                </template>
                <b-dropdown-item :to="{ name: 'Change-Password' }">{{ $gettext("Change_password") }}</b-dropdown-item>
                <b-dropdown-item @click.prevent.stop="logout">{{ $gettext("Logout") }}</b-dropdown-item>
              </b-dropdown>
            </a>
          </li>
          <li style="padding-top: 3px;padding-right: 4px;">

            <LanguageSelector btnBgColor="#ffffff" />

          </li>
        </ul>
        <!-- mobile end -->
      </div>
      <!-- collapsibleMenu :: end -->
    </nav>
  </div>
</template>
<script>
import Vue from "vue";
import LanguageSelector from "../LanguageSelector.vue";
export default {
  name: "MainHeader",
  components: { LanguageSelector },
  data() {
    return {
      username: null,
      token: null,
      imageUrl: null,
      isMobileMenu: window.innerWidth <= 700,
      toggleMenu: false,
    };
  },
  // beforeMount() {
  // console.log(" this.$store.getters.getLoggedUser && :>> ", !this.$store.getters.getLoggedUser.userToken);
  // if (!this.$cookies.get("AIAPADHASHID")) {
  //   this.logout();
  // }
  // },
  created() {
    addEventListener("resize", () => {
      this.isMobileMenu = innerWidth <= 700;
    });
  },
  methods: {
    handleClickOutside(event) {
      if (this.toggleMenu && !this.$el.contains(event.target)) {
        this.hideMenu();
      }
    },
    showMenu: function () {
      this.toggleMenu = true
      document.querySelector(".isMobileMenu").classList.add("show-menu");
    },
    hideMenu: function () {
      this.toggleMenu = false
      document.querySelector(".isMobileMenu").classList.remove("show-menu");
    },
    getImage() {
      this.imageUrl = localStorage.getItem("image");
    },
    setToken() {
      this.token = this.$store.getters.getLoggedUser && this.$store.getters.getLoggedUser.userToken ? true : false;
      this.username = this.$store.getters.getLoggedUser.userName;
      this.imageUrl = this.$store.getters.getProfileImage;
      // this.$store.getters.getSigninFormValues.then((data) => {
      //   const { userToken, userName } = data;
      //   this.token = userToken
      //   this.userName = userName;
      // });
    },
    logout() {
      const payload = {};
      this.$store.dispatch("onLogout");
      //this.$store.commit("ON_LOGOUT", payload);
      // console.log("hitting here");
      // Vue.$cookies.remove("AIAPADHASHID");
      // Vue.$cookies.remove("username");
      // Vue.$cookies.remove("user_id");
      // Vue.$cookies.remove("company_id");
      // Vue.$cookies.remove("role_id");
      // Vue.$cookies.remove("admin");
      // Vue.$cookies.remove("residence");
      // localStorage.removeItem("image");
      // this.$store.dispatch("onLogout");
      // this.$store.dispatch("onResetProfileImage");
      // this.$store.dispatch("onResetApplicantCv");
      // this.$store.dispatch("onResetApplicantExperiance");
      // this.$store.dispatch("onResetApplicantEducation");
      // this.$store.dispatch("onResetApplicantSocialLink");
      // this.$store.dispatch("onResetApplicantPersonalDetails");
      // this.$store.dispatch("onResetApplicantCurrentJob");

      // console.log("Logout");
      // console.log("this.$store.getters :>> ", this.$store.getters);
      // if (this.$route.name !== "Login") {
      //   this.$router.push({ path: "/login" });
      // }
      return true;
    },
  },
  computed: {


  },
  mounted() {
    // Add an event listener to hide the modal when clicking outside of it
    window.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    // Remove the event listener when the component is unmounted to prevent memory leaks
    window.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
<style>
.navbar-none {
  display: none;
}

.navbar {
  font-family: mp-bold;
  text-transform: Uppercase;
  font-size: 16px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
}




.custom-nav-bg {
  background: #6244e5;
}

.custom-button {
  color: #6244e5;
  border-radius: 10px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}

.navbar .navbar-nav .nav-link {
  color: #ffffff;
  letter-spacing: 1px;
  padding-left: 0px !important;
}

.nav-dropdown>button {
  color: #ffffff !important;
}

.navbar .nav-profile-img {
  display: flex;
  align-items: center;
}

.navbar .nav-rounded-circle {
  border-radius: 50%;
  border: 1px solid #6244e5;
}

.navbar .user-dropdown button {
  background: white;
  color: black;
  border: 1px solid white;
}

.dropdownLink {
  color: black !important;
  text-decoration: none !important;
}

.dropdownLink:visited {
  color: black !important;
  text-decoration: none !important;
}

.user-dropdown ul.dropdown-menu.dropdown-menu-right.show {
  border-top: 2px solid #eaeaea !important;
  border-radius: 05px;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.navbar .isMobileMenu {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  width: 50%;
  background-color: #6244e5;
  z-index: 6;
  transition: all 1s;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.navbar .isMobileMenu .profile-nav {
  border: 2px solid #eaeaea;
  display: block;
}

/* .navbar .isMobileMenu .nav-item {
  margin-bottom: 15px;
} */

.navbar .btn-mobile-menu {
  color: #6244e5 !important;
  font-size: 14px;
}

.btn-color {
  color: #6244e5 !important;
}

.navbar .show-menu {
  right: 0 !important;
}

/* div#dropdown-1 {
  background:transparent !important;
}
div#dropdown-1:after {
  background-image: url("../../assets/app-icons/arrow-black-down.svg");

  background-size: 10px 20px;
  display: inline-block;
  width: 10px;
  height: 20px;
  content: "";
  filter: brightness(0%);
  position: absolute;
  right: -5px;
  top: 08px;
}

div#dropdown-1 .btn:hover {
  z-index: 0;
} */
</style>

import Api from "./Api";
import { API_VERSION } from "@/config/index";

export default {
  GetAllCities(payload) {
    return Api().get(`/${API_VERSION}/webapp/city?currentpage=${payload.current}&perpage=${payload.pageSize}&search=${payload.searchText}`);
  },

  // old
  // GetAllCountry(payload) {
  //   return Api().get(`/${API_VERSION}/webapp/country?role_id=${payload.role_id ? payload.role_id : ""}&job_create=${payload.job_create ? payload.job_create : ""}`);
  // },
  // new
  GetAllCountry(payload) {
    return Api().get(`/${API_VERSION}/webapp/country`);
  },
  GetAllCityOfCountry(payload) {
    return Api().post(`/${API_VERSION}/webapp/city-of-country` + `${payload.all ? `?all` : ``}`, payload);
  },
  GetCityForHome(payload) {
    return Api().get(`/${API_VERSION}/webapp/city-home?search=${payload.searchText ? payload.searchText : ""}&role_id=${payload.role_id ? payload.role_id : ""}`);
  },
  GetCountryForHome(payload) {
    return Api().get(`/${API_VERSION}/webapp/country-home?search=${payload.searchText ? payload.searchText : ""}&role_id=${payload.role_id ? payload.role_id : ""}`);
  },
};

<template>
  <div style="padding: 20px;">
    <h1 class="text-center my-5">Pricing Plans</h1>
    <div class="text-center mb-4">
      <span :class="{ 'font-weight-bold': !isYearly }" class="mr-3">Monthly</span>
      <b-form-checkbox style="display: inline;" v-model="isYearly" switch></b-form-checkbox>
      <!-- <span :class="{ 'font-weight-bold': isYearly }" class="ml-2">Yearly {{ isYearly ? "(1 Month FREE)" : "" }}</span> -->
      <span :class="{ 'font-weight-bold': isYearly }" class="ml-2">Yearly (1 Month FREE)</span>
    </div>

    <div class="card-deck">
      <b-card v-for="(plan, index) in plans" :key="index" class="text-center border-0"
        :class="{ 'selected': selectedPlan === plan }">
        <template #header>
          <h5 style="font-size: 1.2rem;" class="font-weight-bold" v-html="getCardHeader(plan)"> </h5>
          <h5>
            {{ getFormattedPrice(plan) }}
          </h5>
        </template>
        <b-card-text>
          <div class="list">
            <!-- <h5 v-if="isYearly"> {{ plan.tier === 'Enterprise' ? "" : "1 month free" }}</h5> -->
            <h5>{{ plan.tier === 'Enterprise' ? "25+ Jobs Per Month" : plan.tier }}</h5>
            <!-- <h5>{{ plan.tier === 'Enterprise' ? plan.Remark : "" }}</h5> -->
            <h5>Multi-user</h5>
            <h5>Access to all features</h5>
          </div>
          <!-- <h3 class="mb-4">{{ plan.tier === "Enterprise" ? "" : "Price" }}</h3> -->
          <!-- <p class="display-4 font-weight-bold">{{ getFormattedPrice(plan) }}</p> -->
        </b-card-text>
        <!-- <b-button v-if="plan.tier !== 'Enterprise'" variant="primary" @click="selectPlan(plan)">{{ plan.tier ===
          "Enterprise" ? "Contact Us" : "Subscribe Now" }}</b-button> -->
        <!-- <b-button v-if="plan.tier !== 'Enterprise'" variant="primary" @click="selectPlan(plan)">Subscribe Now</b-button> -->
        <b-button v-if="plan.tier !== 'Enterprise'" variant="primary" @click="selectPlan(plan)">Subscribe
          Now</b-button>
        <b-button v-if="plan.tier === 'Enterprise'" variant="primary" @click="modalShow = !modalShow">Contact
          Us</b-button>
      </b-card>
      <template>
        <div>
          <!-- <b-modal v-model="modalShow">
            Hello From Modal!
          </b-modal> -->

          <b-modal v-model="paymentModalShow"
            :title="this.isYearly ? `${this.selectedPlan.tier} Yearly (1 Month FREE)` : `${this.selectedPlan.tier} (Monthly)`"
            @hidden="resetPaymentModal" hide-footer>
            <ValidationObserver ref="form1">
              <div class="customized-form support-form">
                <ValidationProvider name="Firstname" rules="required" v-slot="{ errors, classes }">
                  <label>First Name</label>
                  <b-form-input placeholder="First Name" v-model="billinDetails.fname" :class="classes"></b-form-input>

                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>

              <!-- <div class="customized-form support-form">
                <ValidationProvider name="Lastname" rules="required" v-slot="{ errors, classes }">
                  <label>Last Name</label>
                  <b-form-input placeholder="Last Name" v-model="billinDetails.lname" :class="classes"></b-form-input>

                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div> -->

              <div class="customized-form support-form">
                <ValidationProvider name="Phone" rules="required" v-slot="{ errors, classes }">
                  <label>Phone</label> <small style="color:black">(e.g., +911234567890 or +256123456789)</small>
                  <b-form-input placeholder="Phone" v-model="billinDetails.phone" maxlength="13" :class="classes"
                    type="tel"></b-form-input>

                  <div class="error-msg" v-if="phoneErr">
                    <span class="phoneErr">
                      {{ errors[0] ? "" : "Please enter a valid phone number!" }}</span>
                  </div>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <!-- <div class="customized-form support-form">
                <ValidationProvider name="PostcodeOrPobox" rules="required" v-slot="{ errors, classes }">
                  <label>Postcode or P.O. Box</label>
                  <b-form-input placeholder="Postcode or P.O. Box" v-model="billinDetails.Postcode"
                    :class="classes"></b-form-input>

                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div> -->
              <div class="mb-3">
                <vue-recaptcha :sitekey="reCaptchaSiteKey" @verify="captchaVerify" @expired="captchaExpired"
                  @error="captchaError"></vue-recaptcha>
              </div>
              <button id="submit1" class="btn btn-primary" @click="validateInput">Submit</button>
            </ValidationObserver>
          </b-modal>

          <b-modal v-model="modalShow" title="Enterprise Plan" @hidden="resetModal" hide-footer>
            <ValidationObserver ref="form">
              <div class="customized-form support-form">
                <input type="hidden" name="_cc" value="ruchitha.deshpande@binarynumbers.io" />
                <div>
                  <ValidationProvider name="userName" rules="required" v-slot="{ errors, classes }">
                    <label>Name</label>
                    <b-form-input placeholder="Name" v-model="formData.name" :class="classes"> </b-form-input>

                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div>
                  <ValidationProvider name="email" rules="required|email" v-slot="{ errors, classes }">
                    <label>Email</label>
                    <b-form-input placeholder="E-mail" v-model="formData.email" :class="classes"> </b-form-input>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div>
                  <ValidationProvider name="phone" rules="required" v-slot="{ errors, classes }">
                    <label>Phone</label> <small style="color:black">(eg. +911234567890 or +256123456789)</small>
                    <b-form-input placeholder="phone" v-model="formData.phone" maxlength="13" :class="classes"
                      type="tel"></b-form-input>
                    <div class="error-msg" v-if="phoneErr">
                      <span class="phoneErr">
                        {{ errors[0] ? "" : "Please enter a valid phone number!"
                        }}</span>
                    </div>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>

                <div class="mb-3">
                  <vue-recaptcha :sitekey="reCaptchaSiteKey" @verify="captchaVerify" @expired="captchaExpired"
                    @error="captchaError"></vue-recaptcha>
                </div>
                <button id="submit" class="btn btn-primary" @click="validateInput1">Submit</button>
              </div>
            </ValidationObserver>
          </b-modal>
        </div>
      </template>
    </div>
    <!-- <template>
      <div>
        <a :href="gatewayDetails.payment_gateway_url" target="_blank">Proceed to Payment</a>

      </div>
    </template> -->
  </div>
</template>

<script>
import RecruiterJobService from "@/apiServices/RecruiterJobService";
import UserService from "@/apiServices/UserService";
import CompanyServices from "@/apiServices/CompanyServices";
import EmpSubscriptionServices from "@/apiServices/EmpSubscriptionServices";
import { reCaptchaSiteKey } from "@/config";
import VueRecaptcha from "vue-recaptcha";


export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      company_data: null,
      captchaValue: null,
      reCaptchaSiteKey: reCaptchaSiteKey,
      modalShow: false,
      paymentModalShow: false,
      // error: false,
      errors: {},
      phoneErr: false,
      formData: {
        name: null,
        email: null,
        phone: null,
        // message: null
      },
      billinDetails: {
        fname: null,
        // lname: null,
        phone: null,
        // Postcode: null
      },
      gatewayDetails:
      {
        // "gateway_buyer_uuid": null,
        "payment_token": null,
        "payment_gateway_url": null
      }
      ,
      selectedPlan: {
        plan_id: null,
        tier: null,
        Monthly_Price: null,
        Annual_Price: null,
        currency_id: null,
        Remark: null,
        currency_code: null
      },
      plans: [
        // {
        //   plan_id: 1,
        //   tier: '3 Jobs per month',
        //   Monthly_Price: 102000,
        //   Annual_Price: 1000000,
        //   currency_id: 2,
        //   Remark: 'Annual Price (1 month free)',
        //   currency_code: 'TZS'
        // },
        // {
        //   plan_id: 2,
        //   tier: '5 Jobs per month',
        //   Monthly_Price: 153000,
        //   Annual_Price: 1000000,
        //   currency_id: 2,
        //   Remark: 'Annual Price (1 month free)',
        //   currency_code: 'TZS'
        // },
        // {
        //   plan_id: 3,
        //   tier: '10 Jobs per month',
        //   Monthly_Price: 275400,
        //   Annual_Price: 1000000,
        //   currency_id: 2,
        //   Remark: 'Annual Price (1 month free)',
        //   currency_code: 'TZS'
        // },
        // {
        //   plan_id: 4,
        //   tier: '25 Jobs per month',
        //   Monthly_Price: 619650,
        //   Annual_Price: 1000000,
        //   currency_id: 2,
        //   Remark: 'Annual Price (1 month free)',
        //   currency_code: 'TZS'
        // },
        // {
        //   plan_id: 5,
        //   tier: 'Enterprise',
        //   Monthly_Price: 0,
        //   Annual_Price: 0,
        //   currency_id: 2,
        //   Remark: 'Email for pricing',
        //   currency_code: 'TZS'
        // }
      ],
      isYearly: false
    };
  },
  beforeMount() {
    // const empRoleId = this.$cookies.get("role_id");
    // const empUserId = this.$cookies.get("user_id");
    const company_id = this.$cookies.get("company_id");
    this.getSingleCompany(company_id)
    this.getPlan();
  },
  methods: {
    captchaVerify(event) {
      console.log(`verify event`, event);
      this.captchaValue = event;
    },
    captchaExpired() {
      this.captchaValue = null;
    },
    captchaError() {
      this.captchaValue = null;
    },
    getPlan() {
      RecruiterJobService.getPlan().then((res) => {
        console.log("getPlan res", res.data.data);
        this.plans = res.data.data;
        console.log("getPlan res", res.data.data.length);
        // this.jobCount = res.data.data.length;

        // if (res.data.data.length > 3) {
        //   this.$router.push({
        //     path: `/employer-pricing`,
        //     // query: { d_job_id: queryId },
        //   });

        // }
      });
    },
    selectPlan(plan) {
      this.selectedPlan = plan;
      this.billinDetails.fname = this.company_data.company_admin_name
      this.paymentModalShow = !this.paymentModalShow
      console.log(` this.selectedPlan ${JSON.stringify(this.selectedPlan)}`);

      // EmpSubscriptionServices.OrderUserExistsOrNot(this.company_data.user_id)
      //   .then((res) => {
      //     console.log("res", res.data.data);
      //     if (res.data.data.length > 0) {
      //       const existingOrder = res.data.data[0]
      //       existingOrder.updated_by = this.company_data.user_id
      //       console.log(existingOrder);
      //       const { billing_firstname, billing_lastname, billing_postcode_or_pobox, billing_phone } = existingOrder
      //       this.billinDetails.fname = billing_firstname
      //       this.billinDetails.lname = billing_lastname
      //       this.billinDetails.phone = billing_phone
      //       this.billinDetails.Postcode = billing_postcode_or_pobox
      //       this.paymentModalShow = !this.paymentModalShow

      //     } else {

      //       this.paymentModalShow = !this.paymentModalShow
      //     }
      //   })
      //   .catch((errors) => {
      //     console.log("errors", errors);
      //     this.paymentModalShow = !this.paymentModalShow
      //   })
    },
    getCardHeader(plan) {

      if (plan.tier === "3 Jobs per month") {
        return "3 Pack"

      } else if (plan.tier === "5 Jobs per month") {
        return "5 Pack"
      } else if (plan.tier === "10 Jobs per month") {
        return "10 Pack"
      } else if (plan.tier === "25 Jobs per month") {
        return "25 Pack"
      } else {
        return `Enterprise <br/> <br/><br/>`
      }
    },
    getFormattedPrice(plan) {
      const price = this.isYearly ? plan.Annual_Price : plan.Monthly_Price;
      const currencyCode = plan.currency_code;
      if (price === 0) {
        // return plan.Remark;
        return "";
      } else {
        const formattedPrice = new Intl.NumberFormat().format(price);
        // console.log(`${currencyCode} ${formattedPrice}`);
        return `${currencyCode} ${formattedPrice}`;
      }
    },
    getSingleCompany(company_id) {
      CompanyServices.GetSingleCompany({ company_id })
        .then((res) => {
          this.company_data = res.data.data[0];
          console.log(this.company_data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async contactUs() {
      let loader = this.$loading.show();
      try {
        let response = await UserService.contactUs({
          name: this.formData.name,
          email: this.formData.email,
          // message: this.formData.message,
          phone: this.formData.phone
        });

        const { data } = response;
        loader.hide();
        if (data.status) {
          this.$toasted.show("Success!", {
            duration: 6000,
            icon: "check",
          });
          this.formData = {
            name: '',
            email: '',
            phone: '',
            // message: ''
          }
          this.modalShow = false
          this.captchaValue = null
        } else {
          this.$toasted.show(data.message || "Failed!", {
            duration: 6000,
            icon: "clear",
          });
        }
      } catch (e) {
        console.log(`e`, e);
        loader.hide();
        this.$toasted.show("Failed!", {
          duration: 6000,
          icon: "clear",
        });
      }
    },
    validateInput(data) {
      this.$refs.form1.validate().then((result) => {
        if (result) {
          console.log(data);
          if (!this.billinDetails.phone.match(/^(?:\+256|\+255|\+254|\+971)\d{9}$|\+91\d{10}$/)) {
            this.$toasted.show("Please enter a valid phone number!", { duration: 6000 });
            this.phoneErr = true
            console.log(this.phoneErr);
            return
          }
          else {
            this.phoneErr = false
          }
          if (this.captchaValue) {
            console.log("this.captchaValue", this.captchaValue);
            console.log("this.this.billinDetails", this.billinDetails);

            // const orderJson = {
            //   "company_id": this.company_data.country_id,
            //   "role_id": this.company_data.role_id,
            //   "buyer_email": this.company_data.company_official_email,
            //   "buyer_name": this.company_data.company_admin_name,
            //   "buyer_userid": this.company_data.user_id,
            //   "buyer_phone": this.billinDetails.phone,
            //   "gateway_buyer_uuid": "",
            //   "amount": this.isYearly ? this.selectedPlan.Annual_Price : this.selectedPlan.Monthly_Price,
            //   "currency": this.selectedPlan.currency_code,
            //   "payment_methods": "ALL",
            //   "billing_firstname": this.billinDetails.fname,
            //   "billing_lastname": this.billinDetails.lname,
            //   "billing_address_1": this.company_data.city_name,
            //   "billing_city": this.company_data.city_name,
            //   "billing_state_or_region": this.company_data.city_name,
            //   "billing_postcode_or_pobox": this.billinDetails.Postcode,
            //   "billing_country": this.company_data.country_name,
            //   "billing_phone": this.billinDetails.phone,
            //   "no_of_items": 1,
            //   "created_by": this.company_data.user_id,
            //   // "created_at": null,
            // }
            // console.log(orderJson);
            // add your code here to handle the selected plan

            var orderJson = {
              "user_id": this.company_data.user_id,
              "buyer_email": this.company_data.company_official_email,
              "buyer_name": this.billinDetails.fname,
              "buyer_phone": this.billinDetails.phone,
              "amount": this.isYearly ? this.selectedPlan.Annual_Price : this.selectedPlan.Monthly_Price,
              "currency": "TZS",
              // "buyer_remarks": "None",
              // "merchant_remarks": "None",
              "no_of_items": 1
            }
            EmpSubscriptionServices.Subscription(orderJson)
              .then((res) => {
                console.log("res", res.data);
                this.$store.dispatch("setPaymentResp", res.data.data.orderRes);
                localStorage.setItem("payment", res.data.data.order_id);
                const redirectUrl = res.data.data.decodedPaymentGatewayUrl
                // this.gatewayDetails.gateway_buyer_uuid = res.data.data[0].gateway_buyer_uuid
                // this.gatewayDetails.payment_gateway_url = res.data.data[0].payment_gateway_url
                this.gatewayDetails.payment_gateway_url = redirectUrl
                // this.gatewayDetails.payment_token = res.data.data.orderRes.data[0].payment_token
                // this.gatewayDetails.qr = res.data.data[0].qr
                this.paymentModalShow = false
                this.captchaValue = null
                window.location.href = redirectUrl;
                // this.$toasted.show("Please verify captcha", { duration: 6000 });
              })
              .catch((e) => {
                console.log(e);
              })
          } else {
            this.$toasted.show("Please verify captcha", { duration: 6000 });
          }
        } else {
          this.$toasted.show("Some fields are missing!", { duration: 6000 });
        }

      });

    },
    validateInput1() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          console.log(this.formData);
          if (!this.formData.phone.match(/^(?:\+256|\+255|\+254|\+971)\d{9}$|\+91\d{10}$/)) {
            this.$toasted.show("Please enter a valid phone number!", { duration: 6000 });
            this.phoneErr = true
            console.log(this.phoneErr);
            return
          }
          else {
            this.phoneErr = false
          }
          if (this.captchaValue) {
            console.log("this.captchaValue", this.captchaValue);

            this.contactUs();
          } else {
            this.$toasted.show("Please verify captcha", { duration: 6000 });
          }
        } else {
          this.$toasted.show("Some fields are missing!", { duration: 6000 });
        }

      });

    },
    resetModal() {
      this.formData = {
        name: '',
        email: '',
        phone: '',
        // message: ''
      }
      this.modalShow = false
      this.captchaValue = null
    },
    resetPaymentModal() {
      this.billinDetails = {
        fname: null,
        lname: null,
        phone: null,
        Postcode: null
      }
      this.paymentModalShow = false
      this.captchaValue = null
    },
  }
};
</script>

<style scoped>
.selected {
  box-shadow: 0 0 20px rgba(0, 123, 255, 0.5);
  transform: scale(1.05);
  z-index: 1;
}

.card-deck {
  display: flex;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
  flex-wrap: nowrap;
  justify-content: center;
}

.card-header {
  text-align: left;
  background-color: #6244e5;
  border: none;
  border-radius: 0;
  color: white;
  font-size: 1.5rem;
  max-height: 96px;
  font-weight: 600;
  padding: 1.5rem;
}


.card {
  border-radius: 10px;
  border: none;
  min-width: 219px;
  max-width: 250px;
  margin: 10px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}


.card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.card-body {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  align-content: center;
}

.display-4 {
  font-size: 1.5rem;
  line-height: 1;
}



.list {
  text-align: left;
}

.phoneErr {
  color: #ff4b4b;
  font-family: "pn-regular";
  padding: 4px;
  display: inherit;
  margin-top: -15px;
  font-size: 13px;
}

@media only screen and (max-width: 1218px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    justify-content: center;
  }


}
</style>

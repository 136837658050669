<template>
  <div class=" payment-status">

    <div class=" order-id">
      <img class="py-3" src="@/assets/app-icons/pament_reject.svg" alt="check-icon" />
      <div v-if="orderStatus?.order_id" class="">
        <div style="text-align: center;">
          <span style="font-weight: bold;">ORDER ID:</span>
          <span>{{ orderStatus?.order_id && ` #${orderStatus.order_id}` }}</span>
        </div>
        <h2 class="fpn-b text-center">Payment Canceled!</h2>
      </div>
      <!-- <p class="mb-0">{{ $gettext("Candidates_on_our_site") }}</p> -->
    </div>
  </div>
</template>

<script>
import EmpSubscriptionServices from "@/apiServices/EmpSubscriptionServices";
export default {
  name: "PaymentStatus",
  components: {

  },
  data() {
    return {
      payment: null,
      orderStatus: null
    };
  },
  beforeMount() {
    // let paymentResp = this.$store.getters.getPaymentResp;
    // console.log("PaymentResp", paymentResp);
    this.payment = localStorage.getItem("payment");
    console.log("this.payment", this.payment);
    if (this.payment !== null) {

      this.getPaymentStatus({ "order_id": this.payment })
    }

  },
  methods: {
    getPaymentStatus(payment) {
      EmpSubscriptionServices.SubscriptionStatus(payment)
        .then((res) => {
          console.log("SubscriptionStatus::res", res.data.orderStatusRespose);
          this.orderStatus = res.data.orderStatusRespose

          if (res.data.orderStatusRespose.payment_status === "CANCELLED") {
            localStorage.setItem("payment", null);
          }

        })
    },
  }
};
</script>
<style>
.payment-status {
  display: flex;
  flex-direction: column;
  padding: 175px 20px 20px 20px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.order-id {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  align-items: center;
  padding: 10px;
  justify-content: center;
  align-content: center;
}
</style>
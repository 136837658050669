<template>
    <div class="bg-grey">
        <b-container fluid class="py-3 mt-5 job-listings">
            <b-row class="pt-5 bg-primary for-mob" style="position: relative; top:-21px">
                <b-col md="12" class="py-5 bg-primary mx-auto">

                    <div class="">
                        <div class="BasicDetails" style="margin-bottom: 20px;
                text-align: center; 
                color: white; 
                font-size: 18px; 
                font-weight: 700; 
                word-wrap: break-word">
                            {{ isJobReview ? `4.Reviews/Suggestion` : `` }}
                        </div>
                        <div class="d-flex flex-wrap justify-content-around">
                            <div className="Rectangle24" :style="isJobReview ? 'opacity: 1.0' : 'opacity: 0.5'"
                                style="width: 84px;height: 6px;background: white;border-radius: 7px;"></div>
                            <div className="Rectangle24" :style="isJobReview ? 'opacity: 1.0' : 'opacity: 0.5'"
                                style="width: 84px;height: 6px;background: white;border-radius: 7px;opacity: 0.50"></div>
                            <div className="Rectangle24" :style="isJobReview ? 'opacity: 1.0' : 'opacity: 0.5'"
                                style="width: 84px;height: 6px;background: white;border-radius: 7px;opacity: 0.50"></div>
                            <div className="Rectangle24" :style="isJobReview ? 'opacity: 1.0' : 'opacity: 0.5'"
                                style="width: 84px;height: 6px;background: white;border-radius: 7px;opacity: 0.50"></div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div class="job-review">
                <b-container class="box" style="padding: 10px;  margin-bottom: 5px;">
                    <h4 class="heading">Review the new job</h4>
                </b-container>
                <b-container class="box" style="padding: 10px;  margin-bottom: 5px;">
                    <h4 class="heading">Basic Details</h4>
                    <hr />
                    <b-row>
                        <b-col cols="12" sm="6">
                            <b-form-group label="Job Title*" label-for="job-title">
                                <b-form-input id="job-title" v-model="jobDetails.job_title" disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <b-form-group label="Job Category*" label-for="job-category">
                                <b-form-input id="job-category" v-model="jobDetails.job_category.checkbox"
                                    disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12">
                            <b-form-group label="Job Description*" label-for="job-description">
                                <b-form-textarea style="min-height: 80px;" id="job-description"
                                    v-model="jobDetails.job_description" disabled></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" sm="6">
                            <b-form-group label="Location*" label-for="location">
                                <p class="form-control" style="padding-top:12px"> {{ formattedCountryNames }}</p>
                                <!-- <b-form-input id="location" v-model="jobDetails.country[0].label" disabled></b-form-input> -->
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <b-form-group label="City*" label-for="city">
                                <!-- <b-form-input id="city" v-model="jobDetails.city[0].label" disabled></b-form-input> -->
                                <p class="form-control" style="padding-top:12px"> {{ formattedCityNames }}</p>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" sm="6">
                            <b-form-group label="No. Of Vacancies*" label-for="vacancies">
                                <b-form-input type="number" id="vacancies" v-model="jobDetails.vacancies"
                                    disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <b-form-group label="Type of Job*" label-for="job-type">
                                <!-- {{ jobDetails.job_type[0].job_type_title }} -->
                                <b-form-input d="job-type" v-model="jobDetails.job_type" disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" sm="6">
                            <b-form-group label="Min. Salary/Month*" label-for="min-salary">
                                <b-form-input type="number" id="min-salary" v-model="jobDetails.job_salary_range_from"
                                    disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <b-form-group label="Max. Salary/Month*" label-for="max-salary">
                                <b-form-input type="number" id="max-salary" v-model="jobDetails.job_salary_range_to"
                                    disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" sm="6">
                            <b-form-group label="Currency" label-for="currency">
                                <b-form-input id="currency" v-model="jobDetails.currency.currency_name"
                                    disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <b-form-group label="Job Deadline*" label-for="job-deadline">
                                <b-form-input type="text" id="job-deadline" v-model="jobDetails.job_end_date"
                                    disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container class="box" style="padding: 10px;  margin-bottom: 5px;">
                    <h4 class="heading">Candidate Details</h4>

                    <b-row>
                        <b-col cols="12" sm="6">
                            <b-form-group label="Experience*" label-for="experience">
                                <b-form-input id="experience" v-model="jobDetails.experience" disabled></b-form-input>
                            </b-form-group>
                        </b-col>


                        <b-col v-if="jobDetails.education" cols="12" sm="6">
                            <b-form-group label="Education" label-for="education">
                                <p class="form-control"
                                    style="padding-top: 12px;position: relative;overflow: auto;min-height: 80px;"> {{
                                        formattedEducationNames }}</p>
                                <!-- <b-form-input id="education" v-model="jobDetails.education[0].ms_edu_degree"
                                disabled></b-form-input> -->
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" sm="6">
                            <b-form-group label="Skills*" label-for="skills">
                                <p class="form-control"
                                    style="padding-top: 12px;position: relative;min-height: 80px;overflow: auto;"> {{
                                        formattedSkillsNames }}</p>

                                <!-- <b-form-input id="skills" v-model="jobDetails.job_skills[0].skill_name" disabled></b-form-input> -->
                            </b-form-group>
                        </b-col>
                        <b-col v-if="jobDetails.nationality" cols="12" sm="6">
                            <b-form-group label="Nationality" label-for="nationality">
                                <p class="form-control" style="padding-top:12px"> {{ formattedNationalityNames }}</p>

                                <!-- <b-form-input id="nationality" v-model="jobDetails.nationality[0].nationality"
                                disabled></b-form-input> -->
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row v-if="jobDetails.gender">
                        <b-col cols="12" sm="6">
                            <b-form-group label="Gender" label-for="gender">
                                <b-form-input id="gender" v-model="jobDetails.gender" disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                </b-container>


                <b-container class="box" style="padding: 10px;  margin-bottom: 5px;">
                    <h4 class="heading">Responsibilities</h4>
                    <hr>
                    <!-- responsibilities :: start -->
                    <b-row v-for="(responsibility, index) in VirtualInterview.responsibilities" :key="index">
                        <b-col cols="12" sm="12">
                            <b-form-group label=" " label-for="Responsibilities">
                                <b-form-input id="Responsibilities" v-model="responsibility.responsibility"
                                    disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <!-- responsibilities :: end -->
                </b-container>
                <b-container class="box" style="padding: 10px;  margin-bottom: 5px;">
                    <h4 class="heading">Virtual Interview</h4>
                    <hr />
                    <div v-for="(question, index) in VirtualInterview.questions" :key="index" class="box  px-3 mt-3">
                        <span style="font-weight: bold; position: relative; font-size: 20px; top: 17px;">{{ parseInt(index +
                            1)
                        }}</span>
                        <hr />
                        <div>
                            <!--End Question Name-->
                            <div class="question-name my-3">
                                <div class="left">

                                    <b-form-input class="form-control" v-model="question.quest_title"
                                        placeholder="Sample_Text_Based_Question_goes_here" disabled>
                                    </b-form-input>
                                </div>
                            </div>
                            <!--End Question Name-->

                            <!--Start Question Input-->
                            <div class="question-input">
                                <div v-for="(option, i) in question.ques_option" :key="i" class="question-input-inner">
                                    <div class="left">
                                        <b-form-input class="form-control" v-model="option.optionTitle"
                                            placeholder="Enter_Option" disabled></b-form-input>
                                    </div>
                                    <div class="right d-flex">
                                        <b-form-checkbox class="radio-square text-dark" v-model="option.answer"
                                            disabled>Mark_As_Correct
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                            <!--End Question Input-->

                            <!--Start Question status-->
                            <div class="question-status pt-4" style="width:60px; text-align: center;">
                                <div class="score">
                                    <h6 class="fmp-b">Score</h6>
                                    <b-form-input type="number" v-model="question.score" disabled> </b-form-input>
                                </div>
                            </div>
                            <!--End Question Status-->

                        </div>

                    </div>
                </b-container>







                <div class="offset-md-4 col-md-4 d-flex justify-content-between text-center p-4">
                    <button @click="goBack" class="btn btn-light btn-md" style="min-width: 100px">BACK</button>
                    <button @click="confirm" class="btn btn-primary btn-md" style="min-width: 100px">CONFIRM </button>
                </div>
            </div>
        </b-container>
    </div>
</template>
  
<script>
import JobServices from "@/apiServices/RecruiterJobService";
import RecruiterJobService from "@/apiServices/RecruiterJobService";
import moment from "moment";
export default {
    components: {},
    data() {
        return {
            isJobReview: true,
            jobDetails: {
                job_id: null,
                job_title: null,
                job_category: null,
                job_description: null,
                country: [],
                city: [],
                vacancies: null,
                job_type: null,
                job_salary_range_from: null,
                job_salary_range_to: null,
                currency: null,
                job_end_date: null,
                experience: null,
                education: null,
                job_skills: null,
                nationality: null,
                gender: null,
            },
            VirtualInterview: {
                responsibilities: [
                    {
                        responsibility: null,
                    },
                ],
                draft: false,
                questions: [
                    {
                        quest_title: null,
                        score: null,
                        wfc_id: null,
                        ques_option: [
                            {
                                optionTitle: null,
                                answer: null,
                            },
                            {
                                optionTitle: null,
                                answer: null,
                            },
                        ],
                    },
                ],
                nxpId: null,
            }
        }

    },

    beforeUpdate() { },
    beforeMount() {
        const jobData = this.$store.getters.getJobDetails ? this.$store.getters.getJobDetails : null;
        console.log("jobData", jobData);
        // this.VirtualInterview = { ...storeData };
        console.log("this.jobDetails", this.jobDetails);
        const jobVI = this.$store.getters.getJobDetailsWithVI;
        console.log("jobVI", jobVI);
        this.VirtualInterview = jobVI;

        if (jobData.job_title) {
            this.getDraftJob(jobVI.nxpId)
            // this.jobDetails.job_title = jobData.job_title;
            // this.jobDetails.job_category = jobData.job_category;
            // this.jobDetails.job_description = jobData.job_description;
            // // this.jobDetails.country = jobData.country;
            // this.jobDetails.country = jobData.country
            // this.jobDetails.city = jobData.city;
            // this.jobDetails.vacancies = jobData.vacancies;
            // // jobDetails.job_type[0].job_type_title 
            // if (this.$route.query.d_job_id) {

            //     this.jobDetails.job_type = jobData.job_type[0];
            //     this.jobDetails.currency = jobData.currency[0];
            // }
            // else {
            //     this.jobDetails.job_type = jobData.job_type;
            //     this.jobDetails.currency = jobData.currency;
            // }
            // this.jobDetails.job_salary_range_from = jobData.job_salary_range_from;
            // this.jobDetails.job_salary_range_to = jobData.job_salary_range_to;
            // this.jobDetails.job_end_date = moment(jobData.job_end_date).format("YYYY-MM-DD HH:mm:ss");//Date(jobData.job_end_date);
            // this.jobDetails.experience = jobData.experience;
            // if (jobData.education === null) {
            //     this.jobDetails.education = jobData.education ? jobData.education : null;

            // } else {
            //     this.jobDetails.education = jobData.education.length ? jobData.education : null;
            // }
            // this.jobDetails.job_skills = jobData.job_skills;
            // this.jobDetails.nationality = jobData.nationality.length ? jobData.nationality : null;
            // this.jobDetails.gender = jobData.gender ? jobData.gender : null;

        }
        else {
            console.log("else and getdraftJob part", this.$route.query.d_job_id);
            this.getDraftJob(this.$route.query.d_job_id)
        }



    },
    mounted() {
        console.log("this.jobDetails", this.jobDetails);
    },
    computed: {
        formattedCountryNames() {
            return this.jobDetails.country.map(country => country.label).join(', ');
        },
        formattedCityNames() {


            return this.jobDetails.city.map(city => city.label).join(', ');
        },
        formattedEducationNames() {

            return this.jobDetails.education.map(education => education.ms_edu_degree).join(', ');
        },
        formattedSkillsNames() {


            return this.jobDetails.job_skills.map(education => education.skill_name).join(', ');
        },
        formattedNationalityNames() {


            return this.jobDetails.nationality.map(nationality => nationality.nationality).join(', ');
        },
    },
    methods: {
        getDraftJob(d_job_id) {
            let loader = this.$loading.show();
            RecruiterJobService.GetSingleDraftJob({ d_job_id })
                .then((res) => {
                    console.log("res::aman", res.data.data);
                    // console.log("data.skill", data.speciality.checkbox_id);
                    const jobData = res.data.data.length ? res.data.data[0] : null;
                    console.log("jobData", jobData);
                    this.jobDetails.job_title = jobData.job_title;
                    this.jobDetails.job_category = jobData.speciality;
                    this.jobDetails.job_description = jobData.job_description;
                    // this.jobDetails.country = jobData.country;
                    this.jobDetails.country = jobData.country
                    this.jobDetails.city = jobData.city;
                    this.jobDetails.vacancies = jobData.vacancy;
                    // jobDetails.job_type[0].job_type_title 
                    // this.jobDetails.job_type = {
                    //     job_type_title: jobData.job_type
                    // }
                    this.jobDetails.job_type = jobData.job_type
                    console.log("this.jobDetails.job_type", this.jobDetails.job_type);

                    if (this.$route.query.d_job_id) {

                        if (jobData.currency.length) {

                            this.jobDetails.currency = jobData.currency[0];
                        }
                        else {

                            this.jobDetails.currency = { currency_name: "" };
                        }
                        // this.jobDetails.currency = jobData.currency[0];
                    }
                    else {
                        if (jobData.currency.length) {

                            this.jobDetails.currency = jobData.currency[0];
                        }
                        else {

                            this.jobDetails.currency = { currency_name: "" };
                        }
                        // this.jobDetails.currency = jobData.currency[0];
                        // this.jobDetails.job_type = jobData.job_type;
                        // this.jobDetails.currency = jobData.currency;
                    }
                    this.jobDetails.job_salary_range_from = jobData.job_salary_range_from;
                    this.jobDetails.job_salary_range_to = jobData.job_salary_range_to;
                    //  this.jobDetails.job_end_date = Date(jobData.job_end_date);
                    this.jobDetails.experience = jobData.job_experiance;
                    if (jobData.education === null) {
                        this.jobDetails.education = jobData.job_education ? jobData.job_education : null;

                    } else {
                        this.jobDetails.education = jobData.job_education.length ?
                            jobData.job_education.map((edu) => {
                                return {
                                    ms_edu_id: edu.jem_id,
                                    ms_edu_degree: edu.job_education,
                                };
                            })
                            : null;
                    }
                    this.jobDetails.job_skills = jobData.skill;
                    this.jobDetails.nationality = jobData.nationality.length ? jobData.nationality : null;
                    this.jobDetails.gender = jobData.gender ? jobData.gender : null;
                    this.jobDetails.job_end_date = moment(jobData.job_end_date).format("YYYY-MM-DD HH:mm:ss");//Date(jobData.job_end_date);
                    loader.hide();
                })
                .catch((err) => {
                    console.log("GetDraftJob Error", err.message);
                    loader.hide();
                });
        },
        confirm() {
            this.VirtualInterview.draft = false
            this.VirtualInterview.confirm = true
            console.log("Payload", this.VirtualInterview);
            JobServices.InsertVirtualQuestions(this.VirtualInterview)
                .then((res) => {
                    this.$toasted.show("Job Created Successfully!", {
                        position: "top-right",
                        duration: 6000,
                        icon: "check",
                    });
                    // this.nxpId = null
                    this.$store.commit("ON_SET_NXPID", null);
                    this.$router.push({
                        name: "Emp-Job-Success",
                        params: { job_id: this.$store.getters.getNxpId },
                    });
                })
                .catch((e) => console.log(e));
        },
        goBack() {

            // this.$router.push({
            //     name: "Employer-JobCreation-1",
            //     // query: { d_job_id: d_job_id, page: 2 },
            // });

            if (this.$route.query.d_job_id) {
                const j_id = this.$route.query.d_job_id;
                this.$router.push({
                    name: "Employer-JobCreation-1",
                    query: { d_job_id: j_id },
                });
            } else {
                const path = this.$router.path;
                this.$router.push({ name: "Employer-JobCreation-1", query: { d_job_id: null }, });
            }
        },

    },
}
</script>

  
<style scoped>
.job-review {
    width: 100%;
    padding-top: 30px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 60px;
    background: #eaeaea;
}

.heading {
    font-family: mp-bold;
    margin-bottom: 0px;
}

.box {
    background-color: white;
    border-radius: 5px;
}

.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat;
}

.form-control {
    background-color: #e9ecef;
    margin-bottom: 15px;
    border: 1px solid #eaeaea;
    font-size: 15px;
    height: 50px;
    font-family: pn-regular;
    transition: none !important;
}

.Rectangle24 {
    width: 84px;
    height: 6px;
    background: white;
    border-radius: 7px;
}

.for-mob {
    display: none !important;
}

@media only screen and (max-width: 768px) {
    .for-desktop {
        display: none !important;
        ;
    }

    .for-mob {
        display: block !important;
        ;
    }

    .job-review[data-v-67625fd4] {
        margin-top: 15px !important;
    }
}
</style>
  
import Api from "./Api";
import { API_VERSION } from "@/config/index";

export default {
  addApplicantProfile(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/job-profile`, payload);
  },
  getApplicantProfile(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/job-profile/${payload.applicant_id}`);
  },
  uploadMainResume(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/upload-main-resume`, payload);
  },
  deleteMainCv() {
    return Api().delete(`/${API_VERSION}/webapp/applicant/upload-main-resume`);
  },
  uploadProfileImage(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/uload-profile-image`, payload);
  },
  deleteProfileImage() {
    return Api().delete(`/${API_VERSION}/webapp/applicant/delete-image`);
  },
  updateApplicantProfile(payload, applicant_id) {
    return Api().patch(`/${API_VERSION}/webapp/applicant/job-profile`, payload);
  },
  getSubscription() {
    return Api().get(`/${API_VERSION}/webapp/subscription`);
  },
  // addUserSubscription(payload) {
  //     return Api().post(`/${API_VERSION}/webapp/applicant/subscription`, payload);
  // },
  getUserSubscription(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/subscription`);
  },
  updateUserSubscription(payload) {
    return Api().put(`/${API_VERSION}/webapp/applicant/subscription`, payload);
  },
  uploadNewCv(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/upload-cv`, payload.formData);
  },
  GetJobCv(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/upload-cv?job_id=${payload.job_id}`);
  },
  DeleteJobCv(payload) {
    return Api().delete(`/${API_VERSION}/webapp/applicant/upload-cv/${payload}`);
  },
  uploadVideo(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/video`, payload);
  },
  deleteVideo(payload) {
    return Api().delete(`/${API_VERSION}/webapp/applicant/video/${payload.video_id}`);
  },
  deleteAllVideos() {
    return Api().delete(`/${API_VERSION}/webapp/applicant/delete-all-videos`);
  },
  GetUploadedVideos(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/video`);
  },

  getAllSkills() {
    return Api().get(`/${API_VERSION}/webapp/skill`);
  },
  getAllIndustry() {
    return Api().get(`/${API_VERSION}/webapp/recruiter/industry`);
  },
  addReferal(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/referal`, payload);
  },
  getReferals(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/referal/${payload.referal_code}`);
  },

  uploadCv(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/pdf-parser`, payload);
  },
  getUploadCv(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/pdf-parser/${payload.parser_id}`);
  },
  GetAllJobTitles() {
    return Api().get(`/${API_VERSION}/webapp/job_titles`);
  },
  GetCurrency() {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-currency`);
  },
  GetAllNationality() {
    return Api().get(`/${API_VERSION}/webapp/nationality`);
  },
  GetAllSpeciality() {
    return Api().get(`/${API_VERSION}/webapp/speciality`);
  },
  AddAnsSuggestedQues(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/suggested-ques-ans`, payload);
  },
  GetSuggestedQuesAns(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/suggested-ques-ans?currentPage=${payload.currentPage}&perPage=${payload.perPage}`);
  },
};

<template>
  <div class="bg-grey">
    <!-- <EmployerHeader /> -->
    <br />
    <br />
    <br />
    <b-container fluid class="job-create-two user-profile-screen emp-profile-screen customized-form">
      <b-row>
        <ValidationObserver ref="resp" tag="div" class="col-md-12 p-0">
          <b-col class="col-md-12">
            <div class="profile-flex pt-4 pb-3">
              <h4 class="heading">{{ $gettext("Responsibilities ") }}</h4>
            </div>
            <div class="question-card">
              <div class="question-name" v-for="(res, index) in responsibilities" :key="index">
                <div class="left">
                  <b-form-input class="form-control" v-model="responsibilities[index].job_responsibility"
                    :placeholder="$gettext('Add_Responsibilities')"> </b-form-input>
                </div>
                <div class="right ml-auto">
                  <button class="btn btn-add" v-show="index != responsibilities.length - 1"
                    @click="removeResponsibility(index)">
                    <img src="@/assets/app-icons/delete.svg" width="25px" />
                  </button>
                </div>

                <div class="right ml-auto" v-show="index == responsibilities.length - 1">
                  <button class="btn btn-add" @click="addResponsibility(index)">+</button>
                  <!-- <div v-show="index != 0"> -->
                  <button class="btn btn-add" @click="removeResponsibility(index)">
                    <img src="@/assets/app-icons/delete.svg" width="25px" />
                  </button>
                  <!-- </div> -->
                </div>
              </div>
              <div v-if="submitted && responsibilities.some((m) => !m.job_responsibility)"
                class="validate-error display-block">
                <span>{{ "This field is required" }}</span>
              </div>
            </div>
          </b-col>
          <div class="my-3 px-3" style="display: flex;flex-direction: row;justify-content: space-evenly;">
            <button type="button" @click="toggleVI()" class="for-mob vi-btn">
              {{ $gettext("Suggested_Virtual_Interview_Questions") }}
            </button>
          </div>
          <!-- <b-col class="col-md-12">
            <div class="profile-flex pt-4 pb-3">
              <h4 class="heading">Education</h4>
            </div>
            <div class="question-card">
              <div class="question-name" v-for="(e, index) in education" :key="index">
                <div class="left">
                  <b-form-input class="form-control" v-model="education[index].job_education" placeholder="Add Education..."> </b-form-input>


                  <div
                    v-if="
                      submitted &&
                        (!e.job_education ||
                          String(e.job_education).length == 0)
                    "
                    class="validate-error"
                  >
                    <span>{{ "This field is required" }}</span>
                  </div>


                </div>
                <div class="right ml-auto" v-show="index != education.length - 1">
                  <button class="btn btn-add" @click="removeEdu(index)">
                    <img src="@/assets/app-icons/delete.svg" width="25px" />
                  </button>
                </div>
                <div class="right ml-auto" v-show="index == education.length - 1">
                  <button class="btn btn-add" @click="addEdu(index)">+</button>

                  <button class="btn btn-add" @click="removeEdu(index)">
                    <img src="@/assets/app-icons/delete.svg" width="25px" />
                  </button>
                </div>
              </div>
            </div>
          </b-col> -->
        </ValidationObserver>

        <b-col class="col-md-12 my-grid-box" v-if="virtualInterview">
          <div>
            <div class="profile-flex pt-4 pb-3">
              <h4 class="heading">{{ $gettext("Virtual_Interview") }}</h4>
            </div>

            <div class="question-card" v-for="(que, index) in q" :key="index">
              <div class="question-name">
                <div class="left">
                  <!-- <ValidationProvider
                    name="question"
                    rules="required"
                    v-slot="{ errors, classes }"
                  > -->
                  <b-form-input :readonly="q[index].universal_question_id ? true : false" class="form-control"
                    v-model="q[index].que"
                    :placeholder="selectedQueType[index] === 'Multiple Choice' ? $gettext('Sample_MCQ_Question_goes_here') : $gettext('Sample_Text_Based_Question_goes_here')">
                  </b-form-input>
                  <div v-if="submitted && (!q[index].que || String(q[index].que).length == 0)" class="validate-error">
                    <span>{{ "This field is required" }}</span>
                  </div>
                  <!-- </ValidationProvider> -->
                </div>
                <div class="right">
                  <!-- <a
                    v-show="index <= 2"
                    @click="
                      () => {
                        que.que = '';
                        que.options = que.options.map((m) => ({
                          ...m,
                          option: '',
                          status: false,
                        }));
                        que.score = null;
                      }
                    "
                    style="cursor: pointer"
                  >
                    <img src="@/assets/app-icons/delete.svg" width="25px" />
                  </a> -->
                  <a v-show="true" @click="deleteQue(index)" style="cursor: pointer; padding:10px;">
                    <img src="@/assets/app-icons/delete.svg" width="25px" />
                  </a>
                </div>
              </div>
              <!--End Question Name-->

              <div class="question-input">
                <div class="question-input-inner" v-for="(opt, i) in que.options" :key="i">
                  <div class="left">
                    <b-form-input :readonly="q[index].universal_question_id ? true : false" class="form-control"
                      v-model="q[index].options[i].option"
                      :placeholder="$gettext('Enter_Option') + (i + 1)"></b-form-input>
                    <div
                      v-if="submitted && (!q[index].options[i].option || String(q[index].options[i].option).length == 0)"
                      class="validate-error">
                      <span>{{ "This field is required" }}</span>
                    </div>
                  </div>
                  <div class="right d-flex" style="padding: 10px;">
                    <b-form-checkbox class="radio-square text-dark" v-model="q[index].options[i].status"
                      @input="correctAns(index, i)" :unchecked-value="false">{{ $gettext("Mark_As_Correct")
                      }}</b-form-checkbox>
                    <a v-if="!q[index].options[i].universal_question_option_id ? true : false" class="delete-option"
                      @click="deleteOption(index, i)">
                      <img src="@/assets/app-icons/delete.svg" width="20px" />
                    </a>
                  </div>
                </div>
                <button class="btn btn-add-options" v-if="selectedQueType[index] === 'Multiple Choice'"
                  @click="addOption(index)">{{ $gettext("ADD_OPTIONS") }} +</button>
              </div>
              <!--End Question Input-->
              <div class="question-status">
                <div class="score">
                  <h6 class="text-dark">{{ $gettext("Score") }}</h6>
                  <b-form-input style="min-width: 57px;" type="number" v-model="q[index].score"> </b-form-input>
                  <div v-if="submitted && (!q[index].score || String(q[index].score).length == 0)" class="validate-error">
                    <span>{{ "This field is required" }}</span>
                  </div>
                </div>
                <!-- <div class="mandatory">
                  <h6 class="text-dark">{{ $gettext("Mandatory") }}</h6>
                  <b-form-checkbox selected size="lg" switch v-model="q[index].mandatory">
                    :disabled="
                      !virtualInterview || (index < 3 && q[index].mandatory)
                    "
                  </b-form-checkbox>
                </div> -->
              </div>
              <!--End Question Status-->
            </div>

            <!--End Question Card-->
            <div>
              <b-button class="btn btn-add-question" :disabled="!virtualInterview" @click="addQue"> {{
                $gettext("Add_Questions") }} + </b-button>
              <b-modal id="modal-scoped">
                <template #modal-header="{ close }">
                  <b-button size="sm" @click="close()"> x </b-button>
                </template>

                <template>
                  <!-- <b-form-group label="Choose Type Of Question">
                  <b-form-radio-group
                    v-model="qType"
                    @ok="handleOk"
                    :options="['Multiple Choice', 'Text Based']"
                  >
                  </b-form-radio-group>
                </b-form-group> -->
                </template>

                <template #modal-footer>
                  <!-- Emulate built in modal footer ok and cancel button actions -->
                  <b-button class="text-center" size="sm" variant="success" @click="chooseQueType"> Confirm </b-button>
                </template>
              </b-modal>
              <!-- <span class="f-light fs-14"> (Upto {{ 10 - q.length }}) </span> -->
            </div>
          </div>
          <div class="for-desktop">
            <div class="grid-box">
              <h4 class="heading">{{ $gettext("Suggested_Virtual_Interview_Questions") }}</h4>
            </div>
            <div class="my-2 border-left pl-3 question-list question-card">
              <div v-if="suggestedQues.length == 0">
                <h2 class="text-center mt-5">{{ $gettext("No_Suggested_Questions_Found") }}</h2>
              </div>
              <div class="pb-2" v-for="(data, index) in suggestedQues" :key="index" @click="onSelectSuggestedQues(data)"
                style="cursor: pointer">
                <div class="p-2">
                  <div class="d-flex align-items-center">
                    <!-- <b-form-checkbox
                      class="radio-square text-dark"
                    ></b-form-checkbox> -->
                    <span>Q{{ index + 1 }}.</span>
                    <b-form-input v-model="data.question" :disabled="true" style="cursor: pointer"
                      class="no-form-control form-control" placeholder="Question"> </b-form-input>
                  </div>
                  <!--End Question Name-->

                  <div class="question-input ">
                    <div class="question-input-inner d-flex flex-wrap">
                      <div style="width: 100%;" class="" v-for="(opt, i) in data.options" :key="i">
                        <b-form-input v-model="opt.question_option_title" :disabled="true" class="form-control"
                          style="cursor: pointer" :placeholder="'Enter Option ' + (i + 1)"></b-form-input>
                      </div>
                    </div>
                  </div>
                  <!--End Question Input-->
                </div>
                <hr />
              </div>
            </div>
          </div>
        </b-col>
        <!--End Virtual Interview-->
        <ValidationObserver ref="videoInterview" tag="div" class="col-md-12 p-0">
          <b-col class="col-md-12" v-if="virtualVideoInterview">
            <div class="profile-flex pt-4 pb-3">
              <h4 class="heading">Virtual Video Interview</h4>
            </div>
            <div class="question-card">
              <ValidationProvider name="videoInt" :rules="virtualVideoInterview ? 'required|max:1000' : ''"
                v-slot="{ errors, classes }">
                <b-form-input class="form-control width-50 w-50" placeholder="Q1. Sample MCQ Question goes here?"
                  v-model="videoQ.que"> </b-form-input>
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider name="videoDesc" :rules="virtualVideoInterview ? '' : ''" v-slot="{ errors, classes }">
                <b-form-textarea class="form-control width-50 w-50" v-model="videoQ.question_tips" rows="6" max-rows="10"
                  placeholder="Enter some tips to help the candidate upload the appropriate video…"> </b-form-textarea>
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </b-col><!--End Virtual Video Interview-->

          <!--Start EQ Template-->
          <b-col class="col-md-12 pt-4" v-if="EQTemplate">
            <div class="profile-flex">
              <h4 class="heading">EI Assessment</h4>

              <!-- <button v-b-modal.template-modal class="btn btn-select-template">
                SELECT TEMPLATE
              </button> -->
            </div>
            <p>This test is based on the 5 competency model as outlined by Daniel Goleman in Emotional Intelligence The
              results of the test are automatically scored on the basis of the above model and cannot be altered by
              Employers. Please note candidates will take at least 15 minutes to answer this questionnaire and once
              selected by the employer it will be mandatory for all candidates to fill it out.</p>
            <div class="question-card">
              <div class="template-header">
                <div class="left">
                  <!-- <ValidationProvider
                    name="videoDesc"
                    :rules="EQTemplate ? 'required' : ''"
                    v-slot="{ errors, classes }"
                  >
                    <v-select
                      class="form-control"
                      :options="templates"
                      :class="classes"
                      v-model="templateSelected"
                      placeholder="Select Template"
                      @input="onSelectTemplate"
                    >
                    </v-select>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider> -->
                </div>
                <!-- <div class="right">
                  <img src="@/assets/app-icons/delete.svg" width="25px" />
                </div> -->
              </div>
              <div class="border-bottom py-3" v-for="(que, index) in selectedTempQues" :key="index">
                <h5 class="">{{ que.ei_type_name }}</h5>

                <div class="" v-for="(o, i) in que.question" :key="i">
                  <p>Q{{ i + 1 }}. {{ o.eiq_name }}</p>
                  <!-- <b-form-checkbox
                    class="radio-square text-dark"
                    :disabled="true"
                    v-model="o.correct"
                    :unchecked-value="'N'"
                    :value="'Y'"
                    >{{ o.text }}</b-form-checkbox
                  > -->
                </div>
              </div>
            </div>
          </b-col><!--End EQ Template-->
        </ValidationObserver>
      </b-row>
      <b-row>
        <b-col class="offset-md-4 col-md-4 emp-next-step">
          <!-- <button class="btn btn-back" @click="back">BACK</button> -->
          <button class="btn btn-next" @click.prevent.stop="ValidateInput">{{ $gettext("Submit") }}</button>
        </b-col>
      </b-row>

      <!--Select Template Modal-->
      <b-modal hide-footer id="template-modal" title="Select EI Template" class="template-modal" size="lg"
        :scrollable="true" :centered="true">
        <div v-for="(data, index) in tempQue" :key="index">
          <div class="py-3 px-3 collapse-card">
            <div class="template-modal-title" v-b-toggle="'collapse-' + index">
              <h4 class="fw-bold text-capitalize">
                {{ data[0].template_name }}
              </h4>
              <p class="light-text"></p>
            </div>
            <b-collapse :id="'collapse-' + index">
              <div class="template-question border-bottom" v-for="(q, i) in data" :key="i">
                <h6>Q{{ i + 1 }}. {{ q.question }}</h6>
                <div class="template-options">
                  <b-form-radio-group class="radio-square option-center" :options="q.options"> </b-form-radio-group>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </b-modal>
      <!--Close Modal-->

      <!--Choose Question-->
      <b-modal hide-footer id="type-question-modal" title="Choose type of question" :centered="true">
        <b-row>
          <b-col class="col-md-12 radio-square option-center">
            <div class="text-center">
              <b-form-group>
                <b-form-radio-group v-model="qType" @ok="handleOk" :options="['Multiple Choice', 'Text Based']"
                  class="type-question"> </b-form-radio-group>
              </b-form-group>
            </div>
          </b-col>
          <b-button class="btn-center btn-confirm my-4" size="sm" @click="chooseQueType"> CONFIRM </b-button>
        </b-row>
      </b-modal>
      <!--Close Modal-->
      <b-modal ref="Suggest_VI" id="Suggest_VI" class="modal-bottom" hide-footer hide-header>
        <div style="margin-bottom: 23px;">
          <p style="    color: #000;    font-size: 18px;   font-style: normal;    font-weight: 600;    line-height: 28.8px;"
            @click="toggleVI()"> &lt; Back</p>
          <p style="margin-bottom:5px;font-size: 18px;font-weight: 700;text-align: center;">Suggested Virtual Interview
            Questions</p>
        </div>
        <div style="width: 100%;height: 83vh;overflow: auto;">
          <div v-if="suggestedQues.length == 0">
            <h2 class="text-center mt-5">No Suggested Questions Found</h2>
          </div>
          <div>
            <div class="pb-2 border-bottom" v-for="(data, index) in suggestedQues" :key="index" style="cursor: pointer">
              <div class="box p-2">
                <div class="d-flex align-items-center" style="cursor: pointer">
                  <!-- <b-form-checkbox
                        class="radio-square text-dark"
                      ></b-form-checkbox> -->
                  <span>Q{{ index + 1 }}.</span>
                  <b-form-input v-model="data.question" :disabled="true" class="no-form-control" placeholder="Question"
                    style="cursor: pointer"> </b-form-input>
                  <button class="btn btn-primary" @click="onSelectSuggestedQues(data)">+</button>
                </div>

                <!--End Question Name-->

                <div class="question-input">
                  <div>
                    <div class="left py-1" v-for="(opt, i) in data.options" :key="opt.question_option_id"
                      style="cursor: pointer">
                      <b-form-input class="form-control" v-model="opt.question_option_title" :disabled="true"
                        :placeholder="'Enter Option ' + (i + 1)" style="cursor: pointer"></b-form-input>
                    </div>
                  </div>
                </div>
                <!--End Question Input-->
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import JobService from "@/apiServices/RecruiterJobService";
import EmployerHeader from "@/views/Employer-Profile-Screens/Employer.vue";
import QuestionServices from "@/apiServices/QuestionServices";
import JobServices from "@/apiServices/RecruiterJobService";
import RecruiterJobService from "@/apiServices/RecruiterJobService";

export default {
  components: { EmployerHeader },

  data() {
    return {
      isSuggestVI: false,
      submitted: false,
      status: false,
      qType: "",
      template_id: null,
      count: 0,
      suggestedQues: [],
      responsibilities: [{ job_responsibility: null, jrm_id: null }],
      education: [{ job_education: null, jem_id: null }],
      delEdu: [],
      delResp: [],
      selectedQueType: [],
      selectedTempQues: [],
      templateSelected: null,
      lastJob: {},
      jobDetails: {},
      selectTemplate: {},
      templates: [],
      tempQue: [],
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: "",
        feature: "Y",
      },
      // textBasedQuestion: [],
      // textQ: [
      //   {
      //     que: "",
      //     ans: "",
      //     score: null,
      //     mandatory: false,
      //     wfc_id: 2,
      //     eq: {},
      //   },
      // ],
      MCQquestions: [],
      videoQ: null,
      q: [
        {
          que: "",
          options: [
            { option: "", status: true },
            { option: "", status: false },
          ],
          score: null,
          mandatory: true,
          wfc_id: 2,
        },
        {
          que: "",
          options: [
            { option: "", status: true },
            { option: "", status: false },
          ],
          score: null,
          mandatory: true,
          wfc_id: 2,
        },
        {
          que: "",
          options: [
            { option: "", status: true },
            { option: "", status: false },
          ],
          score: null,
          mandatory: true,
          wfc_id: 2,
        },
      ],
      removed_questions: [],
    };
  },
  async beforeMount() {
    // if (this.$store.getters.getWfc) {
    //   if (this.$store.getters.getWfc.wfc?.includes("4")) {
    //     this.getAllEQTemplates();
    //     this.onSelectTemplate({ template_id: 1 });
    //   }
    //   if (this.$store.getters.getPage2 == 2) {
    //     this.getQuesFromStore();
    //   } else {
    //     // this.getEQTempQuestions();
    //     this.getResponsibility();
    //     this.getEducation();
    //     this.getQues();
    //   }
    // } else {
    //   this.$router.go(-1);
    // }
    await this.getSingleJob();
    this.getResponsibility();
    this.getEducation();
    this.getQues();
  },
  computed: {
    virtualInterview() {
      // if (this.$store.getters.getWfc && this.$store.getters.getWfc.wfc?.includes("2")) {
      //   return true;
      // } else {
      //   return false;
      // }
      return true;
    },
    virtualVideoInterview() {
      if (this.$store.getters.getWfc && this.$store.getters.getWfc.wfc?.includes("3")) {
        return true;
      } else {
        return false;
      }
    },
    EQTemplate() {
      if (this.$store.getters.getWfc && this.$store.getters.getWfc.wfc?.includes("4")) {
        this.onSelectTemplate({ template_id: 1 });
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    toggleVI() {
      this.isSuggestVI = !this.isSuggestVI;
      if (this.isSuggestVI) {
        this.$bvModal.show("Suggest_VI")
      }
      else {
        this.$bvModal.hide("Suggest_VI")
      }
    },
    async validateEQ() {
      if (this.$store.getters.getWfc.wfc?.includes("4")) {
        if (this.templateSelected) {
          return true;
        } else {
          this.$toasted.show("Select EI Template", {
            duration: 6000,
            icon: "clear",
          });
          return false;
        }
      } else {
        return true;
      }
    },
    async getSuggestedQues() {
      // await this.getSingleJob();
      let loader = this.$loading.show();

      console.log("getSuggestedQues", this.jobDetails.job_title);
      const job_title = this.jobDetails.job_title ?? "";
      await QuestionServices.SuggestedQues({
        job_title: encodeURIComponent(job_title),
      }).then((res) => {
        this.suggestedQues = res.data.data;
        this.suggestedQues = this.suggestedQues.filter((a) => !this.q.some((b) => a.question === b.que));

        this.suggestedQues = this.suggestedQues.reduce((acc, current) => {
          const x = acc.find((item) => item.question_id === current.question_id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
      });
      loader.hide();
    },
    validateVirtualInt() {
      let flag = true;
      let ansError = false;

      this.q.map((ques) => {
        if (!ques.que || String(ques.que).length == 0) {
          flag = false;
        } else if (!ques.score || String(ques.score).length == 0) {
          flag = false;
        }
        let correctFlag = 0;
        ques.options.map((op, opIndex) => {
          if (!op.option || String(op.option).length == 0) {
            flag = false;
          }
          if (op.status) {
            correctFlag = 1;
          }

          if (opIndex == ques.options.length - 1 && correctFlag == 0) {
            console.log("correctFlag in error :>> ", correctFlag);
            flag = false;
            ansError = true;
          } else if (opIndex == ques.options.length - 1 && correctFlag == 1) {
            console.log("correctFlag in success :>> ", correctFlag);
            correctFlag = 0;
          }
        });
      });
      console.log("flag :>> ", flag);
      if (ansError) {
        this.$toasted.show("Please select 1 correct answer for MCQ questions", {
          duration: 6000,
          icon: "clear",
        });
      }
      if (flag) {
        return true;
      } else {
        return false;
      }
    },
    validateVirtualVideoInt() {
      return this.$refs.videoInterview
        .validate()
        .then((result) => {
          if (result) {
            return true;
          } else {
            this.$toasted.show("Somefields are missing in Virtual Interview!", {
              duration: 6000,
              icon: "clear",
            });
            return false;
          }
        })
        .catch((err) => { });
    },
    onSelectSuggestedQues(data) {
      const ques = {
        mandatory: true,
        options: data.options.map((m) => {
          return {
            option: m.question_option_title,
            status: m.question_option_correct == "Y" ? true : false,
            universal_question_option_id: m.universal_question_option_id,
          };
        }),
        que: data.question,
        universal_question_id: data.universal_question_id,
        score: null,
        wfc_id: 2,
      };
      if (this.q.length > 9) {
        this.$toasted.show("You have added max question.", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }
      // if (this.count < 3) {
      //   this.q.splice(this.count, 1, ques);
      //   this.count++;
      // } else {
      const getDuplicate = this.q.filter((f) => f.que == ques.que);

      if (getDuplicate.length) {
        this.$toasted.show("this question already added", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }

      this.q.unshift(ques);

      this.suggestedQues = this.suggestedQues.filter((a) => !this.q.some((b) => a.question === b.que));
      // this.q.push(ques);
      // this.suggestedQues = this.suggestedQues.filter(
      //   (a) => !this.q.some((b) => a.question === b.que)
      // );
      // // }
      this.$toasted.show("Question selected", {
        duration: 6000,
        icon: "check",
      });
    },
    ValidateResp() {
      let valid = false;
      return new Promise((resolve, reject) => {
        console.log(
          "this.responsibilities :>> ",
          this.responsibilities.some((x) => x.job_responsibility)
        );
        if (this.responsibilities.some((x) => x.job_responsibility)) {
          valid = true;
          //reject(valid);
        } else {
          valid = false;
        }
        console.log("valid :>> ", valid);
        if (!valid) {
          this.$toasted.show("There are some fields are missing in Responsibilities", { duration: 6000, icon: "clear" });
        }
        resolve(valid);
      });
      // return this.$refs.resp
      //   .validate()
      //   .then((result) => {
      //     if (result) {
      //       console.log("result :>> ", result);
      //       return true;
      //     } else {
      //       this.$toasted.show("Some fields are missing!", {
      //         position: "top-right",
      //         duration: 6000,
      //         icon: "clear",
      //       });
      //       return false;
      //       throw new Error("invalid form");
      //     }
      //   })
      //   .catch((err) => {
      //
      //   });
    },
    ValidateEdu() {
      let valid = false;

      return new Promise((resolve, reject) => {
        if (this.education.some((x) => !x.job_education || x.job_education == "")) {
          valid = false;
          //reject(valid);
        } else {
          valid = true;
        }
        if (!valid) {
          this.$toasted.show("There are some fields are missing in Education", {
            duration: 6000,
            icon: "clear",
          });
        }
        resolve(valid);
      });
    },
    getQuesFromStore() {
      let qu = JSON.stringify(this.$store.getters.getVQ);
      let removed_questions = JSON.stringify(this.$store.getters.getRemovedQuestions);
      let resp = JSON.stringify(this.$store.getters.getResp);
      let removedResp = JSON.stringify(this.$store.getters.getRemovedResp);
      let removedEdu = JSON.stringify(this.$store.getters.getRemovedEdu);
      let edu = JSON.stringify(this.$store.getters.getEdu);
      let videoQ = this.virtualVideoInterview ? JSON.stringify(this.$store.getters.getVideoQ) : null;
      let tempQ = this.$store.getters.getEq ? JSON.stringify(this.$store.getters.getEq) : null;
      tempQ = this.$store.getters.getEq ? JSON.parse(tempQ) : null;
      this.q = JSON.parse(qu);
      this.removed_questions = JSON.parse(removed_questions);
      this.responsibilities = JSON.parse(resp);
      this.delResp = JSON.parse(removedResp);
      this.education = JSON.parse(edu);
      this.delEdu = JSON.parse(removedEdu);
      this.videoQ = JSON.parse(videoQ);
      this.templateSelected = tempQ;
      // if (this.templateSelected) this.onSelectTemplate(tempQ);

      // this.q = this.$store.getters.getVQ
      // this.responsibilities = this.$store.getters.getResp
      // this.education = this.$store.getters.getEdu
      // this.ve

      this.getSuggestedQues();
    },
    back() {
      this.$router.push(`/update-jobcreation-1/${this.$route.params.job_id}`);
      const q = this.q;
      const removed_questions = this.removed_questions;
      const temp = this.templateSelected;
      console.log("temp :>> ", temp);
      const edu = this.education;
      const resp = this.responsibilities;
      const video = this.videoQ;
      this.$store.commit("ON_SET_VQ", q);
      this.$store.commit("ON_SET_REMOVED_QUESTIONS", removed_questions);
      this.$store.commit("ON_SET_EQQ", temp);
      this.$store.commit("ON_SET_RESP", resp);
      this.$store.commit("ON_SET_REMOVED_RESP", this.delResp);
      this.$store.commit("ON_SET_REMOVED_EDU", this.delEdu);
      this.$store.commit("ON_SET_EDU", edu);
      this.$store.commit("ON_SET_PAGE2", 2);
      this.$store.commit("ON_SET_VIDEOQ", video);
    },
    getAllEQTemplates() {
      QuestionServices.GetAllEQTemplates().then((res) => {
        this.templates = res.data.data.map((t) => {
          return {
            label: t.template_name,
            template_id: t.template_id,
          };
        });

        // this.getEQTemplateQue();
      });
    },
    async ValidateInput() {
      // return this.$refs.ques.validate().then((result) => {
      //   if (result) {
      //     console.log("result :>> ", result);
      //     this.updateJob();
      //   } else {
      //     this.$toasted.show("Some fields are missing!", {
      //       position: "top-right",
      //       duration: 6000,
      //     });
      //   }
      // });

      try {
        this.submitted = true;
        const p = await Promise.all([
          this.ValidateResp(),
          //    this.ValidateEdu(),
          this.validateVirtualInt(),
          this.validateVirtualVideoInt(),
          // this.validateEQ(),
        ]);

        let score = 0;
        this.q.map((m) => {
          if (m.score) {
            score = score + parseInt(m.score);
          } else {
            score = score + 0;
          }
        });
        console.log("score :>> ", score);
        if (score != 100) {
          this.$toasted.show(`Score sum is ${score}. It must be equal to 100`, {
            duration: 6000,
            icon: "clear",
          });
          return;
        }

        if (p.includes(false)) {
          console.log("There is some missing fields!!");
          return;
        } else {
          console.log("Form success!");
          // this.updateJob();
          this.submitQue();
        }
      } catch (error) {
        return false;
      }
    },
    addResponsibility(index) {
      this.responsibilities.push({ job_responsibility: null, jrm_id: null });
    },
    removeResponsibility(index) {
      if (this.responsibilities.length != 1) {
        this.delResp.push(this.responsibilities[index].jrm_id);
        this.responsibilities.splice(index, 1);
      } else {
        return;
      }
    },
    addEdu(index) {
      this.education.push({ job_education: null, jem_id: null });
    },
    removeEdu(index) {
      if (this.education.length > 1) {
        this.delEdu.push(this.education[index].jem_id);
        this.education.splice(index, 1);
      } else {
        return;
      }
    },
    onSelectTemplate(e) {
      console.log("e :>> ", e);
      if (e?.template_id) {
        QuestionServices.GetEQTemplateQue(e?.template_id).then((res) => {
          this.selectedTempQues = res.data.data;

          if (this.selectedTempQues.length > 0) {
            this.selectedTempQues.forEach(async (q, i) => {
              if (q.options && q.options.length > 0) {
                this.selectedTempQues[i].options = await q.options.map((option) => {
                  return {
                    text: option.question_option_title,
                    value: option.question_option_id,
                    correct: option.question_option_correct,
                  };
                });
              }
            });
            console.log("this.selectedTemplate :>> ", this.selectedTempQues);
          }
        });
      } else {
        this.selectedTempQues = [];
      }
    },
    // async getEQTemplateQue() {
    //   this.templates.forEach(async (t, index) => {
    //     const q = await QuestionServices.GetEQTemplateQue(t.template_id);
    //     if (q.length > 0) {
    //       q.data.data.forEach((que) => {
    //         //console.log("que :>> ", que);
    //         que.options = que.options.map((o) => {
    //           return {
    //             text: o.question_option_title,
    //             value: o.question_option_id,
    //           };
    //         });
    //       });
    //       this.tempQue.push(q.data.data);
    //       //console.log("this.tempQue :>> ", this.tempQue);
    //       this.tempQue = [...new Set(this.tempQue)];

    //       // console.log("this.tempQue :>> ", this.tempQue);
    //     }
    //   });

    //   console.log("this.tempQue :>> ", this.tempQue);
    //   // this.tempQue.forEach((q, index) => {
    //   //   console.log("q :>> ", q);
    //   //   q.options.map((o) => {
    //   //     return {
    //   //       text: o.question_option_title,
    //   //       value: o.question_option_id,
    //   //     };
    //   //   });
    //   //   console.log("q.options :>> ", this.tempQue);
    //   // });
    // },

    addOption(index) {
      this.q[index].options.push({ option: "", status: false });
    },
    deleteOption(index, i) {
      if (this.q[index].options.length > 2) {
        if (this.q[index].options[i].option_id && String(this.q[index].options[i].option_id).length > 0) {
          this.q[index]["removed_options"].push(this.q[index].options[i]);
        }
        this.q[index].options.splice(i, 1);
        console.log("this.q[index].removed_options :>> ", this.q[index]["removed_options"]);
      }
    },
    handleOk(e) {
      e.preventDefault();
      this.chooseQueType();
    },
    chooseQueType() {
      this.selectedQueType.push(this.qType);
      console.log("this.selectedQueType :>> ", this.selectedQueType);
      let len = this.selectedQueType.length - 1;
      if (this.selectedQueType[len] === "Multiple Choice") {
        console.log("this.selectedQueType[len] :>> ", this.selectedQueType[len]);
        this.addQue();
      } else {
        console.log("this.selectedQueType[len] :>> ", this.selectedQueType[len]);
        this.addTextBasedQue();
      }
      this.$nextTick(() => {
        this.$bvModal.hide("type-question-modal");
      });
    },
    addTextBasedQue() {
      if (this.q.length < 10) {
        this.q.push({ q: "", score: null, mandatory: false, wfc_id: 2 });
      } else {
        return;
      }
    },
    addQue() {
      this.selectedQueType.push("Multiple Choice");
      if (this.q.length < 10) {
        this.q.push({
          que: "",
          options: [
            { option: "", status: true },
            { option: "", status: false },
          ],
          removed_options: [],
          score: null,
          mandatory: false,
          wfc_id: 2,
        });
      } else {
        return;
      }
    },
    deleteQue(index) {
      if (this.q.length > 3) {
        if (this.q[index].question_id && String(this.q[index].question_id).length > 0) {
          this.removed_questions.push(this.q[index]);
        }
        console.log("hitting");
        this.suggestedQues.unshift({
          question_id: this.q[index].question_id,
          question: this.q[index].que,
          universal_question_id: this.q[index].universal_question_id || null,
          options: this.q[index].options.map((m) => {
            return {
              question_id: this.q[index].question_id,
              question_option_title: m.option,
              question_option_id: m.option_id,
              universal_question_option_id: m.universal_question_option_id,
            };
          }),
        });
        //remove duplicate object
        this.suggestedQues = this.suggestedQues.reduce((acc, current) => {
          const x = acc.find((item) => item.question_id === current.question_id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        this.q.splice(index, 1);
        console.log("this.removed_questions :>> ", this.removed_questions);
      } else {
        console.log("hittinh here");
        if (this.q[index].que && String(this.q[index].que).length > 0) {
          this.suggestedQues.unshift({
            question_id: this.q[index].question_id,
            question: this.q[index].que,
            universal_question_id: this.q[index].universal_question_id || null,
            options: this.q[index].options.map((m) => {
              return {
                question_option_title: m.option,
                question_option_id: m.option_id,
                universal_question_option_id: m.universal_question_option_id,
              };
            }),
          });
        }
        this.q[index].que = "";
        this.q[index].options = this.q[index].options.map((m) => ({
          ...m,
          option: "",
          status: false,
        }));
        this.q[index].score = null;
      }
    },
    correctAns(index, i) {
      if (this.q[index].options[i].status) {
        this.q[index].ans = this.q[index].options[i].option;
        console.log("this.q[index] :>> ", this.q[index].options[i].status);
        // this.q[index].options.map((op, opIndex) => {
        //   if (opIndex != i) {
        //     op.status = false;
        //   }
        // });
      }
    },
    getResponsibility() {
      const job_id = this.$route.params.job_id;
      let loader = this.$loading.show();
      JobServices.GetJobResponsiblity({ job_id }).then((res) => {
        this.responsibilities = res.data.data.map(({ job_responsibility, jrm_id }) => {
          return { job_responsibility, jrm_id };
        });
        if (this.responsibilities.length == 0) {
          this.responsibilities = [{ job_responsibility: null, jrm_id: null }];
        }
        loader.hide();
      });
    },
    async getSingleJob() {
      console.log("get single job");
      const job_id = this.$route.params.job_id;

      console.log("job_id :>> ", job_id);
      await RecruiterJobService.GetSingleJob({ job_id }).then((job) => {
        console.log("job :>> ", job);
        this.jobDetails = job.data.data[0];
        console.log("this.jobDetails", this.jobDetails);

        // loader.hide();
      });
    },
    getEducation() {
      const job_id = this.$route.params.job_id;
      let loader = this.$loading.show();
      JobService.GetJobEducation({ job_id }).then((edu) => {
        console.log("edu :>> ", edu);
        this.education = edu.data.data.map(({ job_education, jem_id }) => {
          return { job_education, jem_id };
        });
        if (this.education.length == 0) {
          this.education = [{ job_education: null, jem_id: null }];
        }
        loader.hide();
      });
    },
    async getQues() {
      const job_id = this.$route.params.job_id;
      let loader = this.$loading.show();
      QuestionServices.GetAllJobQues({ job_id }).then((qu) => {
        console.log("qu.data.data :>> ", qu.data.data);
        const { template_id } = qu.data.data;
        if (qu.data.data.questions.length > 0) {
          this.q = qu.data.data.questions.map((m) => {
            console.log(`m::::`, m);
            console.log(`m.wfc_id`, m.wfc_id);
            return {
              question_id: m.question_id,
              que: m.question,
              universal_question_id: m.universal_question_id || null,
              options:
                m.options && m.options.length > 0
                  ? m.options.map((o) => {
                    return {
                      option_id: o.question_option_id,
                      option: o.question_option_title,
                      status: o.question_option_correct == "Y" ? true : false,
                      universal_question_option_id: o.universal_question_option_id || null,
                    };
                  })
                  : [],
              removed_options: [],
              score: m.question_score,
              question_tips: m.question_tips,
              mandatory: m.question_is_mandatory == "Y" ? true : false,
              wfc_id: m.wfc_id,
            };
          });
        }

        if (template_id.length > 0 && this.EQTemplate) {
          if (template_id[0].template_name && template_id[0].template_id) {
            this.templateSelected = {
              label: template_id[0].template_name,
              template_id: template_id[0].template_id,
            };
            // this.onSelectTemplate({
            //   template_id: this.templateSelected.template_id,
            // });
          }
        }

        this.videoQ = this.q.filter((f) => {
          return f.wfc_id == 3;
        });
        this.q = this.q.filter((f) => f.wfc_id == 2);
        this.videoQ = this.virtualVideoInterview && this.videoQ[0] ? this.videoQ[0] : null;
        this.q = this.q.filter((f) => {
          return f.wfc_id == 2;
        });

        this.q.map((ques) => {
          this.selectedQueType.push("Multiple Choice");
        });
      });

      await this.getSuggestedQues();
      loader.hide();
    },
    updateJob() {
      let job = this.$store.getters.getJobDetails;
      console.log("job :>> ", job);

      const wfc = this.$store.getters.getWfc;
      const deselectedWfc = this.$store.getters.getDeselectedWfc;

      console.log("job :>> ", job);
      console.log("wfc :>> ", wfc);
      const edu = this.education.filter((f) => f.job_education);
      let resp = this.responsibilities.filter((f) => f.job_responsibility);
      const templateSelected = this.templateSelected;
      const delEdu = this.delEdu;
      const delResp = this.delResp;

      const payload = {
        job,
        wfc,
        deselectedWfc,
        templateSelected,
        resp,
        edu,
        delEdu,
        delResp,
        job_id: this.$route.params.job_id,
      };

      JobServices.UpdateJob(payload)
        .then((res) => {
          this.$toasted.show("Job Updated Successfully!", {
            position: "top-right",
            duration: 6000,
          });
          console.log("job update res :::>> ", res.data.data[0]);
          console.log('this.q.length > 0 && this.q[0].que != "" :>> ', this.q.length > 0 && this.q[0].que != "");
          if (this.q.length > 0 && this.q[0].que != "") {
            this.submitQue();
          } else {
            this.$router.push({
              name: "Job-Success",
              params: { job_id: this.$route.params.job_id },
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Job Creation Failed!", {
            position: "top-right",
            duration: 6000,
          });
        });
    },
    submitQue() {
      let que = [...this.q];
      que = que.map((m) => {
        return {
          ...m,
          job_id: this.$route.params.job_id,
        };
      });
      console.log(`this.videoQ:::`, this.videoQ);
      if (this.videoQ) que.push(this.videoQ);
      const payload = {
        q: que,
        removed_questions: this.removed_questions,
        job_id: this.$route.params.job_id,
        wfc: this.$store.getters.getWfc,
      };

      QuestionServices.UpdateQuestion(payload)
        .then((res) => {
          this.$toasted.show("Questions updated successfully!", {
            position: "top-right",
            duration: 6000,
          });

          // this.$store.dispatch("onResetJobDetail");

          this.$router.push({
            name: "Employer-jobs",
            params: { job_id: this.$route.params.job_id },
          });
        })
        .catch((err) => {
          this.$toasted.show("Questions not Submitted!", {
            position: "top-right",
            duration: 6000,
          });
        });
    },
  },
};
</script>

<style scoped>
.validate-error {
  color: #ff4b4b;
  font-size: 13px;
  padding: 4px;
  font-family: "pn-regular";
  margin-top: -15px;
}

.grid-box {
  display: grid;
  grid-template-columns: 60% 40%;
}

.question-list {
  height: 100vh;
  overflow-y: scroll;
}

.no-form-control {
  background: white !important;
  border: 1px solid white !important;
  height: 30px !important;
  margin-bottom: 0px !important;
}

.Rectangle24 {
  width: 84px;
  height: 6px;
  background: white;
  border-radius: 7px;
}

.for-mob {
  display: none !important;
  ;
}

.btn-fs-8 {
  font-size: 12px;
}

.my-grid-box {
  display: grid;
  grid-template-columns: 60% 40%;
}

.job-create-two .question-card .question-input-inner {
  display: flex;
  align-items: baseline;
  flex-direction: column !important;
}

@media only screen and (max-width: 768px) {
  .my-col-6 {
    max-width: 50%;
    font-size: large;
    padding: 10px 10px !important;
    margin-block: 10px;
  }

  .vi-btn {
    background: #6244e5;
    border: none;
    padding: 10px;
    width: 100%;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    color: white;
  }

  .for-desktop {
    display: none !important;
    ;
  }

  .for-mob {
    display: block !important;
    ;
  }

  .job-create-two .question-card .question-name {
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }

  .job-create-two .question-card .question-name .right {
    display: flex;
    justify-content: flex-end !important;
    flex: 0 0 0;
  }

  .job-create-two .question-card .question-input-inner .left {
    flex: 0 0 100% !important;
  }

  .job-create-two .question-card .question-input-inner {
    flex-wrap: wrap !important;
    justify-content: flex-end !important;
  }

  .btn-fs-8 {
    font-size: 8px;
  }

  .my-grid-box {
    display: grid;
    grid-template-columns: 100%;
  }
}
</style>

<template>
  <div>
    <b-container fluid class="my-5 pt-5 user-onboard emp-onboard customized-form">
      <!-- greetUser :: start -->
      <!--<h4 class="user-welcome pb-4">Welcome, User</h4>-->
      <!-- greetUser :: end -->

      <!-- user-step-heading :: start -->
      <div class="user-step-heading">
        <div class="user-step-left align-items-end">
          <img src="@/assets/app-icons/cv.svg" class="img-fluid pr-3" alt="cv-icon" width="50px" />
          <div class="">
            <!-- <h5 class="user-step">STEP 1</h5> -->
            <h4 class="user-step-name">{{ $gettext("Enter_company_details") }}</h4>
          </div>
        </div>
      </div>
      <!-- user-step-heading :: end -->

      <!-- profileSettingsForm :: start -->
      <ValidationObserver ref="profile">
        <!-- firstRow :: start -->
        <b-row class="pb-4">
          <!-- Upload Company Logo :: start -->
          <b-col class="w-md-3 w-sm-4 w-lg-2p5 mt-3 border-right text-center mb-4">
            <label>{{ $gettext("COMPANY_LOGO") }}</label>

            <div class="custom-upload upload-company-img text-center">
              <!-- default image -->
              <div v-if="!previewImage && !profile.company_logo_path">
                <img src="@/assets/app-icons/cloud-upload.svg" class="img-fluid" alt="upload-icon" width="50px" />
              </div>

              <!-- selected image preview -->
              <div v-if="previewImage">
                <img :src="previewImage" alt="" height="59px" width="74px" />
              </div>

              <!-- path to upload -->
              <div v-if="profile.company_logo_path && !previewImage">
                <img :src="`${image_url}${profile.company_logo_path}`" alt="" class="profile-img" />
              </div>

              <div>
                <!-- label -->
                <label for="file-upload" class="btn upload-btn"> {{ $gettext("UPLOAD_LOGO ") }}</label>

                <!-- uploadBtn -->
                <div class="upload-max">
                  <span v-if="image_path">
                    <a :href="image_path" target="_blank">{{ $gettext("View_Photo") }}</a>
                  </span>
                  Max 100MB
                </div>

                <!-- uploadInput -->
                <input id="file-upload" ref="fileInput" type="file" @input="onFileChange" />
              </div>
            </div>
          </b-col>
          <!-- Upload Company Logo :: end -->

          <!-- Upload Gallery :: start -->
          <b-col class="w-md-3 w-sm-4 w-lg-2p5 mt-3 border-right text-center mb-4">
            <label>{{ $gettext("COMPANY_GALLERY") }}</label>

            <div class="custom-upload upload-company-img text-center">
              <!-- default image -->
              <div v-if="!previewCompanyImages.length && !profile.company_gallery?.length">
                <img src="@/assets/app-icons/cloud-upload.svg" class="img-fluid" alt="upload-icon" width="50px" />
              </div>

              <!-- selected image preview -->
              <div v-if="!!previewCompanyImages.length && !profile.company_gallery?.length"
                class="d-flex flex-row justify-content-center align-items-center flex-wrap">
                <div v-for="(previewImage, index) in previewCompanyImages" :key="index">
                  <img :src="`${previewImage}`" :alt="`preview-${index + 1}`" height="59px" width="74px" />
                </div>
              </div>

              <!-- path to upload -->
              <div v-if="!!company_gallery?.length && !previewCompanyImages.length">
                <!-- <div v-for="(previewImage, index) in company_gallery" :key="previewImage.company_image_id">
                  <img :src="previewImage.company_image_path" :alt="`preview-${index + 1}`" height="59px" width="74px" />
              </div> -->
                <span>{{ company_gallery?.length }} {{ $gettext("Uploads") }}</span>
              </div>

              <div>
                <!-- label -->
                <label for="file-upload2" class="btn upload-btn">{{ $gettext("UPLOAD_PHOTOS_VIDEO") }} </label>

                <!-- uploadBtn -->
                <div class="upload-max">
                  <span v-if="image_path2">
                    <a :href="image_path2" target="_blank">{{ $gettext("View_Photo") }}</a>
                  </span>
                  Max 100MB
                </div>

                <!-- inputBtn -->
                <input id="file-upload2" ref="fileInput2" accept="video/*, image/*" type="file" @input="onFileChange2"
                  multiple />
              </div>
            </div>
          </b-col>
          <!-- Upload Gallery :: end -->

          <!--End Upload Company Gallery-->
          <b-col class="col-12 w-lg-5 w-md-6 pt-2 emp-form customized-form">
            <!-- company name :: start -->
            <div class="profile-div mt-n3">
              <label>{{ $gettext("Company_name") }}</label>
              <ValidationProvider name="username" rules="required" v-slot="{ errors, classes }">
                <b-form-input v-model="company_name" :placeholder="$gettext('Company_name')"
                  :class="classes"></b-form-input>
                <div class="error-msg" :class="classes">
                  <!-- <span>{{ errors[0] }}</span> -->
                  <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <!-- company name :: end -->
            <div>
              <label>{{ $gettext("ADMIN_NAME") }}</label>
              <ValidationProvider name="name" rules="required" v-slot="{ errors, classes }">
                <b-form-input v-model="admin_name" :placeholder="$gettext('Enter_Name')" :class="classes"></b-form-input>
                <div class="error-msg" :class="classes">
                  <!-- <span>{{ errors[0] }}</span> -->
                  <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div>
              <label>{{ $gettext("ADMIN_EMAIL") }}</label>
              <ValidationProvider name="username" rules="required" v-slot="{ errors, classes }">
                <b-form-input v-model="admin_email" :placeholder="$gettext('Enter_Email')"
                  :class="classes"></b-form-input>
                <div class="error-msg" :class="classes">
                  <!-- <span>{{ errors[0] }}</span> -->
                  <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="profile-div mt-n3">
              <label>{{ $gettext("Profile") }}</label>
              <ValidationProvider name="description" rules="required|max:1000" v-slot="{ errors, classes }">
                <b-form-textarea style="resize: none; height: 148px;" class="" v-model="company_profile"
                  :placeholder="$gettext('Add_your_company_profile')" :class="classes" rows="3" max-rows="5">
                </b-form-textarea>
                <div class="error-msg" :class="classes">
                  <!-- <span>{{ errors[0] }}</span> -->
                  <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </b-col>
        </b-row>
        <!-- firstRow :: end-->

        <!-- secondRow :: start -->
        <b-row class="border-top border-bottom py-3">
          <b-col class="col-12 col-md-5">
            <div>
              <label>{{ $gettext("SIZE") }}</label>
              <ValidationProvider name="size" rules="required" v-slot="{ errors, classes }">
                <b-form-radio-group v-model="employer_size" class="radio-square option-center pt-2 ml-n4"
                  :class="classes">
                  <b-form-radio value="100"> &lt; 100 </b-form-radio>
                  <b-form-radio value="100-500">100-500</b-form-radio>
                  <b-form-radio value="501-1000">501-1000</b-form-radio>
                  <b-form-radio value="1001-5000">1001-5000</b-form-radio>
                  <b-form-radio value="5000">>5000</b-form-radio>
                </b-form-radio-group>
                <div class="error-msg" :class="classes">
                  <!-- <span>{{ errors[0] }}</span> -->
                  <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="pt-4">
              <label>{{ $gettext("Industry") }}</label>
              <ValidationProvider name="industry" rules="required" v-slot="{ errors, classes }">
                <multiselect style="" class="multis" :class="classes" :limit="4" :max="10" v-model="industry_id"
                  :placeholder="$gettext('Select_Industry')" label="label" track-by="industry_id" :options="industry"
                  :multiple="true" @remove="removeIndustry"> </multiselect>
                <div class="error-msg" :class="classes">
                  <!-- <span>{{ errors[0] }}</span> -->
                  <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </b-col>
          <b-col class="col-12 col-md-3 border-left">
            <div>
              <label>{{ $gettext("CONTACT_LOCATION") }}</label>
              <div class="pt-3">
                <ValidationProvider name="email" rules="required" v-slot="{ errors, classes }"
                  v-if="company_official_email">
                  <b-form-input class="form-control" :placeholder="$gettext('Official_Email')"
                    v-model="company_official_email"> </b-form-input>
                  <div class="error-msg" :class="classes">
                    <!-- <span> {{ errors[0] }}</span> -->
                    <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <!-- Edited By Aman -->
                <!-- Here i have removed  v-if="company_official_phone" from  ValidationProvider and change required rules as optional -->
                <ValidationProvider name="phone" :rules="requiredRule" v-slot="{ errors, classes }">
                  <b-form-input @input="PhoneValidator" class="form-control" :placeholder="$gettext('Contact_Number')"
                    v-model="company_official_phone" :class="classes"> </b-form-input>
                  <div class="error-msg" :class="classes">
                    <!-- <span> {{ errors[0] }}</span> -->
                    <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                  </div>
                </ValidationProvider>

                <b-form-input class="form-control" :placeholder="$gettext('Website_URL')" v-model="company_website_url">
                </b-form-input>
              </div>
            </div>
          </b-col>
          <b-col class="col-md-4 mt-md-5">
            <!-- Country & City start -->
            <div class="row mb-3">
              <div class="col-md-6 cust-mb-3">
                <ValidationProvider name="count" rules="required|max:150" v-slot="{ errors, classes }">
                  <!-- {{ selectedCountry }} -->
                  <multiselect style="" class="multis" :class="classes" :limit="4" :max="10" v-model="selectedCountry"
                    :placeholder="$gettext('Select_country')" label="country_name" track-by="country_id"
                    :options="countryOptions" :multiple="false" @input="onChangeCountry" />
                  <!-- <multiselect
                      style=""
                      class="multis"
                      :class="classes"
                      v-model="selectedCountry"
                      placeholder="Select Country"
                      label="country_name"
                      track-by="country_id"
                      :options="countryOptions"
                      :multiple="false"
                      @input="onChangeCountry"
                    /> -->
                  <div class="error-msg" :class="classes">
                    <!-- <span>{{ errors[0] }}</span> -->
                    <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider name="city" rules="required|max:150" v-slot="{ errors, classes }">
                  <!-- {{ selectedCity }} -->
                  <multiselect style="" class="multis" :class="classes" v-model="selectedCity"
                    :placeholder="$gettext('Select_city')" label="city_name" track-by="city_id" :options="cityOptions"
                    :multiple="false" />
                  <div class="error-msg" :class="classes">
                    <!-- <span>{{ errors[0] }}</span> -->
                    <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <!-- Country & City end -->

            <ValidationProvider name="address" rules="required|max:150" v-slot="{ errors, classes }">
              <b-form-textarea class="w-100 overflow-auto" :class="classes" v-model="company_office_address"
                :placeholder="$gettext('Add_your_company_address')" rows="6" max-rows="10"> </b-form-textarea>
              <div class="error-msg" :class="classes">
                <!-- <span>{{ errors[0] }}</span> -->
                <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-col>
        </b-row>
        <!-- secondRow :: end -->

        <!-- thirdRow:: start -->
        <b-row class="pt-3">
          <b-col class="col-md-12 customized-form">
            <label>{{ $gettext("ADD_SOCIAL_LINKS") }}</label>
            <div class="social-profile">
              <div class="social-profile-grid">
                <div>
                  <b-form-input v-model="social_link.linked_in" :placeholder="$gettext('ENTER_LINKEDIN_PROFILE_URL')">
                  </b-form-input>
                </div>
                <div>
                  <b-form-input v-model="social_link.facebook" :placeholder="$gettext('ENTER_FACEBOOK_PROFILE_URL')">
                  </b-form-input>
                </div>
                <div>
                  <b-form-input v-model="social_link.instagram" :placeholder="$gettext('ENTER_INSTAGRAM_PROFILE_URL')">
                  </b-form-input>
                </div>
                <div>
                  <b-form-input v-model="social_link.twitter" :placeholder="$gettext('ENTER_TWITTER_HANDLE')">
                  </b-form-input>
                </div>
                <button class="btn btn-add-profile mt-0 ">{{ $gettext("ADD_SOCIAL_PROFILE_URL") }} +</button>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- thirdRow :: end -->

        <!-- submitRow  desktop:: start -->
        <b-row class="my-5" style="display: flex;justify-content: center;align-items: center;align-content: center;">
          <!-- <b-col class="col-md-4">
            <button class="btn btn-tip">TIPS</button>
          </b-col> -->
          <b-col style="    display: flex;    align-items: center;    justify-content: center;" class="">
            <button class="btn btn-light btn-md mr-5" @click="$router.push({ name: 'Employer-onboarding-1' })">{{
              $gettext("Back") }}</button>
            <button class="btn btn-primary btn-md ml-5" @click.prevent.stop="ValidateProfile">{{ $gettext("CONTINUE")
            }}</button>
          </b-col>
          <!-- <b-col class="col-md-4">
            <button class="btn btn-tip">TIPS</button>
          </b-col> -->
        </b-row>
      </ValidationObserver>
      <!-- profileSettingsForm :: end -->
    </b-container>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import CompanyServices from "@/apiServices/CompanyServices";
import LocationServices from "@/apiServices/LocationServices";
import CompanyService from "@/apiServices/CompanyServices";
import { IMAGEURL } from "@/config";

export default {
  components: { Multiselect },
  data() {
    return {
      requiredRule: null,
      match: null,
      image_url: IMAGEURL,
      previewImage: null,
      previewImage2: null,
      industry: [],
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: "",
        feature: "Y",
      },
      selectedCountry: null,
      selectedCity: null,
      countries: [],
      removedIndustry: [],
      cities: [],
      profile: {},
      industry_id: null,
      company_id: null,
      company_name: null,
      company_gallery: null,
      image_path: null,
      image_path2: null,
      image: null,
      image2: null,
      admin_name: null,
      admin_email: null,
      company_profile: null,
      employer_size: null,
      company_official_phone: null,
      company_official_email: null,
      company_website_url: null,
      company_office_address: null,
      city: null,
      social_link: { linked_in: "", facebook: "", instagram: "", twitter: "" },
      countryOptions: [],
      cityOptions: [],
      previewCompanyImages: [],
    };
  },
  beforeMount() {
    this.getAllIndustry();
    this.getAllCountry();
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getEmployerDetails();
    });
  },
  methods: {
    PhoneValidator() {
      const pattern = /^\+\d{2}[1-9]\d{9}$|^[1-9]\d{9}$/;
      console.log(this.company_official_phone.length, this.company_official_phone);
      this.requiredRule = this.company_official_phone.length === 0 || pattern.test(this.company_official_phone) ? "" : "required";
      this.company_official_phone = this.requiredRule === "required" ? null : this.company_official_phone;
      console.log(this.requiredRule, "this.company_official_phone", this.company_official_phone);

      // this.requiredRule= this.company_official_phone=null || pattern.test(this.company_official_phone)?'':'required'
    },
    getAllIndustry() {
      CompanyService.getAllIndustry().then((res) => {
        const sortData = () => {
          return new Promise((resolve, reject) => {
            res.data.data.sort((a, b) => a.industry_name.localeCompare(b.industry_name));
            resolve(res.data.data);
          });
        };
        sortData();
        this.industry = res.data.data.map((r) => {
          return {
            label: r.industry_name,
            industry_id: r.industry_id,
          };
        });
      });
    },
    onFileChange(e) {
      const targetInput = this.$refs.fileInput;
      const file = [...targetInput.files];
      this.image = file[0];
      console.log(this.image);
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);

        this.$emit("input", file[0]);
      }
    },
    onFileChange2(e) {
      //let input2 = this.$refs.fileInput2;
      //this.image = e.target.files[0];
      //let file = input2.files;
      //console.log(this.image);
      //if (file && file[0]) {
      //  let reader = new FileReader();
      //  reader.onload = (e) => {
      //    this.previewImage2 = e.target.result;
      //  };
      //  reader.readAsDataURL(file[0]);
      //  this.$emit("input2", file[0]);
      //}
      const targetInput = this.$refs.fileInput2;
      const files = [...targetInput.files];
      console.log("files", files);
      const companyImages = [];
      for (let file of files) {
        companyImages.push(file);
      }
      this.image2 = companyImages;
      console.log(this.image2);
      const previews = [];
      for (let file of files) {
        const reader = new FileReader();
        reader.onload = (event) => {
          previews.push(event.target.result);
        };
        reader.readAsDataURL(file);
      }

      this.previewCompanyImages = previews;
    },
    removeIndustry(e) {
      console.log("e :>> ", e);
      this.removedIndustry.push(e.industry_id);
    },
    getEmployerDetails() {
      const payload = { company_id: this.$cookies.get("company_id") };
      CompanyServices.GetSingleCompany(payload).then((result) => {
        console.log(result);
        this.profile = { ...result.data.data[0] };
        console.log(result);
        const selectedCountry = {
          country_id: this.profile.country_id,
          country_code: this.profile.country_code,
          country_name: this.profile.country_name,
          country_mob_code: this.profile.country_mob_code,
          job_country_active: this.profile.job_country_active,
          iso_2: this.profile.iso_2,
          iso_3: this.profile.iso_3,
        };

        const selectedCity = {
          city_id: this.profile.city_id,
          city_name: this.profile.city_name,
          country_name: this.profile.country_name,
        };
        const country_id = this.profile.country_id
        LocationServices.GetAllCityOfCountry({ country_id, all: true }).then((res) => {
          this.cityOptions = res.data.data;
        });

        this.company_id = this.profile.company_id;
        this.company_name = this.profile.company_long_name;
        this.industry_id = this.profile.industry.map((m) => {
          return {
            label: m.industry_name,
            industry_id: m.industry_id,
          };
        });
        this.image_path = this.profile.company_employer_image_path;
        this.image_path2 = this.profile.company_gallery;
        this.admin_name = this.profile.company_admin_name;
        this.admin_email = this.profile.company_admin_email;
        this.company_profile = this.profile.company_profile;
        this.company_website_url = this.profile.company_website_url;
        this.employer_size = this.profile.company_employer_size;
        this.company_official_phone = this.profile.company_official_phone;
        this.company_official_email = this.profile.company_official_email;
        this.company_office_address = this.profile.company_office_address;
        this.social_link.linked_in = this.profile.linked_in;
        this.social_link.instagram = this.profile.instagram;
        this.social_link.facebook = this.profile.facebook;
        this.social_link.twitter = this.profile.twitter;
        this.selectedCountry = selectedCountry.country_id === null ? null : selectedCountry;

        this.selectedCity = selectedCity.city_id === null ? null : selectedCity;
        this.company_gallery = this.profile.images;
      });
    },

    getAllCountry() {
      LocationServices.GetAllCountry({}).then((res) => {
        this.countryOptions = res.data.data;
        console.log("this.countryOptions", this.countryOptions);
        console.log("this.selectedCountry", this.selectedCountry);
      });
    },
    ValidateProfile() {
      return this.$refs.profile.validate().then((result) => {
        if (result) {
          console.log("if k ander hu");
          console.log("succes");
          this.onSubmit();
          return true;
        } else {
          console.log("else k ander hu");

          this.$toasted.show("Some Fields are missing in Company Profile", {
            position: "top-right",
            duration: 6000,
            icon: "clear",
          });
        }
      });
    },
    onSubmit() {
      console.log("this.industry :>> ", this.industry_id);

      const payload = {
        admin_name: this.admin_name,
        admin_email: this.admin_email,
        industry_id: this.industry_id,
        company_profile: this.company_profile,
        employer_size: this.employer_size,
        company_official_phone: this.company_official_phone,
        company_official_email: this.company_official_email,
        company_website_url: this.company_website_url,
        company_office_address: this.company_office_address,
        social_link: this.social_link,
        country: this.selectedCountry,
        city: this.selectedCity,
      };

      const company_id = this.$cookies.get("company_id");

      const handleLogoUpload = async () => {
        const formData = new FormData();
        formData.append("image", this.image);
        const response = await CompanyServices.UpdateCompany(formData, company_id);
        console.log("response:", response);
      };
      console.log("onsubmit k ander hu");

      const handleGalleryUpload = async () => {
        const companyGallery = new FormData();
        for (const galleryImage of this.image2) {
          companyGallery.append("image2", galleryImage);
        }
        console.log("Company images", this.image2);
        const response = await CompanyServices.UpdateCompany(companyGallery, company_id);
        console.log("response:", response);
      };

      const handleUpdateCompany = async () => {
        return CompanyServices.UpdateCompany(payload, company_id);
      };

      this.image && handleLogoUpload();
      this.image2?.length > 0 && handleGalleryUpload();

      handleUpdateCompany()
        .then((res) => {
          console.log("res", res);
          const newPayload = Object.assign({}, payload, {
            image: this.image,
            image2: this.image2,
          });
          console.log("newPayload", newPayload);
          this.$store.dispatch("onSetCompanyProfile", newPayload);
          this.$router.push({ name: "Employer-onboarding-3" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onChangeCountry(e, id, city_reset = true) {
      console.log("e :>> ", e);
      console.log(`city_reset`, city_reset);
      if (e) {
        const country_id = [e.country_id];
        if (city_reset) this.selectedCity = null;
        LocationServices.GetAllCityOfCountry({ country_id, all: true }).then((res) => {
          this.cityOptions = res.data.data;
        });
      } else {
        this.cityOptions = [];
        if (city_reset) this.selectedCity = null;
      }
    },
  },
};
</script>

<style scoped>
.multis>>>.multiselect__tags {
  /* color: orange; */
  background-color: #eaeaea;
}

.multis>>>.multiselect__tag {
  background: #6244e5;
}

.multis>>>.multiselect__tag-icon:after {
  color: white;
}

.multis>>>.multiselect__tag-icon:hover {
  background: #6244e5;
}

.multis>>>.multiselect__option--highlight {
  background: #6244e5;
}

.multis>>>.multiselect__option:after {
  background-color: #6244e5;
  color: white;
}

.multis>>>.multiselect__input {
  background-color: #eaeaea;
}

.cust-mb-3 {
  margin-bottom: 5px;
}

.submit-desktop {
  display: block;
}

@media screen and (max-width:768px) {
  .cust-mb-3 {
    margin-bottom: 1rem;
  }

  .submit-desktop {
    display: none;
  }



  .custom-control-inline {
    display: inline-flex;
    margin-right: 0rem;
  }
}
</style>

<template>
  <!-- <span style="position: absolute; top: 3%; left: 50%; z-index: 2000"> -->
  <div>
    <languages-dropdown :selected="$language.current" :selected-languages="selectedList"
      btn-bg-color="rgb(97 68 229 / 0%)" v-on:change="optionSelected"></languages-dropdown>
  </div>
  <!-- </span> -->
</template>

<script>
import LanguagesDropdown from "vue-languages-dropdown";

export default {
  components: { "languages-dropdown": LanguagesDropdown },
  props: {},
  data() {
    return {
      //   selectedList: $language.available, // ["en", "ur", "ar"],
      selectedLang: "en",
      selectedList: ["en", "sw"],
    };
  },
  beforeMount() {
    let lang = this.$cookies.get("selected_lang");
    console.log("lang>>>>>", lang);
  },
  methods: {
    optionSelected: function (data) {
      console.log(data);
      this.$language.current = data.code;
      this.$cookies.set("selected_lang", data.code);
    },
  },
};
</script>

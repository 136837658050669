<template>
  <div>
    <p v-show="false">{{ fetchUser }}</p>
    <div class="border-bottom d-flex align-items-center justify-content-between w-100" v-for="app of applicants"
      :key="app.user_id" style="cursor: pointer"
      :class="selectedUser && selectedUser.user_id == app.user_id ? 'selected' : ''">
      <div class="d-flex align-items-center my-2 mx-2 w-100" @click="onSelectUser(app)">
        <div class="company-img">
          <img v-if="app.user_job_profile_image" :src="image_url + app.user_job_profile_image"
            class="nav-rounded-circle" />
          <img src="@/assets/unknown.jpg" class="nav-rounded-circle" v-if="!app.user_job_profile_image" />
        </div>
        <div class="pl-2">
          <h5 style="color: #000; text-transform: capitalize; font-weight: bold;" class="mb-0"
            :class="selectedUser && selectedUser.user_id == app.user_id ? 'seletcted-user-detail' : 'user-detail'">
            {{ app.user_username }}
          </h5>
          <p class="mb-0" style="color: #0000008d; text-transform: capitalize; font-weight: 700;"
            :class="selectedUser && selectedUser.user_id == app.user_id ? 'seletcted-user-detail' : 'user-detail'">
            {{ app.user_job_title }}
          </p>
        </div>
      </div>
      <img class="arrow-icon" width="10px" src="@/assets/right-arrow-icon.png" alt="" />
    </div>
  </div>
</template>

<script>
import RecruiterJobService from "@/apiServices/RecruiterJobService";
import { IMAGEURL } from "@/config";
export default {
  name: "AllAppliedApplicants",
  props: {
    user_id: Number,
    applicants: Array,
  },
  data() {
    return {
      // applicants: [],
      image_url: IMAGEURL,
      selectedUser: null,
    };
  },
  beforeMount() {
    // this.getApplicants();
  },
  computed: {
    fetchUser: function () {
      if (this.user_id) {
        const user = this.applicants.filter((f) => f.user_id == this.user_id)[0];

        this.onSelectUser(user);
      }
    },
  },
  mounted() { },
  methods: {
    // getApplicants() {
    //   RecruiterJobService.GetAppliedApplicantForMessage().then((res) => {
    //
    //     this.applicants = res.data.data;
    //   });
    // },
    onSelectUser(user) {
      this.selectedUser = user;

      this.$emit("selectedUser", user);
    },
  },
};
</script>

<style>
.navbar .nav-rounded-circle {
  border-radius: 50%;
  border: 1px solid #6244e5;
}

.selected {
  border-right: 5px solid #6244e5;
  /* color: #000000; */
}

.seletcted-user-detail {
  color: #000000;
  font-weight: 700;
}

.user-detail {
  color: #9a9a9a;
}

.company-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
}

.company-img img {
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
}

.arrow-icon {
  filter: invert(98%) sepia(98%) saturate(0%) hue-rotate(321deg) brightness(102%) contrast(102%);
  margin-right: 10px;
}
</style>

import Api from "./Api";
import { API_VERSION } from "@/config/index";

export default {
  InsertQuestion(payload, job_id) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/question/${job_id}`, payload);
  },
  GetAllQuestions() {
    return Api().get(`/${API_VERSION}/webapp/recruiter/question`);
  },
  GetAllEQTemplates() {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-eqtemplates`);
  },
  GetEQTemplateQue(template_id) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-eqtemplates-questions/${template_id}`);
  },
  GetAllJobs(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/question?currentpage=${payload.current}&perpage=${payload.pageSize}&search=${payload.searchText}`);
  },
  GetSingleJob(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/question/${payload.job_id}`);
  },
  UpdateJob(payload) {
    return Api().put(`/${API_VERSION}/webapp/recruiter/question/${payload.job_id}`, payload);
  },
  GetAllJobQues(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/job-questions/${payload.job_id}`);
  },
  UpdateQuestion(payload) {
    return Api().put(`/${API_VERSION}/webapp/recruiter/question`, payload);
  },
  GetAllDraftQues(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-all-draft-questions/${payload.d_job_id}`);
  },
  UpdateDraftQues(payload) {
    return Api().put(`/${API_VERSION}/webapp/recruiter/update-video-question`, payload);
  },
  GetAllSuggestedQuestions(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/suggested-question?perPage=${payload.perPage}&currentPage=${payload.currentPage}`);
  },
  SuggestedQues(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/suggested-ques/${payload.job_title}`);
  },
};

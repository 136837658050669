import Api from "./Api";
import { API_VERSION } from "@/config/index";

export default {
  InsertCompany(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/company`, payload);
  },
  GetAllCompanies(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/company?currentpage=${payload.current}&perpage=${payload.pageSize}&search=${payload.searchText}`);
  },
  GetSingleCompany(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/company/${payload.company_id}`);
  },
  IsCompanyPresent(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/company-present/${payload}`);
  },
  UpdateCompany(payload, company_id) {
    return Api().put(`/${API_VERSION}/webapp/recruiter/company/${company_id}`, payload);
  },
  getSubscription() {
    return Api().get(`/${API_VERSION}/webapp/applicant/all_subscription`);
  },
  // addUserSubscription(payload) {
  //     return Api().post(`/${API_VERSION}/webapp/applicant/subscription`, payload);
  // },
  getUserSubscription(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/subscription`);
  },

  updateUserSubscription(payload) {
    return Api().put(`/${API_VERSION}/webapp/applicant/subscription`, payload);
  },
  getCreatedEmployee(payload, company_id) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/company-employee?currentpage=${payload.current}&perpage=${payload.pageSize}&search=${payload.searchText}`);
  },
  deleteEmployee(payload) {
    return Api().delete(`/${API_VERSION}/webapp/recruiter/delete-employee/${payload.user_id}`);
  },
  getAllIndustry() {
    return Api().get(`/${API_VERSION}/webapp/recruiter/industry`);
  },
  uploadCompanyVideo(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/company-video`, payload);
  },
  deleteCompanyImage(img_id) {
    return Api().delete(`/${API_VERSION}/webapp/recruiter/delete-company-image/${img_id}`);
  },
  deleteCompanyVideo(video_id) {
    return Api().delete(`/${API_VERSION}/webapp/recruiter/delete-company-video/${video_id}`);
  },
  ReUploadImage(payload, company_id) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/company_image/${company_id}`, payload);
  },
  DeleteProfileImage(payload) {
    return Api().delete(`/${API_VERSION}/webapp/recruiter/company_image/${payload}`);
  },
  SendMailToEmployee(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/send-mail-employees`, payload);
  },
};

import Api from "./Api";
import { API_VERSION } from "@/config/index";

export default {
  UpdateJobNew(payload) {
    console.log("updateJobnew called with payload", payload);
    return Api().put(`/${API_VERSION}/webapp/recruiter/basic-details`, payload);
  },
  InsertJob(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/jobs`, payload);
  },
  InsertOnlyBasicDetailsJob(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/basic-details`, payload);
  },
  InsertOnlyCandidateDetails(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/candidate-details/${payload.nxpId}`, payload);
  },
  InsertVirtualQuestions(payload) {
    console.log("InsertVirtualQuestions payload", payload);
    return Api().post(`/${API_VERSION}/webapp/recruiter/interview-questions/${payload.nxpId}`, payload);
  },
  GetAllEmployersNApplicants(payload, filter) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/all-employers-n-applicants`);
  },
  GetJobs(payload, filter) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/get-jobs?currentpage=${payload.current}&perpage=${payload.pageSize}&search=${payload.searchText}&company_id=${payload.company_id}&admin=${payload.admin}`, filter);
  },
  GetAllJobs(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/jobs?currentpage=${payload.current}&perpage=${payload.pageSize}&search=${payload.searchText}&company_id=${payload.company_id}&admin=${payload.admin}`);
  },
  GetFilteredJobs(payload, filter) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/filtered-jobs?currentpage=${payload.current}&perpage=${payload.pageSize}&search=${payload.searchText}&company_id=${payload.company_id}`, filter);
  },
  GetFilteredApplicant(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/get-filtered-applicant/${payload.job_id}`, payload);
  },
  GetInterviewAnswerForJob(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/job-interview-answer/${payload.user_id}/${payload.job_id}`);
  },
  GetAppliedApplicant(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/jobApplied-applicants/${payload.job_id}?applicant=${payload.searchApplicant ? payload.searchApplicant : ""}&city_id=${payload.city_id ? payload.city_id : ""}`, payload);
  },
  GetRejectedApplicants(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/rejected-applicants/${payload.job_id}`);
  },
  RecoverApplicant(payload) {
    return Api().put(`/${API_VERSION}/webapp/recruiter/recover-applicant/${payload.job_apply_id}`, payload);
  },
  GetSingleJob(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/jobs/${payload.job_id}`);
  },
  GetJobResponsiblity(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/job-responsiblity/${payload.job_id}`);
  },
  GetJobEducation(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/job-education/${payload.job_id}`);
  },
  UpdateJob(payload) {
    return Api().put(`/${API_VERSION}/webapp/recruiter/jobs/${payload.job_id}`, payload);
  },
  getAllSkills() {
    return Api().get(`/${API_VERSION}/webapp/skill`);
  },
  getAllIndustrySkills(payload, jobId) {
    console.log("jobID", jobId);
    return Api().post(`/${API_VERSION}/webapp/indusry-skill/${jobId}`, payload);
  },
  SearchSkills(payload) {
    return Api().get(`/${API_VERSION}/webapp/skill?search=${payload.search}`);
  },
  AddShortlist(payload) {
    return Api().put(`/${API_VERSION}/webapp/recruiter/shortlist`, payload);
  },

  GetApplicantProfile(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-applicant-profile/${payload.user_id}?job_id=${payload.job_id}`);
  },
  GetShortlisted(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/shortlist/${payload.job_id}`);
  },
  GetSingleShortlisted(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/shortlist/${payload.user_id}`);
  },
  selectedShortlist(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/selected_shortlist/${payload.user_id}`);
  },
  ShortlistJob(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/shortlist-jobs`, payload);
  },
  DeleteJob(payload) {
    return Api().delete(`/${API_VERSION}/webapp/recruiter/jobs/${payload.job_id}`);
  },
  //reject application
  RejectApplication(payload) {
    return Api().patch(`/${API_VERSION}/webapp/recruiter/application/reject/${payload.job_apply_id}`);
  },
  //set virtual video points
  SetVideoPoints(payload) {
    return Api().patch(`/${API_VERSION}/webapp/recruiter/application/setvideopoints`, payload);
  },

  //get job titles
  GetAllJobTitles() {
    return Api().get(`/${API_VERSION}/webapp/job_titles`);
  },
  getJobCount() {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-jobs-count`);
  },
  getChatGPT() {
    return Api().get(`/${API_VERSION}/webapp/recruiter/chat-gpt`);
  },
  getPlan() {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-plans`);
  },

  GetEducationStreams() {
    return Api().get(`/${API_VERSION}/webapp/recruiter/user-education`);
  },

  GetCurrency() {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-currency`);
  },
  GetAllApplicants(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-recent-applicants?country_id=${payload.country_id ? payload.country_id : ""}&currentPage=${payload.currentPage}&perPage=${payload.perPage}`);
  },
  GetAllMatchApplicants(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-matched-applicants?perPage=${payload.perPage ? payload.perPage : 10}&currentPage=${payload.current ? payload.current : 1}`);
  },
  GetApplicantsByIndustry(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-applicant-by-industry?perPage=${payload.pageSize}&currentPage=${payload.current}`);
  },
  GetSearchApplicants(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/get-search-applicants?currentPage=${payload.current}&perPage=${payload.pageSize}&search=${payload.searchText}&city_id=${payload.city_id}&country_id=${payload.country_id ? payload.country_id : ""}`, payload);
  },
  GetAllAppliedApplicants() {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-all-applied-applicants`);
  },
  GetAllNationality() {
    return Api().get(`/${API_VERSION}/webapp/nationality`);
  },
  GetAllSpeciality() {
    return Api().get(`/${API_VERSION}/webapp/speciality`);
  },
  AddDraftJob(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/add-job-draft`, payload);
  },
  GetAllDraftJobs() {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-all-draft-jobs`);
  },

  GetSingleDraftJob(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/get-single-draft-job/${payload.d_job_id}`);
  },
  AddDraftJobV2(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/add-draft-v2`, payload);
  },
  GetDraftResponsiblity(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/draft-responsiblity/${payload.d_job_id}`);
  },
  GetDraftEducation(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/draft-education/${payload.d_job_id}`);
  },
  GetAppliedApplicantForMessage() {
    return Api().get(`/${API_VERSION}/webapp/get-applied-applicant`);
  },
  GetApplicantMessage(payload) {
    return Api().get(`/${API_VERSION}/webapp/get-message/${payload.app_id}?job_id=${payload.job_id ? payload.job_id : ""}`);
  },
  SendMessage(payload) {
    return Api().post(`/${API_VERSION}/webapp/send-message`, payload);
  },
  enableDisableChat(payload) {
    return Api().post(`/${API_VERSION}/webapp/enable-disable-chat`, payload);
  },
  chatActive(payload) {
    return Api().post(`/${API_VERSION}/webapp/chat-active`, payload);
  },
  GetRecommendedApp() {
    return Api().get(`/${API_VERSION}/webapp/recruiter/recommended-applicants`);
  },
  GetApplicantVideos(payload) {
    return Api().get(`/${API_VERSION}/webapp/recruiter/applicant-videos/${payload.user_id}`);
  },
};

<template>
  <b-col cols="12" class="px-0">
    <!-- new :: start -->
    <b-navbar class="py-3" toggleable="lg" type="dark" fixed="top">
      <!-- small screen only -->
      <!-- <b-navbar-brand class="d-block d-sm-none" style="transform: translateX(-50%); left: 50%; position: fixed; top: 15px;">
        <router-link to="/">
          <img width="130" height="40" src="@/assets/logos/Logo-Rectangle-White.svg" class="img-fluid" />
        </router-link>
      </b-navbar-brand> -->

      <!-- normal header -->
      <b-navbar-brand>
        <router-link to="/">
          <img width="130" height="40" src="@/assets/logos/Logo-Rectangle-White.svg" class="img-fluid" />
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item>
            <router-link to="/employers">
              <b-button class="cta-btn2 py-3 bg-white mx-1"> {{ $gettext("How_it_works") }} </b-button>
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/login">
              <b-button style="margin-right: 16px !important;" class="cta-btn2 py-3 bg-white mx-1"> {{
                $gettext("Employers_Login") }} </b-button>
            </router-link>
          </b-nav-item>
          <b-nav-item class="d-lg-none ">
            <router-link to="/download-zaajira-app">
              <b-button style="margin-right: 16px !important;" class="cta-btn2 py-3 bg-white mx-1"> {{
                $gettext("Candidates") }} </b-button>
            </router-link>
          </b-nav-item>
          <b-nav-item class="d-lg-none ">
            <router-link to="/support">
              <b-button style="margin-right: 16px !important;" class="cta-btn2 py-3 bg-white mx-1"> {{
                $gettext("Support") }} </b-button>
            </router-link>
          </b-nav-item>

          <!-- <div class="d-lg-none nav-item" style="padding: 2px 0 0 4px;">
            <b-dropdown id="dropdown-left" variant="primary" text="" class="nav-dropdown m-md-2">
              <template #button-content>
                <span style="color: white;" class="text-capitalize mr-1">More</span>
                <img src="../../assets/app-icons/arrow-white-down.svg" width="12px" class="ml-1" />
              </template>
              <b-dropdown-item class="text-capitalize"
                @click="$router.push({ path: '/download-zaajira-app' })">Candidates</b-dropdown-item>
              <b-dropdown-item class="text-capitalize"
                @click="$router.push({ path: '/support' })">Support</b-dropdown-item>
            </b-dropdown>
          </div> -->


          <!-- <img src="../../assets/app-icons/arrow-white-down.svg" width="12px" class="ml-1" /> -->

          <!-- <b-row style="border-bottom: 1px solid #ffffff26;"
            class="bg-primary pt-3 d-flex align-items-baseline d-block d-lg-none">

            <b-col col-md="3" class="mx-auto">
              <a href="https://play.google.com/store/apps/details?id=com.zaajira" target="_blank"
                rel="noopener noreferrer">
                <img class="img-fluid" width="150" src="@/assets/google.png" alt="google-play" />
              </a>
            </b-col>
            <b-col col-md="3" class="mx-auto">
              <a href="https://apps.apple.com/app/zaajira/id6449016993?platform=iphone" target="_blank"
                rel="noopener noreferrer">
                <img class="img-fluid" width="150" src="@/assets/apple.png" alt="google-play" />
              </a>
            </b-col>
          </b-row> -->
          <b-row style="padding-left: 15px;" class="desktop-view">
            <b-nav-item href="https://play.google.com/store/apps/details?id=com.zaajira" target="_blank"
              class="my-auto nav-img ">
              <img style="max-width: 75%;" class="img-fluid rounded padT" width="150" src="@/assets/google.png"
                alt="google-play" />
            </b-nav-item>
            <!-- <b-nav-item>
            <b-button class="cta-btn p-3">
              Looking for a Job?
            </b-button>
          </b-nav-item> -->
            <b-nav-item href="https://apps.apple.com/app/zaajira/id6449016993?platform=iphone" target="_blank"
              class="my-auto nav-img">
              <img style="max-width: 75%; padding-right:0px" class="img-fluid rounded padT2" width="150"
                src="@/assets/apple.png" alt="apple-store" />
            </b-nav-item>
          </b-row>
          <span v-if="shouldShowLanguageSelector" style="padding-left: 12px;">
            <LanguageSelector />
          </span>
          <b-nav-item class="my-auto nav-img lang-desktop">
            <LanguageSelector />
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <!-- new :: end -->
  </b-col>
</template>
<script>
import { BCol, BButton, BImg, BNavbar, BNavbarBrand, BNavbarToggle, BCollapse, BNavbarNav, BNavItem } from "bootstrap-vue";
import LanguageSelector from "../../views/LanguageSelector.vue";
export default {
  name: "NavbarComp",
  components: { BCol, BButton, BImg, BNavbar, BNavbarBrand, BNavbarToggle, BCollapse, BNavbarNav, BNavItem, LanguageSelector },
  data() {
    return {
      isMobileMenu: window.innerWidth <= 700,
      toggleBtn: false,
      windowWidth: window.innerWidth,
      toggleLang: window.innerWidth <= 992
    };
  },
  methods: {
    toggleMenu() {
      this.toggleBtn = !this.toggleBtn;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  computed: {
    shouldShowLanguageSelector() {
      return this.toggleLang;
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.toggleLang = this.windowWidth <= 992;
    }
  },
  watch: {
    windowWidth(newValue) {
      this.toggleLang = newValue <= 992;
    }
  }
};
</script>
<style scoped>
.navbar {
  /* background-color: #6244e5; */
  border-bottom: 1px solid rgb(255 255 255 / 45%);
  background: rgba(98, 68, 229, 0.8) !important;
}

.padT {
  padding-top: 0px;
}

.padT2 {
  padding-top: 0px;
}

@media screen and (max-width: 992px) {
  .navbar {
    /* background-color: #6244e5; */
    border-bottom: 1px solid rgb(255 255 255 / 45%);
    background: rgba(98, 68, 229, 1) !important;
  }

  .navbar-nav .nav-link {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .nav-link {
    display: block;
    padding: 0;
  }

  .nav-img {
    padding: 0 12px 11px 13px;
  }

  #lang-mob {
    display: none;
  }

  .lang-desktop {
    display: block;
  }

  @media screen and (max-width:992px) {
    #lang {
      display: block;
    }

    .lang-desktop {
      display: none;
    }
  }

  .py-3 {
    padding-bottom: 0 !important;
    padding-top: 12px !important;
  }

  .padT {
    padding-top: 12px;
  }

  .padT2 {
    padding-top: 7px;
  }
}

.cta-btn {
  width: 282px;
  background-color: transparent;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 1) !important;
  opacity: 1;
}

.cta-btn2 {
  color: #ffffff;
  background-color: transparent !important;
  font-size: 1rem;
}

.scrolled {
  border: 1px solid red;
}

.scrollDown {
  border: 1px solid blue;
}

.btn-secondary {
  background-color: transparent !important;
}
</style>

<template>
  <b-container fluid class="auth-page">

    <b-row v-if="!this.$cookies.get('company_id')">
      <b-col col md="12">
        <NavbarCompNew />
      </b-col>
    </b-row>
    <b-row class="main pt-6 py-2">
      <!-- left :: start-->
      <b-col cols="12" md="8" class="mx-auto main__left p-5 d-flex align-items-center justify-content-start">
        <div>
          <h2 class="text-main my-auto">
            {{ $gettext("Connect_with_best_candidates") }}
          </h2>
        </div>
      </b-col>
      <!-- left :: end -->

      <!-- right :: start -->
      <b-col cols="12" md="4" class="mx-auto main__right p-4">
        <div class="main__login p-4 mx-auto"
          style="border-radius: 8px; opacity: 1; background-color: rgba(255, 255, 255, 1)">
          <h5 class="heading py-4 mb-0">{{ $gettext("Login") }}</h5>
          <ValidationObserver ref="login">
            <b-form class="customized-form py-4">
              <!-- phoneOrEmail :: start -->
              <ValidationProvider name="phoneOrEmail" rules="required|phoneOrEmail" v-slot="{ errors, classes }">
                <b-form-input @focus="getRemember" id="email" class="py-4" placeholder="E-mail or phone number"
                  v-model="phoneOrEmail" :class="classes"> </b-form-input>
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <!-- phoneOrEmail :: end -->
              <!-- password :: start -->
              <ValidationProvider name="password" rules="required" v-slot="{ errors, classes }">
                <b-form-input class="py-4" placeholder="Password" v-model="password" type="password" :class="classes">
                </b-form-input>
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <!-- password :: end -->
              <!-- Remember Me :: start -->
              <input style="accent-color: #6244e5" value="remember_Me" id="remember_Me" type="checkbox"
                v-model="remember_Me" @change="rememberMe" />
              <label class="mx-2" for="checkbox">{{ $gettext("Remember_me") }}</label>
              <!-- Remember Me:: end -->
              <!-- captcha :: start -->

              <vue-recaptcha :sitekey="reCaptchaSiteKey" @verify="captchaVerify" @expired="captchaExpired"
                @error="captchaError" ref="recaptcha-login" class="d-flex justify-content-center mx-auto"
                style="overflow: hidden"></vue-recaptcha>

              <!-- captcha :: end -->
              <b-button block class="main__login--button px-2 py-3 mt-4 mx-auto" @click.prevent.stop="ValidateInput"> {{
                $gettext("Login") }} </b-button>
            </b-form>
          </ValidationObserver>
          <div class="py-3 mt-4 border-top d-flex mx-auto justify-content-center">
            <h6 class="f-light text-center">{{ $gettext("No_account") }}</h6>
            <!-- <b-button v-b-modal.modal-prevent-closing>Open Modal</b-button> -->
            <h6 style="cursor: pointer" class="link-color text-center text-color font-weight-bold pl-2"
              @click="() => $router.push({ path: '/register' })">{{ $gettext("Register") }}</h6>
          </div>
          <div class="link-color text-center text-color font-weight-bold py-3" @click="handleForgotPass">{{
            $gettext("Forgot_password") }}</div>
        </div>
      </b-col>
      <!-- right :: end -->
    </b-row>
    <b-row>
      <AuthFooter />
    </b-row>
  </b-container>
</template>
<script>
import UserService from "@/apiServices/UserService";
import CompanyService from "@/apiServices/CompanyServices";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";
import GoogleLogin from "vue-google-login";
import { socialLoginClientId, reCaptchaSiteKey, FRONTEND_BASE_URL } from "@/config";

// import moment from "moment";

// import Vue from "vue";

import VueRecaptcha from "vue-recaptcha";
import NavbarComp from "../components/shared/NavbarComp.vue";
import NavbarCompNew from "../components/shared/NavbarCompNew.vue";
import AuthFooter from "../components/shared/AuthFooter.vue";
import Pricing from "./Pricing.vue";

export default {
  components: { GoogleLogin, VueRecaptcha, NavbarComp, NavbarCompNew, AuthFooter },
  data() {
    return {
      remember_Me: false,
      captchaValue: null,
      reCaptchaSiteKey: reCaptchaSiteKey,
      params: {
        client_id: socialLoginClientId,
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      //loginAttempt: true,
      fEmail: null,
      phoneOrEmail: null,
      nameState: null,
      password: null,
      company_id: null,
      token: null,
      sectionSlider: {
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    };
  },
  methods: {
    setRemember() {
      if (this.remember_Me) {
        this.$cookies.set("rusername", this.phoneOrEmail);
        this.$cookies.set("pswd", this.password);
        console.log("setRemember", this.remember_Me, this.phoneOrEmail, this.password);
      } else {
        this.resetRemember();
      }
    },
    getRemember() {
      if (this.$cookies.get("rusername") !== null && this.phoneOrEmail !== "" && this.password !== "") {
        this.phoneOrEmail = this.$cookies.get("rusername");
        this.password = this.$cookies.get("pswd");
        this.remember_Me = true;
        console.log("1st if", this.remember_Me, this.phoneOrEmail, this.password);
      }
    },
    resetRemember() {
      this.$cookies.set("rusername", "", "-1");
      this.$cookies.set("pswd", "", "-1");
      this.remember_Me = false;
    },
    rememberMe() {
      if (this.remember_Me === true) {
        this.setRemember();
      } else {
        this.resetRemember();
      }
    },

    captchaVerify(event) {
      this.rememberMe();
      console.log(`Captcha Verify Event :`, event);
      this.captchaValue = event;
    },
    captchaExpired() {
      console.log("Captcha Expired");
      this.captchaValue = null;
    },
    captchaError() {
      console.log("Captcha Error");
      this.captchaValue = null;
    },
    // validate the input
    ValidateInput() {
      this.$refs.login.validate().then((result) => {
        if (result) {
          if (this.captchaValue) this.login();
          else
            this.$toasted.show("Please verify captcha", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          return;
        } else {
          return;
        }
      });
    },
    forgotPass() {
      let loader = this.$loading.show();
      // new stuff
      const isItEmail = (emailToCheck) => /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(emailToCheck);

      if (isItEmail(this.phoneOrEmail)) {
        UserService.forgotPassword({ user_email: this.phoneOrEmail })
          .then((res) => {
            this.$toasted.show("An OTP sent to your email", {
              duration: 6000,
              type: "error",
              icon: "check",
            });
            this.$router.push({ name: "Forgot_Password" });
            loader.hide();
          })
          .catch((err) => {
            // this.$router.push({ name: "Forgot_Password" });
            loader.hide();
            this.$toasted.show("Email not found", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          });
      } else {
        UserService.forgotPassword({ user_mobile_number: this.phoneOrEmail })
          .then((res) => {
            this.$toasted.show("An OTP sent to your email", {
              duration: 6000,
              type: "success",
              icon: "check",
            });
            this.$router.push({ name: "Forgot_Password" });
            loader.hide();
          })
          .catch((err) => {
            // this.$router.push({ name: "Forgot_Password" });
            loader.hide();
            this.$toasted.show("Email not found", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          });
      }

      // new stuff end
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    handleForgotPass() {
      if (this.phoneOrEmail !== "" && this.phoneOrEmail !== null) {
        const payload = this.phoneOrEmail;
        this.$store.commit("SET_EMAIL", payload);
        this.forgotPass();
      } else {
        this.$toasted.show("Please enter email or phone", {
          duration: 6000,
          type: "error",
          icon: "clear",
        });
      }
    },
    async onSuccess(googleUser) {
      console.log(googleUser);
      const profile = googleUser.getBasicProfile();
      try {
        const c = await googleUser.getAuthResponse();
        // This only gets the user information: id, name, imageUrl and email
        console.log(googleUser.getBasicProfile());
        console.log("profile.getId() :>> ", profile.getId());
        console.log("profile.getName :>> ", profile.getName());
        console.log("profile.getEmail() :>> ", profile.getEmail());
        console.log("c :>> ", c);

        const user = await UserService.getUser({
          email: profile.getEmail(),
        });

        if (user.data.data.length > 0) {
          const token = { token: c.id_token, role_id: 3 };
          try {
            const login = await UserService.socialLogin(token);
            console.log("login :>> ", login.data.data);
            const payload = {
              userName: login.data.data.user_username,
              userEmail: login.data.data.user_email,
              userRole: login.data.data.role_id,
              userToken: token.token,
            };

            if (payload.userRole == 3) {
              this.$store.dispatch("onSetUserSigninValues", payload);
            }

            if (payload.userRole == 2) {
              let company_id = login.data.data.company_id;
              const company = await CompanyService.GetSingleCompany({
                company_id,
              });
              console.log("company.data.data :>> ", company.data.data);
              if (company.data.data.length > 0) {
                const p = {
                  userName: company.data.data[0].company_long_name,
                  userEmail: login.data.data.user_email,
                  userRole: login.data.data.role_id,
                  userToken: c.id_token,
                };
                this.$store.dispatch("onSetUserSigninValues", p);
              }
              this.$cookies.set("admin", login.data.data.admin);
            }

            this.$cookies.set("AIAPADHASHID", c.id_token);
            this.$cookies.set("role_id", login.data.data.role_id);
            this.$cookies.set("user_id", login.data.data.user_id);
            this.$toasted.show("Login Success", {
              duration: 6000,
              type: "success",
              icon: "check",
            });
            if (login.data.data.role_id == 3) {
              this.$router.push({ name: "Applicant-Profile" });
            } else {
              this.$cookies.set("company_id", login.data.data.company_id);
              this.$router.push({ name: "Employer-Profile" });
            }
          } catch (error) {
            this.$toasted.show("Login Failed", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          }
        } else {
          this.$store.commit("SET_NAME", profile.getName());
          this.$store.commit("SET_EMAIL", profile.getEmail());
          this.$toasted.show("Please set your password", {
            duration: 6000,
            type: "error",
            icon: "clear",
          });
          this.$router.push({ name: "Register" });
        }
      } catch (error) {
        this.$toasted.show("There is something wrong", {
          duration: 6000,
          type: "error",
          icon: "clear",
        });
      }
    },
    onFailure(failed) {
      console.log("Login Failed", failed);
      this.$toasted.show("Login Failed", {
        duration: 6000,
        type: "error",
        icon: "clear",
      });
    },
    async login() {
      try {
        if (this.phoneOrEmail && this.password) {
          // empty login details initially
          let loginDetails = {};

          // check if user entered is a valid email or phone number
          const isItEmail = (emailToCheck) => /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(emailToCheck);

          // set property accordingly
          if (isItEmail(this.phoneOrEmail)) {
            loginDetails = {
              email: this.phoneOrEmail,
              password: this.password,
              captcha: this.captchaValue,
            };
          } else {
            loginDetails = {
              mobile_number: this.phoneOrEmail,
              password: this.password,
              captcha: this.captchaValue,
            };
          }

          // login service called
          console.log("Calling UserService.login()");
          const loginUser = await UserService.Login(loginDetails);

          // lets check the response in console
          console.log("Response of UserService.login() =", loginUser);
          console.log(loginUser.data.data.user.user_id, "loginUser.data");
          this.$store.dispatch("setUserID", loginUser.data.data.user.user_id);

          // if user not found
          if (loginUser.data.data.notFound) {
            this.$refs["recaptcha-login"] && this.$refs["recaptcha-login"].reset();
            this.$toasted.show("User not found", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
            return;
          }

          // if invalid password
          if (loginUser.data.data.invalidPass) {
            this.$refs["recaptcha-login"] && this.$refs["recaptcha-login"].reset();
            const attempts = loginUser.data.data.attempsRemaining ? loginUser.data.data.attempsRemaining : 0;
            if (loginUser.data.data.block_login && attempts == 0) {
              // this.loginAttempt = false;
              this.$toasted.show(loginUser.data.data.msg, {
                duration: 6000,
                type: "success",
                icon: "check",
              });
            } else {
              this.$toasted.show(loginUser.data.data.msg, {
                duration: 6000,
                type: "error",
                con: "clear",
              });
            }
            return;
          }

          // if data archived
          if (loginUser.data.data && loginUser.data.data.archived) {
            this.$refs["recaptcha-login"] && this.$refs["recaptcha-login"].reset();
            this.$toasted.show(loginUser.data.data.msg, {
              duration: 6000,
              type: "success",
              icon: "check",
            });
            return;
          }

          // if captcha verification failed
          if (loginUser.data.data && loginUser.data.data.captcha_failed) {
            this.$refs["recaptcha-login"] && this.$refs["recaptcha-login"].reset();
            this.$toasted.show(loginUser.data.data.msg, {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
            return;
          }

          const payload = {
            userName: loginUser.data.data.user.user_username,
            userEmail: loginUser.data.data.user.user_email,
            userRole: loginUser.data.data.user.role_id,
            userToken: loginUser.data.data.token,
          };

          console.log("[check default role here] Now the payload is = ", payload);

          // check if person is candidate
          if (payload.userRole === 3) {
            console.log("THIS PERSON IS CANDIDATE");
            this.$store.dispatch("onSetUserSigninValues", payload);
          }

          // check if person is employee
          if (payload.userRole === 2) {
            console.log("THIS PERSON IS EMPLOYER");
            try {
              let company_id = loginUser.data.data.user.company_id;
              const company = await CompanyService.GetSingleCompany({
                company_id,
              });
              console.log("COMPANY DATA = ", company.data.data);
              if (company.data.data.length > 0) {
                const p = {
                  userName: company.data.data[0].company_long_name,
                  userEmail: loginUser.data.data.user.user_email,
                  userRole: loginUser.data.data.user.role_id,
                  userToken: loginUser.data.data.token,
                };
                this.$store.dispatch("onSetUserSigninValues", p);

                const profileImage = company.data.data[0].company_logo_path;
                localStorage.setItem("image", profileImage);
                const pay = { image: profileImage };
                console.log("pay :>> ", pay);
                this.$store.dispatch("onSetProfileImage", pay);
              }
            } catch (error) {
              console.log("error from login.vue component : ", error.message);
            }
          }

          // set cookies
          this.$cookies.set("username", payload.userName);
          this.$cookies.set("AIAPADHASHID", payload.userToken);
          this.$cookies.set("role_id", payload.userRole);

          console.log("Final login Data after cookie is set = ", loginUser.data.data.user);

          console.log("now checking if user is first login ?");
          if (loginUser.data.data.user.first_login == "Y") {
            try {
              console.log("This is the first time user logging so set the login flag");
              let setLoginFlagResponse = await UserService.SetLoginFlag();
              console.log("SetLoginFlag Response : ", setLoginFlagResponse);
            } catch (error) {
              console.log(error.message);
            }
          } else {
            console.log("Not the first time login");
          }

          const employee = {
            company_id: loginUser.data.data.user.company_id,
            role_id: loginUser.data.data.user.role_id,
          };
          // if (employee.company_id && employee.role_id == 2) {
          //   console.log("employee :>> ", employee);
          //   this.$cookies.set(
          //     "company_id",
          //     loginUser.data.data.user.company_id
          //   );
          //   this.$toasted.show("Login Success", {
          //     position: "top-right",
          //     duration: 6000,
          //     icon: "check",
          //   });
          //   this.$router.push({ name: "Employer-Profile" });
          //   return;
          // }

          const { user_id, role_id, user_username, admin, first_login, company_id } = loginUser.data.data.user;

          console.log("COMPANY ID :", company_id);
          this.$cookies.set("google_login", false);

          if (company_id) {
            this.$cookies.set("company_id", company_id);
          }
          this.$cookies.set("user_id", user_id);
          this.$cookies.set("admin", admin);

          let p = { applicant_id: user_id };

          if (role_id == 3) {
            try {
              if (loginUser.data.data.user.first_login == "N") {
                const firstTimeLogin = await ApplicantProfileService.getApplicantProfile(p);
                console.log("First Time Login = ", firstTimeLogin);

                if (firstTimeLogin.data.data.user_data.length > 0) {
                  const profileImage = firstTimeLogin.data.data.user_data[0].user_job_profile_image;
                  localStorage.setItem("image", profileImage);
                  const pay = { image: profileImage };
                  console.log("pay :>> ", pay);
                  this.$store.dispatch("onSetProfileImage", pay);
                  console.log("image :>> ", profileImage);
                  console.log("loginUser.data.data.user 2 :>> ", loginUser.data.data.user);
                }
                this.$router.push({ name: "Employer-jobs" });
              } else {
                try {
                  let setLoginFlagResponse = await UserService.SetLoginFlag();
                  console.log("setLoginFlagResponse", setLoginFlagResponse);
                  if (loginUser.data.data.user.referal_id) {
                    console.log("User have referal code");
                    // this.$router.push({
                    //   name: "Applicant-Referrals",
                    //   query: { login: 1 },
                    // });
                    this.$router.push({ name: "Applicant-Profile" });
                    this.$toasted.show("Login Success!", {
                      duration: 6000,
                      icon: "check",
                    });
                  } else {
                    console.log("User do not have referal code");
                    // this.$router.push({
                    //   name: "Applicant-Settings",
                    //   query: { login: 1 },
                    // });
                    this.$router.push({ name: "Applicant-Profile" });
                    this.$toasted.show("Login Success!", {
                      duration: 6000,
                      icon: "check",
                    });
                  }
                } catch (error) {
                  console.log("error while setting flag :>> ", error);
                }
              }

              // } else {
              //   const role = loginUser.data.data.user.role_id;
              //   console.log("role_id :>> ", role);
              //   if (role === 3) {
              //     console.log(" hitting role 3:>> ", role);
              //     const applicant = await ApplicantProfileService.addApplicantProfile(
              //       {
              //         user_id: loginUser.data.data.user.user_id,
              //         role_id: loginUser.data.data.user.role_id,
              //       }
              //     );
              //     this.$toasted.show("Login Success!", {
              //       position: "top-right",
              //       duration: 6000,
              //       icon: "check",
              //     });
              //     console.log("applicant :>> ", applicant);
              //     if (applicant.data.status) {
              //       if (loginUser.data.data.user.first_login == "N")
              //         this.$router.push({ name: "Applicant-Profile" });
              //       else
              //         this.$router.push({
              //           name: "Applicant-Settings",
              //           query: { login: 1 },
              //         });
              //     }
              //   }
              // }
            } catch (error) { }
          } else if (role_id === 2) {
            console.log("user having role id of 2");
            const p = { user_id: user_id, company_long_name: user_username };
            console.log(p);
            if (admin != "N") {
              console.log("user is not admin");
              const comp = await CompanyService.IsCompanyPresent(user_id);
              console.log("res of await CompanyService.IsCompanyPresent = ", comp);
              if (loginUser.data.data.user.first_login == "Y") {
                try {
                  console.log("This is the first login");
                  let setLoginFlagResponse = await UserService.SetLoginFlag();
                  console.log("setLoginFlagResponse = ", setLoginFlagResponse);
                  this.$cookies.set("company_id", company_id);
                  this.$cookies.set("admin", "Y");
                  this.$router.push({
                    name: "Employer-onboarding-1",
                    query: { login: 1 },
                  });
                } catch (error) {
                  console.log("error while setting flag : ", error);
                }

                // CompanyService.InsertCompany(p)
                //   .then(async (res) => {
                //     console.log("1st time login :>> ", res.data.data);
                //     this.$cookies.set(
                //       "company_id",
                //       res.data.data.company_id[0]
                //     );
                //     this.$cookies.set("admin", "Y");
                //     this.$router.push({
                //       name: "Employer-settings",
                //       query: { login: 1 },
                //     });
                //   })
                //   .catch((err) => {
                //
                //   });
              } else {
                this.$cookies.set("company_id", company_id);
                // this.$router.push({
                //   name: "Search-Candidate",
                // });

                // CompanyService.InsertCompany(p)
                //   .then(async (res) => {
                //     console.log("2nd time login ::::::>> ", res.data.data);
                //     this.$cookies.set(
                //       "company_id",
                //       res.data.data.company_id[0]
                //     );
                //   })
                //   .catch((err) => {
                //
                //   });
              }
            } else {
              console.log("hitting ---");
              this.$router.push({
                name: "Employer-jobs",
              });
            }
          } else {
            console.log("You are not authorized");
            this.$toasted.show("You are not authorized", {
              duration: 6000,
              icon: "clear",
            });
            return;
          }
        } else {
          console.log("Enter Email and Password");
        }
      } catch (error) {
        const err = JSON.stringify(error);
        this.$toasted.show(err, {
          duration: 6000,
          type: "error",
          icon: "clear",
        });
      }
    },
  },
};
</script>
<style scoped>
.main {
  background-color: #6244e5;
}

.text-main {
  color: rgba(255, 255, 255, 1);
  font-size: clamp(2rem, 5vw, 3.5rem);
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.main__login--button {
  background-color: #6244e5;
}

.link-color {
  color: #6244e5;
  cursor: pointer;
  font-size: 14px;
}
</style>

import Api from "./Api";
import { API_VERSION } from "@/config/index";

export default {
  Login(payload) {
    return Api().post(`/${API_VERSION}/webapp/login`, payload);
  },

  Register(payload) {
    return Api().post(`/${API_VERSION}/webapp/register?referal_code=${payload.referal_code}`, payload);
  },
  createEmployee(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/createEmployee`, payload);
  },
  employeeLogin(payload) {
    return Api().post(`/${API_VERSION}/webapp/recruiter/employeeLogin`, payload);
  },
  updatePassword(payload) {
    return Api().post(`/${API_VERSION}/webapp/update-user`, payload);
  },

  formSubmit(payload) {
    return Api().post(`/${API_VERSION}/webapp/form-submit`, payload);
  },
  SetLoginFlag() {
    return Api().patch(`/${API_VERSION}/webapp/setloginflag`);
  },
  socialLogin(payload) {
    return Api().post(`/${API_VERSION}/webapp/social-login`, payload);
  },
  getRegisteredUser(payload) {
    return Api().get(`/${API_VERSION}/webapp/get-registered-user`);
  },
  getUser(payload) {
    return Api().get(`/${API_VERSION}/webapp/get-user?email=${payload.email}`);
  },
  forgotPassword(payload) {
    return Api().post(`/${API_VERSION}/webapp/forgot-password`, payload);
  },
  resetPassword(payload) {
    return Api().post(`/${API_VERSION}/webapp/reset-password`, payload);
  },
  verifyEmail(payload) {
    return Api().post(`/${API_VERSION}/webapp/verify-email?referal_code=${payload.referal_code}`, payload);
  },
  verifyByLink(payload) {
    return Api().post(`/${API_VERSION}/webapp/varify-by-link?referal_code=${payload.referal_code}`, payload);
  },
  resetInvalidPassAttempts() {
    return Api().post(`/${API_VERSION}/webapp/reset-invalid_pass_attempt`);
  },
  supportRequest(payload) {
    return Api().post(`/${API_VERSION}/webapp/support-ticket`, payload);
  },
  contactUs(payload) {
    return Api().post(`/${API_VERSION}/webapp/contact-us`, payload);
  },
};

<template>
  <div class="container-fluid profile-user emp-settings customized-form px-1 px-md-3 pt-4 pb-5 mt-5">
    <div class="col-md-12 box">
      <div class="profile-flex border-bottom py-3">
        <h4 class="heading">{{ $gettext("Change_password") }}</h4>
      </div>
      <ValidationObserver ref="changePassword" tag="div">
        <div class="border-bottom py-3">
          <b-form class="customized-form">
            <!-- <ValidationProvider name="email" rules="email" v-slot="{ errors, classes }" tag="div">
              <b-form-input :disabled="true" placeholder="E-mail" class="col-xl-3 input-email" :class="classes" v-model="email"> </b-form-input>
              <div class="error-msg" :class="classes">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider> -->
            <div class="account-grid">
              <ValidationProvider name="password" rules="required" vid="confirmation" tag="div" v-slot="{ errors, classes }">
                <b-form-input type="password" :placeholder="$gettext('New_Password')" v-model="password" :class="classes" class="form-control"> </b-form-input>
                <div class="error-msg" :class="classes">
                  <!-- <span>{{ errors[0] }}</span> -->
                  <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ errors, classes }" tag="div">
                <b-form-input :class="classes" :placeholder="$gettext('Confirm_password')" type="password" v-model="confirmPassword" class="form-control"> </b-form-input>
                <div class="error-msg" :class="classes">
                  <!-- <span>{{ errors[0] }}</span> -->
                  <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                </div>
              </ValidationProvider>
              <button class="btn btn-primary btn-submit" @click.prevent.stop="updatePassword">{{ $gettext("Change_password") }}</button>
            </div>
          </b-form>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import UserService from "@/apiServices/UserService";
export default {
  data() {
    return {
      email: null,
      password: null,
      confirmPassword: null,
    };
  },
  methods: {
    updatePassword() {
      const payload = {
        user_id: this.$cookies.get("user_id"),
        email: this.email,
        password: this.password,
      };
      this.$refs.changePassword.validate().then((result) => {
        if (result) {
          UserService.updatePassword(payload)
            .then((res) => {
              this.$toasted.show("Password Changed Successfully!", {
                position: "top-center",
                duration: 6000,
                icon: "check",
              });
            })
            .catch((err) => {
              this.$toasted.show("Something Missing!", {
                position: "top-center",
                duration: 6000,
                icon: "clear",
              });
            });
        } else {
          this.$toasted.show("Fill the correct fields!", {
            position: "top-center",
            duration: 6000,
            icon: "clear",
          });
        }
      });
    },
  },
};
</script>

<style></style>

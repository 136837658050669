var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"user-header",attrs:{"fluid":""}},[_c('div',{staticClass:"pt-5 mt-5"}),_c('b-row',[_c('b-col',{staticClass:"col-md-7 col-12 user-about pt-3 pb-5"},[_c('div',{staticClass:"user-img"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":() => {
              if (_vm.$route.name != 'Employer-Profile') _vm.$router.push({ name: 'Employer-Profile' });
            }}},[_c('img',{staticClass:"rounded img-center",attrs:{"src":`${_vm.image_url}${this.$store.getters.getProfileImage}`,"width":"100"}}),(!this.$store.getters.getProfileImage)?_c('img',{staticClass:"rounded img-center",attrs:{"src":require("../../assets/unknown.jpg"),"width":"100"}}):_vm._e()])]),_c('div',{staticClass:"user-content ml-3"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":() => {
              if (_vm.$route.name != 'Employer-Profile') _vm.$router.push({ name: 'Employer-Profile' });
            }}},[_c('h3',{staticClass:"user-name"},[_vm._v(" "+_vm._s(_vm.$store.getters.getLoggedUser.userName)+" ")]),_c('p',{staticClass:"user-loc"},[_vm._v(_vm._s(_vm.$store.getters.getCountry)),_c('br'),_vm._v(_vm._s(_vm.$store.getters.getCity))])])])]),(_vm.$route.fullPath === '/applicant-profile-emp')?_c('b-col',{staticClass:"col-md-5 align-self-center"},[_c('div',{staticClass:"app-emp-header"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/app-icons/forward.svg"),"width":"15px"}}),_c('h6',[_vm._v("Shortlist")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/app-icons/forward.svg"),"width":"15px"}}),_c('h6',[_vm._v("Message")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/app-icons/forward.svg"),"width":"15px"}}),_c('h6',[_vm._v("Forward")])])])]):_vm._e(),(_vm.$route.fullPath !== '/applicant-profile-emp')?_c('b-col',{staticClass:"col-md-12 col-xl-5 user-header-nav"},[_c('ul',{staticClass:"user-nav-list"},[_c('li',{staticClass:"user-nav-item"},[_c('router-link',{staticClass:"user-nav-link",attrs:{"to":{ name: 'Employer-Profile' }}},[_vm._v("Profile")])],1),_c('li',{staticClass:"user-nav-item"},[_c('router-link',{staticClass:"user-nav-link",class:{
              'router-link-active': _vm.$route.fullPath === '/employer-jobs' || _vm.$route.fullPath === '/employer-jobcreation-1',
            },attrs:{"to":{ name: 'Employer-jobs' }}},[_vm._v("JOBS")])],1),_c('li',{staticClass:"user-nav-item"},[_c('router-link',{staticClass:"user-nav-link",attrs:{"to":{ name: 'Employer-settings' }}},[_vm._v("Settings")])],1),_c('li',{staticClass:"user-nav-item"},[_c('router-link',{staticClass:"user-nav-link",attrs:{"to":{ name: 'Employer-Messages' }}},[_vm._v("Messages")])],1)])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
    Enabling_jobs_for_millions: "Enabling jobs for millions of talented workers in Africa",
    Employers: "Employers",
    Employers_Login: "Employers Login",
    How_it_works: "How it works",
    Pricing: "Pricing",
    Find_Jobs: "Find Jobs",
    Saved_Jobs: "Saved Jobs",
    Chat: "Chat",
    Hire_a_Candidate: "Hire a Candidate",
    Employer: "Employer",
    Find_a_Job: "Find a Job",
    Search_by_title_company_or_keywords: "Search by title, company or keywords..",
    Candidates: "Candidates",
    Candidate: "Candidate",
    Why_Apply_On_Zaajira: "Why Apply On Zaajira?",
    Feature_that_supercharge_your_job_search_and_profile: "Feature that supercharge your job search and profile",
    Connect_with_top_employers_in_minutes: "Connect with top employers in minutes",
    Increase_your_chances_with_custom_cards: "Increase your chances with custom cards",
    Browse_specific_jobs_with_detailed_requirements: "Browse specific jobs with detailed requirements",
    Zaajira_Success_Stories: "Zaajira Success Stories",
    Showroom_Manager: "Showroom Manager",
    Trusted_by_leading_companies_of_the_world: "Trusted by leading companies of the world!",
    An_integrated_AI_talent_acquisition_platform: "An integrated AI talent acquisition platform, helping screen applicant volumes and connect relevant talent.",
    Support: "Support",
    Terms_and_Conditions: "Terms & Conditions",
    Privacy_Policy: "Privacy Policy",
    Contact: "Contact",
    OTP_sent_to_email: "An OTP has been sent to your Email",
    Login_Register: "Login/Register",
    Looking_for_job: "Looking for a Job?",
    Login_signup_code: "Login With a Code",
    Email_phone_number: "E-mail or phone number",
    Field_required: "This field is required",
    Not_robot: "I'm not a robot",
    Remember_me: "Remember Me",
    Password: "Password",
    Register: "Register",
    Login: "Login",
    No_account: "Don't have an account?",
    Forgot_password: "Forgot Password?",
    Company_name: "Company Name",
    Already_have_account: "Already have an account?",
    Reset_password: "RESET PASSWORD",
    Enter_OTP: "Enter OTP",
    Confirm_password: "Confirm Password",
    Change_password: "Change Password",
    Register_for_free: "Register now, for FREE",
    Download_mobile_app: "Download The Zaajira Mobile App!",
    Apply_now_mobile_app: "Apply for your dream job NOW, using our mobile application!",
    Jobs: "JOBS",
    Jobs1: "Jobs",
    Create: "CREATE",
    View: "VIEW",
    Messages: "MESSAGES",
    Profile: "Profile",
    Edit: "EDIT",
    New_user: "NEW USER",
    View_users: "VIEW USERS",
    Settings: "Settings",
    Logout: "Logout",
    Search_candidates: "Search candidates",
    Select_country: "Select Country",
    Loading: "Loading",
    Select_city: "Select City",
    Select_any: "Select Any",
    Press_enter_to_select: "Press enter to select",
    Press_enter_to_remove: "Press enter to remove",
    Date: "DATE",
    Name: "NAME",
    Back: "BACK",
    Full_name: "Full Name",
    Job_title: "Job Title",
    Age: "Age",
    Years_of_experience: "Years of Experience",
    Country_of_residence: "Country of residence",
    Nationality: "Nationality",
    Current_salary_monthly: "Current Salary (Monthly)",
    Education: "Education",
    Skills: "Skills",
    Industry: "Industry",
    Shortlist: "Shortlist",
    Job_detail: "Job detail",
    Search: "Search",
    Search_result: "Search result",
    Jobs_found: "Jobs found",
    Filters: "Filters",
    Clear_all: "Clear all",
    Reset: "Reset",
    Apply: "Apply",
    Apply_Filter: "Apply Filter",
    View_candidate_details: "VIEW CANDIDATE DETAILS",
    Sort_by: "Sort By",
    Selected: "Selected",
    Salary_range: "Salary Range",
    Gender: "Gender",
    Date_modified: "Date Modified",
    Experience: "Experience",
    Years: "Years",
    Location: "Location",
    day_ago: "day ago",
    Male: "Male",
    Female: "Female",
    Any: "Any",
    Share: "SHARE",
    First: "FIRST",
    Prev: "PREV",
    Next: "NEXT",
    Last: "LAST",
    Filter_Section: "#Filter Section",
    Industry_name: "Industry Name",
    Basic_details: "Basic Details",
    Candidate_details: "Candidate Details",
    Interview_Questions: "Interview Questions",
    Review_Suggestions: "Review/Suggestions",
    Job_Category: "Job Category",
    Select_Currency: "Select Currency",
    Min_Salary_Month: "Min. Salary/Month",
    Max_Salary_Month: "Max. Salary/Month",
    Type_of_Job: "Type of Job",
    Select_Job_Type: "Select Job Type",
    Job_Deadline: "Job Deadline",
    SAVE_DRAFT: "SAVE DRAFT",
    SELECT_CITY: "SELECT-CITY",
    City_Name: "City Name",
    TYPE_OF_JOB: "TYPE OF JOB",
    FULL_TIME: "Full Time",
    PART_TIME: "Part Time",
    Remote: "Remote",
    Internship: "Internship",
    Contract: "Contract",
    Work_from_home: "Work From Home",
    Some_fields_missing: "Some fields Are Missing",
    Connect_with_best_candidates: "Connect with the best\ncandidates directly,\nwith ease!",
    SUPPORT_TICKET_REQUEST: "SUPPORT TICKET REQUEST",
    Please_let_us_know_how_we_can_assist: "Please let us know how we can assist you and we will get back to you shortly",
    Email: "E-mail",
    Im_an: "I'm an...",
    Message: "Message",
    Submit: "Submit",
    Invalid_Password_3_attempts: "Invalid Password. 3 attempts remaining",
    No_new_messages_found: "No new messages found",
    DISABLE_CHAT: "Disable Chat",
    VIEW_PROFILE: "View Profile",
    Type_your_message: "Type your message",
    ENABLE_CHAT: "Enable Chat",
    d_ago_6: "6d ago",
    Create_Users: "Create Users",
    ADD_USER: "ADD USER+",
    ADD: "ADD+",
    REMOVE: "REMOVE",
    Enter_Name: "Enter Name",
    SUBMIT_EMPLOYEES: "SUBMIT EMPLOYEES",
    Action: "Action",
    Enter_company_details: "Enter company details",
    COMPANY_LOGO: "COMPANY LOGO",
    COMPANY_GALLERY: "COMPANY GALLERY",
    UPLOAD_LOGO: "UPLOAD LOGO",
    UPLOAD_PHOTOS_VIDEO: "UPLOAD PHOTOS/VIDEO",
    ADMIN_NAME: "ADMIN NAME",
    ADMIN_EMAIL: "ADMIN EMAIL",
    Enter_Email: "Enter Email",
    SIZE: "COMPANY SIZE",
    CONTACT_LOCATION: "CONTACT & LOCATION",
    ADD_SOCIAL_LINKS: "ADD SOCIAL LINKS",
    ADD_SOCIAL_PROFILE_URL: "ADD SOCIAL PROFILE URL",
    ENTER_LINKEDIN_PROFILE_URL: "ENTER LINKEDIN PROFILE URL",
    ENTER_FACEBOOK_PROFILE_URL: "ENTER FACEBOOK PROFILE URL",
    ENTER_TWITTER_HANDLE: "ENTER TWITTER HANDLE",
    ENTER_INSTAGRAM_PROFILE_URL: "ENTER INSTAGRAM PROFILE URL",
    CONTINUE: "CONTINUE",
    List_is_empty: "List is empty",
    Add_your_company_address: "Add your company address",
    Contact_Number: "Contact Number",
    Website_URL: "Website URL",
    Select_Industry: "Select Industry",
    Add_your_company_profile: "Add your company profile in 2 paragraphs or less",
    ADD_MORE_USERS: "ADD MORE USERS",
    SKIP: "SKIP",
    CONFIRM_SAVE: "CONFIRM & SAVE",
    Employer_Legal_Name: "Employer Legal Name",
    Social_Links: "Social Links",
    Gallery: "Gallery",
    Delete: "Delete",
    Connect_with_top_employers: "Connect with top employers in minutes",
    Increase_chances_with_custom_cards: "Increase your chances with custom cards",
    Browse_specific_jobs: "Browse specific jobs with detailed requirements",
    Zaajira_Success_Stories: "Zaajira Success Stories",
    Share_this_job_on_social_network: "Share this job on social network",
    Your_search_produced_no_results: "Your search produced no results",
    Try_adjusting_to_display_better_results: "Try adjusting to display better results",
    Please_Wait_Job_Is_Loading: "Please Wait Job Is Loading...",
    Job_Description: "Job Description",
    Write_Job_Description: "Write Job Description",
    No_Of_Vacancies: "No. Of Vacancies",
    Vacancies: "Vacancies",
    Currency: "Currency",
    AI_Suggestions: 'AI SUGGESTIONS',
    Choose_Description: 'Choose Description',
    Enter_New_Description: 'Enter New Description',
    Salary_must_be_greater: 'Salary must be greater than the minimum salary.',
    Experience_in_Years: 'Experience in Years',
    Select_Skills: 'Select Skills',
    Select_Nationality: 'Select Nationality',
    Select_Gender: 'Select Gender',
    Responsibilities: 'Responsibilities',
    Add_Responsibilities: "Add Responsibilities...",
    Add_Questions: "Add Questions",
    Virtual_Interview: "Virtual Interview",
    Suggested_Virtual_Interview_Questions: "Suggested Virtual Interview Questions",
    Multiple_Choice: "Multiple Choice",
    Sample_MCQ_Question_goes_here: "Q1. Sample MCQ Question goes here?",
    Sample_Text_Based_Question_goes_here: "Q1. Sample Text Based Question goes here?",
    Enter_Option: 'Enter Option',
    Mark_As_Correct: "Mark As Correct",
    ADD_OPTIONS: "ADD OPTIONS",
    Score: "Score",
    ADD_QUESTIONS: "ADD QUESTIONS",
    upto: "Upto",
    Confirm: "Confirm",
    Job_successfully_Posted: "Job successfully Posted",
    Candidates_on_our_site: "Candidates on our site that match closely to your job",
    Recommended_Candidates: "Recommended Candidates",
    Basic_Score: "Basic Score",
    Virtual_Interview: "Virtual Interview",
    Total_Score: "Total Score",
    Actions: "Actions",
    Oops_Candidates_Not_Found: "Oops, Candidates Not Found!",
    Search_Jobs: 'Search Jobs',
    By_Title: "By Title",
    Enter_Title: "Enter Title",
    By_Date: "By Date",
    Start_Date: "Start Date",
    End_Date: "End Date",
    By_No_of_Candidates: "By No. of Candidates",
    More_Than: "More Than",
    By_Status: "By Status",
    Select_Status: "Select Status",
    Open: "Open",
    Closed: "Closed",
    My_Lists: "My Lists",
    Title: "Title",
    Deadlines: "Deadlines",
    Shortlisted: "Shortlisted",
    Update: "Update",
    Status: "Status",
    Draft_Jobs: "DRAFT JOBS",
    Created_Jobs: "CREATED JOBS",
    Create_Job: "CREATE JOB",
    Edit_Job: "EDIT JOB",
    Edit_Interview_Questions: "Edit Interview Questions",
    No_Candidates_Found_in_Applied_section: "No Candidates Found in Applied section",
    All_Candidates: "All Candidates",
    Shortlisted_Candidates: "Shortlisted Candidates",
    No_Candidates_Found_in_Shortlisted_Candidates: "No Candidates Found in Shortlisted section",
    Rejected_Candidates: "Rejected Candidates",
    No_Candidates_Found_in_Rejected_section: "No Candidates Found in Rejected section",
    You_havent_created_any_job_yet: "You haven’t created any job yet.",
    Your_search_produced_no_results: "Your search produced no results.",
    Activate_Your_plan: "Activate Your plan",
    No_Draft_job_found: "No Draft job found",
    Uploads: 'Uploads',
    View_Photo: "View Photo",
    Official_Email: "Official Email",
    Uploading: "Uploading...",
    Username_is_required: "Username is required",
    Email_is_required: "Email is required",
    Password_is_required: "Password is required",
    Enter_Email_ld: "Enter Email ld",
    Enter_Password: "Enter Password",
    Users: "Users",
    User_Name: "User Name",
    User_Email: "User Email",
    New_Password: "New Password",
    Fill_the_correct_fields: "Fill the correct fields!",
    Select_To_Shortlist: "Select To Shortlist",
    Mobile_No: "Mobile No.",
    Salary_Monthly: "Salary (Monthly)",
    Job_Type: "Job Type",
    Available: "Available",
    Not_available: "Not available",
    Experience_Required: "Experience Required",
    Description: "Description",
    Other_jobs_with_high_match_rate: "Other jobs with high match rate",
    Salary_Annually: "Salary (Annually)",
    Your_match_rate_of: "Your match rate of",
    is_lower_than: "is lower than",
    other_applications_recieved: "other applications received",
    You_can_however_still_make_a_difference_by_adding_a: "You can however, still make a difference by adding a",
    video_profile_with_a_relevant_and_impactful_message_to: "video profile with a relevant and impactful message to",
    the_employer: "the employer",
    I_understand: "I understand,",
    continue_to_apply_now: 'continue to apply now',
    ADD_VIDEO_PROFILE: "ADD VIDEO PROFILE",
    Applicants: "Applicants",
    By_Location: "By Location",
    Applied_Candidates: "Applied Candidates",
    Interview_Score: "Interview Score",
    Forward: "Forward",
    Remove_Shortlist: "Remove Shortlist",
    Recover: "Recover",
    Mandatory: "Mandatory",
    No_Suggested_Questions_Found: "No Suggested Questions Found",
    Related_jobs: "Related jobs",
    Posted: "Posted",
    applicant: "applicant",
    Opening: "Opening",
    Address: "Address",
    Apply: "Apply",
    Applied: "Applied",
    Save_Job: "Save Job",
    Saved: "Saved",
    Share_Job: "Share Job",
    Other_information: "Other information",
    Openings: "Openings",
    Minimum_Education: "Minimum Education",
    Salary: "Salary",
    Welcome_to_Zaajira: "Welcome to Zaajira",
    New_User: "New User?",
    APPLIED_JOBS: "APPLIED JOBS",
    Edit_Profile: "Edit Profile",
    Share_Profile: "Share Profile",
    Share_this_profile: "Share this profile",
    Personal_detail: "Personal detail",
    Personal_details: "Personal Details",
    Upload_Photo: "Upload Photo",
    Industries: "Industries",
    Professional_detail: "Professional detail",
    Change_language: "Change language",
    Delete_account: "Delete account",
    only_PNG_JPG_Allowed_up_To_10MB: "only PNG & JPG Allowed up-To 10MB",
    Are_you_sure_you_want_to_delete_your_account: "Are you sure you want to delete your account?",
    Yes: "Yes",
    No: "No",
    Yes_delete: "Yes, delete",
    Logging_out: "Logging out...",
    Are_you_sure_you_want_to_log_out: "Are you sure you want to log out?",
    Save_change: "Save change",
    Professional_details: "Professional details",
    Select_education: "Select education",
    Upload_certificate: "Upload certificate",
    Work_experience: "Work experience",
    View_Certificate: "View Certificate",
    Change_your_password: "Change your password",
    Old_Password: "Old Password",
    Input_your_old_password: "Input your old password",
    Input_your_New_Password: "Input your New Password",
    Retype_New_Password: "Retype New Password",
    Change_your_language: "Change your language",
    Select_Language: "Select Language",
    Mobile_number: "Mobile number",
    Input_your_mobile_number: "Input your mobile number",
    Enter_something: "Enter something...",
    There_are_no_saved_jobs: "There are no saved jobs",
    CreateVisitingCard_paragraph: " Get started by making your visiting card, so you can introduce yourself to recruiters and apply to the best job available",
    Create_visiting_card: "Create visiting card",
    Creating_visiting_card: "Creating visiting card",
    Upload_profile_image: "Upload profile image",
    Select_up_to_3_industry: "Select up-to 3 industry",
    Select_up_to_10_skills: "Select up-to 10 skills",
    All: "All",
    Unread: "Unread",
    Search_name_chat: "Search name/chat",
    Send_your_message: "Send your message…",





















}
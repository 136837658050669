<template>
  <div class="container-fluid profile-user emp-settings customized-form px-1 px-md-3 pt-4 pb-5 mt-5">
    <div class="col-md-12 box mt-3 pb-3" v-if="$cookies.get('admin') !== 'N'">
      <div class="profile-flex border-bottom py-3">
        <div style="display: flex;flex-direction: row;">
          <!-- <span><img src="@/assets/app-icons/create-user-icon.svg" class="img-fluid" alt="icon" width="25px"
              height="33px" /></span> -->
          <h4 style="margin-left: 10px;" class="heading">{{ $gettext("Create_Users") }}</h4>
        </div>
        <button class="btn btn-primary btn-submit px-5" @click="addMoreEmployee">{{ $gettext("ADD_USER") }}</button>
      </div>
      <ValidationObserver ref="addEmployee" tag="div">
        <div class="row" v-for="(e, counter) in employee" :key="counter">
          <div class="col-md-12 user-add-col">
            <div>
              <!-- <ValidationProvider
                  name="uname"
                  rules=""
                  v-slot="{ errors, classes }"
                  tag="div"
                > -->
              <label>{{ $gettext("Name") }} </label>
              <b-form-input :placeholder="$gettext('Enter_Name')" :disabled="e.addedEmployee"
                v-model="e.user_username"></b-form-input>
              <div class="error-msg" v-if="submit && (!e.user_username || String(e.user_username).length == 0)">
                <small>*{{ $gettext("Username_is_required") }}</small>
              </div>

              <!-- <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div> -->
              <!-- </ValidationProvider> -->
            </div>
            <div>
              <ValidationProvider name="uemail" rules="email" v-slot="{ errors, classes }" tag="div">
                <label>{{ $gettext("Email") }}</label>
                <b-form-input :disabled="e.addedEmployee" :placeholder="$gettext('Enter_Email_ld')" v-model="e.user_email"
                  :class="classes"></b-form-input>
                <div class="error-msg" :class="classes">
                  <!-- <span>{{ errors[0] }}</span> -->
                  <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                  <small v-if="submit && (!e.user_email || String(e.user_email).length == 0)">*{{
                    $gettext("Email_is_required") }}</small>
                </div>
              </ValidationProvider>
            </div>
            <div>
              <ValidationProvider name="pass" rules="max:15|min:8" v-slot="{ errors, classes }" tag="div">
                <label>{{ $gettext("Password") }}</label>
                <b-form-input :placeholder="$gettext('Enter_Password')" :disabled="e.addedEmployee" type="password"
                  v-model="e.user_password" :class="classes"></b-form-input>
                <div class="error-msg" :class="classes">
                  <!-- <span>{{ errors[0] }}</span> -->
                  <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                  <small v-if="submit && (!e.user_password || String(e.user_password).length == 0)">*{{
                    $gettext("Password_is_required") }}</small>
                </div>
              </ValidationProvider>
            </div>
            <div class="btn-action-div" style="display: flex;align-content: center;justify-content: space-around;">
              <button v-show="!e.addedEmployee" class="btn btn-user-add my-ml-auto"
                @click="addEmployeeToStore(counter)">{{
                  $gettext("ADD") }}</button>
              <button class="btn btn-user-remove my-ml-auto" @click="removeEmployee(counter)">
                {{ $gettext("REMOVE") }}
                <img src="@/assets/app-icons/delete.svg" width="12px" class="mt-n1" />
              </button>
            </div>
            <b-modal id="myModel" hide-header hide-footer>
              <div class="text-center">
                <b-spinner label="Loading..." style="width: 8rem; height: 8rem"> </b-spinner>
                <h2>{{ $gettext("Uploading") }}</h2>
              </div>
            </b-modal>
          </div>
        </div>
        <div class="text-center">
          <button class="btn btn-primary btn-submit mt-3" @click="submitEmployeeDetails">{{ $gettext("SUBMIT_EMPLOYEES")
          }}</button>
        </div>
      </ValidationObserver>
      <!-- subscription modal start-->
      <div>
        <!-- <b-button id="show-btn" @click="$bvModal.show('bv-modal-example')">Open Modal</b-button> -->

        <b-modal id="bv-modal-example" hide-footer>
          <template #modal-title>
            <div style="color: red;">
              You do not have a paid subscription
            </div>
          </template>
          <div class="d-block text-center">
            <h3>
              Please click here to upgrade your subscription now!
            </h3>
          </div>
          <b-button class="btn btn-primary btn-submit mt-3" block
            @click="$router.push({ path: '/employers/' })">UPGRADE</b-button>
          <!-- <router-link class="btn btn-primary btn-submit mt-3"
            :to="{ path: '/employers', hash: '#pricing' }">UPGRADE</router-link> -->
        </b-modal>
      </div>
      <!-- subscription modal end-->
    </div>
  </div>
</template>

<script>
import UserService from "../apiServices/UserService";
export default {
  data() {
    return {
      employee: [
        {
          user_username: null,
          user_email: null,
          user_password: null,
          company_id: null,
          role_id: null,
          addedEmployee: false,
          admin: "N",
        },
      ],

      submit: null,
    };
  },

  methods: {
    addMoreEmployee() {
      console.log("this.selectedSub", this.selectedSub);
      if (this.selectedSub) {
        if (this.createdEmployee.length < +this.selectedSub.subs_pkg_no_of_users && this.remainingEmployee > 0) {
          this.submit = false;
          this.employee.push({
            user_username: null,
            user_email: null,
            user_password: null,
            company_id: null,
            role_id: null,
            addedEmployee: false,
            admin: "N",
          });

          this.remainingEmployee = this.remainingEmployee - 1;
          this.$toasted.show(`You can create ${this.remainingEmployee} more employee`, {
            duration: 6000,
          });
        } else {
          this.$toasted.show("You have reached max limit of employee creation", {
            duration: 6000,
            icon: "clear",
          });
        }
      } else {


        this.$bvModal.show('bv-modal-example')
        // this.$toasted.show("You dont have any plan", {
        //   duration: 6000,
        //   icon: "clear",
        // });
      }
    },

    removeEmployee(index) {
      this.employee.splice(index, 1);
      this.payload.splice(index, 1);
      this.remainingEmployee = this.remainingEmployee + 1;
      this.$store.dispatch("onResetEmployeeDetails", { index });
      let emp = this.$store.getters.getCreateEmployee;
    },

    addEmployeeToStore(counter) {
      if (this.selectedSub) {
        if (this.createdEmployee.length < +this.selectedSub.subs_pkg_no_of_users) {
          this.$refs.addEmployee.validate().then((result) => {
            if (result) {
              this.employee[counter].role_id = this.$cookies.get("role_id");
              this.employee[counter].addedEmployee = true;
              this.employee[counter].company_id = this.$cookies.get("company_id");

              let emp = { ...this.employee[counter] };
              delete emp.addedEmployee;
              const duplicateEmail = this.payload.filter((f) => {
                if (f.user_email == emp.user_email) {
                  this.employee[counter].addedEmployee = false;
                  return true;
                }
              });
              if (duplicateEmail.length > 0) {
                duplicateEmail.forEach((f) => {
                  this.$toasted.show(`${f.user_email} Email has duplicate Email`, { duration: 6000, icon: "clear" });
                });
                return;
              }

              this.payload.push(emp);

              this.payload = [...new Set(this.payload)];

              this.$toasted.show("Employee added!", {
                position: "top-right",
                duration: 6000,
                icon: "check",
              });
            } else {
              return;
            }
          });
        } else {
          this.$toasted.show("You have reached max limit of employee creation.please Upgrade your plan", { duration: 6000, icon: "clear" });
          return;
        }
      } else {
        this.$toasted.show("you dont have any plan", {
          duration: 6000,
          icon: "clear",
        });
      }
    },

    validateEmployee() {
      let valid = true;
      return new Promise((resolve, reject) => {
        this.employee.map((m) => {
          let user_email = m.user_email ? true : false;
          let user_password = m.user_password ? true : false;
          let user_username = m.user_username ? true : false;
          if (!user_email && !user_password && !user_username) {
            valid = false;
          }
        });
        resolve(valid);
      });
    },

    async submitEmployeeDetails(e) {
      this.submit = true;
      let validEmployee = await this.validateEmployee();
      if (this.employee.length > 0 && validEmployee) {
        console.log("this.payload", this.employee);
        UserService.createEmployee(this.employee)
          .then((res) => {
            if (res.data.data.user.length > 0) {
              this.payload = [];
              this.employee = res.data.data.user.map((m) => {
                return {
                  user_username: m.user_username,
                  user_email: m.user_email,
                  user_password: null,
                  company_id: m.company_id,
                  role_id: 2,
                  addedEmployee: false,
                  admin: "N",
                };
              });
              const email = res.data.data.user;
              email.forEach((e) => {
                this.$toasted.show(`${e.user_email} Email already exists`, {
                  position: "top-right",
                  duration: 5000,
                  icon: "clear",
                });
              });
            } else {
              this.payload = [];
              (this.employee = []),
                this.$toasted.show("Employee added Successfully", {
                  position: "top-right",
                  duration: 6000,
                  icon: "check",
                });
            }
          })
          .catch((error) => {
            this.$toasted.show("This email already registered with other company \n OR \n Internal Server Error!", {
              position: "top-right",
              duration: 6000,
              icon: "clear",
            });
          });
      } else {
        this.$toasted.show("Employee Not Added", {
          position: "top-right",
          duration: 6000,
          icon: "clear",
        });
        return;
      }
    },
  },
};
</script>

<style scoped>
.plan-btn {
  width: 142px;
  font-family: pn-regular;
  letter-spacing: 0.9px;
  /* height: 54px; */
}

.company-details .btn-crud {
  background: #bfbfbf;
  font-family: mp-medium;
  font-size: 13px;
  border-radius: 20px;
  padding: 08px 22px;
  color: white;
  border: 1px solid #6244e5;
  margin-bottom: 0;
}

/* .custom-list-group {
  height: 60%;
} */

.pricing-tabs .tab-content>.tab-pane:not(.active) {
  display: block !important;
  height: 0;
  overflow-y: hidden;
}

.multis>>>.multiselect__tags {
  /* color: orange; */
  background-color: #eaeaea;
}

.multis>>>.multiselect__tag {
  background: #6244e5;
}

.multis>>>.multiselect__tag-icon:after {
  color: white;
}

.multis>>>.multiselect__tag-icon:hover {
  background: #6244e5;
}

.multis>>>.multiselect__option--highlight {
  background: #6244e5;
}

.multis>>>.multiselect__option:after {
  background-color: #6244e5;
  color: white;
}

.multis>>>.multiselect__input {
  background-color: #eaeaea;
}

.emp-settings-page .user-add-col {
  grid-template-columns: 23% 22% 22% 27%;
}

.emp-settings-page .error-msg.is-invalid span {
  padding: 0;
}

.my-ml-auto {
  margin-left: auto !important;
}

@media only screen and (max-width:768px) {
  .my-ml-auto {
    margin-left: 0 !important;
  }
}
</style>

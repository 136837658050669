<template>
  <div class="bg-grey pt-1 mt-1">
    <b-container fluid class="py-3 mt-5 job-listings">


      <b-row class="bg-primary for-desktop" style="position: relative;top: -18px;
">
        <b-col md="12" class="py-5 bg-primary mx-auto">
          <div class="d-flex flex-wrap justify-content-around pt-5 my-5">
            <!-- basicDetails :: start -->
            <div class="myTab mt-3 d-flex justify-content-left">
              <div class="myTabText p-4 w-25 px-auto text-center font-weight-bold">1</div>
              <span :class="['font-weight-bold m-auto', basicDetail ? 'text-white' : 'text-muted']">{{
                $gettext("Basic_details") }}</span>
            </div>
            <!-- basicDetails :: end -->

            <!-- candidateDetails :: start -->
            <div class="myTab mt-3 d-flex justify-content-left">
              <div class="myTabText p-4 w-25 px-auto text-center font-weight-bold">2</div>
              <span :class="['font-weight-bold m-auto', candidateDetails ? 'text-white' : 'text-muted']">{{
                $gettext("Candidate_details") }}</span>
            </div>
            <!-- candidateDetails :: end -->

            <!-- interviewQuestions :: start -->
            <div class="myTab mt-3 d-flex justify-content-left">
              <div class="myTabText p-4 w-25 px-auto text-center font-weight-bold">3</div>
              <span class="text-muted font-weight-bold m-auto">{{ $gettext("Interview_Questions") }}</span>
            </div>
            <!-- interviewQuestions :: end -->

            <!-- reviewSuggestions :: start -->
            <div class="myTab mt-3 d-flex justify-content-left">
              <div class="myTabText p-4 w-25 px-auto text-center font-weight-bold">4</div>
              <span class="text-muted font-weight-bold m-auto">{{ $gettext("Review_Suggestions") }}</span>
            </div>
            <!-- reviewSuggestions :: end -->
          </div>
        </b-col>
      </b-row>

      <b-row class="pt-4 bg-primary for-mob" style="position: relative;top: -21px;">
        <b-col md="12" class="py-5 bg-primary mx-auto">

          <div class="">
            <div class="BasicDetails" style="margin-bottom: 20px;
            text-align: center; 
            color: white; 
            font-size: 18px; 
            font-weight: 700; 
            word-wrap: break-word">
              {{ basicDetail ? `1.Basic Details` : `2.Candidate Details` }}
            </div>
            <div class="d-flex flex-wrap justify-content-around">
              <div className="Rectangle24" :style="basicDetail || candidateDetails ? 'opacity: 1.0' : 'opacity: 0.5'"
                style="width: 84px;height: 6px;background: white;border-radius: 7px;"></div>
              <div className="Rectangle24" :style="candidateDetails ? 'opacity: 1.0' : 'opacity: 0.5'"
                style="width: 84px;height: 6px;background: white;border-radius: 7px;opacity: 0.50"></div>
              <div className="Rectangle24"
                style="width: 84px;height: 6px;background: white;border-radius: 7px;opacity: 0.50"></div>
              <div className="Rectangle24"
                style="width: 84px;height: 6px;background: white;border-radius: 7px;opacity: 0.50"></div>
            </div>
          </div>
        </b-col>
      </b-row>
      <ValidationObserver ref="job_creation_1" tag="div"
        class="container-fluid profile-user job-create customized-form px-1 px-md-3 pt-4 pb-3" v-if="basicDetail">
        <!-- <div class="col-md-12 box py-3 my-3">
        <div class="profile-flex border-bottom py-3">
          <h4 class="heading">Define Workflow</h4>
        </div>
        <div class="pt-3 workflow-div">
          <b-form-group>
            <ValidationProvider name="wfc" rules="required" v-slot="{ errors, classes }">
              <b-form-checkbox-group v-model="wfc" value-field="wfc_id" text-field="wfc_name" :options="workflow" @input="removeWfc" :class="classes"> </b-form-checkbox-group>
              <div class="error-msg" :class="classes">
                 <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
        </div>
      </div> -->
        <div class="col-md-12 py-3 my-3 box">
          <div class="pt-3">
            <!-- firstRow :: start -->
            <div class="row">
              <!-- jobTitle :: start  -->
              <!-- <div class="col-md-4 mt-3">
              <div>
                <h6 class="fpn-b">{{ $gettext("Job_title") }}</h6>
                <vue-simple-suggest :placeholder="$gettext('Job_title')" class="suggest suggest-input form-control" v-model="job_title" :list="job_titles_list" :min-length="1" :filter-by-query="true" value-attribute="job_title_id" display-attribute="job_title" @select="setDescription" :class="submitted && (!job_title || String(job_title).length == 0) ? 'suggest-input-error' : ''"> </vue-simple-suggest>
              </div>
              <div v-if="this.submitted" class="validate-error">
                <span>{{ "This field is required" }}</span>
              </div>
            </div> -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Job_title") }}*</h6>
                  {{ setJobTitle }}
                  <ValidationProvider name="job_titles_list" rules="required" v-slot="{ errors, classes }">
                    <vue-simple-suggest :placeholder="$gettext('Job_title')" class="suggest suggest-input form-control"
                      v-model="job_title" :list="job_titles_list" :filter-by-query="true" :min-length="1"
                      :class="classes">
                    </vue-simple-suggest>
                    <!-- <vue-simple-suggest placeholder="Job Title" class="suggest suggest-input form-control" v-model="job_title" :list="job_titles_list" :min-length="1" :filter-by-query="true" value-attribute="job_title_id" display-attribute="job_title" @select="setDescription" :class="classes"> </vue-simple-suggest> -->
                    <!-- <SimpleSuggest :suggestData="tsetData" /> -->

                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <!-- jobTitle :: end -->

              <!-- jobCategory :: start -->
              <!-- <div class="col-md-4 mt-3">
              <div class="multiselect-dropdown">
                <h6 class="fpn-b">Job Category</h6>
                <ValidationProvider name="speciality" rules="required" class="grid-item"   v-slot="{ errors, classes }">
                <multiselect class="multis" v-model="speciality" placeholder="Job Category" label="checkbox"
                  track-by="checkbox_id" :options="specialityOpt" :multiple="false" :class="classes" />
                  <div class="error-msg" :class="classes">
                     <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div> -->
              <div class="col-md-4 mt-3">
                <div class="multiselect-dropdown">
                  <h6 class="fpn-b">{{ $gettext("Job_Category") }}*</h6>
                  <multiselect class="multis" :class="{ errorCategory: err }" @select="onSelect"
                    @remove="remspecialityHandle" v-model="speciality" :placeholder="$gettext('Job_Category')"
                    label="checkbox" track-by="checkbox_id" :options="specialityOpt" :multiple="false" required />
                  <div v-if="this.submitted && this.err" class="validate-error">
                    <br />
                    <span>{{ $gettext("Field_required") }} </span>
                  </div>
                </div>
              </div>

              <!-- jobCategory :: end -->

              <!-- jobDescription :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <div class="ai-btn">
                    <h6 class="fpn-b">
                      {{ $gettext("Job_Description") }}*
                    </h6>
                    <b-button style="position: absolute;right: 0;top: -2px;" class="btn-sm btn-fs-8" variant="primary"
                      size="s" @click="setDescription">{{
                        $gettext("AI_Suggestions")
                      }}</b-button>
                  </div>

                  <ValidationProvider name="description" rules="required" v-slot="{ errors, classes }">
                    <b-form-textarea :placeholder="$gettext('Write_Job_Description')" v-model="job_description"
                      :class="classes" rows="3" class="h-50"></b-form-textarea>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- jobDescription :: end -->
              <div>
                <b-modal id="desModal" v-model="modalShow" :title="$gettext('Choose_Description')" @show="setModal"
                  @hidden="resetModal" @ok="handleOk">
                  <b-form-group label="" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group id="checkbox-group-2" v-model="selectedDescription"
                      :aria-describedby="ariaDescribedby" name="flavour-2">
                      <div v-for="(desc, index) in descriptionOptions" :key="index">
                        <b-form-checkbox :value="desc">{{ desc }}</b-form-checkbox>
                      </div>
                    </b-form-checkbox-group>
                  </b-form-group>
                  <b-form-input @keyup.enter="handleDescription" v-model="newDescriptionValue"
                    :placeholder="$gettext('Enter_New_Description')"></b-form-input>

                  <!-- <img src="@/assets/app-icons/add.png" @click="addDescription" width="30px" class="ml-2 mb-0" /> -->
                </b-modal>
              </div>
            </div>
            <!-- firstRow :: end -->

            <!-- secondRow :: start -->
            <div class="row">
              <!-- location :: start -->
              <div class="col-md-4 mt-3">
                <div class="multiselect-dropdown">
                  <h6 class="fpn-b">{{ $gettext("Location") }} *</h6>

                  <div class="grid-half">
                    <ValidationProvider name="country" rules="required" class="grid-item" tag="div"
                      v-slot="{ errors, classes }">

                      <multiselect style="" class="multis" :class="classes" @search-change="handleCountrySearch"
                        v-model="country" :placeholder="$gettext('Select_country')" @input="onChangeCountry"
                        @remove="removeCountry" label="label" track-by="country_id" :options="filteredCountries"
                        :multiple="true" :limit="1" :limit-text="limitText" :max="10" />
                      <div class="error-msg" :class="classes">
                        <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                    <div v-if="citiesFetching">{{ $gettext("Loading") }}...</div>
                    <ValidationProvider name="city" rules="required" class="grid-item" tag="div"
                      v-slot="{ errors, classes }" v-if="!citiesFetching">
                      <multiselect style="" class="multis" :class="classes" @search-change="handleCitySearch"
                        v-model="city" :placeholder="$gettext('Select_city')" label="label" :max="10" track-by="city_id"
                        :options="filteredCities" @input="onChangeCity" @remove="removeCity" :multiple="true" :limit="1"
                        :limit-text="limitTextCity" />

                      <div class="error-msg" :class="classes">
                        <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <!-- location :: end -->

              <!-- vacancies :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("No_Of_Vacancies") }}*</h6>
                  <ValidationProvider name="vacancy" rules="required" v-slot="{ errors, classes }">
                    <b-form-input type="number" :placeholder="$gettext('Vacancies')" :class="classes" v-model="vacancies">
                    </b-form-input>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- vacancies :: end -->

              <!-- jobType :: start -->
              <div class="col-md-4 mt-3">
                <div class="multiselect-dropdown">
                  <h6 class="fpn-b">{{ $gettext("Type_of_Job") }}</h6>
                  <ValidationProvider name="job_type" rules="required" tag="div" v-slot="{ errors, classes }">
                    <multiselect class="multis" :class="classes" v-model="selectJobType"
                      :placeholder="$gettext('Select_Job_Type')" label="job_type_title" track-by="job_type_id"
                      :options="select_job_types" :multiple="false" :limit="1" :limit-text="limitTextCity" />
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- jobType :: end -->
            </div>
            <!-- secondRow :: end -->

            <!-- thirdRow :: start -->
            <div class="row">
              <!-- minSalary :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Min_Salary_Month") }}</h6>
                  <!-- <ValidationProvider name="from" rules="required" v-slot="{ errors, classes }" tag="div"> -->
                  <!-- <multiselect class="multis" :class="classes" v-model="salary_from" placeholder="Min. Salary/Month" label="salary_title" track-by="salary_id" :options="minSalaryOptions" @input="addMinSalary" @remove="removeMinSalary" :max="10" :multiple="true" /> -->
                  <b-form-input type="number" :disabled="!basicDetails" :placeholder="$gettext('Min_Salary_Month')"
                    v-model="salary_from"> </b-form-input>
                  <!-- <div class="error-msg" :class="classes">
                    <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                  </div> -->
                  <!-- </ValidationProvider> -->
                </div>
              </div>
              <!-- minSalary :: end -->

              <!-- maxSalary :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Max_Salary_Month") }}</h6>
                  <!-- <ValidationProvider name="to" rules="required" v-slot="{ errors, classes }" tag="div"> -->
                  <!-- <multiselect class="multis" :class="classes" v-model="salary_to" placeholder="Max. Salary/Month" label="salary_title"  track-by="salary_id" :options="maxSalaryOptions" @input="addMaxSalary" @remove="removeMaxSalary" :max="10" :multiple="true" /> -->
                  <b-form-input @input="maxSalHandle" type="number" :disabled="!basicDetails"
                    :placeholder="$gettext('Max_Salary_Month')" v-model="salary_to"> </b-form-input>
                  <span style="color: red; font-size: 100%; text-align: center"
                    v-if="parseFloat(salary_from) > parseFloat(salary_to)">{{ $gettext("Salary_must_be_greater") }}</span>
                  <!-- <div class="error-msg" :class="classes">
                    <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                  </div> -->
                  <!-- </ValidationProvider> -->
                </div>
              </div>
              <!-- maxSalary :: end -->

              <!-- currency :: start -->
              <div class="col-md-4 mt-3">
                <div class="multiselect-dropdown">
                  <h6 class="fpn-b">{{ $gettext("Currency") }}</h6>
                  <ValidationProvider name="currency_name" :rules="currencyRequired" tag="div"
                    v-slot="{ errors, classes }">
                    <multiselect class="multis" v-model="currency" :placeholder="$gettext('Select_Currency')"
                      label="currency_name" track-by="currency_id" :options="currencies" :multiple="false" :limit="1"
                      :limit-text="limitTextCity" />
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- currency :: end -->

            </div>
            <!-- thirdRow :: end -->

            <!-- fourthRow :: start -->
            <div class="row">
              <!-- jobDeadline :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Job_Deadline") }}*</h6>
                  <ValidationProvider name="deadline" rules="required" v-slot="{ errors, classes }" tag="div">
                    <datePicker :disabled-dates="disabled_dates" :disabled="!basicDetails" :value="state.date"
                      v-model="job_end_date" :placeholder="$gettext('Job_Deadline')" class="custom-date width-full"
                      :class="classes"> </datePicker>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- jobDeadline :: end -->
            </div>
            <!-- fourthRow :: end -->

            <!-- fifthRow :: start -->
            <!-- <div class="row">
            <div class="col-md-4 mt-3">
              <div>
                <h6 class="fpn-b">Experience Required</h6>
                <ValidationProvider name="experince" rules="required" v-slot="{ errors, classes }">
                  <v-select type="number" :disabled="!basicDetails" placeholder="Experience in Years" class="form-control" :class="classes" v-model="experiance" :options="['0-2', '2-4', '4-6', '6-8', '8-10', '10+']"> </v-select>
                  <div class="error-msg" :class="classes">
                     <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4 mt-3">
              <h6 class="fpn-n">Speciality (Optional)</h6>
              <multiselect style="" class="multis" v-model="speciality" placeholder="Select Speciality" label="checkbox" track-by="checkbox_id" :options="specialityOpt" :multiple="false" />
            </div>
            <div class="col-md-4 mt-3">
              <b-form-checkbox id="wfh" v-model="wfh" name="wfh" :value="true" :unchecked-value="false"> Work from home </b-form-checkbox>
            </div>
            <div class="col-md-4 mt-3">
              <div>
                <h6 class="fpn-b">Nationality (Optional)</h6>

                <ValidationProvider name="nationality" rules="" tag="div" v-slot="{ errors, classes }">
                  <multiselect style="" class="multis" :class="classes" v-model="nationality" placeholder="Select Nationality" label="nationality" track-by="nationality_id" :options="nationalities" @input="addNationality" @remove="removeNationality" :multiple="true" :limit="1" :limit-text="limitTextCity" />

                  <div class="error-msg" :class="classes">
                     <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4 mt-3">
              <div class="multiselect-dropdown">
                <h6 class="fpn-b">Define Skills</h6>
                <ValidationProvider name="wfc" rules="required" v-slot="{ errors, classes }" tag="div">
                  <v-select :multiple="true" :disabled="!basicDetails" placeholder="Select Skills" class="form-control" :options="skillOptions" v-model="skills" :class="classes" />
                  <multiselect style="" class="multis" :class="classes" v-model="skills" placeholder="Select Skills" label="skill_name" track-by="skill_id" :options="skillOptions" :multiple="true" @input="addSkill" @remove="removeSkills" :max="10" />
                  <multiselect class="multis" v-model="skills" :class="classes" id="ajax" label="skill_name" track-by="skill_id" placeholder="Type to search" open-direction="bottom" :options="skillOptions" :multiple="true" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :max-height="600" :show-no-results="false" :hide-selected="true" @search-change="searchSkills" />
                  <div class="error-msg" :class="classes">
                     <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4 mt-3">
              <h6 class="fpn-b">Gender (Optional)</h6>
              <ValidationProvider name="gender" rules="" v-slot="{ errors, classes }">
                <multiselect style="" class="multis" :class="classes" v-model="gender" placeholder="Select Gender" :options="['Male', 'Female', 'Other', 'Any']" :multiple="false" />
                <div class="error-msg" :class="classes">
                   <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div> -->
            <!-- fifthRow :: end -->
          </div>
          <!-- submitBtns :: start -->
          <div class="py-5 d-flex justify-content-center">
            <b-button class="mx-4" variant="primary" size="lg" @click="checkJobCategory">{{ $gettext("SAVE_DRAFT")
            }}</b-button>
            <b-button class="mx-4" variant="primary" size="lg" @click.prevent.stop="checkJobCategory">{{ $gettext("Next")
            }}</b-button>
          </div>
          <!-- submitBtns :: end -->
        </div>
      </ValidationObserver>

      <ValidationObserver ref="job_creation_2" tag="div"
        class="container-fluid profile-user job-create customized-form px-1 px-md-3 pt-4 pb-3" v-if="candidateDetails">
        <div class="col-md-12 py-3 my-3 box">
          <div class="profile-flex border-bottom py-3">
            <h4 class="heading">{{ $gettext("Candidate_details") }}</h4>
            {{ setJobTitle }}
          </div>
          <div class="pt-3">
            <!-- firstRow :: start -->
            <div class="row">
              <!-- experience :: start  -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Experience") }} *</h6>

                  <ValidationProvider name="experince" rules="required" v-slot="{ errors, classes }">
                    <v-select type="number" :placeholder="$gettext('Experience_in_Years')" class="form-control"
                      :class="classes" v-model="experiance" :options="experianceOpts"> </v-select>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- experience :: end -->

              <!-- education :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Education") }}</h6>
                  <ValidationProvider name="education" v-slot="{ errors, classes }">
                    <!-- {{ education }} -->
                    <multiselect class="multis" :class="classes" v-model="education" :placeholder="$gettext('Education')"
                      label="ms_edu_degree" track-by="ms_edu_id" :options="eduOptions" :max="10" :multiple="true" />
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- education :: end -->

              <!-- skills :: start -->
              <div class="col-md-4 mt-3">
                <div class="multiselect-dropdown">
                  <h6 class="fpn-b">{{ $gettext("Skills") }} *</h6>
                  <ValidationProvider name="skills" rules="required" v-slot="{ errors, classes }" tag="div">

                    <multiselect class="multis" :class="classes" v-model="skills" :placeholder="$gettext('Select_Skills')"
                      label="skill_name" track-by="skill_id" :options="skillOptions" @input="addSkill"
                      @remove="removeSkills" :max="10" :multiple="true" />
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- skills :: end -->
            </div>
            <!-- firstRow :: end -->

            <!-- secondRow :: start -->
            <div class="row">
              <!-- nationality :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Nationality") }}</h6>
                  <ValidationProvider name="nationality" tag="div" v-slot="{ errors, classes }">
                    <multiselect class="multis" :class="classes" v-model="nationality"
                      :placeholder="$gettext('Select_Nationality')" label="nationality" track-by="nationality_id"
                      :options="nationalities" @input="addNationality" @remove="removeNationality" :multiple="true" />
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- nationality :: end -->

              <!-- gender :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Gender") }}</h6>
                  <ValidationProvider name="gender" v-slot="{ errors, classes }">
                    <v-select :placeholder="$gettext('Select_Gender')" class="form-control" :class="classes"
                      v-model="gender" :options="['Any', 'Female', 'Male']"> </v-select>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- gender :: end -->
            </div>
            <!-- secondRow :: end -->
          </div>
          <div class="py-5 d-flex candidate-btns">
            <b-button class="my-btn-mx" variant="primary" size="lg" @click="goBack">{{ $gettext("Back") }}</b-button>
            <b-button class="my-btn-mx" variant="primary" size="lg" @click="addDraftJob">{{ $gettext("SAVE_DRAFT")
            }}</b-button>
            <b-button class="my-btn-mx" variant="primary" size="lg" @click.prevent.stop="ValidateInput2">{{
              $gettext("Next")
            }}</b-button>
          </div>
        </div>
      </ValidationObserver>
    </b-container>
  </div>
</template>

<script>
import DatePicker from "vuejs-datepicker";
import LocationServices from "@/apiServices/LocationServices";
import RecruiterJobService from "@/apiServices/RecruiterJobService";
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
  components: { DatePicker, Multiselect },
  data() {
    return {
      gobackVal: false,
      jobCount: null,
      err: false,
      titleDescription: [],
      modalShow: false,
      selectedDescription: [], // Must be an array reference!
      descriptionOptions: [],
      newDescriptionValue: null,
      nxpId: null,
      experianceOpts: ["0-1", "2-3", "4-5", "5-6", "7-8", "10+"],
      experiance: "4-5",
      basicDetail: true,
      candidateDetails: false,
      finalSubmit: false,
      submitted: false,
      disabled_dates: {
        to: moment().subtract(1, "days").toDate(),
      },
      state: {
        date: new Date(),
      },
      basic: false,
      countries: [],
      filteredCountries: [],
      currencies: [],
      select_job_types: [],
      currency: null,
      currencyRequired: "",
      specialityOpt: [],
      categoryOpt: [
        {
          checkbox: "CategoryOne",
          checkbox_id: 1,
          title: "CategoryOne",
        },
        {
          checkbox: "CategoryTwo",
          checkbox_id: 2,
          title: "CategoryTwo",
        },
        {
          checkbox: "CategoryThree",
          checkbox_id: 3,
          title: "CategoryThree",
        },
      ],
      // minSalaryOptions: [
      //   {
      //     salary_id: 1,
      //     salary_title: "5000 - 10000",
      //   },
      //   {
      //     salary_id: 2,
      //     salary_title: "10001 - 15000",
      //   },
      //   {
      //     salary_id: 3,
      //     salary_title: "15001 - 20000",
      //   },
      // ],
      // maxSalaryOptions: [
      //   {
      //     salary_id: 1,
      //     salary_title: "5000 - 10000",
      //   },
      //   {
      //     salary_id: 2,
      //     salary_title: "10001 - 15000",
      //   },
      //   {
      //     salary_id: 3,
      //     salary_title: "15001 - 20000",
      //   },
      // ],
      speciality: null,
      jobCategory: null,
      nationality: null,
      countrySearchTerm: "",
      citySearchTerm: "",
      nationalities: [],
      delNationality: [],
      delCountry: [],
      delCity: [],
      vacancies: null,
      job_id: null,
      cities: [],
      filteredCities: [],
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: "",
        feature: "Y",
      },
      workflow: [
        { wfc_id: "1", wfc_name: "Basic Details", disabled: true },
        { wfc_id: "2", wfc_name: "Virtual Interview", disabled: true },
        { wfc_id: "3", wfc_name: "Virtual Video Interview" },
        { wfc_id: "4", wfc_name: "EI Assessment" },
      ],
      wfc: ["1", "2"],
      wfc2: [],
      delWfc: [],
      skills: [],
      limit: 10,
      skillOptions: [
        // {
        //   "skill_id": 1,
        //   "skill_name": ".NET (Software Development Framework)",
        // }
      ],
      job_title: null,
      job_titleDuplicate: null,
      country: [],
      city: null,
      job_description: "",
      job_title_id: null,
      salary_from: null,
      salary_to: null,
      experiance: null,
      salaryErr: false,
      minSal: null,
      maxSal: null,
      job_type: null,
      job_end_date: null,
      skills: [],
      removedSkills: [],
      job_titles_list: [],
      isLoading: false,
      wfh: false,
      gender: null,
      delGender: [],
      selectJobType: null,
      education: null,
      eduOptions: [],
      citiesFetching: false,
    };
  },

  beforeUpdate() {
    this.getAllJobTypes();
  },
  async beforeMount() {
    const createJob = this.$route.query.create_job;
    const pageVal = this.$route.query
    if (this.$route.query.candidatePage) {
      this.candidateDetails = true
      this.basicDetail = false
    }
    console.log(pageVal);
    console.log(createJob);

    const removeStoredJob = async () => {
      if (createJob && createJob !== undefined) {
        console.log("aman");
        this.$store.commit("ON_SET_NXPID", null);
        this.$store.dispatch("onSetJob_Details", null);
        this.$store.dispatch("onSetJob_Details_With_VI", null);
      }
    }
    await removeStoredJob()
    console.log("this.$store.getters.getNxpId", this.$store.getters.getNxpId);

    // this.getChatGPT();
    this.getJobCount();
    // this.getAllSkills();
    // this.getAllIndustrySkills();
    this.getAllJobTitles();
    this.getAllCurrency();
    this.getAllCities(this.pagination);
    this.getAllCountries();
    this.getEducationList();
    this.getAllNationality();
    this.getAllSpeciality();
    this.getAllJobTypes();
    // this.prefilledData();

    if (this.$route.query?.page && this.$route.query?.page === 2) {
      this.basicDetail = false;
      this.candidateDetails = true;
      this.nxpId = this.$store.getters.getNxpId;
    }

    if (this.$route.query.d_job_id) {
      console.log(" this.getDraftJob", this.$route.query.d_job_id);
      this.getDraftJob(this.$route.query.d_job_id);
    }
    else {
      this.prefilledData();
    }
  },
  mounted() {
    // this.addDraftJob();
    this.nxpId = this.$store.getters.getNxpId;
    console.log("this.nxpId", this.nxpId);
    console.log(this.job_titleDuplicate);
  },
  computed: {
    basicDetails() {
      if (this.wfc?.includes("1")) {
        // const jobDetails = this.$store.getters.getJobDetails;
        // const wfc = this.$store.getters.getWfc;
        // console.log("jobDetails :>> ", jobDetails);
        // console.log("wfc :>> ", wfc);
        // this.job_title = jobDetails.job_title;
        // this.city = jobDetails.city;
        // this.job_description = jobDetails.job_description;
        // this.skills = jobDetails.job_skills;
        // this.salary_from = jobDetails.job_salary_range_from;
        // this.salary_to = jobDetails.job_salary_range_to;
        // this.job_type = jobDetails.job_type;
        // this.job_end_date = jobDetails.job_end_date;
        return true;
      } else {
        // this.wfc = [];
        // this.skillOptions = [];
        // this.job_title = null;
        // this.city = {};
        // this.job_description = null;
        // this.salary_from = null;
        // this.salary_to = null;
        // this.job_type = null;
        // this.job_end_date = null;
        // this.skills = null;
        return false;
      }
    },
    setJobTitle() {
      console.log("this.job_title", this.job_title);
      this.job_title = this.job_title ? this.job_title : this.job_titleDuplicate
    },
  },

  methods: {
    //  validateMinSalary(value, args) {
    //    console.log(args);
    //    return Number(value) >= this.salary_to || Number(value) < 1;
    //  },
    //  validateMaxSalary() {
    //    console.log(args);
    //    return Number(value) < this.salary_from || Number(value) < 1;
    //  },

    handleCountrySearch(searchTerm) {
      //this.countrySearchTerm = searchTerm;
      if (!searchTerm) {
        this.filteredCountries = this.countries;
        return;
      }
      const regexp = new RegExp(`^${searchTerm}`, "i");
      const filtered = [...this.countries].filter((country) => {
        return regexp.test(country.label);
      });
      this.filteredCountries = filtered;
    },

    handleCitySearch(searchTerm) {
      this.citySearchTerm = searchTerm;
      if (!searchTerm) {
        this.filteredCities = this.cities;
        return;
      }
      const regexp = new RegExp(`^${searchTerm}`, "i");
      const filtered = [...this.cities].filter((city) => {
        return regexp.test(city.label);
      });
      this.filteredCities = filtered;
    },
    goBack() {
      this.gobackVal = true
      this.basicDetail = true;
      this.candidateDetails = false;
      if (this.speciality.checkbox.length) {
        // console.log("this.speciality.checkbox.length", this.speciality.checkbox.length);
        this.err = false;
      }
    },
    setDescription() {
      let e = this.job_title;
      console.log("e====>", e);
      // console.log("e====>",e);
      // this.job_title_id = e.job_title_id;
      // this.job_description = e.job_title_description;
      // this.descriptionOptions.push(e.job_title_description);
      this.descriptionOptions = [...this.titleDescription.filter((obj) => obj.job_title === e).map((obj) => obj.job_title_description)];
      console.log("the ====>", this.descriptionOptions);
      this.modalShow = !this.modalShow;
    },
    handleDescription(e) {
      console.log("NewDES", e);

      this.descriptionOptions.push(this.newDescriptionValue);
      this.newDescriptionValue = null;
    },
    setModal() {
      console.log("model-show");
    },
    resetModal() {
      this.descriptionOptions = [];
      this.selectedDescription = [];
      this.newDescriptionValue = null;
    },
    handleOk() {
      this.job_description = this.selectedDescription.join(",\n ");
      console.log("this.selectedDescription", this.selectedDescription);
      console.log("this.job_description", this.job_description);
    },

    getEducationList() {
      RecruiterJobService.GetEducationStreams().then((res) => {
        const { data: education } = res.data;
        console.log("education", education);

        const sortData = () => {
          return new Promise((resolve, reject) => {
            education.sort((a, b) => a.ms_edu_degree.localeCompare(b.ms_edu_degree));
            resolve(education);
          });
        };

        sortData()
          .then(sortedData => {
            // console.log("EdusortedData", sortedData);
            this.eduOptions = sortedData.map((edu) => {
              return {
                ms_edu_id: edu.ms_edu_id,
                ms_edu_degree: edu.ms_edu_degree,
              };
            });
            // console.log(this.eduOptions);

          })
          .catch(error => {
            console.error(error);
          });

      });
    },
    getDraftJob(d_job_id) {
      let loader = this.$loading.show();
      RecruiterJobService.GetSingleDraftJob({ d_job_id })
        .then((res) => {
          console.log("res::aman", res.data.data);
          // console.log("data.skill", data.speciality.checkbox_id);
          const data = res.data.data.length ? res.data.data[0] : null;
          this.wfc = data.wfc.length ? data.wfc : ["1", "2"];
          this.wfc2 = this.wfc;
          this.job_title = data.job_title;
          this.job_title_id = data.job_title_id;
          this.city = data.city;
          this.country = data.country;
          this.currency = data.currency;
          this.job_description = data.job_description;
          this.education =
            data.job_education !== null
              ? data.job_education.map((edu) => {
                return {
                  ms_edu_id: edu.jem_id,
                  ms_edu_degree: edu.job_education,
                };
              })
              : null;

          this.skills = data.skill;
          this.salary_from = data.job_salary_range_from;
          this.salary_to = data.job_salary_range_to;
          this.delGender = data.delGender ? delGender : [];
          this.selectJobType = this.select_job_types.filter((item) => (item.job_type_title === data.job_type));
          this.experiance = data.job_experiance;
          this.vacancies = +data.vacancies;
          this.nationality = data.nationality.length ? data.nationality : null;
          this.gender = data.gender;
          this.vacancies = data.vacancy;
          this.speciality = data.speciality;
          this.wfh = data.wfh_is_active && data.wfh_is_active == "Y" ? true : false;
          this.currency = data.currency ? data.currency : null;
          this.job_end_date = moment(data.job_end_date).format("YYYY-MM-DD HH:mm:ss");
          this.onChangeCountry(this.country);
          this.getAllIndustrySkills(data.speciality.checkbox_id)
          this.wfc = this.$store.getters.getWfc.wfc;
          loader.hide();
        })
        .catch((err) => {
          console.log("GetDraftJob Error", err.message);
          loader.hide();
        });
    },
    removeSkills(e) {
      this.removedSkills.push(e);
    },
    removeCity(e) {
      this.delCity.push(e);
    },
    removeNationality(e) {
      this.delNationality.push(e);
    },
    addNationality(e) {
      const addedNationality = e.map((m) => {
        return m.nationality_id;
      });
      this.delNationality = this.delNationality.filter((f) => {
        if (!addedNationality.includes(f.nationality_id)) {
          return true;
        }
      });
    },
    removeWfc(e) {
      this.wfc2.forEach((f) => {
        this.delWfc = this.wfc2.filter((f) => {
          if (!e.includes(f)) {
            return true;
          }
        });
      });
    },
    addSkill(e) {
      console.log(e);
      const addedSkill = e.map((m) => {
        return m.skill_id;
      });
      this.removedSkills = this.removedSkills.filter((f) => {
        if (!addedSkill.includes(f.skill_id)) {
          return true;
        }
      });
    },
    onChangeCity(e) {
      const addedCity = e.map((m) => {
        return m.city_id;
      });
      this.delCity = this.delCity.filter((f) => {
        if (!addedCity.includes(f.city_id)) {
          return true;
        }
      });
    },
    addDraftJob() {

      return this.$refs.job_creation_2.validate().then((result) => {
        console.log(result);
        this.submitted = true;
        if (result) {
          this.submit2();
          // const payload = {
          //   job: {
          //     job_id: this.$route.query.d_job_id,
          //     company_id: this.$cookies.get("company_id"),
          //     job_title: this.job_title,
          //     job_salary_range_from: this.salary_from,
          //     vacancies: this.vacancies,
          //     country: this.country,
          //     job_salary_range_to: this.salary_to,
          //     job_type: this.job_type,
          //     job_skills: this.skills,
          //     job_end_date: this.job_end_date,
          //     job_description: this.job_description,
          //     job_title_id: this.job_title_id,
          //     city: this.city,
          //     currency: this.currency,
          //     job_category: this.jobCategory,
          //     experiance: this.experiance,
          //     nationality: this.nationality,
          //     gender: this.gender,
          //     speciality_id: this.speciality ? this.speciality.checkbox_id : null,
          //     delGender: this.delGender,
          //     wfh: this.wfh,
          //     wfc: this.wfc,
          //     delWfc: this.delWfc,
          //     removedSkills: this.removedSkills,
          //     delCity: this.delCity,
          //     delCountry: this.delCountry,
          //     delNationality: this.delNationality,
          //     draft: "Y",
          //     education: this.education,
          //     nationality: null,
          //     gender: null,
          //     wfc: ["1", "2"],
          //   },
          // };

          console.log("payload", payload);
          RecruiterJobService.AddDraftJob(payload)
            .then((res) => {
              this.job_id = res.data.data[0];
              this.$toasted.show("Job saved to draft", {
                duration: 6000,
                icon: "check",
              });
              const queryId = this.$route?.query?.d_job_id ? this.$route?.query?.d_job_id : this.job_id;

              this.$router.push({
                path: `/employer-jobcreation-1`,
                query: { d_job_id: queryId },
              });
            })
            .catch((err) => {
              console.log("Unable to Add Draft Job : ", err.message);
              this.$toasted.show("Error occurred", {
                duration: 6000,
                icon: "clear",
              });
            });
        } else {
          this.$toasted.show("Some Fields Are missing", {
            position: "top-right",
            duration: 6000,
            icon: "clear",
          });
        }
      });


    },
    getAllSpeciality() {
      RecruiterJobService.GetAllSpeciality().then((res) => {
        const sortedData = res.data.data.sort((a, b) => a.checkbox.localeCompare(b.checkbox));
        console.log("sortedData", sortedData);
        this.specialityOpt = sortedData;
      });
    },

    selectGender(e) {
      const addedGender = e;
      this.delGender = this.delGender.filter((f) => {
        if (!addedGender.includes(f)) {
          return true;
        }
      });
    },
    getAllNationality() {
      RecruiterJobService.GetAllNationality().then((res) => {
        this.nationalities = res.data.data;
      });
    },
    removeGender(e) {
      this.delGender.push(e);
    },
    limitText(count) {
      let text = null;
      count == 1 ? (text = `and ${count} other country`) : (text = `and ${count} other countries`);
      return text;
    },
    limitTextCity(count) {
      let text = null;
      count == 1 ? (text = `and ${count} other city`) : (text = `and ${count} other cities`);
      return text;
    },
    getAllCurrency() {
      RecruiterJobService.GetCurrency().then((res) => {
        this.currencies = res.data.data;
      });
    },
    getAllJobTypes() {
      this.select_job_types = [
        {
          job_type_id: 1,
          job_type_title: this.$gettext("Any"),
        },
        {
          job_type_id: 2,
          job_type_title: this.$gettext("FULL_TIME"),
        },
        {
          job_type_id: 3,
          job_type_title: this.$gettext("PART_TIME"),
        },
        {
          job_type_id: 4,
          job_type_title: this.$gettext("Remote"),
        },
        {
          job_type_id: 5,
          job_type_title: this.$gettext("Internship"),
        },
        {
          job_type_id: 6,
          job_type_title: this.$gettext("Contract"),
        },
        {
          job_type_id: 7,
          job_type_title: this.$gettext("Work_from_home"),
        },
      ];
    },
    getAllCountries() {
      const role_id = this.$cookies.get("role_id");
      LocationServices.GetAllCountry({ role_id, job_create: "Y" }).then((res) => {
        this.countries = res.data.data.map((c) => {
          return {
            label: c.country_name,
            country_id: c.country_id,
          };
        });
        this.filteredCountries = this.countries;
      });
    },
    getAllCities(payload) {
      LocationServices.GetAllCities(payload).then((res) => {
        this.cities = res.data.data.map((city) => {
          return {
            label: city.city_name,
            city_id: city.city_id,
          };
        });
        this.filteredCities = this.cities;
      });
    },
    onChangeCountry(e) {
      //   const addedCity = e.map((m) => {
      //   return m.city_id;
      // });
      // this.delCity = this.delCity.filter((f) => {
      //   if (!addedCity.includes(f.city_id)) {
      //     return true;
      //   }
      // });

      if (!e || !e[0]?.country_id) {
        console.log("no country id");
        this.cities = [];
        this.filteredCities = this.cities;
        return;
      }

      if (e) {
        const country_id = e.map((m) => {
          return m.country_id;
        });
        this.delCountry = this.delCountry.filter((f) => {
          if (!country_id.includes(f.country_id)) {
            return true;
          }
        });
        this.citiesFetching = true;
        LocationServices.GetAllCityOfCountry({ country_id: `${country_id}` }).then((res) => {
          console.log(res);
          this.cities = res.data.data.map((m) => {
            return {
              label: m.city_name,
              city_id: m.city_id,
            };
          });
          this.filteredCities = this.cities;
          this.citiesFetching = false;
        });
      }
    },
    removeCountry(e) {
      this.delCountry.push(e);

      const country_id = [e.country_id];
      LocationServices.GetAllCityOfCountry(country_id).then((res) => {
        const city = res.data.data.map((m) => {
          return m.city_id;
        });
        let delCity = this.city.filter((f) => {
          if (city.includes(f.city_id)) {
            return true;
          }
        });

        this.delCity = [...delCity];

        this.city = this.city.filter((f) => {
          if (!city.includes(f.city_id)) {
            return true;
          }
        });
      });
    },
    searchSkills(search) {
      console.log(search);
      if (search && String(search.length) > 0) {
        this.isLoading = true;
        RecruiterJobService.SearchSkills({ search }).then((skills) => {
          this.isLoading = false;

          this.skillOptions = skills.data.data;
        });
      }
    },
    getJobCount() {
      RecruiterJobService.getJobCount().then((res) => {
        console.log("getJobCount res", res.data.data.length);
        this.jobCount = res.data.data.length;
        if (res.data.data.length >= 3) {
          this.$router.push({
            path: `/employer-pricing`,
            // query: { d_job_id: queryId },
          });
        }
      });
    },
    getChatGPT() {
      RecruiterJobService.getChatGPT().then((res) => {
        console.log("getJobCount res", res.data);
      });
    },
    getAllSkills() {
      RecruiterJobService.getAllSkills().then((skills) => {
        this.skillOptions = skills.data.data.map((s) => {
          return {
            label: s.skill_name,
            skill_id: s.skill_id,
          };
        });

        // this.skillOptions = skills.data.data;
      });
    },
    async getAllIndustrySkills(payload) {
      // const arrayOfIndustry = Array.from(String(payload), Number);
      console.log("getAllIndustrySkills", payload);
      const payloadData = [];
      payloadData[0] = payload
      console.log(payloadData);

      const djobId = this.$route.query.d_job_id ? this.$route.query.d_job_id : this.nxpId
      console.log("djobId", djobId);
      await RecruiterJobService.getAllIndustrySkills(payloadData, djobId).then((skills) => {
        console.log("skills", skills);
        // this.skillOptions = skills.data.data.map((s) => {
        //   return {
        //     "skill_id": s.skill_id,
        //     "skill_name": s.skill_name,
        //   };
        // });

        this.skillOptions = skills.data.data;
      });
    },
    async getAllJobTitles() {
      try {
        let response = await RecruiterJobService.GetAllJobTitles();

        let { data } = response;

        if (data && data.status) {
          // this.job_titles_list = data.data.map((job) => ({
          //   ...job,
          //   job_title_id: job.job_title_id.toString(),
          // }));

          this.job_titles_list = [...new Set(data.data.map((obj) => obj.job_title))];
          this.titleDescription = data.data.map((job) => ({
            ...job,
            job_title_id: job.job_title_id.toString(),
          }));
          console.log("kuch bhi", this.titleDescription);
        }
      } catch (error) {
        console.log("GetAllJobTitles Error : ", error);
      }
    },
    prefilledData() {
      if (this.$store.getters.getPage == 2) {
        const { job_title, job_title_id, job_salary_range_from, job_salary_range_to, job_description, job_type, job_skills, city, job_end_date, country, currency, nationality, vacancies, gender, delGender, wfh } = this.$store.getters.getJobDetails;
        console.log("if::this.$store.getters.getJobDetails", this.$store.getters.getJobDetails);

        this.job_title = job_title;
        this.job_title_id = job_title_id;
        this.city = city;
        this.country = country;
        this.currency = currency;
        this.job_description = job_description;
        this.skills = job_skills;
        this.salary_from = job_salary_range_from;
        this.salary_to = job_salary_range_to;
        (this.gender = null), (this.delGender = delGender ? delGender : []);
        this.selectJobType = job_type;
        this.vacancies = +vacancies;
        this.nationality = nationality;
        this.wfh = wfh;
        this.job_end_date = moment(job_end_date).format("YYYY-MM-DD HH:mm:ss");
        this.wfc = this.$store.getters.getWfc.wfc;
      } else {
        // console.log("else::this.$store.getters.getJobDetails", jobDetails.job_title);
        const jobDetails = this.$store.getters.getJobDetails;
        console.log("this.nxpId[0]", this.nxpId[0]);
        console.log("jobDetails", jobDetails);
        this.job_titleDuplicate = jobDetails.job_title
        console.log("this.job_title", this.job_title);
        this.job_title = jobDetails.job_title;
        console.log("this.job_title", this.job_title);
        this.job_title_id = jobDetails.job_title_id ? jobDetails.job_title_id : this.nxpId[0]
        this.city = jobDetails.city;
        this.country = jobDetails.country;
        this.currency = jobDetails.currency;
        this.job_description = jobDetails.job_description;
        this.skills = jobDetails.job_skills;
        this.salary_from = jobDetails.job_salary_range_from;
        this.salary_to = jobDetails.job_salary_range_to;
        this.selectJobType = jobDetails.job_type;
        this.speciality = {
          checkbox: jobDetails?.job_category?.checkbox ?? null,
          checkbox_id: jobDetails?.job_category?.checkbox_id ?? null,
          title: jobDetails?.job_category?.title ?? null,
        };
        this.selectedJob = {
          job_type_id: jobDetails?.job_type?.job_type_id ?? null,
          job_type_title: jobDetails?.job_type?.job_type_title ?? null,
        };
        this.job_end_date = jobDetails.job_end_date;
        this.experiance = jobDetails.experience;
        this.education = jobDetails.education
        this.currency = jobDetails.currency;
        this.vacancies = jobDetails.vacancies ? +jobDetails.vacancies : null;
        this.nationality = jobDetails.nationality ? jobDetails.nationality : [];
        this.gender = jobDetails.gender ? jobDetails.gender : null;
        const wfc = this.$store.getters.getWfc;
        wfc && Object.keys(wfc).length > 0 && wfc.constructor === Object && wfc.wfc.length > 0 ? (this.wfc = wfc.wfc) : null;
      }
    },
    ValidateInput() {
      return this.$refs.job_creation_1.validate().then((result) => {
        if (result && this.job_title && String(this.job_title).length > 0) {
          const payload = {
            title: this.job_title,
            description: this.job_description,
            title_id: this.job_title_id ? this.job_title_id : null,
            speciality_id: this.speciality ? this.speciality?.checkbox_id : null,
            salary_from: this.salary_from,
            salary_to: this.salary_to,
            currency_id: this.currency ? this.currency?.currency_id : null,
            vacancies: this.vacancies,
            draft: false,
            type: this.selectJobType.job_type_title,
            job_end_date: this.job_end_date,
            country: this.country,
            city: this.city,
          };
          RecruiterJobService.InsertOnlyBasicDetailsJob(payload)
            .then((res) => {
              // console.log("Basic Job Details", res.data.data);
              this.nxpId = res.data.data.insertedJob;
              console.log(res);
              this.$toasted.show("Job Created Successfully!", {
                position: "top-right",
                duration: 6000,
              });
              this.basicDetail = false;
              this.candidateDetails = true;
            })
            .catch((err) => {
              console.log(err);
              this.$toasted.show("Job Creation Failed!", {
                position: "top-right",
                duration: 6000,
              });
            });
          this.basicDetail = false;
          this.candidateDetails = true;
          this.$store.commit("ON_SET_JOBTITLE", this.job_title);
        } else {
          this.$toasted.show("Some Fields Are missing", {
            position: "top-right",
            duration: 6000,
            icon: "clear",
          });
        }
      });
    },

    onSelect(e) {
      console.log("=====e", e.checkbox_id);
      if (e.checkbox.length > 0) {
        this.err = false;
        this.getAllIndustrySkills(e.checkbox_id)
      }
    },
    remspecialityHandle(e) {
      console.log(e);
      this.speciality = null;
    },

    async maxSalHandle() {
      if (parseFloat(this.salary_from) > parseFloat(this.salary_to)) {
        console.log("this.salary_from", parseFloat(this.salary_from));
        console.log("this.salary_to", parseFloat(this.salary_to));
        this.salaryErr = true;
      } else {
        this.salaryErr = false;
      }
    },
    checkSalaryNotNull(sal) {
      if (sal !== null) {
        this.currencyRequired = "required";
        return true
      } else {
        this.currencyRequired = "";
        return false
      }
    },

    async checkJobCategory() {
      this.submitted = true;
      // this.salary_from!==null || this.salary_to
      const SalCurrency = this.checkSalaryNotNull(this.salary_from) || this.checkSalaryNotNull(this.salary_to)

      console.log("SalCurrency", SalCurrency);
      await this.maxSalHandle();
      if (this.speciality === null || this.speciality.checkbox == "" || this.speciality.checkbox == null) {
        this.err = this.speciality == null || this.speciality.checkbox == "" || this.speciality.checkbox == null ? true : false;
        console.log("this.speciality", this.speciality);
        // this.err = true;
        console.log("this.submitted=true this.speciality ");
        console.log("this.speciality ", this.speciality.checkbox);
        return this.$refs.job_creation_1.validate().then((result) => {
          console.log("result", result);
        });
      } else {
        this.err = false;
        this.salaryErr = false;
        this.SaveDraftOne();
      }
    },
    SaveDraftOne() {
      console.log("this.$route.query.d_job_id", this.$route.query.d_job_id);
      return this.$refs.job_creation_1.validate().then((result) => {
        if (result && this.job_title && String(this.job_title).length > 0) {
          const payload = {
            title: this.job_title,
            description: this.job_description,
            title_id: this.job_title_id,
            speciality_id: this.speciality.checkbox_id,
            salary_from: this.salary_from === null ? 0 : this.salary_from,
            salary_to: this.salary_to === null ? 0 : this.salary_to,
            currency_id: this.currency === null ? null : this.currency.currency_id,
            vacancies: this.vacancies,
            draft: true,
            type: this.selectJobType.job_type_title,
            job_end_date: this.job_end_date,
            country: this.country,
            city: this.city,
            job_id: this.$route.query.d_job_id ? this.$route.query.d_job_id : this.nxpId,
          };
          RecruiterJobService.InsertOnlyBasicDetailsJob(payload)
            .then((res) => {
              this.nxpId = res.data.data.insertedJob;
              console.log("nacchoooooooooooo");
              console.log(res.data.data.insertedJob);
              console.log("nxpId", this.nxpId);
              // console.log("next id-->>", this.nxpId[0]);
              // localStorage.nxpId = res.data.data.insertedJob;
              // console.log(localStorage.nxpId);
              this.$store.commit("ON_SET_NXPID", res.data.data.insertedJob);

              this.$toasted.show("Job Added To Draft", {
                position: "top-right",
                duration: 6000,
              });
              this.basicDetail = false;
              this.candidateDetails = true;
            })
            .catch((err) => {
              this.$toasted.show("Job Creation Failed!", {
                position: "top-right",
                duration: 6000,
              });
            });

          this.$store.commit("ON_SET_JOBTITLE", this.job_title);
        } else {
          this.$toasted.show("Some Fields Are missing", {
            position: "top-right",
            duration: 6000,
            icon: "clear",
          });
        }
      });
    },
    ValidateInput1() {
      return this.$refs.job_creation_2.validate().then((result) => {
        console.log(result);
        this.submitted = true;
        if (result) {
          this.submit();
        } else {
          this.$toasted.show("Some Fields Are missing", {
            position: "top-right",
            duration: 6000,
            icon: "clear",
          });
        }
      });
    },
    ValidateInput2() {
      return this.$refs.job_creation_2.validate().then((result) => {
        console.log(result);
        this.submitted = true;
        if (result) {
          this.submit2();
        } else {
          this.$toasted.show("Some Fields Are missing", {
            position: "top-right",
            duration: 6000,
            icon: "clear",
          });
        }
      });
    },

    submit() {
      const payload = {
        experience: this.experiance,
        gender: this.gender,
        draft: false,
        education: this.education ? this.education?.ms_edu_id : null,
        nationality: this.nationality,
        skills: this.skills,
        nxpId: this.nxpId,
      };
      console.log(payload);

      RecruiterJobService.InsertOnlyCandidateDetails(payload)
        .then((res) => {
          this.$store.commit("ON_SET_NXPID", res.data.data);

          this.$toasted.show("Job Created Successfully!", {
            position: "top-right",
            duration: 6000,
          });

          return Promise.resolve({ success: true });
        })
        .then(({ success }) => {
          if (!success) {
            throw new Error("Something Went Wrong");
          }
          const successPayload = Object.assign(
            {},
            {
              experience: payload.experience,
              education: this.education ? this.education?.ms_edu_id : null,
              nationality: payload.nationality,
              gender: payload.gender,
            },
            {
              company_id: this.$cookies.get("company_id"),
              job_title_id: this.job_title_id,
              job_title: this.job_title,
              job_category: this.speciality,
              job_description: this.job_description,
              country: this.country,
              city: this.city,
              vacancies: this.vacancies,
              currency: this.currency,
              job_salary_range_from: this.salary_from,
              job_salary_range_to: this.salary_to,
              job_type: this.selectJobType,
              job_end_date: this.job_end_date,
              job_skills: this.skills,
            }
          );

          this.$store.dispatch("onSetJob_Details", successPayload);
          const queryId = this.$route?.query?.d_job_id ? this.$route?.query?.d_job_id : this.job_id;

          this.$router.push({
            path: `/employer-jobcreation-1`,
            query: { d_job_id: queryId },
          });
        })
        .catch((err) => {
          console.log("RecruiterJobService.InsertJob error :- ", err.message);
          this.$toasted.show("Job Creation Failed!", {
            position: "top-right",
            duration: 6000,
          });
        });

      // new code end

      // const company_id = this.$cookies.get("company_id");
      // const payload = {
      //   company_id: this.$cookies.get("company_id"),
      //   company_id: company_id,

      //   job_title_id: this.job_title_id,
      //   job_title: this.job_title,

      //   job_category: this.jobCategory,
      //   job_description: this.job_description,
      //   country: this.country,
      //   city: this.city,
      //   vacancies: this.vacancies,
      //   currency: this.currency,
      //   job_salary_range_from: this.salary_from,
      //   job_salary_range_to: this.salary_to,
      //   job_type: this.job_type,
      //   job_end_date: this.job_end_date,

      //   experiance: this.experiance,
      //   education: this.education,
      //   job_skills: this.skills,
      //   nationality: this.nationality,
      //   gender: this.gender,

      //   // speciality_id: this.speciality ? this.speciality.checkbox_id : null,
      //   // delGender: this.delGender,
      //   // wfh: this.wfh,
      // };
      // this.$store.dispatch("onSetJob_Details", payload);
      // this.$store.dispatch("onSetWfc", { wfc: [...this.wfc] });
      // this.$store.commit("ON_SET_PAGE", 1);

      // if (this.$route.query.d_job_id) {
      //   const j_id = this.$route.query.d_job_id;
      //   this.$router.push({
      //     path: `/employer-jobcreation-1`,
      //     query: { d_job_id: j_id },
      //   });
      // } else {
      //   this.$router.push({
      //     path: `/employer-jobcreation-1`,
      //     query: { d_job_id: this.job_id },
      //   });
      // }
      // RecruiterJobService.InsertJob(payload)
      //   .then((res) => {
      //     this.$store.dispatch("onSetWfc", { payload: this.wfc });
      //     this.$toasted.show("Job Created Successfully!", {
      //       position: "top-right",
      //       duration: 6000,
      //     });
      //     payload.city = this.city;
      //     this.$store.dispatch("onSetJob_Details", payload);
      //     this.$router.push({
      //       path: `/employer-jobcreation-1`,
      //     });
      //   })
      //   .catch((err) => {
      //     console.log("RecruiterJobService.InsertJob error :- ", err.message);
      //     this.$toasted.show("Job Creation Failed!", {
      //       position: "top-right",
      //       duration: 6000,
      //     });
      //   });
    },

    submit2() {
      console.log("this.speciality", this.speciality.checkbox_id);
      const payload = {
        experience: this.experiance,
        gender: this.gender,
        draft: true,
        education: this.education,
        nationality: this.nationality,
        skills: this.skills,
        nxpId: this.nxpId,
        industryId: this.speciality.checkbox_id
      };
      console.log("payload", payload);
      this.$store.commit("onSetJob_Details", payload);
      RecruiterJobService.InsertOnlyCandidateDetails(payload)
        .then((res) => {
          this.$store.commit("ON_SET_NXPID", res.data.data);

          this.$toasted.show("Job Added To Draft", {
            position: "top-right",
            duration: 6000,
          });

          return Promise.resolve({ success: true });
        })
        .then(({ success }) => {
          console.log("this.education", this.education);
          if (!success) {
            throw new Error("Something Went Wrong");
          }
          const successPayload = Object.assign(
            {},
            {
              experience: payload.experience,
              // education: this.education ? this.education?.ms_edu_id : null,
              education: this.education ? this.education : null,
              nationality: payload.nationality,
              gender: payload.gender,
            },
            {
              company_id: this.$cookies.get("company_id"),
              job_title_id: this.job_title_id,
              job_title: this.job_title,
              job_category: this.speciality,
              job_description: this.job_description,
              country: this.country,
              city: this.city,
              vacancies: this.vacancies,
              currency: this.currency,
              job_salary_range_from: this.salary_from,
              job_salary_range_to: this.salary_to,
              job_type: this.selectJobType,
              job_end_date: this.job_end_date,
              job_skills: this.skills,
            }
          );
          // console.log("successPayload", successPayload);

          this.$store.dispatch("onSetJob_Details", successPayload);

          const queryId = this.$route?.query?.d_job_id ? this.$route?.query?.d_job_id : this.job_id;

          this.$router.push({
            path: `/employer-jobcreation-1`,
            query: { d_job_id: queryId },
          });
        })
        .catch((err) => {
          console.log("RecruiterJobService.InsertJob error :- ", err.message);
          this.$toasted.show("Job Creation Failed!", {
            position: "top-right",
            duration: 6000,
          });
        });

      // new code end

      // const company_id = this.$cookies.get("company_id");
      // const payload = {
      //   company_id: this.$cookies.get("company_id"),
      //   company_id: company_id,

      //   job_title_id: this.job_title_id,
      //   job_title: this.job_title,

      //   job_category: this.jobCategory,
      //   job_description: this.job_description,
      //   country: this.country,
      //   city: this.city,
      //   vacancies: this.vacancies,
      //   currency: this.currency,
      //   job_salary_range_from: this.salary_from,
      //   job_salary_range_to: this.salary_to,
      //   job_type: this.job_type,
      //   job_end_date: this.job_end_date,

      //   experiance: this.experiance,
      //   education: this.education,
      //   job_skills: this.skills,
      //   nationality: this.nationality,
      //   gender: this.gender,

      //   // speciality_id: this.speciality ? this.speciality.checkbox_id : null,
      //   // delGender: this.delGender,
      //   // wfh: this.wfh,
      // };
      // this.$store.dispatch("onSetJob_Details", payload);
      // this.$store.dispatch("onSetWfc", { wfc: [...this.wfc] });
      // this.$store.commit("ON_SET_PAGE", 1);

      // if (this.$route.query.d_job_id) {
      //   const j_id = this.$route.query.d_job_id;
      //   this.$router.push({
      //     path: `/employer-jobcreation-1`,
      //     query: { d_job_id: j_id },
      //   });
      // } else {
      //   this.$router.push({
      //     path: `/employer-jobcreation-1`,
      //     query: { d_job_id: this.job_id },
      //   });
      // }
      // RecruiterJobService.InsertJob(payload)
      //   .then((res) => {
      //     this.$store.dispatch("onSetWfc", { payload: this.wfc });
      //     this.$toasted.show("Job Created Successfully!", {
      //       position: "top-right",
      //       duration: 6000,
      //     });
      //     payload.city = this.city;
      //     this.$store.dispatch("onSetJob_Details", payload);
      //     this.$router.push({
      //       path: `/employer-jobcreation-1`,
      //     });
      //   })
      //   .catch((err) => {
      //     console.log("RecruiterJobService.InsertJob error :- ", err.message);
      //     this.$toasted.show("Job Creation Failed!", {
      //       position: "top-right",
      //       duration: 6000,
      //     });
      //   });
    },
  },
};
</script>

<style lang="css" scoped>
.vs__dropdown-toggle {
  background: none !important;
  border: none !important;
}

.validate-error {
  color: #ff4b4b;
  font-size: 13px;
  padding: 4px;
  font-family: "pn-regular";
  margin-top: -15px;
}

.vue-simple-suggest.designed>>>.input-wrapper input {
  background: #eaeaea;
}

.multis>>>.multiselect__tags {
  /* color: orange; */
  background-color: #eaeaea;
}

.multis>>>.multiselect__tag {
  background: #6244e5;
}

.multis>>>.multiselect__tag-icon:after {
  color: white;
}

.multis>>>.multiselect__tag-icon:hover {
  background: #6244e5;
}

.multis>>>.multiselect__option--highlight {
  background: #6244e5;
}

.multis>>>.multiselect__option:after {
  background-color: #6244e5;
  color: white;
}

.multis>>>.multiselect__input {
  background-color: #eaeaea;
}

.multis>>>.multiselect__spinner:after {
  background: #eaeaea;
}

.multis>>>.multiselect__spinner {
  background: #eaeaea;
}

.multiselect-dropdown span {
  margin-right: 10px;
}

.suggest-input.error-msg {
  margin-top: 10px;
}

.suggest-input.is-invalid {
  border: 2px solid #ff4b4b !important;
}

.suggest-input-error {
  border: 2px solid #ff4b4b !important;
}

.vue-simple-suggest.designed .input-wrapper input {
  border: 1px solid #eaeaea !important;
}

.grid-half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;
}

.detail-one {
  display: grid;
  grid-template-columns: 43% 57% 35%;
  grid-column-gap: 30px;
}

.grid-three {
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
}

.Rectangle24 {
  width: 84px;
  height: 6px;
  background: white;
  border-radius: 7px;
}

.for-mob {
  display: none !important;
  ;
}

.btn-fs-8 {
  font-size: 12px;
}

.ai-btn {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.candidate-btns {
  justify-content: space-around !important;
}

.my-btn-mx {
  margin-right: 1.5rem !important;
}


@media only screen and (max-width: 768px) {
  .for-desktop {
    display: none !important;
    ;
  }

  .for-mob {
    display: block !important;
    ;
  }

  .btn-fs-8 {
    font-size: 8px;
  }

  .detail-one {
    grid-template-columns: auto;
  }

  .grid-three {
    display: block;
    grid-template-columns: auto;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .candidate-btns {
    justify-content: space-between !important;
  }

  .my-btn-mx {
    margin-right: 0rem !important;
  }

}

/* tab styles */
.myTab {
  width: 300px;
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(57, 39, 142, 1);
}

.myTabText {
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}

.errorCategory {
  border: 2px solid red;
  border-radius: 5px;
}
</style>

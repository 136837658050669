<template>
  <div class="bg-grey pt-1 mt-1">
    <b-container fluid class="py-3 mt-5 job-listings">
      <b-row class="bg-primary for-desktop" style="position: relative; top:-18px;">
        <b-col md="12" class="py-5 bg-primary mx-auto">
          <div class="d-flex flex-wrap justify-content-around pt-5 my-5">
            <!-- basicDetails :: start -->
            <div class="myTab mt-3 d-flex justify-content-left">
              <div class="myTabText p-4 w-25 px-auto text-center font-weight-bold">1</div>
              <span :class="['font-weight-bold my-auto mx-auto', basicDetails ? 'text-white' : 'text-muted']">{{
                $gettext("Basic_details") }}</span>
            </div>
            <!-- basicDetails :: end -->

            <!-- candidateDetails :: start -->
            <div class="myTab mt-3 d-flex justify-content-left">
              <div class="myTabText p-4 w-25 px-auto text-center font-weight-bold">2</div>
              <span :class="['font-weight-bold my-auto mx-auto', candidateDetails ? 'text-white' : 'text-muted']">{{
                $gettext("Candidate_details") }}</span>
            </div>
            <!-- candidateDetails :: end -->

            <!-- interviewQuestions :: start -->
            <div class="myTab mt-3 d-flex justify-content-left">
              <div class="myTabText p-4 w-25 px-auto text-center font-weight-bold">3</div>
              <span class="text-muted font-weight-bold my-auto mx-auto">{{ $gettext("Interview_Questions") }}</span>
            </div>
            <!-- interviewQuestions :: end -->

            <!-- reviewSuggestions :: start -->
            <div class="myTab mt-3 d-flex justify-content-left">
              <div class="myTabText p-4 w-25 px-auto text-center font-weight-bold">4</div>
              <span class="text-white font-weight-bold my-auto mx-auto">{{ $gettext("Review_Suggestions") }}</span>
            </div>
            <!-- reviewSuggestions :: end -->
          </div>
        </b-col>
        <!--  <div style="position: absolute; bottom: -20px; right: 75px; z-index: 1">
        <b-button class="px-3 p-2" variant="light" size="md" style="border-radius: 10px; box-shadow: 0px 2px 21px rgba(0, 0, 0, 0.14105); opacity: 1; background-color: rgba(255, 255, 255, 1)"
          ><b><b-icon-info-circle></b-icon-info-circle> Tips for you</b></b-button
        >
      </div>-->
      </b-row>
      <b-row class="pt-4 bg-primary for-mob" style="position: relative;top:-21px">
        <b-col md="12" class="py-5 bg-primary mx-auto">

          <div class="">
            <div class="BasicDetails" style="margin-bottom: 20px;
            text-align: center; 
            color: white; 
            font-size: 18px; 
            font-weight: 700; 
            word-wrap: break-word">
              4.Review/Suggestions
            </div>
            <div class="d-flex flex-wrap justify-content-around">
              <div className="Rectangle24" style="width: 84px;height: 6px;background: white;border-radius: 7px;opacity:1">
              </div>
              <div className="Rectangle24"
                style="width: 84px;height: 6px;background: white;border-radius: 7px;opacity: 1">
              </div>
              <div className="Rectangle24"
                style="width: 84px;height: 6px;background: white;border-radius: 7px;opacity: 1">
              </div>
              <div className="Rectangle24"
                style="width: 84px;height: 6px;background: white;border-radius: 7px;opacity: 1">
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="container py-1 mt-2 job-listings emp-dashboard mx-auto">
        <!-- <div class="row emp-dashboard-header mt-5 pt-5 pb-5">
        <div class="col-md-2">
          <div class="mt-3" style="cursor: pointer" @click="$router.push('/employer-jobs')">
            <img src="@/assets/app-icons/left-arrow.svg" width="10px" />
            <span class="back-route-text"> &nbsp;Back</span>
          </div>
        </div>
        <div class="col-md-9 job-post-card mb-0">
          <div class="job-post-header">
            <div class="left">
              <div>
                <img :src="job.company_logo_path" class="img-logo" />
              </div>
              <div>
                <h5 class="fmp-b mb-0" style="text-transform: capitalize; color: black">
                  {{ jobDetails.job_title }}
                </h5>
              </div>
              <div class="d-flex align-items-center">
                <img src="@/assets/app-icons/clock.svg" width="16px" />
                <span class="pl-1"> {{ jobDetails.days }}d ago </span>
                <span class="ml-5">Deadline {{ job.deadline }}</span>
              </div>
            </div>
          </div>
          <div class="job-post-details">
            <div>
              <h6 class="heading">Salary (Annually)</h6>
              <div class="d-flex">
                <div class="mr-2">{{ jobDetails.currency_code }}</div>
                <div class="pr-2">{{ jobDetails.job_salary_range_from }}</div>

                <div class="pr-2">-</div>
                <div class="mr-2">{{ jobDetails.currency_code }}</div>
                <div>{{ jobDetails.job_salary_range_to }}</div>
              </div>
            </div>
            <div>
              <h6 class="heading">Job Type</h6>
              <p>{{ jobDetails.job_type }}</p>
            </div>
            <div>
              <h6 class="heading">Location</h6>
              <div class="d-flex job-location">
                <p v-for="(country, country_index) in jobDetails.country" :key="'B' + country_index">
                  {{ country.country_name }}
                </p>
                <p v-for="(city, city_index) in jobDetails.city" :key="'C' + city_index">
                  {{ city.city_name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> -->

        <!--End Emp-dashboard-filters-->
        <div class="row mx-auto py-5 my-5">
          <b-col class="col-md-12 col-12 white-round my-2 py-5 text-center">
            <img src="@/assets/app-icons/green-tick.svg" alt="check-icon" />
            <h2 class="fpn-b py-3">{{ $gettext("Job_successfully_Posted") }}</h2>
            <p class="mb-0">{{ $gettext("Candidates_on_our_site") }}</p>
          </b-col>
          <div class="col-md-12 lists-table" v-if="this.applicantDetails && this.applicantDetails.length">
            <h5 class="heading pb-2">{{ $gettext("Recommended_Candidates") }}</h5>
            <!-- <h5 class="heading pb-2" v-if="!this.applicantLoading && this.applicantDetails.length == 0">Applicant Not Found!</h5> -->
            <div class="table-responsive text-center mt-4">
              <table class="table">
                <thead class="thead">
                  <tr class="d-flex align-items-center">
                    <th class="col-2">{{ $gettext("Name") }}</th>
                    <!-- <th class="col-1">Video</th> -->
                    <th class="col-2"></th>
                    <th v-if="workFlows.basics" class="col-2">{{ $gettext("Basic_Score") }}</th>
                    <th v-if="workFlows.virtual_interview" class="col-2">{{ $gettext("Virtual_Interview") }}</th>
                    <!-- <th v-if="workFlows.eq_template" class="col-1">EI Score</th> -->
                    <!-- <th v-if="workFlows.virtual_video_interview" class="col-1">Virtual Video</th> -->
                    <!-- <th class="col-1">Video Profile</th> -->
                    <th class="col-2">{{ $gettext("Total_Score") }}</th>
                    <th class="col-2">{{ $gettext("Actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="d-flex" v-for="(data, applicant_details_index) in applicantDetails"
                    :key="'A' + applicant_details_index">
                    <!-- @click="viewApplicant(data.user_id)" -->
                    <td style="cursor: pointer" class="col-sm-2">{{ data.user_username }}</td>
                    <!-- <td class="col-sm-1">
                    <h6 class="icon-blue video-icon" v-if="workFlows.virtual_video_interview">
                      <img src="@/assets/app-icons/blue-video.svg" height="22" width="22" class="video" v-b-modal.video_modal @click="openVideoModal(data)" />
                    </h6>
                    <h6 v-else>-</h6>
                  </td> -->
                    <td class="col-sm-2">
                      {{ data.user_job_introduction }}
                    </td>
                    <td v-if="workFlows.basics" class="col-sm-2">
                      {{ data.basic_points.toFixed(2) }}
                    </td>
                    <td v-if="workFlows.virtual_interview" class="col-sm-2">-</td>
                    <!-- <td v-if="workFlows.eq_template" class="col-sm-1">
                    {{ data.eq_template_points }}
                  </td> -->
                    <!-- <td v-if="workFlows.virtual_video_interview && data.virtual_video_points_evaluated == 'Y'" class="col-sm-1">
                    {{ data.virtual_video_interview_points }}
                  </td> -->
                    <!-- <td v-if="workFlows.virtual_video_interview && data.virtual_video_points_evaluated == 'N'" class="col-sm-1"><input v-model="data.virtual_video_interview_points" type="number" style="width: 50px" />/20 <button @click="setVideoPoints(data)">Set</button></td> -->
                    <!-- <td class="col-sm-1">
                    {{ data.video_profile_points }}
                  </td> -->
                    <td class="col-sm-2">
                      {{ data.match_percentage.toFixed(2) }}
                    </td>
                    <td class="d-flex justify-content-around col-sm-2">
                      <!-- <div class="icon-blue" @click="
                      () =>
                        $router.push({
                          name: 'Employer-Messages',
                          query: { user_id: data.user_id },
                        })
                    ">
                      <img v-b-tooltip.hover title="Message" src="@/assets/app-icons/chat.svg" height="12" width="12"
                        class="chat" />
                    </div> -->
                      <div class="icon-blue" @click="showForwardModal(data)">
                        <img v-b-tooltip.hover title="Forward" src="@/assets/app-icons/forward.svg" height="14" width="14"
                          class="chat" />
                      </div>
                      <div style="cursor: pointer">
                        <img v-b-tooltip.hover title="Shortlist" src="@/assets/app-icons/o-star.svg" height="22"
                          width="22" @click="addShortlist(data)" />
                        <img v-b-tooltip.hover title="Shortlist" v-if="data.shortlisted === 'N'"
                          src="@/assets/app-icons/blue-star.svg" height="22" width="22" alt="shortlist"
                          @click="addShortlist(data)" />
                      </div>

                      <!-- <div @click="handleClick(data)" class="icon-blue">
                      <img
                        v-b-tooltip.hover
                        title="Delete"
                        src="@/assets/app-icons/reject.svg"
                        height="12"
                        alt="shortlist"
                        width="12"
                        class="chat"
                      />
                    </div> -->
                      <!-- <div class="icon-blue">
                          <img
                            src="@/assets/app-icons/share.svg"
                            height="12"
                            width="12"
                            class="chat"
                          />
                        </div> -->

                      <!-- <div class="icon-blue" style="cursor: pointer">
                          <img
                            src="@/assets/app-icons/blue-user.svg"
                            @click="viewApplicant(data.user_id)"
                            height="22"
                            width="22"
                            alt="user"
                          />
                        </div> -->

                      <!-- <div style="cursor: pointer">
                      <img v-b-tooltip.hover title="Shortlist" v-if="data.shortlisted === 'Y'" src="@/assets/app-icons/o-star.svg" height="22" width="22" @click="addShortlist(data)" />
                      <img v-b-tooltip.hover title="Shortlist" v-if="data.shortlisted === 'N'" src="@/assets/app-icons/blue-star.svg" height="22" width="22" alt="shortlist" @click="addShortlist(data)" />
                    </div> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-12 lists-table text-center" v-else>
            <h5 class="heading pb-2">{{ $gettext("Oops_Candidates_Not_Found") }}</h5>
          </div>
        </div>
      </div>
      <b-modal id="video_modal" size="lg" title="Video">
        <iframe class="modal-img"
          :src="`https://iframe.videodelivery.net/${selectedApplicant && selectedApplicant.length > 0 ? selectedApplicant[0] : null}/thumbnails/thumbnail.jpg?time=1s&height=500`"
          style="border: none" height="500" width="600"
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowfullscreen="true"></iframe>
      </b-modal>

      <!-- <vue-confirm-dialog></vue-confirm-dialog> -->

      <b-modal ref="myModal" hide-header @ok="handleOk">
        <div>
          <b-form-group>
            <b-form-checkbox-group v-model="selectedEmployee" :options="employees" class="diplay-block">
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import CloudflareVideoPlayer from "vue-cloudflare-video-player";
import Multiselect from "vue-multiselect";
import RecruiterJobService from "@/apiServices/RecruiterJobService";
import CompanyServices from "@/apiServices/CompanyServices";

import moment from "moment";
export default {
  components: { CloudflareVideoPlayer, Multiselect },
  data() {
    return {
      jobDetails: {},
      applicantDetails: [],
      selectedApplicant: [],
      sendApplicant: null,
      employees: [],
      selectedEmployee: [],
      countries: [],
      city: [],
      cities: [],
      isLoading: false,
      applicantLoading: true,
      skills: [],
      selectedSub: null,
      basicScore: null,
      screenerScore: null,
      qScore: null,
      eqScore: null,
      vScore: null,
      show: false,
      shortlisted: [],
      selectedSkill: [],
      country: [],
      days: null,
      loading: null,
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: "",
        feature: "Y",
      },
      workFlows: {
        basics: true,
        virtual_interview: true,
        virtual_video_interview: false,
        eq_template: false,
      },
    };
  },

  computed: {
    // shortlistedApp() {
    //   return this.getAllShortlisted();
    // },
  },
  beforeMount() {
    //to uncomment
    this.getJobDetails();
    this.getRecommendedApp();
    this.getCompanyEmployees();
    //already commented
    // this.getAllAppliedApplicants();
  },

  mounted() {
    // this.addDraftJob();
    this.$store.commit("ON_SET_NXPID", null);
    this.$store.commit("ON_SET_JOBDETAILS_WITH_VI", {})
    this.$store.commit("ON_SET_JOBDETAILS", {});
    console.log("JobDetail", this.$store.getJobDetails);
    console.log("VI", this.$store.getJobDetailsWithVI);
  },

  methods: {
    getCompanyEmployees() {
      CompanyServices.getCreatedEmployee(this.pagination).then((res) => {
        console.log("getCompanyEmployees", res);
        const user_id = this.$cookies.get("user_id");
        this.employees = res.data.data.filter((f) => f.user_id != user_id);
        this.employees = this.employees.map((m) => {
          return {
            text: `${m.user_username} ${m.user_email}`,
            value: { Email: m.user_email, Name: m.user_username },
          };
        });
      });
    },
    handleOk(e) {
      // e.preventDefault();

      console.log("hitting 1134");
      const payload = {
        applicant: this.sendApplicant,
        emp: this.selectedEmployee,
      };
      CompanyServices.SendMailToEmployee(payload).then((res) => {
        this.$toasted.show("Candidate Details Sent Successfully", {
          duration: 6000,
          icon: "check",
        });
      });
    },
    getJobDetails() {
      let job_id = this.$route.params.job_id;
      RecruiterJobService.GetSingleJob({ job_id }).then((res) => {
        this.jobDetails = res.data.data[0];
        console.log("this.jobDetails", this.jobDetails);
        const now = moment();
        const created_at = moment(this.jobDetails.job_start_date).format("YYYY-MM-DD");
        const day = now.diff(created_at, "days");
        console.log("day :>> ", day);
        this.jobDetails.days = day;
      });
    },
    getRecommendedApp() {
      let loader = this.$loading.show();
      RecruiterJobService.GetRecommendedApp()
        .then((res) => {
          console.log("Candidate details :", res.data.data);
          this.applicantDetails = res.data.data;
          loader.hide();
          // if (this.applicantDetails.length == 0) {
          //   this.$router.push({ name: "Employer-jobs" });
          // }
        })
        .catch((err) => {
          loader.hide();
        });
    },
    showForwardModal(data) {
      this.sendApplicant = data;
      this.sendApplicant.job_id = this.$route.params.job_id;
      this.$refs.myModal.show();
    },
    getAllAppliedApplicants() {
      // RecruiterJobService.GetAllAppliedApplicants().then((res) => {
      //
      //   this.applicantDetails = res.data.data;
      // });
      let job_id = this.$route.params.job_id;
      let searchApplicant = this.searchApplicant;
      let country = this.country;
      let city = this.city;
      let skills = this.selectedSkill;
      let basicScore = null;
      let screenerScore = null;
      let eqScore = null;
      let vScore = null;

      console.log("job_id :>> ", job_id);
      this.loading = this.$loading.show();
      RecruiterJobService.GetAppliedApplicant({
        job_id,
        searchApplicant,
        country,
        city,
        skills,
        basicScore,
        screenerScore,
        eqScore,
        vScore,
      })
        .then((res) => {
          this.applicantDetails = res.data.data;
          this.applicantDetails.forEach((app, index) => {
            this.applicantDetails[index].answer = app.answer;
            this.applicantDetails[index].totalScore = 0;
            this.applicantDetails[index].eqScore = 0;
            app.answer.forEach((ans, i) => {
              this.applicantDetails[index].totalScore = this.applicantDetails[index].totalScore + ans.score;
              if (ans.wfc_id == 4) {
                this.applicantDetails[index].eqScore = this.applicantDetails[index].eqScore + ans.score;
              }
            });
          });
          this.loading.hide();
          this.applicantLoading = false;
          console.log("this.applicantDetails :>> ", this.applicantDetails);
        })
        .catch((err) => {
          this.loading.hide();
          this.applicantLoading = false;
        });
    },
    addShortlist(user) {
      // { job_id: job.job_id, job_title: job.job_title, shortlisted: "" }
      // const payload = {
      //   user_id: user.user_id,
      //   // job_id: user.job_id,
      //   job_id: user.job_id,
      //   company_id: this.$cookies.get("company_id"),
      //   // shortlisted: "Y",
      //   shortlisted: "",
      // };
      console.log("jobDetails", this.jobDetails);
      const payload = {
        user_id: user.user_id,
        // job_id: this.selectedJob.job_id,
        // job_title: this.selectedJob.job_title,
        jobs: [{ job_id: this.jobDetails.job_id, job_title: this.jobDetails.job_title, shortlisted: "" }],
        // job.shortlisted = "Y";
        removedJob: null,
      };

      RecruiterJobService.AddShortlist(payload)
        .then((s) => {
          console.log("s :>> ", s.data.data);
          this.getAllAppliedApplicants();
          this.getRecommendedApp()
          this.$toasted.show("Applicant Shortlisted!", {
            duration: 6000,
            icon: "check",
          });
        })
        .catch((e) => {
          this.$toasted.show("Applicant already shortlisted!", {
            duration: 6000,
            icon: "clear",
          });
        });
    },
    handleClick(application) {
      this.$confirm({
        message: `Are you sure you want to reject this application?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            // ... do something
            console.log("confirm :>> ", confirm);
            this.rejectApplication(application);
          }
        },
      });
    },
    async rejectApplication(application) {
      try {
        console.log("application:: :>> ", application);
        let response = await RecruiterJobService.RejectApplication({
          job_apply_id: application.job_apply_id,
        });
        let { data } = response;
        if (data && data.status) {
          this.$toasted.show(data.message, {
            duration: 6000,
            icon: "check",
          });
          this.getAllAppliedApplicants();
        } else {
          this.$toasted.show(data.message, {
            duration: 6000,
            icon: "check",
          });
        }
      } catch (error) { }
    },
  },
};
</script>

<style lang="css" scoped>
.emp-dashboard .icon-blue {
  cursor: pointer;
}

.multis>>>.multiselect__tags {
  /* color: orange; */
  background-color: #eaeaea;
}

.multis>>>.multiselect__tag {
  background: #6244e5;
}

.multis>>>.multiselect__tag-icon:after {
  color: white;
}

.multis>>>.multiselect__tag-icon:hover {
  background: #6244e5;
}

.multis>>>.multiselect__option--highlight {
  background: #6244e5;
}

.multis>>>.multiselect__option:after {
  background-color: #6244e5;
  color: white;
}

.multis>>>.multiselect__input {
  background-color: #eaeaea;
}

.multiselect-dropdown span {
  margin-right: 10px;
}

/* tab styles */
.myTab {
  width: 350px;
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(57, 39, 142, 1);
}

.myTabText {
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}

.for-mob {
  display: none !important;
  ;
}

@media only screen and (max-width: 768px) {
  .for-desktop {
    display: none !important;
    ;
  }

  .for-mob {
    display: block !important;
    ;
  }
}</style>

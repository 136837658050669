<template>
  <div>
    <b-container fluid class="my-5 pt-5 user-onboard emp-onboard emp-onboard-3">
      <!--h4 class="user-welcome">Welcome, User</h4>-->
      <div class="user-step-heading">
        <div class="user-step-left">
          <img src="@/assets/app-icons/cv.svg" class="img-fluid pr-3" alt="cv-icon" width="50px" />
          <div class="pt-2">
            <!-- <h5 class="user-step">STEP 3</h5> -->
            <h4 class="user-step-name">Create users</h4>
          </div>
        </div>
      </div>
      <!--End user-step-heading-->
      <div class="py-3 add-more-user">
        <button class="btn add-user-btn" @click="addMoreEmployee">ADD MORE USERS</button>
      </div>

      <b-row class="customized-form" v-for="(e, counter) in employee" :key="counter">
        <b-col class="col-md-12 user-add-col">
          <div>
            <label>NAME</label>
            <b-form-input placeholder="Enter Name" v-model="e.user_username"></b-form-input>
          </div>
          <div>
            <label>EMAIL</label>
            <b-form-input placeholder="Enter E-mail Id" v-model="e.user_email"></b-form-input>
          </div>
          <div>
            <label>PASSWORD</label>
            <b-form-input placeholder="Enter Password" type="password" v-model="e.user_password"></b-form-input>
          </div>
          <div class="btn-action-div">
            <button class="btn btn-user-add" @click="addEmployeeToStore(counter)">ADD</button>
            <button class="btn btn-user-remove" @click="removeEmployee(counter)">
              REMOVE
              <img src="@/assets/app-icons/delete.svg" width="12px" class="mt-n1" />
            </button>
          </div>
        </b-col>
      </b-row>

      <b-row class="my-5">
        <!--   <b-col class="col-md-4">
          <button class="btn btn-tip">TIPS</button>
        </b-col>-->
        <b-col class="col-md-4 bg-red user-step-footer">
          <button class="btn btn-light" @click="$router.go(-1)">BACK</button>
          <button class="btn btn-danger" @click="$router.push({ name: 'Employer-Profile' })">SKIP</button>
          <button class="btn btn-confirm" @click.prevent.stop="submitEmployeeDetails">CONFIRM & SAVE</button>
        </b-col>
      </b-row>
      <!--End user-step-footer-->
    </b-container>
  </div>
</template>
<script>
import UserService from "@/apiServices/UserService";
export default {
  data() {
    return {
      payload: [],
      employee: [
        {
          user_username: null,
          user_email: null,
          user_password: null,
          company_id: null,
          role_id: null,
        },
      ],
    };
  },
  methods: {
    addMoreEmployee() {
      this.employee.push({
        user_username: null,
        user_email: null,
        user_password: null,
        company_id: null,
        role_id: null,
      });
      console.log("this.employee :>> ", this.employee);
    },
    removeEmployee(index) {
      this.employee.splice(index, 1);
    },
    addEmployeeToStore(counter) {
      this.employee[counter].role_id = this.$cookies.get("role_id");
      this.employee[counter].company_id = this.$cookies.get("company_id");
      this.payload.push(this.employee[counter]);
      this.payload = [...new Set(this.payload)];
      console.log("this.payload :>> ", this.payload);
      this.$toasted.show("Employee added!", {
        position: "top-right",
        duration: 6000,
        icon: "check",
      });
    },
    submitEmployeeDetails() {
      if (this.payload.length > 0) {
        UserService.createEmployee(this.payload)
          .then((res) => {
            this.$toasted.show("Employee add Successfully", {
              position: "top-right",
              duration: 6000,
              icon: "check",
            });
            this.$router.push({ name: "Employer-Profile" });
          })
          .catch((error) => {
            this.$toasted.show("Employee Email already exists", {
              position: "top-right",
              duration: 6000,
              icon: "clear",
            });
          });
      } else {
        this.$router.push({ path: "/employer-profile" });
      }
    },
  },
};
</script>
<style scoped>
@media screen and (max-width:768px) {
  .add-more-user {
    display: flex;
    justify-content: center;
  }
}
</style>

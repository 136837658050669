<template>
  <div id="app">
    <notifications group="error" class="give-a-classname" />
    <!-- <MainHeader v-if="$route.fullPath !== '/login' &&
      $route.fullPath !== '/register' &&
      $route.fullPath !== '/forgot-password' &&
      $route.fullPath !== '/' &&
      $route.fullPath !== '/employers' &&
      $route.fullPath !== '/download-zaajira-app' &&
      $route.fullPath !== '/support' &&
      $route.fullPath !== '/terms-and-conditions' &&
      $route.fullPath !== '/job-details' &&
      $route.fullPath !== '/privacy-policy'" /> -->
    <!-- <LanguageSelectorVue /> -->
    <!-- <Header :isLoggedIn="isLoggedIn" /> -->
    <!-- <Header /> -->
    <div id="main-section" class="main-section">
      <router-view />
    </div>
    <!-- <AiAssistant /> -->
    <Footer :isLoggedIn="$store.getters.getLoggedUser.isLoggedIn" />
    <!-- <Footer :isLoggedIn="isLoggedIn" /> -->
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";
// import MainHeader from "@/views/partials/MainHeader.vue";
// import Footer from "@/views/partials/Footer.vue";
// import Header from "@/components/Partials/Header.vue";
import Footer from "@/components/Partials/Footer.vue";
// import AiAssistant from "@/views/Web-Candidate/Ai-Assistant/Ai-Assistant.vue";
import UserService from "@/apiServices/UserService.js";
import langs from "./locales/availableLang";
import LanguageSelectorVue from "./views/LanguageSelector.vue";
import CandidateService from "@/apiServices/CandidateService";
import MasterServices from "@/apiServices/MasterServices";
import { formattedNumber, convertToTimeSince } from '@/views/Web-Candidate/Helper/HelperFunctions.js'
import { IMAGEURL } from "@/config";
import avtar from '@/assets/zaajira-candidate/assets/bg-images/avtar-image.png'


export default {
  components: {
    // MainHeader,
    // Header,
    Footer,
    LanguageSelectorVue,
    // AiAssistant
  },
  data() {
    return {
      isLoggedIn: this.$store.getters.getLoggedUser.isLoggedIn,
      reset: false,
      interval: null,
    };
  },

  watch: {
    '$store.getters.getLoggedUser.isLoggedIn'(newValue) {
      this.isLoggedIn = newValue;
    },
    'this.$store.getters.getCurrentLang'(newValue) {
      this.$language.current = newValue;
    },
  },
  created() {
    this.isLoggedIn = this.$store.getters.getLoggedUser.isLoggedIn
    console.log('this.isLoggedIn', this.isLoggedIn);
  },
  beforeMount() {
    const CurrentLang = JSON.parse(localStorage.getItem('currentLang'))
    console.log("lang>>>>>", CurrentLang);
    this.$language.current = CurrentLang !== null && CurrentLang !== undefined ? CurrentLang?.value : 'en'
  },
};
</script>

<style scoped>
* {
  margin: 0;
  /* padding: 0; */
}

html,
body {
  height: 100%;
}

/* .main-section {
  min-height: calc(100vh - 271px) !important;
} */
.main-section {
  min-height: calc(100vh - 271px) !important;
  overflow: auto;
}
</style>

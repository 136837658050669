import EmployerSettings from "@/views/Employer-Profile-Screens/Employer-Settings";
import EmployerProfile from "@/views/Employer-Profile-Screens/Employer-Profile";
import EmployerDashboard from "@/views/Employer-Profile-Screens/Employer-Dashboard";
import EmployerJobs from "@/views/Employer-Profile-Screens/Employer-JobVacancy";
import ApplicantProfileEmployer from "@/views/Employer-Profile-Screens/Applicant-Profile-Employer.vue";

import EmployerJobCreation from "@/views/Employer-Profile-Screens/Employer-JobCreation";
import EmployerJobPricing from "@/views/Pricing";
import UpdateJobCreation1 from "@/views/Employer-Profile-Screens/updateJobs/Employer-JobCreation-1";

import EmployerInterviewQue from "@/views/Employer-Profile-Screens/updateJobs/Employer-InterviewQue.vue";


import EmployerJobCreation1 from "@/views/Employer-Profile-Screens/Employer-JobCreation-1";
import EmployerJobReview from "@/views/Employer-Profile-Screens/Employer-Job-Review";
import UpdateJobCreation2 from "@/views/Employer-Profile-Screens/updateJobs/Employer-JobCreation-1";

import JobSuccess from "@/views/Employer-Profile-Screens/Job-Success";
import EmployerMessages from "@/views/Employer-Profile-Screens/message/Employer-Messages";
import SearchCandidate from "@/views/Candidate-Search";
import PaymentSuccess from "@/views/Payment-Success";
import PaymentCanceled from "@/views/Payment-Canceled";

export default [
  {
    path: "/payment-success",
    name: "payment-success",
    component: PaymentSuccess,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/payment-canceled",
    name: "payment-canceled",
    component: PaymentCanceled,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/employer-settings",
    name: "Employer-settings",
    component: EmployerSettings,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/employer-profile",
    name: "Employer-Profile",
    component: EmployerProfile,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/employer-dashboard/:job_id",
    name: "Employer-Dashboard",
    component: EmployerDashboard,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/employer-jobs",
    name: "Employer-jobs",
    component: EmployerJobs,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/applicant-profile-employer/:user_id",
    name: "Applicant-Profile-Employer",
    component: ApplicantProfileEmployer,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/employer-jobcreation",
    name: "Employer-JobCreation",
    component: EmployerJobCreation,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/employer-pricing",
    name: "Employer-Pricing",
    component: EmployerJobPricing,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/update-jobcreation-1/:job_id",
    name: "Update-JobCreation-1",
    component: UpdateJobCreation1,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/update-InterviewQue/:job_id",
    name: "Update-InterviewQue",
    component: EmployerInterviewQue,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/employer-job-review",
    name: "employer-job-review",
    component: EmployerJobReview,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/employer-jobcreation-1",
    name: "Employer-JobCreation-1",
    component: EmployerJobCreation1,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },

  {
    path: "/update-jobcreation-2/:job_id",
    name: "Update-JobCreation-2",
    component: UpdateJobCreation2,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/employer-message",
    name: "Employer-Messages",
    component: EmployerMessages,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/emp-job-success",
    name: "Emp-Job-Success",
    component: JobSuccess,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/search-candidate",
    name: "Search-Candidate",
    component: SearchCandidate,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
];

<template>
  <div class="container-fluid profile-user emp-settings customized-form px-1 px-md-3 pt-4 pb-5 mt-5">
    <div class="row mt-2">
      <div v-if="$cookies.get('admin') == 'Y'" class="col-md-12">
        <div class="profile-flex pb-2">
          <h4 class="heading">{{ $gettext("Users") }}({{ createdEmployee ? createdEmployee.length : null }})</h4>
        </div>
        <div class="table-vertical-scroll" style="height: 50vh;padding: 10px;">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>{{ $gettext("User_Name") }}</th>
                <th>{{ $gettext("User_Email") }}</th>
                <th>{{ $gettext("Action") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in createdEmployee" :key="index">
                <td>{{ data.username ? data.username : "-" }}</td>
                <td>{{ data.email }}</td>
                <td>
                  <img v-if="data.admin != 'Y'" style="cursor: pointer" src="@/assets/app-icons/delete.svg" width="16px"
                    class="mt-n1" @click="onDeleteEmployee(data.user_id)" />
                  <span v-if="data.admin == 'Y'">-</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyServices from "@/apiServices/CompanyServices";
export default {
  data() {
    return {
      createdEmployee: [],
    };
  },
  beforeMount() {
    this.getCreatedEmployee();
  },

  methods: {
    async getCreatedEmployee() {
      const company_id = this.$cookies.get("company_id");
      let loader = this.$loading.show();
      const createdUser = await CompanyServices.getCreatedEmployee(company_id);

      this.createdEmployee = createdUser.data.data.map((e) => {
        return {
          username: e.user_username,
          email: e.user_email,
          admin: e.admin,
          user_id: e.user_id,
        };
      });
      // this.createdEmployee = this.createdEmployee.filter((f) => {
      //   if (f.admin == "N") {
      //     return true;
      //   }
      // });
      this.getEmployerDetails();
      loader.hide();
    },
    getEmployerDetails() {
      const payload = { company_id: this.$cookies.get("company_id") };
      let loader = this.$loading.show();
      CompanyServices.GetSingleCompany(payload).then((result) => {
        this.profile = { ...result.data.data[0] };
        let admin = {
          a: true,
          email: this.profile.company_admin_email,
          username: this.profile.company_admin_name,
        };
        // this.createdEmployee.unshift(admin);
        this.profile.industry = this.profile.industry.map((m) => {
          return m.industry_name;
        });

        const pay = { image: this.profile.company_logo_path };
        if (this.$refs.slick) {
          this.$refs.slick.destroy();
          this.$nextTick(() => {
            this.$refs.slick.create();
            this.$refs.slick.goTo(0, true);
          });
        }
        this.$store.dispatch("onSetProfileImage", pay);
        loader.hide();
      });
    },
    onDeleteEmployee(user_id) {
      this.$bvModal
        .msgBoxConfirm("Do You Want to delete this Employee", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            CompanyServices.deleteEmployee({ user_id })
              .then((res) => {
                this.$toasted.show("Employee deleted successfully", {
                  duration: 6000,
                  icon: "check",
                });
                this.getCreatedEmployee();
              })
              .catch((err) => {
                this.$toasted.show("Employee not deleted", {
                  duration: 6000,
                  icon: "clear",
                });
              });
            this.$refs.myModal.hide();
          } else {
            return;
          }
        });
    },
  },
};
</script>

<style></style>

// // tset URL for Payment Gateway
// https://www.zaajira.com/apiapi/v1.0/webapp/payment-gateway/payment-test
// // choose subscription plan api url
// https://www.zaajira.com/apiapi/v1.0/webapp/payment-gateway/payment
// // tset URL for Payment Gateway
// http://localhost:6002/api/v1.0/webapp/payment-gateway/payment-test
// // choose subscription plan api url
// http://localhost:6002/api/v1.0/webapp/payment-gateway/payment


import Api from "./Api";
import { API_VERSION } from "@/config/index";

export default {
    OrderUserExistsOrNot(payload) {
        console.log("choosen plan payload", payload);
        return Api().get(`/${API_VERSION}/webapp/payment-gateway/order-exists/${payload}`);
    },
    Subscription(payload) {
        console.log("choosen plan payload", payload);
        return Api().post(`/${API_VERSION}/webapp/payment-gateway/payment`, payload);
    },
    SubscriptionStatus(payload) {
        console.log("order number", payload);
        return Api().post(`/${API_VERSION}/webapp/payment-gateway/payment-status`, payload);
    },

}
<template>
  <div class="bg-grey">
    <b-container fluid class="py-3 mt-5 job-listings">
      <!-- JobSearchBar :: start -->
      <div class="job-search-bar mt-5">
        <div class="selector-wrapper job-selector-wrapper search-block my-d-flex">
          <input type="text" class="form-control"
            :placeholder="$cookies.get('role_id') != 2 ? $gettext('Search_jobs') : $gettext('Search_candidates')"
            v-model="search" />
          <img @click="toggleSearchSetting" class="search-setting" src="@/assets/app-icons/search-setting.svg" alt=""
            height="40px" width="30px" />

        </div>

        <div :class="searchSetting ? 'my-d-block' : 'my-d-none'"
          class="selector-wrapper location-selector-wrapper location-block custom-select-2">
          <!-- <multiselect
            style=""
            v-model="selectedCity"
            placeholder="Select City"
            label="city_name"
            track-by="city_id"
            :options="cities"
            @input="onSelectCity"
            :multiple="false"
            @remove="removeFromFilter"
          /> -->
          <!-- Edited By Aman Shrivas -->
          <multiselect v-model="selectedCountry" :placeholder="$gettext('Select_country')" label="checkbox"
            track-by="checkbox_id" :options="searchedC ? searchedC : countries" @search-change="SearchCountry"
            @input="onSelectCountry" :multiple="false" />
          <button style="position: absolute; right: 27px;" @click="getApplicantDetails"
            class="btn btn-primary for-mob"><b-icon icon="search"></b-icon></button>
        </div>

        <div class="search-button for-desktop">
          <button class="btn btn-job-search " @click="getApplicantDetails">{{ $gettext("Search") }}</button>
        </div>
      </div>
      <!-- JobSearchBar :: end -->

      <!-- JobSortBar :: start -->
      <div class="job-sort-bar py-2 mt-3 mb-2">
        <!-- <div class="col-md-12 col-xl-12 left">
          <h6 class="fpn-b" v-if="$cookies.get('role_id') != 2 ? true : false">{{ appliedJobs.length }} Jobs Applied</h6>
          <div class="d-xl-none mobile-filter">
            <button class="btn btn-mobile-filter mb-3" @click="showFilters">
              <b-icon icon="funnel-fill" @click="showFilters" class="mobile-filter-icon" width="20px">
              </b-icon>{{ gettext("Filters") }}</button>
          </div>
        </div> -->
        <!-- <div class="col-md-12 col-xl-12 left" @click="toggleFilter"> -->
        <!-- <div class="col-md-12 col-xl-12 left" @click="toggleFilter">
          <h6 class="fpn-b" v-if="$cookies.get('role_id') != 2 ? true : false">{{ appliedJobs.length }} Jobs Applied</h6>
          <div class="d-xl-none mobile-filter">
            <button class="btn btn-mobile-filter mb-3">
              <b-icon icon="funnel-fill" class="mobile-filter-icon" width="20px">
              </b-icon>{{ gettext("Filters") }}</button>
          </div>
        </div> -->
        <div class="col-md-12 col-xl-12 right">
          <div @click="toggleFilter" class="d-xl-none mobile-filter" style="padding-top: 14px;margin: -10px;">
            <button class="btn btn-mobile-filter mb-3">
              <b-icon icon="funnel-fill" class="mobile-filter-icon" width="20px">
              </b-icon>{{ gettext("Filters") }}</button>
          </div>
          <div class="ml-auto pr-3" style="cursor: pointer custom-select-2">
            <img src="@/assets/app-icons/sort-amount-down-alt-solid.svg" alt="" height="16px" width="16px" />
            {{ $gettext("Sort_by") }}
          </div>
          <div>
            <multiselect :options="sortOptions" :placeholder="$gettext('Sort_by')" label="label" track-by="label"
              :multiple="false" v-model="sort" @input="sortCandidates" />
          </div>
        </div>
      </div>
      <!-- JobStartBar :: end -->

      <div class="row">
        <div id="filter1" class="col-md-12 col-lg-12 col-xl-3 mb-4">
          <!-- ColFilterBox :: start -->
          <div class="col-filter-box">
            <div class="text-right d-xl-none">
              <svg @click="hideFilters" width="3rem" height="3rem" viewBox="0 0 16 16" class="text-body bi bi-x"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
            <!-- v-show="advanceSearch" -->
            <div class="sub-heading">
              <h5 class="mb-0">{{ $gettext("Filters") }}</h5>
            </div>
            <!-- v-show="advanceSearch" -->
            <div>
              <div v-for="(data, index) in filters" :key="index">
                <!-- {{index}}  {{data}} -->
                <div class="collapse-card" :class="data.checkbox && data.checkbox.length ? 'col-filter-collapse' : ''">
                  <div
                    v-if="data.title != 'Shortlist' && data.title != 'Work From Home Jobs' && data.checkbox && data.checkbox.length"
                    class="filter-tab-title hello" v-b-toggle="'collapse-' + index">
                    {{ data.title === "Salary_range" ? $gettext(data.title) + "(Monthly) (" + companyCurr + ")" :
                      $gettext(data.title) }}
                  </div>
                  <div v-if="data.title == 'Work From Home Jobs'"
                    class="d-flex justify-content-between align-items-center">
                    <span class="filter-tab-title">{{ data.title }} </span>
                    <b-form-checkbox v-model="wfh" @change="getAllWfhJobs" switch> </b-form-checkbox>
                  </div>

                  <div v-if="data.title == 'Shortlist' && $cookies.get('role_id') == 3"
                    class="d-flex justify-content-between align-items-center">
                    <span class="filter-tab-title">{{ data.title }}</span>
                    <b-form-checkbox v-model="bookmarkFilter" @change="getAllBookmarkJobs" switch></b-form-checkbox>
                  </div>

                  <b-collapse :id="'collapse-' + index" class="pt-2">
                    <div class="flex-between mb-1" v-for="(check, i) in data.checkbox" :key="i">
                      <span class="filter-tab-text">{{ data.title === "Salary_range"
                        ? formattedNumber(check.checkbox_id.min) + " - " + formattedNumber(check.checkbox_id.max) :
                        check.checkbox }}
                        <!-- {{ formattedNumber(check.checkbox_id.min) }} - {{ formattedNumber(check.checkbox_id.max) }} -->
                      </span>
                      <b-form-checkbox v-model="appliedFilter.filters" :value="check" @change="onChangeCheckbox">
                      </b-form-checkbox>
                    </div>
                  </b-collapse>
                </div>
              </div>

              <div class="flex-between col-filter-btn">
                <button type="button" class="btn btn-md btn-light" @click="resetFilters">{{ $gettext("Reset") }}</button>
                <button type="button" class="btn btn-md btn-primary" @click="() => {
                  onApplyFilter();
                }
                  ">
                  {{ $gettext("Apply") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- ColFilterBox :: end -->

        <!-- container :: start -->
        <div class="col-md-12 col-xl-9">
          <div class="p-4 mx-auto bg-white" v-if="!jobLength">
            <h1>{{ $gettext("Your_search_produced_no_results") }}</h1>
            <h4>{{ $gettext("Try_adjusting_to_display_better_results") }}</h4>
          </div>
          <div id="candidateData" :current-page="pagination.current" :per-page="pagination.pageSize" class="job-post-card"
            v-for="(candidate, index) in candidates" :key="index">
            <div class="job-post-header">
              <div class="left">
                <div style="cursor: pointer" @click="() =>
                  $router.push({
                    name: 'Applicant-Profile-Employer',
                    params: { user_id: candidate.user_id },
                    query: { shortlist: '', c: candidate.updated_at },
                  })
                  ">
                  <img v-if="candidate.user_job_profile_image" :src="`${image_url}${candidate.user_job_profile_image}`"
                    height="50" width="auto" />
                  <img v-if="!candidate.user_job_profile_image" src="../assets/unknown.jpg" height="50" width="auto" />
                </div>
                <div style="cursor: pointer" @click="() =>
                  $router.push({
                    name: 'Applicant-Profile-Employer',
                    params: { user_id: candidate.user_id },
                    query: { shortlist: '', c: candidate.updated_at },
                  })
                  ">
                  <span>{{ candidate.user_username ? candidate.user_username : "-" }}</span>
                </div>
                <div class="float-right">
                  {{ candidate.updated_at }} {{ $gettext("day_ago") }}
                  <!-- <img src="@/assets/app-icons/clock.svg" width="16px" /> -->
                  <!-- <span class="pl-1"> {{ days[index] }}d ago </span> -->
                </div>
              </div>
            </div>
            <div>
              <h5 class="job-title mt-3 " style="text-transform: capitalize;font-weight: bold;">
                <!-- {{ candidate.user_job_title ? candidate.user_job_title : "-" }} -->
                {{ candidate.designation && candidate.designation !== "null" ? candidate.designation : "Not Entered" }}
              </h5>
            </div>
            <div class="job-post-details">
              <div class="item-left">
                <h6 class="heading">{{ $gettext(" Current_salary_monthly") }}</h6>
                <!-- <div class="d-flex"> -->
                <div class="">
                  <!-- <div class="mr-2">{{ candidate.currency_code }}</div>
                  <div class="pr-2">
                    {{ numberWithCommas(candidate.current_salary) }}
                  </div> -->
                  <div class="mr-2">{{ candidate.converted_salary !== 0 && candidate.converted_salary > 0 ? companyCurr :
                    "" }}</div>
                  <div class="pr-2 fw-bold">
                    <!-- {{ candidate.converted_salary !== 0 ? parseFloat(candidate.converted_salary).toFixed(2) : "Not
                                        Entered" }} -->
                    {{ candidate.converted_salary !== 0 && candidate.converted_salary > 0 ?
                      formattedNumber(candidate.converted_salary) : "Not Entered" }}
                  </div>
                </div>
              </div>
              <div class="item-right">
                <h6 class="heading">{{ $gettext("Experience") }}</h6>
                <p class="fw-bold">
                  <!-- {{ candidate.user_job_experience ? candidate.user_job_experience : 0 }} -->
                  {{ candidate.experiance_year ? candidate.experiance_year : 0 }}
                  {{ $gettext(" Years") }}
                </p>
              </div>
              <div class="item-left">
                <h6 class="heading">{{ $gettext("Location") }}</h6>
                <div class="d-flex job-location fw-bold">
                  <p>{{ candidate.country_name }} {{ candidate.city_name }}</p>
                </div>
              </div>
              <div class="item-right">
                <h6 class="heading">{{ $gettext("Gender") }}</h6>
                <!-- <div class="d-flex job-location "> -->
                <div>
                  <p class="fw-bold">{{ candidate.user_gender == "M" ? $gettext("Male") : $gettext("Female") }}</p>
                </div>
              </div>
              <div>
                <button v-if="$cookies.get('role_id') == 3" class="btn btn-match" style="border: 4px solid red"
                  :disabled="true" :style="100 <= candidate.match_percentage && candidate.match_percentage <= 75
                    ? 'background-color:#5ACEB1 !important'
                    : 50 <= candidate.match_percentage && candidate.match_percentage <= 74.99
                      ? 'background-color:#5A5AFF !important'
                      : 25 <= candidate.match_percentage && candidate.match_percentage <= 49.99
                        ? 'background-color:#FF8C00 !important'
                        : (24.99 >= candidate.match_percentage && candidate.match_percentage >= 0) || !candidate.match_percentage
                          ? 'background-color: #FF5A5A !important'
                          : ''
                    ">
                  {{ job.match_percentage ? job.match_percentage.toFixed(2) : 0 }}
                  % Match &nbsp;<img v-b-tooltip.hover
                    title="The match rated is calculated based on your profile and the job details, if you want to increase your match rate, make sure to answer more questions which are suggested to you in your profile page"
                    src="@/assets/app-icons/question-mark.svg" />
                </button>
              </div>
            </div>
            <!-- <div class="job-post-description">
              <h6 class="desc-title">Description</h6>
              <p class="desc-content mb-0" style="white-space: pre-line">
                {{ candidate.user_job_introduction ? candidate.user_job_introduction : "-" }}
              </p>
            </div> -->
            <div class="job-post-footer">
              <div class="left">
                <div class="share-text mr-4" @click="share(candidate)" v-b-modal.share-modal>
                  <span>{{ $gettext("Share") }}</span>
                  <!-- <ShareNetwork
                    network="whatsapp"
                    :url="`${SERVERURL}job-details/${job.job_id}`"
                    :title="job.job_title"
                    :description="job.job_description"
                  >
                    Share
                  </ShareNetwork> -->
                  <img src="@/assets/app-icons/share.svg" width="16px" class="ml-2 mb-0" />
                </div>
                <div v-if="candidate.cv.length" class="share-text mr-5">
                  <div
                    v-if="!selectedSub || selectedSub.subs_pkg_title == 'Free' || selectedSub.subs_pkg_title == 'FREE'">
                    <p @click="() =>
                      $toasted.show('You are not allowed to see this', {
                        duration: 6000,
                        icon: 'clear',
                      })
                      " style="cursor: pointer">
                      CV
                    </p>
                  </div>
                  <div v-else>
                    <a :href="candidate.cv[0].user_job_cv_path" target="_blank" style="color: black"> CV <img
                        src="@/assets/app-icons/pdf.svg" width="16px" /> </a>
                  </div>
                </div>
                <div class="mr-5" v-if="candidate.videos.length">
                  <h6 class="icon-blue video-icon mb-0 d-flex align-items-center justify-content-center">
                    <img
                      v-if="!selectedSub || selectedSub.subs_pkg_title == 'Free' || selectedSub.subs_pkg_title == 'FREE'"
                      src="@/assets/app-icons/blue-video.svg" height="16px" width="16px" class="video"
                      style="cursor: pointer" @click="() => {
                        $toasted.show('You are not allowed', {
                          duration: 6000,
                          icon: 'clear',
                        });
                      }
                        " />
                    <img v-else src="@/assets/app-icons/blue-video.svg" height="16px" width="16px" class="video"
                      style="cursor: pointer" v-b-modal.video_modal @click="() => {
                        openVideoModal(candidate);
                      }
                        " />
                  </h6>
                </div>
                <!-- <div
                  class="icon-blue d-flex align-items-center justify-content-center"
                  v-show="selectedSub"
                  v-b-tooltip.hover
                  title="Message"
                  @click="
                    () =>
                      $router.push({
                        name: 'Employer-Messages',
                        query: { user_id: candidate.user_id },
                      })
                  "
                >
                  <img
                    src="@/assets/app-icons/chat.svg"
                    height="12"
                    width="12"
                    class="chat"
                    style="cursor:pointer"
                  />
                </div> -->
              </div>
              <div class="right">
                <router-link class="btn btn-job-details" :to="{
                  name: 'Applicant-Profile-Employer',
                  params: { user_id: candidate.user_id },
                  query: { shortlist: '' },
                }" style="text-decoration: none; color: inherit">
                  {{ $gettext("View_candidate_details") }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="" v-if="candidates.length >= 1">
            <!-- <div>
              <button v-show="pagination.current != 1" :disabled="pagination.current == 1" class="btn btn-back" @click="PreviousPage">Back</button>
            </div>
            <div>
              <button v-show="pagination.current * pagination.pageSize < totalCount" :disabled="pagination.current * pagination.pageSize >= totalCount" class="btn btn-next" @click="nextPage">Next</button>
            </div> -->

            <!-- Edited By Aman Shrivas -->
            <template>
              <div class="overflow-auto">
                <div class="col-lg-8"></div>
                <div class="col-lg-4 col-md-12">
                  <!-- <h6 class="text-center">Fill alignment</h6> -->
                  <b-pagination v-model="pagination.current" :total-rows="pagination.total" aria-controls="candidateData"
                    :per-page="pagination.pageSize" :first-text="$gettext('First')" :prev-text="$gettext('Prev')"
                    :next-text="$gettext('Next')" :last-text="$gettext('Last')" @page-click="pageChanged"
                    align="fill"></b-pagination>
                </div>
              </div>
            </template>
            <!-- end Edited Aman Shrivas -->
          </div>
          <b-modal ref="myModal" hide-header hide-footer>
            <div class="text-center">
              <b-spinner label="Loading..." style="width: 8rem; height: 8rem"> </b-spinner>
              <h2>{{ $gettext("Please_Wait_Job_Is_Loading") }}</h2>
            </div>
          </b-modal>
        </div>
        <!-- container :: end -->

        <!-- modal :: start -->

        <!--share is fixed-->
        <b-modal id="share-modal" ref="modal" @hidden="resetModal" @ok="handleOk" hide-footer>
          <div>
            <h4>{{ $gettext("Share_this_job_on_social_network") }}</h4>
            <p v-if="socialLink">✔ Text Copied</p>
            <div class="social-icons-grid">
              <ShareNetwork network="Whatsapp" :url="`${userProfile}${selectedJob.user_id}`"
                :title="selectedJob.user_username ? selectedJob.user_username : ''"
                :description="selectedJob.user_job_introduction ? selectedJob.user_job_introduction : ''"
                @open="handleSubmit">
                <div>
                  <i class="fa fa-whatsapp"></i>
                </div>
              </ShareNetwork>
              <ShareNetwork network="Facebook" :url="`${userProfile}${selectedJob.user_id}`"
                :title="selectedJob.user_job_title ? selectedJob.user_job_title : ''"
                :description="selectedJob.user_job_introduction ? selectedJob.user_job_introduction : ''"
                @open="handleSubmit">
                <div>
                  <i class="fa fa-facebook"></i>
                </div>
              </ShareNetwork>
              <ShareNetwork network="Twitter" :url="`${userProfile}${selectedJob.user_id}`"
                :title="selectedJob.user_job_title ? selectedJob.user_job_title : ''"
                :description="selectedJob.user_job_introduction ? selectedJob.user_job_introduction : ''"
                @open="handleSubmit">
                <div>
                  <i class="fa fa-twitter"></i>
                </div>
              </ShareNetwork>

              <!-- <ShareNetwork network="fakeblock" :url="`${userProfile}${selectedJob.user_id}`"
                :title="selectedJob.user_job_title ? selectedJob.user_job_title : ''"
                :description="selectedJob.user_job_introduction ? selectedJob.user_job_introduction : ''"
                @open="handleSubmit">
                <div>
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </div>
              </ShareNetwork> -->

              <!-- <a :href="`https://www.snapchat.com/scan?attachmentUrl=${userProfile}${selectedJob.user_id}`"
                target="_blank">
                <i class="fa fa-snapchat-square" aria-hidden="true"></i>
              </a> -->


              <!-- <a href="https://www.tiktok.com/en/" target="_blank">
                <div style="width:17.15px; margin-top: -2px;">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333335 333336" shape-rendering="geometricPrecision"
                    text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd"
                    clip-rule="evenodd">
                    <path
                      d="M72464 0h188407c39856 0 72464 32609 72464 72465v188407c0 39855-32608 72464-72464 72464H72464C32608 333336 0 300727 0 260872V72465C0 32609 32608 0 72464 0zm127664 70642c337 2877 825 5661 1461 8341l6304 2c1170 9991 4006 19119 8465 26697 7282 6745 16797 10904 28280 11641v9208c2131 444 4350 746 6659 894v29690c-14847 1462-27841-3426-42981-12531l2324 50847c0 16398 61 23892-8738 38977-20546 35222-58194 36677-82176 18323-12269-4256-23069-12466-29881-23612-19875-32516-1959-85512 55687-90966l-94 7835v1970c3105-646 6365-1144 9794-1468v31311c-12484 2057-20412 5890-24119 12980-7424 14197-4049 26526 3716 34309 16276 2796 34401-8481 31673-43351V70644h33628z"
                      fill="#1a121f" />
                    <path
                      d="M200128 70642c3093 26406 18915 45038 44510 46681v25046l-165 15v-21275c-25595-1642-40311-17390-43404-43796l-27114-1v111095c3912 50005-35050 51490-49955 32531 17482 10934 45867 3826 42501-39202V70641h33628zm-72854 184165c-15319-3153-29249-12306-37430-25689-19875-32516-1959-85512 55687-90966l-94 7835c-53444 8512-58809 65920-44009 89802 5707 9209 15076 15686 25846 19019z"
                      fill="#26f4ee" />
                    <path
                      d="M207892 78985c1761 15036 7293 28119 16454 36903-12866-6655-20630-19315-23062-36905l6609 2zm36580 47511c2181 463 4456 777 6824 929v29690c-14847 1462-27841-3426-42981-12531l2324 50847c0 16398 61 23892-8738 38977-21443 36760-61517 36743-85239 15810 30930 17765 84928 3857 84829-56453v-55496c15141 9105 28134 13993 42981 12530v-24302zm-99036 21460c3105-646 6365-1144 9794-1468v31311c-12484 2057-20412 5890-24119 12980-10441 19964 474 36238 14923 41365-18075-649-36010-19214-23555-43031 3707-7089 10474-10923 22958-12980v-28177z"
                      fill="#fb2c53" />
                    <path
                      d="M201068 77313c3093 26406 17809 42154 43404 43796v29689c-14847 1462-27841-3425-42981-12530v55496c119 72433-77802 77945-100063 42025-14800-23881-9435-81290 44009-89802v30147c-12483 2057-19250 5891-22958 12980-22909 43808 56997 69872 51475-706V77313l27114 1z"
                      fill="#fefefe" />
                  </svg>
                </div>
              </a> -->

              <ShareNetwork network="Linkedin" :url="`${userProfile}${selectedJob.user_id}`"
                :title="selectedJob.user_job_title ? selectedJob.user_job_title : ''"
                :description="selectedJob.user_job_introduction ? selectedJob.user_job_introduction : ''"
                @open="handleSubmit">
                <div>
                  <i class="fa fa-linkedin"></i>
                </div>
              </ShareNetwork>
            </div>
          </div>
        </b-modal>

        <!-- modal :: end -->
      </div>


    </b-container>
    <b-modal id="video_modal" size="lg" title="Video">
      <iframe class="modal-img"
        :src="`https://iframe.videodelivery.net/${selectedCandidateVideo ? selectedCandidateVideo.video_path : null}/thumbnails/thumbnail.jpg?time=1s&height=500`"
        style="border: none" height="500" width="600"
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
    </b-modal>
    <b-modal ref="filter-modal" id="filter-modal" class="modal-bottom fade-in-bottom">
      <div>
        <!-- ColFilterBox :: start -->
        <div class="col-filter-box">
          <!-- <div class="text-right d-xl-none">
              <svg @click="hideFilters" width="3rem" height="3rem" viewBox="0 0 16 16" class="text-body bi bi-x"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div> -->
          <div @click="toggleFilter" class="Line29"></div>
          <!-- v-show="advanceSearch" -->
          <div class="sub-heading">
            <h5 class="mb-0">{{ $gettext("Filters") }}</h5>
          </div>
          <!-- v-show="advanceSearch" -->
          <div>
            <div v-for="(data, index) in filters" :key="index">
              <!-- {{index}}  {{data}} -->
              <div class="collapse-card" :class="data.checkbox && data.checkbox.length ? 'col-filter-collapse' : ''">
                <div
                  v-if="data.title != 'Shortlist' && data.title != 'Work From Home Jobs' && data.checkbox && data.checkbox.length"
                  class="filter-tab-title hello" v-b-toggle="'collapse-' + index">
                  {{ data.title === "Salary_range" ? $gettext(data.title) + "(Monthly) (" + companyCurr + ")" :
                    $gettext(data.title) }}
                </div>
                <div v-if="data.title == 'Work From Home Jobs'" class="d-flex justify-content-between align-items-center">
                  <span class="filter-tab-title">{{ data.title }} </span>
                  <b-form-checkbox v-model="wfh" @change="getAllWfhJobs" switch> </b-form-checkbox>
                </div>

                <div v-if="data.title == 'Shortlist' && $cookies.get('role_id') == 3"
                  class="d-flex justify-content-between align-items-center">
                  <span class="filter-tab-title">{{ data.title }}</span>
                  <b-form-checkbox v-model="bookmarkFilter" @change="getAllBookmarkJobs" switch></b-form-checkbox>
                </div>

                <b-collapse :id="'collapse-' + index" class="pt-2">
                  <div class="flex-between mb-1" v-for="(check, i) in data.checkbox" :key="i">
                    <span class="filter-tab-text">{{ data.title === "Salary_range"
                      ? formattedNumber(check.checkbox_id.min) + " - " + formattedNumber(check.checkbox_id.max) :
                      check.checkbox }}
                      <!-- {{ formattedNumber(check.checkbox_id.min) }} - {{ formattedNumber(check.checkbox_id.max) }} -->
                    </span>
                    <b-form-checkbox v-model="appliedFilter.filters" :value="check" @change="onChangeCheckbox">
                    </b-form-checkbox>
                  </div>
                </b-collapse>
              </div>
            </div>

            <div class="flex-between col-filter-btn">
              <button type="button" class="btn btn-md btn-light" @click="resetFilters">{{ $gettext("Reset")
              }}</button>
              <button type="button" class="btn btn-md btn-primary" @click="() => {
                  onApplyFilter();
                }
                ">
                {{ $gettext("Apply") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApplicantJobService from "@/apiServices/ApplicantJobServices";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";
import ApplyJobService from "@/apiServices/ApplyJobServices";
import LocationService from "@/apiServices/LocationServices";
import NavigatorShare from "vue-navigator-share";
import Multiselect from "vue-multiselect";
import FilterService from "@/apiServices/FilterService";
import CompanyServices from "@/apiServices/CompanyServices";
import RecruiterJobService from "@/apiServices/RecruiterJobService";
import { userProfile } from "@/config";
import { numberWithCommas } from "@/helpers/HelperFunctions.js";
import { IMAGEURL } from "@/config";
import numeral from 'numeral';

import moment from "moment";

export default {
  components: {
    NavigatorShare,
    Multiselect, numeral
  },
  data() {
    return {
      searchSetting: false,
      isFilter: false,
      hasRefreshed: false,
      socialLink: false,
      companyCurr: null,
      image_url: IMAGEURL,
      perPage: 20,
      currentPage: 1,
      userProfile: userProfile,
      name: "",
      nameState: null,
      wfh: null,
      selectedJob: {},
      isMobileFilter: window.innerWidth <= 1600,
      advanceSearch: this.$route.query.type == "advance",
      selectedCandidateVideo: null,
      selectedCity: null,
      selectedCountry: null,
      sort: { label: "Date" },
      jobLength: false,
      candidates: [],
      jobs: [],
      days: [],
      selectedSub: null,
      cities: [],
      countries: [],
      searchedC: false,
      search: "",
      role_id: this.$cookies.get("role_id"),
      country_id: this.$route.query.country_id ? this.$route.query.country_id : "",
      city: null,
      totalCount: 0,
      appDetails: null,
      bookmarkFilter: null,
      sortingOptions: [{ label: "Name" }],
      sortOptions: [{ label: "Date" }, { label: "Name" }],
      search: "",
      appliedJobs: [],
      appliedJob: {
        user_id: null,
        job_id: null,
      },
      appliedFilter: { filters: [] },
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 21,
        total: 0,
        searchText: this.$route.query.search || "",
        city_id: this.$route.query.city ? this.$route.query.city : "",
        feature: "Y",
        user_id: this.$cookies.get("user_id"),
        role_id: this.$cookies.get("role_id"),
      },
      selected: [],
      filters: [
        // { title: "Work From Home Jobs", checked: false },
        // { title: "Country", checkbox: [] },
        { title: "Country", checkbox: [] },
        { title: "City", checkbox: [] },
        { title: "Industry", checkbox: [] },
        // {
        //   title: "Employment type",
        //   checkbox: [
        //     {
        //       title: "job_type",
        //       checkbox: "Full Time",
        //       checkbox_id: "Full Time",
        //     },
        //     {
        //       title: "job_type",
        //       checkbox: "Part Time",
        //       checkbox_id: "Part Time",
        //     },
        //     { title: "job_type", checkbox: "Remote", checkbox_id: "Remote" },
        //     {
        //       title: "job_type",
        //       checkbox: "Internship",
        //       checkbox_id: "Internship",
        //     },
        //     {
        //       title: "job_type",
        //       checkbox: "Contract",
        //       checkbox_id: "Contract",
        //     },
        //   ],
        // },
        // { title: "Industry", checkbox: [] },
        {
          title: "Salary_range",
          checkbox: [
            {
              title: "salary",
              checkbox: "100 - 1000",
              checkbox_id: { min: 100, max: 500 },
            },
            {
              title: "salary",
              checkbox: "1001 - 5000",
              checkbox_id: { min: 1001, max: 5000 },
            },
            {
              title: "salary",
              checkbox: "5001 - 10000",
              checkbox_id: { min: 5001, max: 10000 },
            },
            {
              title: "salary",
              checkbox: "10001 - 20000",
              checkbox_id: { min: 10001, max: 20000 },
            },
            {
              title: "salary",
              checkbox: "20001 - 30000",
              checkbox_id: { min: 20001, max: 30000 },
            },
            {
              title: "salary",
              checkbox: "30001 - 40000",
              checkbox_id: { min: 30001, max: 40000 },
            },
            {
              title: "salary",
              checkbox: "40001 - 50000",
              checkbox_id: { min: 40001, max: 50000 },
            },
            {
              title: "salary",
              checkbox: "50001 - 100000",
              checkbox_id: { min: 50001, max: 100000 },
            },
            {
              title: "salary",
              checkbox: "100001 - 250000",
              checkbox_id: { min: 100001, max: 250000 },
            },
            {
              title: "salary",
              checkbox: "250001 - 500000",
              checkbox_id: { min: 250001, max: 500000 },
            },
            {
              title: "salary",
              checkbox: "500001 - 1M",
              checkbox_id: { min: 500001, max: 1000000 },
            },
            {
              title: "salary",
              checkbox: "1M+",
              checkbox_id: { min: 1000000, max: 10000000 },
            },
          ],
        },
        {
          title: "Gender",
          checkbox: [
            {
              title: "gender",
              checkbox: "Any",
              checkbox_id: "gender_any",
            },
            {
              title: "gender",
              checkbox: "Female",
              checkbox_id: "F",
            },
            {
              title: "gender",
              checkbox: "Male",
              checkbox_id: "M",
            },
            // Edited By Aman Shrivas Replaced Other to any at line no. 540 and 541
          ],
        },
        {
          title: "Date_modified",
          checkbox: [
            {
              title: "date_modified",
              checkbox: "1 Day Ago",
              checkbox_id: 1,
            },
            {
              title: "date_modified",
              checkbox: "7 Days Ago",
              checkbox_id: 7,
            },
            {
              title: "date_modified",
              checkbox: "30 Days Ago",
              checkbox_id: 30,
            },
          ],
        },
        { title: "Shortlist", checked: false },
      ],
      job: {
        match_percentage: null,
      },
    };
  },
  computed: {

    url() {
      return window.location.href;
    },
    title() {
      return "Job";
    },
  },

  created() {
    // location.replace(location.href = `${FRONTEND_BASE_URL}` + "Search-Candidate")
    addEventListener("resize", () => {
      this.isMobileFilter = innerWidth <= 1600;
    });
  },
  beforeMount() {

    //this.getAllJobs(this.pagination);
    this.getCities(this.pagination);
    this.getSelectedSubscription();
    // this.getSkills();
    // this.getIndustry();

    this.getCountries();
    this.getApplicantDetails();
    this.getAllSpeciality();

  },
  watch: {
    search: function (e) {
      this.pagination.searchText = e;
      this.$router.push({ name: "Search-Candidate", query: { search: e } });
    },
  },
  mounted() {
    // window.addEventListener("keypress", (e) => {
    //   if (e.key == "Enter") {
    //     this.getAllJobs();
    //   }
    // });
  },
  methods: {
    toggleSearchSetting() {
      this.searchSetting = !this.searchSetting;
    },
    toggleFilter() {
      this.isFilter = !this.isFilter;
      if (this.isFilter) {
        this.$bvModal.show("filter-modal")
      }
      else {
        this.$bvModal.hide("filter-modal")
      }
    },
    formattedNumber(sal) {
      //console.log(sal);

      return numeral(sal).format('0,0');
    },
    gettext(key) {
      return this.$gettext(key);
    },
    numberWithCommas: numberWithCommas,
    openVideoModal(data) {
      if (data.videos.length > 0) {
        this.selectedCandidateVideo = data.videos[0];
        this.$bvModal.show("video_modal");
      }
    },
    onApplyFilter() {
      const d = this.appliedFilter.filters.some((f) => f.title == "Country");
      if (d) {
        this.country_id = "";
      } else {
        this.country_id = this.$route.query.country_id;
      }
      this.pagination.current = 1;
      this.getApplicantDetails();
    },
    getApplicantDetails() {
      let payload = this.pagination;
      payload.country_id = this.country_id;
      // Edited By Aman Shrivas Added Condition at line no. 637
      //payload.filter = !this.appliedFilter.filters[0] || this.appliedFilter.filters[0].checkbox_id!==null?this.appliedFilter:{filters:[]}
      console.log("filter", this.appliedFilter);

      let dup_array = [...this.appliedFilter.filters];
      dup_array.map((x, index) => {
        if (x.title == "gender" && x.checkbox == "Any" && x.checkbox_id == "gender_any") {
          dup_array.splice(index, 1);
        }
      });

      // const gender = this.appliedFilter.filters.some((f) => f.title == "gender" && f.checkbox_id == "gender_any");
      payload.filter = !this.appliedFilter.filters[0] || this.appliedFilter.filters[0].checkbox_id !== null ? { filters: dup_array } : { filters: [] };
      // if (gender) {
      //   payload.filter = { filters: [] };
      // }
      // payload.pageSize = payload.pageSize
      console.log("payload", payload);
      this.jobLength = true
      this.loader = this.$loading.show();
      RecruiterJobService.GetSearchApplicants(payload)
        .then((res) => {

          this.candidates = res.data.data.data;
          // this.totalCount = res.data.data.total;
          this.pagination.total = res.data.data.total;
          console.log("this.filters[3]", this.filters[3].title);
          // this.filters[3].title += " (" + res.data.data.companyCurr + ")";
          this.companyCurr = res.data.data.companyCurr;

          console.log(" this.jobLength", this.jobLength);
          this.loader.hide();
          if (this.candidates.length !== 0) {
            this.jobLength = true;
          } else {
            this.jobLength = false;
          }

          console.log(" this.jobLength", this.jobLength);
          // console.log("this.loader", this.loader);
        })
        .catch((e) => {
          this.loader.hide();
          e.message
        });
    },
    showFilters: function () {
      document.querySelector(".isMobileFilter").classList.add("show-filter");
      document.querySelector(".navbar").classList.add("navbar-none");
    },
    hideFilters: function () {
      document.querySelector(".isMobileFilter").classList.remove("show-filter");
      document.querySelector(".navbar").classList.remove("navbar-none");
    },
    removeFromFilter() {
      this.appliedFilter.filters.map((x, index) => {
        if (x.title == "City2") {
          this.appliedFilter.filters.splice(index, 1);
        }
      });
    },
    onError(err) {
      console.log(err);
    },
    onSuccess(err) {
      console.log(err);
    },
    showModal() {
      this.$refs.myModal.show();
    },
    hideModal() {
      this.$refs.myModal.hide();
    },

    resetModal() {
      this.selectedJob = {};
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async onChangeCheckbox(e) {
      let payload = e;
      console.log("onChangeCheckbox", payload);
      // const date_mod = payload.filter((f) => f.title == "date_modified");
      // console.log("date_mod :>> ", date_mod);
      // if (date_mod.length > 1) {
      //   this.appliedFilter.filters.pop();
      // }

      const lastElement = payload[payload.length - 1];

      if (!payload.some((s) => s.title === "Country")) {
        this.cities = [];
        this.filters[1].checkbox = null;
        if (this.selectedCountry && !payload.some((s) => s == this.selectedCountry.checkbox_id)) {
          this.selectedCountry = null;
        }
      }

      payload = payload.map((m) => m.checkbox_id);
      if (lastElement && lastElement.title == "Country") {
        let loader = this.$loading.show();
        try {
          const city = await LocationService.GetAllCityOfCountry(payload);
          this.cities = city.data.data;
          this.filters[1].checkbox = this.cities.map((m) => {
            return {
              title: "City",
              checkbox: m.city_name,
              checkbox_id: m.city_id,
            };
          });
          loader.hide();
        } catch (error) {
          loader.hide();
        }
      }

      //  let loader = this.$loading.show();
      // try {
      //   const city = await LocationService.GetAllCities(payload);
      //
      //   // this.cities = city.data.data;
      //   // const c = await FilterService.GetAllCities();
      //   // this.filters[2].checkbox = c.data.data;
      //   loader.hide();
      // } catch (error) {
      //
      //   loader.hide();
      // }
    },
    handleSubmit() {
      const profile = `${userProfile}${selectedJob.user_id}`;
      setTimeout(() => {
        this.socialLink = true
      }, 6000)
      // this.socialLink = false
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return;
      // }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("share-modal");
      });
    },

    onSelectCity() {
      this.pagination.city_id = null;
    },
    onSelectCountry() {
      const payload = [this.selectedCountry];
      console.log(payload[0]);
      this.country_id = "";
      this.appliedFilter.filters = payload[0] ? payload : [];
      this.onChangeCheckbox(this.appliedFilter.filters);
      console.log(this.appliedFilter.filters);
    },
    // Search by letter
    SearchCountry(input) {
      console.log(input);
      let value = input;
      let regex = new RegExp(`^${value}`, `i`);
      this.searchedC = this.countries.filter((x) => regex.test(x.checkbox)).sort((a, b) => a.checkbox.localeCompare(b.checkbox));
      console.log(searchedC);
    },

    getAllJobs(paginate = {}) {
      return;
    },
    pageChanged(e) {
      console.log(e, this.totalCount);
      // console.log(e.target.ariaLabel);
      // console.log(e.target.ariaPosInSet);
      let pageNumber = e.target.ariaPosInSet;
      console.log("this.pagination.current", this.pagination.current);
      if (e.target.ariaLabel === "Go to first page") {
        console.log("Go to first page");
        this.pagination.current = 1;
        console.log("this.pagination.current", this.pagination.current);
        this.getApplicantDetails();
        this.sort = { label: "Date" };
      }
      if (e.target.ariaLabel === "Go to previous page") {
        console.log("Go to previous page");
        console.log("this.pagination.current", this.pagination.current);
        this.PreviousPage();
      }
      if (e.target.ariaLabel === `Go to page ${pageNumber}`) {
        console.log(`Go to ${pageNumber}`);
        this.pagination.current = pageNumber;
        console.log("this.pagination.current", this.pagination.current);
        this.getApplicantDetails();
        this.sort = { label: "Date" };
      }
      if (e.target.ariaLabel === "Go to next page") {
        console.log("Go to next page");
        console.log("this.pagination.current", this.pagination.current);
        this.nextPage();
      }
      if (e.target.ariaLabel === "Go to last page") {
        console.log("Go to last page");
        this.pagination.current = this.totalCount % 10 === 0 ? this.totalCount / 10 : this.totalCount / 10 + 1;
        console.log("this.pagination.current", this.pagination.current);
        this.getApplicantDetails();
        this.sort = { label: "Date" };
      }
    },
    nextPage() {
      this.pagination.current = this.pagination.current + 1;
      this.candidates = [];
      // this.showModal();
      this.getApplicantDetails();
      this.sort = { label: "Date" };
    },
    PreviousPage() {
      this.pagination.current = this.pagination.current - 1;
      this.candidates = [];
      // this.showModal();
      this.getApplicantDetails();
      this.sort = { label: "Date" };
    },
    // getSkills() {
    //   ApplicantProfileService.getAllSkills().then((skill) => {
    //     this.filters[0].checkbox = skill.data.data;
    //   });
    // },
    getAllSpeciality() {
      ApplicantJobService.GetAllSpeciality()
        .then((res) => {
          const sortedData = res.data.data.sort((a, b) => a.checkbox.localeCompare(b.checkbox));
          console.log("sortedData", sortedData);
          this.filters[2].checkbox = sortedData;
        })
        .catch((e) => console.log("ApplicantJobService.GetAllSpeciality() Error :- ", e.message));
    },
    sortCandidates() {
      if (this.sort.label == "Name") {
        const sorting = function (a, b) {
          if (a.user_username < b.user_username) {
            return -1;
          }
          if (a.user_username > b.user_username) {
            return 1;
          }
          return 0;
        };
        this.candidates.sort(sorting);
      }
      if (this.sort.label == "Date") {
        this.getApplicantDetails();
        // const sortingDate = function(a, b) {
        //   let date1 = moment(a.created_at);
        //   let date2 = moment(b.created_at);
        //   let diff = date2.diff(date1);
        //   if (date2.isAfter(date1)) {
        //     return 1;
        //   } else {
        //     return -1;
        //   }

        //   return 0;
        // };
        // this.days = this.days.sort(function(a, b) {
        //   return a - b;
        // });
        // this.jobs.sort(sortingDate);
      }
    },
    getAllWfhJobs() {
      // this.pagination.wfh = this.wfh;
      // console.log(`this.wfh`, this.wfh);
      //this.getAllJobs(this.pagination);
    },
    getCountries() {
      let loader = this.$loading.show();
      const role_id = this.$cookies.get("role_id");
      FilterService.GetAllCountries({ role_id })
        .then((res) => {
          const { data: countriesData } = res.data;
          const sortedData = countriesData.sort((a, b) => a.checkbox.localeCompare(b.checkbox));
          console.log("sortedData", sortedData);

          const countries = [
            Object.assign(
              {},
              {
                checkbox: "Select Any",
                checkbox_id: null,
                title: "Country",
              }
            ),
            ...sortedData,
          ];
          this.countries = countries;
          this.filters[0].checkbox = sortedData;
          loader.hide();
        })
        .catch((err) => {
          console.log("GetAllCountries Error:- ", err.message);
          loader.hide();
        });
    },
    async getCities(payload) {
      // let loader = this.$loading.show();
      // try {
      //   const city = await LocationService.GetAllCities(payload);
      //
      //   this.cities = city.data.data;
      //   const c = await FilterService.GetAllCities();
      //   this.filters[2].checkbox = c.data.data;
      //   loader.hide();
      // } catch (error) {
      //
      //   loader.hide();
      // }
    },
    getSelectedSubscription() {
      const user_id = this.$cookies.get("user_id");
      let loader = this.$loading.show();
      CompanyServices.getUserSubscription(user_id).then((res) => {
        this.selectedSub = res.data.data[0];
        // this.selectedSub.subscription_type_id == 1
        //   ? (this.activesub = "Monthly")
        //   : (this.activesub = "Yearly");
        if (this.selectedSub) {
          this.selectedSub.subscription_end_date_timestamp = moment.unix(this.selectedSub.subscription_end_date_timestamp).format("YYYY-MM-DD HH:mm:ss");
        }
      });
      loader.hide();
    },
    getIndustry() {
      let loader = this.$loading.show();
      FilterService.GetAllIndustry()
        .then((i) => {
          // this.filters[4].checkbox = i.data.data;
          // this.filters[4].checkbox = i.data.data.map((ind) => {
          //   return {
          //     title: "industry",
          //     checkbox: ind.industry_name,
          //     checkbox_id: ind.industry_id,
          //   };
          // });
          loader.hide();
        })
        .catch((err) => {
          loader.hide();
        });
    },
    // getCities(payload) {
    //   LocationService.GetAllCities(payload).then((city) => {
    //     this.cities = city.data.data.map((city) => {
    //       return {
    //         label: city.city_name,
    //         city_id: city.city_id,
    //       };
    //     });
    //   });
    // },
    getAllBookmarkJobs() {
      const user_id = this.$cookies.get("user_id");
      if (!this.bookmarkFilter) {
        this.showModal();
        this.jobs = [];
        let loader = this.$loading.show();
        ApplicantJobService.GetBookmark({ user_id })
          .then(async (res) => {
            const data = await res.data.data.forEach((job, index) => {
              if (job.job_active === "Y") {
                // job.applied = true;
                job.bookmark = true;
                this.jobs.push(job);
              }
            });
            this.jobs = this.jobs.map((m) => {
              return {
                ...m,
                deadline: moment(m.job_end_date).format("DD/MM/YYYY"),
              };
            });
            loader.hide();
            //  this.getAppliedJobs();
            this.daysAgo();
            this.hideModal();
          })
          .catch((err) => {
            loader.hide();
          });
      } else {
        this.jobs = [];
        this.getAllJobs(this.pagination);
        this.hideModal();
      }
    },
    getJobsBookMarkv2() {
      const user_id = this.$cookies.get("user_id");
      if (this.bookmarkFilter) {
        this.showModal();
        this.jobs = [];
        ApplicantJobService.GetBookmark({ user_id }).then(async (res) => {
          const data = await res.data.data.forEach((job, index) => {
            if (job.job_active === "Y") {
              job.applied = true;
              job.bookmark = true;
              this.jobs.push(job);
            }
          });

          this.jobs = this.jobs.map((m) => {
            return {
              ...m,
              deadline: moment(m.job_end_date).format("DD/MM/YYYY"),
            };
          });
          // this.getAppliedJobs();
          this.daysAgo();
          this.hideModal();
        });
      }
      // else {
      //   this.jobs = [];
      //   this.getAllJobs(this.pagination);
      //   this.hideModal();
      // }
    },

    AddBookmark(job_id, index) {
      const user_id = this.$cookies.get("user_id");
      ApplicantJobService.AddBookmark({ user_id, job_id })
        .then((res) => {
          if (res.data.data.bookmark_active == "Y") {
            this.jobs[index].bookmark = true;
          } else {
            this.jobs[index].bookmark = false;
            this.getJobsBookMarkv2();
          }
        })
        .catch((err) => {
          // this.getSingleBookmark(user_id, job_id);
          // this.jobs[index].bookmark = false;
        });
    },

    getAllBookmark() {
      const user_id = this.$cookies.get("user_id");
      ApplicantJobService.GetBookmark({ user_id }).then((res) => {
        const bm = res.data.data;
        this.jobs.forEach((job, index) => {
          bm.forEach((b) => {
            if (job.job_id == b.job_id) {
              this.jobs[index].bookmark = true;
            } else {
              //console.log("hitting :>> ", job.job_id == b.job_id);
              //this.jobs[index].bookmark = false;
            }
          });
        });
      });
    },
    // getQuery() {
    //   this.pagination.searchText = this.search;
    //   this.pagination.city_id = this.city?.city_id;
    //   this.jobs = [];
    //   ApplicantJobService.GetFilteredJobs(this.pagination).then(async (res) => {
    //     this.jobs = [];
    //     const data = await res.data.data.forEach((job, index) => {
    //       if (job.job_active === "Y") {
    //         job.applied = true;
    //         this.jobs.push(job);
    //       }
    //     });
    //     this.getAppliedJobs();
    //   });
    // },
    onApplyJob(id) {
      if (this.$cookies.get("role_id") == 3) {
        this.$router.push({
          name: "Job-Application",
          params: { job_id: id },
        });
      } else {
        this.$router.push({ name: "Register" });
      }
    },
    daysAgo() {
      this.jobs.forEach((job) => {
        let now = moment().format("YYYY-MM-DD");
        let jobCreated = moment(job.job_start_date);
        let diff = jobCreated.diff(now, "day") * -1;
        this.days.push(diff);
      });
    },
    getAppliedJobs() {
      this.pagination.user_id = this.$cookies.get("user_id");
      if (this.$cookies.get("role_id") == 3) {
        let loader = this.$loading.show();
        ApplyJobService.GetAllAppliedJobs(this.pagination)
          .then((res) => {
            this.appliedJobs = res.data.data;

            this.appliedJobs.forEach((aJob, index) => {
              this.jobs.forEach((j, i) => {
                if (j.job_id == aJob.job_id) {
                  this.jobs[i].applied = false;
                }
              });
            });
            loader.hide();
          })
          .catch((err) => {
            loader.hide();
          });
      }
    },
    share(job) {
      console.log(job);
      this.selectedJob = job;
      this.$bvModal.show("share-modal");
    },
    resetFilters() {
      this.appliedFilter = { filters: [] };
      this.bookmarkFilter = null;
    },
    // appliedFilters() {
    //   this.appliedFilter.user_id = this.$cookies.get("user_id");
    //   this.appliedFilter.role_id = this.$cookies.get("role_id");
    //   Object.assign(this.appliedFilter, this.pagination);
    //   ApplicantJobService.GetAllJobs(this.appliedFilter).then(async (res) => {
    //     this.jobs = [];
    //     this.totalCount = res.data.data.totalCount;
    //     this.bookmarkFilter = false;
    //     const data = await res.data.data.data.forEach((job, index) => {
    //       if (job.job_active === "Y") {
    //         job.applied = true;
    //         this.jobs.push(job);

    //         this.getAppliedJobs();
    //       }
    //     });

    //     this.role_id && this.role_id == 3 ? this.getAllBookmark() : null;
    //   });
    // },
    applyJob(job_id, index) {
      const user_id = this.$cookies.get("user_id");
      const token = this.$cookies.get("AIAPADHASHID");
      const role_id = this.$cookies.get("role_id");
      const payload = { user_id: user_id, job_id: job_id };
      if (token && role_id == 3) {
        ApplyJobService.ApplyJob(payload)
          .then((res) => {
            // this.$bvModal.show("successPage");
            this.jobs[index].applied = true;
            this.$toasted.show("Job Applied Successfully!", {
              position: "top-right",
              duration: 6000,
              icon: "check",
            });
            //this.$router.push({ name: "Job-applied-successfully" });
          })
          .catch((err) => {
            this.jobs[index].applied = false;
            this.$toasted.show("You have already applied for this job", {
              position: "top-right",
              duration: 6000,
              icon: "clear",
            });
          });
      } else {
        this.$router.push("/register");
      }
    },

    showFilters: function () {
      document.querySelector(".isMobileFilter").classList.add("show-filter");
      document.querySelector(".navbar").classList.add("navbar-none");
    },
    hideFilters: function () {
      document.querySelector(".isMobileFilter").classList.remove("show-filter");
      document.querySelector(".navbar").classList.remove("navbar-none");
    },
  },
};
</script>

<style>
.app-job .share-text {
  align-items: start;
}

.multiselect-dropdown span {
  margin-right: 10px;
}

.search-setting {
  display: none;
}

.my-d-none {
  display: block;
}

.for-mob {
  display: none !important;
  ;
}

@media only screen and (max-width: 768px) {
  .for-desktop {
    display: none !important;
    ;
  }

  .for-mob {
    display: inline !important;
    ;
  }

  #filter1 {
    display: none;
  }

  #filter-modal___BV_modal_header_ {
    display: none;
  }

  #filter-modal___BV_modal_footer_ {
    display: none;
  }

  .modal-content {
    position: fixed !important;
    ;
    display: flex;
    flex-direction: column;
    bottom: -1px !important;
    width: 101% !important;
    left: -2px !important;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }

  .Line29 {
    width: 50px;
    /* height: 0; */
    left: 40%;
    text-align: center;
    /* top: 9px; */
    position: relative;
    border: 2px #E5E5EA solid;
  }

  .search-setting {
    display: block;
  }

  .my-d-none {
    display: none !important;
  }

  .my-d-block {
    /* display: block !important; */
    display: -webkit-inline-box !important;
  }

  .my-d-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .item-left {
    text-align: left !important;
  }

  .item-right {
    text-align: right !important;
    padding-right: 10px;
  }

  .job-post-card .job-post-footer {
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  .job-post-card .right .btn-job-details {
    font-size: 10px !important;
  }

  .job-post-card .job-post-footer .right {
    grid-gap: 0px !important;
  }

  .fade-in-bottom {
    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-9-1 16:33:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
  @-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }


}
</style>

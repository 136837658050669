import Api from "./Api";
import { API_VERSION } from "@/config/index";

export default {
  ApplyJob(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/apply-job`, payload);
  },
  GetAllAppliedJobs(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/get-apply-job?currentpage=${payload.current}&perpage=${payload.pageSize}&search=${payload.search}`, payload);
  },
  GetPrefilledQA(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/get-job-suggested-question-ans/${payload.job_id}`);
  },
};

<template>
  <!-- <div class="" style="height: 100%;"> -->
  <b-container style="background-color: #eaeaea;" fluid class="p-0 m-0 mt-5 msg-pd-b my-bg-grey">
    <b-row style="height: 68vh;" class="px-0 no-msg-m     ">
      <b-col v-if="applicants && applicants.length" class="col-md-4 bg-white rounded-lg py-3 for-desktop">
        <AllAppliedApplicants :applicants="applicants" :user_id="app_id" @selectedUser="getSelectedApp" />
      </b-col>
      <b-col :class="isMobView ? 'disp-none' : 'for-mob'" v-if="applicants && applicants.length"
        class="col-md-4 bg-white rounded-lg py-3">
        <AllAppliedApplicants :applicants="applicants" :user_id="app_id" @selectedUser="getSelectedApp" />
      </b-col>
      <b-col class="no-msg" v-else>
        <span>No messages found</span>
      </b-col>
      <b-col class="col-md-8 for-desktop" v-show="Object.keys(appDetails).length">
        <div style="height: 68vh;" class="bg-white pb-3 rounded-lg">
          <div class="px-2 py-3 d-flex align-items-center justify-content-between border-bottom">
            <div class="d-flex align-items-center">
              <div class="top-company-img">
                <img v-if="appDetails && appDetails.user_job_profile_image"
                  :src="image_url + appDetails.user_job_profile_image" width="100%" />
                <img src="@/assets/unknown.jpg" width="100%" v-if="!appDetails.user_job_profile_image" />
              </div>
              <div class="pl-2">
                <h5 class="mb-0">{{ appDetails.user_username }}</h5>
                <p class="mb-0">{{ appDetails.user_job_title }}</p>
              </div>
            </div>
            <!-- <span class="close-chat">CLOSE CHAT</span> -->
            <button id="Chat-ED" class="btn chat-toggle-btn" @click="enableDisableChat">{{ chatEnable ?
              $gettext("DISABLE_CHAT") : $gettext("ENABLE_CHAT") }}</button>

            <div class="view-profile-container" @click="
              $router.push({
                name: 'Applicant-Profile-Employer',
                params: { user_id: app_id },
                query: { shortlist: '' },
              })
              ">
              <span>{{ $gettext("VIEW_PROFILE") }}</span>
              <img width="10px" src="@/assets/right-arrow-icon.png" alt="" />
            </div>
          </div>
          <div style="overflow: auto; height:74%;" class="py-3">
            <div v-for="(m, i) of msg" :key="i">
              <div class="d-flex w-100 px-2"
                :class="m.app_msg_status == 'recieved' ? 'justify-content-end' : 'justify-content-start'">
                <div class="text-img" v-if="m.app_msg_status != 'recieved'">
                  <img v-if="appDetails && appDetails.user_job_profile_image"
                    :src="image_url + appDetails.user_job_profile_image" width="100%" />
                  <img src="@/assets/unknown.jpg" width="100%" v-if="!appDetails || !appDetails.user_job_profile_image" />
                </div>
                <div class="d-flex flex-column" :class="m.app_msg_status == 'recieved' ? 'mr-1' : 'ml-1'">
                  <div class="py-2 px-3 bg-light-dark text-message d-flex"
                    :class="m.app_msg_status == 'recieved' ? 'border-radius-left' : 'border-radius-right'">
                    <span>{{ m.message }}</span>
                  </div>
                  <span class="time mt-1">{{ convertTime(m.ago) }} ago</span>
                </div>

                <div class="text-img" v-if="m.app_msg_status == 'recieved'">
                  <img v-if="$store.getters.getProfileImage" :src="`${image_url}${$store.getters.getProfileImage}`"
                    width="100%" />
                  <img v-if="!$store.getters.getProfileImage" src="@/assets/unknown.jpg" width="100%" />
                </div>
              </div>
              <!-- {{ m.message }}
                <br />
                <span class="display-block">{{ m.ago }}m</span> -->
            </div>
          </div>
          <div class="message-box">
            <input :placeholder="$gettext('Type_your_message')" type="text" class="" v-model="message" />
            <button class="btn btn-primary send-btn" @click="sendMessage">
              <img src="@/assets/app-icons/send.svg" alt="" />
            </button>
          </div>
        </div>
      </b-col>
      <b-col :class="!isMobView ? 'disp-none' : 'for-mob'" class="col-md-12" v-show="Object.keys(appDetails).length">
        <div
          style="color: #000;font-size: 18px;font-style: normal;font-weight: 600;line-height: 28.8px; text-transform: uppercase;">
          <span @click="goBack">&lt; Back</span>
        </div>
        <div style="height: 68vh;" class="bg-white pb-3 rounded-lg">
          <div style="border-top-left-radius: 5px;border-top-right-radius: 5px;"
            class="bg-primary px-2 py-3 d-flex align-items-center justify-content-between border-bottom">
            <div class="d-flex align-items-center">
              <div class="top-company-img">
                <img v-if="appDetails && appDetails.user_job_profile_image"
                  :src="image_url + appDetails.user_job_profile_image" width="100%" />
                <img src="@/assets/unknown.jpg" width="100%" v-if="!appDetails.user_job_profile_image" />
              </div>
              <div class="pl-2">
                <h5 style="color: #ffffff; text-transform: capitalize; font-weight: 700;" class="mb-0">{{
                  appDetails.user_username }}</h5>
                <p style="color: #ffffff; text-transform: capitalize;" class="mb-0">{{
                  appDetails.user_job_title }}</p>
              </div>
            </div>
            <!-- <span class="close-chat">CLOSE CHAT</span> -->
            <!-- <button id="Chat-ED" class="btn chat-toggle-btn" @click="enableDisableChat">{{ chatEnable ?
                $gettext("DISABLE_CHAT") : $gettext("ENABLE_CHAT") }}</button>

              <div class="view-profile-container" @click="
                $router.push({
                  name: 'Applicant-Profile-Employer',
                  params: { user_id: app_id },
                  query: { shortlist: '' },
                })
                ">
                <span>{{ $gettext("VIEW_PROFILE") }}</span>
                <img width="10px" src="@/assets/right-arrow-icon.png" alt="" />
              </div> -->

            <b-dropdown id="msg-menu" variant="white" offset="25" dropleft no-caret>
              <template #button-content>
                <!-- <img width="10px" src="@/assets/app-icons/ellipsis-v-solid.svg" alt="" /> -->
                <i class="fa fa-ellipsis-v" style=" font-size:25px;color: #ffffff;"></i>
              </template>

              <b-dropdown-item>
                <div class="view-profile-container" @click="
                  $router.push({
                    name: 'Applicant-Profile-Employer',
                    params: { user_id: app_id },
                    query: { shortlist: '' },
                  })
                  ">
                  <div>
                    <span>
                      <img width="30px" src="@/assets/app-icons/user_profile.svg" alt="" />
                    </span>
                    <span> {{ $gettext("VIEW_PROFILE") }}</span>
                  </div>
                  <!-- <img width="10px" src="@/assets/right-arrow-icon.png" alt="" /> -->
                </div>
              </b-dropdown-item>

              <b-dropdown-item href="#">

                <div>
                  <span>
                    <img width="30px" src="@/assets/app-icons/disable_chat.svg" alt="" />
                  </span>
                  <button id="Chat-ED" class="btn chat-toggle-btn" @click="enableDisableChat">{{
                    chatEnable ?
                    $gettext("DISABLE_CHAT") : $gettext("ENABLE_CHAT") }}</button>
                </div>

              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div style="overflow:auto; height: 76%;" class="py-3">
            <div v-for="(m, i) of msg" :key="i">
              <div class="d-flex w-100 px-2"
                :class="m.app_msg_status == 'recieved' ? 'justify-content-end' : 'justify-content-start'">
                <div class="text-img" v-if="m.app_msg_status != 'recieved'">
                  <img v-if="appDetails && appDetails.user_job_profile_image"
                    :src="image_url + appDetails.user_job_profile_image" width="100%" />
                  <img src="@/assets/unknown.jpg" width="100%" v-if="!appDetails || !appDetails.user_job_profile_image" />
                </div>
                <div class="d-flex flex-column" :class="m.app_msg_status == 'recieved' ? 'mr-1' : 'ml-1'">
                  <div :style="m.app_msg_status === 'recieved' && 'background-color:#6244e5;color:#fff;'"
                    class="py-2 px-3 bg-light-dark text-message d-flex"
                    :class="m.app_msg_status == 'recieved' ? 'border-radius-left' : 'border-radius-right'">
                    <span>{{ m.message }}</span>
                  </div>
                  <span class="time mt-1">{{ convertTime(m.ago) }} ago</span>
                </div>

                <div class="text-img" v-if="m.app_msg_status == 'recieved'">
                  <img v-if="$store.getters.getProfileImage" :src="`${image_url}${$store.getters.getProfileImage}`"
                    width="100%" />
                  <img v-if="!$store.getters.getProfileImage" src="@/assets/unknown.jpg" width="100%" />
                </div>
              </div>
              <!-- {{ m.message }}
                <br />
                <span class="display-block">{{ m.ago }}m</span> -->
            </div>
          </div>
          <div class="message-box">
            <input @keydown.enter="sendMessage" :placeholder="$gettext('Type_your_message')" type="text" class=""
              v-model="message" />
            <button class="btn btn-primary send-btn" @click="sendMessage">
              <img src="@/assets/app-icons/send.svg" alt="" />
            </button>
          </div>
        </div>
      </b-col>

    </b-row>
  </b-container>
  <!-- </div> -->
</template>

<script>
import AllAppliedApplicants from "./AllAppliedApplicants.vue";
import RecruiterJobService from "@/apiServices/RecruiterJobService";
import moment from "moment";
import { IMAGEURL } from "@/config";
import { convertTime } from "@/helpers/HelperFunctions.js";

export default {
  components: {
    AllAppliedApplicants,
  },
  data() {
    return {
      isMobView: false,
      newMessage: false,
      chatEnable: true,
      msg: [],
      image_url: IMAGEURL,
      recieved: [],
      message: null,
      app_id: null,
      job_id: null,
      appDetails: {},
      interval: null,
      // applicants: [],
      applicants: [],
      tabIndex: 0,
    };
  },
  beforeMount() {
    if (this.$route.query.user_id && this.$route.query.user_id != "") {
      this.app_id = +this.$route.query.user_id;
    }
    this.callApi();
  },
  mounted() {
    this.requestNotificationPermission();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    goBack() {
      this.isMobView = !this.isMobView
    },
    async callApi() {
      let loader = this.$loading.show();
      await this.getApplicants();
      await this.fetchAllMsg();

      setTimeout(() => {
        loader.hide();
      }, 2000);
    },

    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-transparent", "text-primary"];
      } else {
        return ["bg-light", "text-info"];
      }
    },
    async getApplicants() {
      // console.log("----dfgfdd-----", this.$store.getters.getProfileImage);
      // console.log("----dfgfdd-----", this.$store.getters.getProfileImage);
      RecruiterJobService.GetAppliedApplicantForMessage().then((res) => {
        this.applicants = res.data.data;
      });
    },
    convertTime: convertTime,
    async fetchAllMsg() {
      this.interval = setInterval(() => {
        // this.getSelectedApp({ user_id: this.app_id, job_id: this.job_id });

        const app_id = this.app_id;
        RecruiterJobService.GetApplicantMessage({
          app_id,
          job_id: this.job_id,
        }).then((res) => {
          this.msg = res.data.data.senderReceiver.map((m) => {
            const now = moment();
            const created = moment(m.created_at).format("YYYY-MM-DD HH:mm:ss");
            let diff = now.diff(created, "minutes");
            this.chatActive();
            return {
              ...m,
              ago: diff,
            };
          });

        });
      }, 5000);
    },
    // //@developer:Aman Shrivas : chat notification functionalities @start
    requestNotificationPermission() {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted!');
          }
        });
      }
    },
    // handleNewChatMessage(message) {
    //   // Your logic to handle the new chat message
    //   // For this example, we will just set a flag to indicate a new message has arrived
    //   this.newMessage = true;

    //   // Trigger a browser notification if the permission has been granted
    //   if (Notification.permission === 'granted') {
    //     // const newMessage = {
    //     //   sender: 'John',
    //     //   timestamp: '2023-07-29 12:30 PM',
    //     //   content: 'Hello, how are you?',
    //     // };
    //     this.showNotification('New Chat Message', message);
    //   }
    // },
    // showNotification(title, body) {
    //   const notification = new Notification(title, { body });
    //   notification.onclick = () => {
    //     // Handle the click event when the user clicks on the notification
    //     // E.g., redirect them to the chat window
    //     // window.location.href = '/chat';
    //   };
    // },
    // getNewChatMessage() {
    //   // Replace this with your logic to fetch new chat messages from the server
    //   // For this example, we will return a sample message every time it's called
    //   return "This is a new chat message!";
    // },
    // //@developer:Aman Shrivas : chat notification functionalities @end
    getSelectedApp(e) {

      this.appDetails = e;
      this.app_id = e?.user_id;
      this.job_id = e?.job_id;
      const app_id = e?.user_id;
      RecruiterJobService.GetApplicantMessage({
        app_id,
        job_id: this.job_id,
      }).then((res) => {
        this.msg = res.data.data.senderReceiver.map((m) => {
          const now = moment();
          const created = moment(m.created_at).format("YYYY-MM-DD HH:mm:ss");
          let diff = now.diff(created, "minutes");
          return {
            ...m,
            ago: diff,
          };
        });
        this.isMobView = true;
        // this.recieved = res.data.data.recievedMessage.map((m) => {
        //   const now = moment();
        //   const created = moment(m.created_at).format("YYYY-MM-DD HH:mm:ss");
        //   let diff = now.diff(created, "minutes");
        //   return {
        //     ...m,
        //     ago: diff,
        //   };
        // });
      });
    },
    getNewMessages(e) {
      RecruiterJobService.GetApplicantMessage({
        app_id: e.user_id,
        job_id: e.job_id,
      }).then((res) => {
        this.msg = res.data.data.senderReceiver.map((m) => {
          const now = moment();
          const created = moment(m.created_at).format("YYYY-MM-DD HH:mm:ss");
          let diff = now.diff(created, "minutes");
          return {
            ...m,
            ago: diff,
          };
        });
      });
    },

    sendMessage() {
      if (!this.app_id && !this.job_id) {
        this.$toasted.show("please select applicant to talk", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }
      if (!this.message || this.message == "") {
        return;
      }
      const payload = {
        reciever_id: this.app_id,
        message: this.message,
        job_id: this.job_id,
      };

      console.log("payload", payload);
      RecruiterJobService.SendMessage(payload).then((res) => {
        this.message = null;
        this.getNewMessages({ user_id: this.app_id, job_id: this.job_id });
      });
    },
    enableDisableChat() {
      this.chatEnable = !this.chatEnable;
      if (!this.app_id && !this.job_id) {
        this.$toasted.show("Can not perform this action at this moment", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }

      const payload = {
        reciever_id: this.app_id,

        job_id: this.job_id,
      };
      RecruiterJobService.enableDisableChat(payload).then((res) => {
        this.$toasted.show("Action Performed", {
          duration: 6000,
          icon: "check",
        });
      });
    },

    chatActive() {
      if (!this.app_id && !this.job_id) {
        this.$toasted.show("Can not perform this action at this moment", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }

      const payload = {
        reciever_id: this.app_id,
        job_id: this.job_id,
      };
      RecruiterJobService.chatActive(payload).then((res) => {
        if (res.data.data.length > 0) {
          this.chatEnable = res.data.data[0].is_active === "Y" ? true : false;
        } else {
          // this.chatEnable =
          document.getElementById("Chat-ED").style.display = "none";
        }

        console.log("---------------res data------------", res.data.data[0].is_active);
      });
    },
  },
};
</script>

<style scoped>
.bg-light-dark {
  background: #eaeaea;
}

.text-message {
  width: 250px;
  /* margin: 10px; */
  overflow: hidden;
  display: block;
}

.margin-left {
  margin-left: auto;
}

.inbox-title-container {
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
}

.inbox-title-container span {
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.border-radius-right {
  border-radius: 15px;
  border-top-left-radius: 0;
}

.border-radius-left {
  border-radius: 15px;
  border-top-right-radius: 0;
}

.time {
  font-size: 0.75rem;
  opacity: 0.7;
}

.text-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
}

.text-img img {
  object-fit: cover;
  object-position: 50% 50%;
}

.top-company-img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
}

.top-company-img img {
  object-fit: cover;
  object-position: 50% 50%;
}

.view-profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.view-profile-container span {
  font-size: 1.1rem;
  font-weight: bold;
  margin-right: 10px;
}

.view-profile-container:hover {
  cursor: pointer;
}

.message-box {
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #eaeaea;
  margin: 0 10px;
  box-sizing: border-box;
  margin-block: 5px 10px;
}

.chat-toggle-btn {
  font-weight: bold;
  background: rgba(0, 0, 0, 0.061);
}

.message-box input {
  border: none;
  width: 100%;
  padding: 10px;
  background: #eaeaea;
}

.message-box input:focus-visible {
  outline: none;
}

.send-btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
  padding: 0;
  margin-right: 4px;
  /* border: 0;
  background: #6244e5; */
}

.send-btn img {
  width: 15px;
  object-fit: contain;
}

/* New Styles */

.text-primary {
  color: #6244e5 !important;
}

.my-bg-grey {
  background-color: #eaeaea;
}

.for-mob {
  display: none !important;
}

.msg-pd-b {
  padding-block: 3.2rem !important;
}


.disp-none {
  display: none !important;
  ;
}

@media only screen and (max-width:768px) {


  .msg-pd-b {
    padding-block: 1.5rem !important;
  }

  .for-desktop {
    display: none !important;
    ;
  }

  .for-mob {
    display: block !important;
    ;
  }

  .no-msg {
    padding: 10px;
  }

  .my-bg-grey {
    background-color: white;
  }

  .no-msg-m {
    margin: 20px !important;
  }

  .no-msg {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    text-align: center;
    background: #eaeaea;
    font-size: 40px;
    font-weight: bold;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }

  .message-box {
    margin-block: 14px 11px !important;
  }
}
</style>

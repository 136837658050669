import Api from "./Api";
import { API_VERSION } from "@/config/index";

export default {
  GetAllJobs(payload, filter, wfh) {
    return Api().post(
      `/${API_VERSION}/webapp/applicant/job/${payload.role_id}/${payload.user_id}?currentPage=${payload.current}&perPage=${payload.pageSize}&search=${payload.search ? payload.search : ""}&city_id=${payload.city_id}&featuredJobs=${payload.feature}&currency_id=${payload.currency_id}`,
      { filter, wfh }
    );
  },
  GetSingleJob(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/job/${payload.job_id}/${payload.user_id}/${payload.role_id}`);
  },
  GetFilteredJobs(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/filtered-jobs`, payload);
  },
  AddApplicantProfile(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/job-profile`, payload);
  },
  UpdateApplicantProfile(payload) {
    return Api().put(`/${API_VERSION}/webapp/applicant/job-profile`, payload);
  },
  GetSingleApplicantProfile(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/job-profile`);
  },
  GetJobApplication(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/job-application/${payload.job_id}`);
  },
  AddJobAnswer(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/job-answer`, payload);
  },
  GetApplicantAppliedJob(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/get-applied-job-details/${payload.job_id}?temp_id=${payload.temp_id}`);
  },
  GetFilteredAppliedJobs(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/get-filtered-applied-jobs`, payload);
  },
  AddBookmark(payload) {
    return Api().post(`/${API_VERSION}/webapp/applicant/bookmark`, payload);
  },
  GetBookmark(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/bookmark`);
  },
  GetSingleBookmark(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/single-bookmark?&job_id=${payload.job_id}`);
  },
  GetFeaturedJobs(payload, page) {
    return Api().post(`/${API_VERSION}/webapp/applicant/get-featured-jobs/${payload.user_id}?&currentPage=${page.current}&pageSize=${page.pageSize}&user_id=${payload.user_id ? payload.user_id : ""}&country_id=${payload.country_id ? payload.country_id : ""}`, payload);
  },
  GetApplicantJobsByLocation(payload) {
    return Api().get(`/${API_VERSION}/webapp/applicant/jobs-by-applicant-location/${payload.country_id}/${payload.city_id}`);
  },
  GetAllSpeciality() {
    return Api().get(`/${API_VERSION}/webapp/speciality`);
  },
  GetAppliedJobsForMessage() {
    return Api().get(`/${API_VERSION}/webapp/get-applied-jobs`);
  },
  GetRecommendedJobs() {
    return Api().get(`/${API_VERSION}/webapp/applicant/recommended-jobs`);
  },
};

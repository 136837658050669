import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSocialSharing from "vue-social-sharing";
import Slick from "vue-slick";
import Toasted from "vue-toasted";
import store from "./store";
// import candidateStore from "./store/index.js";
// import employerStore from "./store/indexEmp.js";

import Notifications from "vue-notification";
import RadialProgressBar from "vue-radial-progress";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { BForm } from "bootstrap-vue";
import VueCookie from "vue-cookies";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";


// @Localization start 

import GetTextPlugin from 'vue-gettext'
import english from "./locales/en";
import swahili from "./locales/sw";

Vue.use(GetTextPlugin, {
  availableLanguages: {
    en: 'English',
    sw: 'Swahili'
  },
  defaultLanguage: 'en',
  translations: {
    en: english,
    sw: swahili
  }
})




// @Localization end

Vue.component("date-picker", DatePicker);

// import VueConfirmDialog from "vue-confirm-dialog";
// Vue.use(VueConfirmDialog);
// Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

import VueSimpleSuggest from "vue-simple-suggest/lib";
Vue.component("vue-simple-suggest", VueSimpleSuggest);

//video player
import VueCoreVideoPlayer from "vue-core-video-player";

//import loader
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

// import Toasted from "vue-toasted";

// Plugins
import headMixin from "@/utils/head";
import Mixin from "@/utils/utilMixin";
import CustomMultiselect from "@/plugins/customMultiselect";
// import Notifications from "@/plugins/Notifications";
import "@/plugins/OutSideClickEvents/clickEvents";
import "vue-select/dist/vue-select.css";

// Common Component Plugins
import EmptyState from "@/plugins/EmptyState/EmptyState";
import vSelect from "vue-select";

// CSS imports
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "@/assets/css/candidate-common.css";
// import "@/assets/css/user-onboarding.css";
// import "@/assets/css/user-profile.css";
// import "@/assets/css/emp-profile.css";
// import "@/assets/css/home.css";
// import "@/assets/css/newstyle.css";
// import "@/assets/css/responsive.css";
// import "@/assets/css/main.css";
// import "@/assets/css/app-profile.css";
// import "@/assets/css/new-style.css";

import { ModalPlugin } from "bootstrap-vue";
Vue.use(ModalPlugin);

import VueTypedJs from "vue-typed-js";
Vue.use(VueTypedJs);

import VueClipboards from "vue-clipboards";
Vue.use(VueClipboards);

Vue.use(VueCoreVideoPlayer);
// Vue.use(VueSocialSharing);
Vue.use(VueSocialSharing, {
  networks: {
    fakeblock: 'https://www.instagram.com/',
    newBlock: 'https://www.instagram.com/'
    // fakeblock: 'https://www.instagram.com/share?url=@url&title=@title'
    // fakeblock: 'https://www.instagram.com/share?url=@url&title=@title'
  }
})
import "vue-social-sharing/dist/vue-social-sharing.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/**
 * VUE GLOBAL FILTERS
 */
import "@/filters/filters";

// import "@/permissionControl/permission"; // permission control

/**
 * USED PLUGINS
 */
import { extend, configure } from "vee-validate";
import { required, email, confirmed, max, min, numeric } from "vee-validate/dist/rules";

// Install classes
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});
extend("Email", {
  validate(value) {
    const EmailREG = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    return EmailREG.test(value);
  },
  message: "The field must be e-mail",
  computesRequired: true,
});
// Validation for mobile number for specific country
extend("Phone", {
  validate(value) {

    const MOBILEREG = /^(?:\+256|\+255|\+254)\d{9}/;
    const MOBILEREG_WithoutPlus = /^(256|255|254)\d{9}$/;
    return MOBILEREG.test(value) || MOBILEREG_WithoutPlus.test(value)
  },
  message: "We will be coming to your country soon",
  computesRequired: true,
});
extend("Industry", {
  validate(value) {
    console.log(4 > value.length > 0);

    return 4 > value.length > 0
  },
  message: "Industry must be min 1 and max 3",
  computesRequired: true,
});
extend("Skill", {
  validate(value) {
    console.log(value.length > 2 && value.length < 11);

    return value.length > 2 && value.length < 11
  },
  message: "Skill must be min 3 and max 10",
  computesRequired: true,
});

extend("phoneOrEmail", {
  validate(value) {
    // console.log("value", value);
    // Custom regex for a phone number
    const MOBILEREG = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const EmailREG = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    return MOBILEREG.test(value) || EmailREG.test(value);
  },
  message: "The field must be either a valid phone number or e-mail",
  computesRequired: true,
});
extend("required", {
  ...required,
  message: "This field is required",
});
extend("max", {
  ...max,
  message: "You have reached max limit",
});
extend("min", {
  ...min,
  message: "Add atleast 8 characters",
});
extend("min-4", {
  ...min,
  message: "Please enter valid phone number",
});
//Radio button validator
extend("radioValidate", {
  ...required,
  message: " mcq is required",
});
extend("radioValidate", {
  validate(value) {
    return {
      required: true,
      valid: true, // ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true,
});
// Add the email rule
extend("email", {
  ...email,
  message: "This field must be a valid email",
});
//Password validator
extend("confirmed", {
  ...confirmed,
  message: "Password not matched",
});
//numeric number
extend("numeric", {
  ...numeric,
  message: "This field must be a valid phone number",
});
Vue.use(VueCookie);
Vue.use(Notifications);
Vue.use(Toasted, {
  iconPack: "material",
  position: "top-center",
  theme: "bubble",
});
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component("v-select", vSelect);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("b-form", BForm);
Vue.component("slick", Slick);

// Plugins
// Vue.mixin(headMixin)
// Vue.mixin(Mixin);
Vue.component("custommultiselect", CustomMultiselect);
Vue.component("EmptyState", EmptyState);

Vue.config.productionTip = false;

Vue.use(Loading, {
  loader: "spinner",
  color: "#6244e5",
  width: 70,
  zIndex: 1019,
  height: 70,
  // backgroundColor: "#4b4b4b"
});

//unregister service worker
if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

new Vue({
  router,
  // candidateStore,
  // employerStore,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div class="bg-grey pt-5 mt-2">
    <!-- <EmployerHeader /> -->
    <div class="container-fluid profile-user emp-job-vacancy px-1 px-md-3 pb-2">
      <div class="search-strip mt-4">
        <div class="selector-wrapper">
          <img src="@/assets/app-icons/search.svg" width="15px" />
          <input type="text" class="form-control" v-model="filters.job_title" :placeholder="$gettext('Search_Jobs')" />
          <!-- <img @click="toggleSearchSetting" class="search-setting" src="@/assets/app-icons/search-setting.svg" alt=""
            height="40px" width="30px" /> -->
          <button @click="() => { searchJob = true; getJobs(); }" class="btn btn-primary for-mob"><b-icon
              icon="search"></b-icon></button>
        </div>
        <div class="search-button for-desktop" :class="searchSetting ? 'search-btn-d-block' : 'my-d-none'">
          <button class="btn" @click="() => {
            searchJob = true;
            getJobs();
          }
            ">
            {{ $gettext("Search") }}
          </button>
        </div>
      </div>
      <!--End Search-Strip-->
      <div class="col-md-12 my-4">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-3 pl-0">
            <div class="d-xl-none mobile-filter">
              <button class="btn btn-mobile-filter mb-3 ml-auto" @click="toggleJobFilter"><b-icon icon="funnel-fill"
                  @click="toggleJobFilter" class="mobile-filter-icon" width="20px"></b-icon>{{ $gettext("Filters")
                  }}</button>
              <!-- <button class="btn btn-mobile-filter mb-3 ml-auto" @click="showFilters"><b-icon icon="funnel-fill"
                  @click="showFilters" class="mobile-filter-icon" width="20px"></b-icon>{{ $gettext("Filters") }}</button> -->
            </div>
            <div>
              <div class="job-filters" :class="{ isMobileFilter }">
                <div class="text-right d-xl-none">
                  <svg @click="hideFilters" width="3rem" height="3rem" viewBox="0 0 16 16" class="text-body bi bi-x"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
                <div class="profile-flex border-bottom">
                  <h5 class="heading">{{ $gettext("Filters") }}</h5>
                </div>
                <b-form class="customized-form pt-2">
                  <b-form-group :label="$gettext('By_Title')">
                    <b-form-input v-model="filters['job_title']" :placeholder="$gettext('Enter_Title')"> </b-form-input>
                  </b-form-group>
                  <b-form-group :label="$gettext('By_Date')" class="mt-n3">
                    <div class="flex-between">
                      <date-picker v-model="filters['start_date']" class="custom-date mr-3"
                        :placeholder="$gettext('Start_Date')"></date-picker>
                      <date-picker v-model="filters['end_date']" class="custom-date"
                        :placeholder="$gettext('End_Date')"></date-picker>
                    </div>
                  </b-form-group>
                  <b-form-group :label="$gettext('By_No_of_Candidates')">
                    <v-select class="form-control mr-3" v-model="filters['applicants']" :options="[1, 2, 3, 4, 5]"
                      :placeholder="$gettext('More_Than')" :selectOnTab="true"> </v-select>
                  </b-form-group>
                  <b-form-group :label="$gettext('By_Status')" class="mt-n3">
                    <v-select class="form-control" :placeholder="$gettext('Select_Status')" :options="[
                      { label: $gettext('Open'), value: 'Y' },
                      { label: $gettext('Closed'), value: 'N' },
                    ]" v-model="filters.status">
                    </v-select>
                  </b-form-group>
                  <div class="filter-footer">
                    <button class="btn btn-reset" @click.prevent.stop="resetFilters">{{ $gettext("Reset") }}</button>
                    <button class="btn btn-apply" @click.prevent.stop="() => {
                      searchJob = true;
                      getJobs();
                    }
                      ">
                      {{ $gettext("Apply") }}
                    </button>
                  </div>
                </b-form>
              </div>
            </div>

          </div>
          <!--End Job Filters-->

          <div class="col-md-12 col-lg-12 col-xl-9 px-0 job-listings">
            <div class="lists-table">
              <div class="profile-flex border-bottom">
                <h5 class="heading">{{ $gettext("My_Lists") }}</h5>
                <div class="mt-2 mt-md-0 dcc-job-btn">
                  <button class="btn btn-primary font-weight-bold my-mr-3" @click="getAllDraftJobs">{{
                    $gettext("Draft_Jobs")
                  }}</button>
                  <button class="btn btn-primary font-weight-bold my-mr-3" @click="getJobs">{{ $gettext("Created_Jobs")
                  }}</button>
                  <button class="btn btn-primary font-weight-bold my-mr-3" @click="onCreateJob">
                    <img src="@/assets/app-icons/edit.svg" width="15px" />
                    {{ $gettext("Create_Job") }}
                  </button>
                </div>
              </div>
              <!-- ------------------------------------ -->

              <EmployerJobs v-if="!draftJobs" :jobs="jobs" :selectedSub="selectedSub" :loading="loading"
                :search="searchJob" @deleteJob="onDelete" />
              <DraftJobs v-if="draftJobs" :jobs="jobs" :selectedSub="selectedSub" :loading="loading"
                @deleteJob="onDelete" />
              <!-- ----------------------------------------- -->
            </div>
            <div class="flex-between jobs-navigation py-3">
              <div>
                <button :disabled="pagination.current == 1" class="btn btn-back" @click="PreviousPage">{{ $gettext("Back")
                }}</button>
              </div>
              <div>
                <button :disabled="pagination.current * pagination.pageSize >= totalCount" class="btn btn-next"
                  @click="nextPage">{{ $gettext("Next") }}</button>
              </div>
            </div>
          </div>
          <!--End Lists Table-->
        </div>
      </div>
    </div>
    <b-modal ref="filter-job-modal" id="filter-job-modal" class="modal-bottom">
      <div>
        <div @click="toggleJobFilter" class="Line29"></div>
        <div class="profile-flex border-bottom">
          <h5 class="heading">{{ $gettext("Filters") }}</h5>
        </div>
        <b-form class="customized-form pt-2">
          <b-form-group :label="$gettext('By_Title')">
            <b-form-input v-model="filters['job_title']" :placeholder="$gettext('Enter_Title')"> </b-form-input>
          </b-form-group>
          <b-form-group :label="$gettext('By_Date')" class="mt-n3">
            <div class="flex-between">
              <date-picker v-model="filters['start_date']" class="custom-date mr-3"
                :placeholder="$gettext('Start_Date')"></date-picker>
              <date-picker v-model="filters['end_date']" class="custom-date"
                :placeholder="$gettext('End_Date')"></date-picker>
            </div>
          </b-form-group>
          <b-form-group :label="$gettext('By_No_of_Candidates')">
            <v-select class="form-control mr-3" v-model="filters['applicants']" :options="[1, 2, 3, 4, 5]"
              :placeholder="$gettext('More_Than')" :selectOnTab="true"> </v-select>
          </b-form-group>
          <b-form-group :label="$gettext('By_Status')" class="mt-n3">
            <v-select class="form-control" :placeholder="$gettext('Select_Status')" :options="[
              { label: $gettext('Open'), value: 'Y' },
              { label: $gettext('Closed'), value: 'N' },
            ]" v-model="filters.status">
            </v-select>
          </b-form-group>
          <div style="display: flex; justify-content: center;">
            <button class="btn btn-reset" @click.prevent.stop="resetFilters">{{ $gettext("Reset") }}</button>
            <button class="btn btn-apply btn-primary" @click.prevent.stop="() => {
              searchJob = true;
              getJobs();
            }
              ">
              {{ $gettext("Apply") }}
            </button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import RecruiterJobService from "@/apiServices/RecruiterJobService";
import Datepicker from "vuejs-datepicker";
import EmployerHeader from "@/views/Employer-Profile-Screens/Employer.vue";
import CompanyServices from "@/apiServices/CompanyServices";
import EmployerJobs from "./jobs/employerJobs.vue";
import DraftJobs from "./jobs/draftJobs.vue";
import moment from "moment";

const getRules = (name, type) => {
  return [
    {
      required: true,
      message: `${name}`,
      trigger: type,
    },
  ];
};

export default {
  components: { Datepicker, EmployerHeader, EmployerJobs, DraftJobs },
  data() {
    return {
      isFilterJob: false,
      searchSetting: false,
      totalCount: 0,
      valid: null,
      draftJobs: false,
      searchJob: false,
      jobs: { alljobs: [], appliedApp: [{}] },
      appliedApplicant: [],
      loading: true,
      selectedSub: null,
      shortlist: [],
      pagination: {
        company_id: null,
        admin: null,
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: "",
        feature: "Y",
      },
      filters: {
        job_title: "",
        start_date: null,
        end_date: null,
        applicants: null,
        status: null,
      },
      isMobileFilter: screen.width <= 1400,
      form: {
        job_title: null,
        job_start_datetime: null,
        job_end_datetime: null,
      },
    };
  },
  created() {
    addEventListener("resize", () => {
      this.isMobileFilter = screen.width <= 1400;
    });
  },
  beforeMount() {
    this.getJobs();
    this.resetJobStore();
    this.resetJobDetails();
  },
  mounted() {
    this.getJobs();
    this.getSelectedSubscription();
  },
  methods: {
    toggleJobFilter() {
      this.isFilterJob = !this.isFilterJob;
      if (this.isFilterJob) {
        this.$bvModal.show("filter-job-modal")
      }
      else {
        this.$bvModal.hide("filter-job-modal")
      }
    },
    toggleSearchSetting() {
      this.searchSetting = !this.searchSetting;
    },
    resetJobStore() {
      this.$store.dispatch("onResetJobDetail");
      this.$store.commit("ON_SET_WFC", null);
      this.$store.commit("ON_SET_PAGE", null);
    },
    getAllDraftJobs() {
      this.draftJobs = true;
      let loader = this.$loading.show();
      RecruiterJobService.GetAllDraftJobs().then((res) => {
        this.jobs = { alljobs: res.data.data };
        loader.hide();
      });
    },
    showFilters: function () {
      document.querySelector(".isMobileFilter").classList.add("show-filter");
      document.querySelector(".navbar").classList.add("navbar-none");
    },
    hideFilters: function () {
      document.querySelector(".isMobileFilter").classList.remove("show-filter");
      document.querySelector(".navbar").classList.remove("navbar-none");
    },
    resetJobDetails() {
      this.$store.dispatch("onResetJobDetail");
    },
    onCreateJob() {
      if (!this.selectedSub) {
        this.$toasted.show("Your plan isn’t active. Please upgrade to start posting jobs", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }
      if (this.selectedSub && this.selectedSub.subs_pkg_no_of_jobs == 0) {
        this.$toasted.show("Please upgrade your plan to create the job", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }
      if (this.valid.valid) {
        this.$router.push({ path: "/employer-jobcreation" });
      } else {
        if (this.selectedSub) {
          this.$toasted.show("You have reached max limit of job creation", {
            duration: 6000,
            icon: "clear",
          });
        }

        if (this.selectedSub == undefined) {
          this.$toasted.show("You dont have any plan", {
            duration: 6000,
            icon: "clear",
          });
        }
      }
    },
    getSelectedSubscription() {
      const user_id = this.$cookies.get("user_id");
      let loader = this.$loading.show();
      CompanyServices.getUserSubscription(user_id).then((res) => {
        this.selectedSub = res.data.data[0];
        // this.selectedSub.subscription_type_id == 1
        //   ? (this.activesub = "Monthly")
        //   : (this.activesub = "Yearly");
        if (this.selectedSub) {
          this.selectedSub.subscription_end_date_timestamp = moment.unix(this.selectedSub.subscription_end_date_timestamp).format("YYYY-MM-DD HH:mm:ss");
        }
      });
      loader.hide();
    },
    // applyFilters() {
    //   this.pagination.company_id = this.$cookies.get("company_id");

    //   RecruiterJobService.GetJobs(this.pagination, this.filters).then((res) => {
    //     this.jobs = { alljobs: [], appliedApp: [{}] };
    //     this.totalCount = res.data.data.totalCount;
    //     if (res.data.data.alljobs && res.data.data.alljobs.length > 0) {
    //       this.jobs.alljobs = res.data.data.alljobs.map((m) => {
    //         return {
    //           job_id: m.job_id,
    //           job_title: m.job_title,
    //           job_end_date: moment(m.job_end_date).format("DD/MM/YY"),
    //           job_active: m.job_active,
    //           applicants: m.applicants,
    //           shortlist: [],
    //         };
    //       });
    //     }

    //     this.jobs.alljobs.forEach((job, index) => {
    //       job.applicants.forEach((app, i) => {
    //         if (app.shortlisted === "Y") {
    //           this.jobs.alljobs[index].shortlist.push(app);
    //         }
    //       });
    //     });
    //   });
    // },
    resetFilters() {
      this.filters = {
        job_title: "",
        start_date: null,
        end_date: null,
        applicants: null,
        status: null,
      };
    },
    nextPage() {
      this.pagination.current = this.pagination.current + 1;
      // this.jobs = [];

      this.getJobs(this.pagination);
    },
    PreviousPage() {
      this.pagination.current = this.pagination.current - 1;
      // this.jobs = [];

      this.getJobs(this.pagination);
    },
    onDelete(job_id) {
      this.$bvModal
        .msgBoxConfirm("Do You Want to delete this Job", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            RecruiterJobService.DeleteJob({ job_id }).then((res) => {
              this.$toasted.show("Job Deleted", {
                duration: 6000,
                icon: "check",
              });
              this.getJobs(this.pagination);
            });
          } else {
            return;
          }
        });
    },
    getJobs(paginate = {}) {
      this.draftJobs = false;
      if (Object.keys(paginate).length === 0 && paginate.constructor === Object) {
        this.pagination.current = 1;
        this.pagination.pageSize = 10;
      }
      // payload.company_id = this.$cookies.get("company_id");
      // payload.admin = this.$cookies.get("admin");
      let loader = this.$loading.show();
      RecruiterJobService.GetJobs(
        {
          current: 1,
          pageSize: 10,

          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          searchText: "",
          feature: "Y",
          ...paginate,
          company_id: this.$cookies.get("company_id"),
          admin: this.$cookies.get("admin"),
        },
        this.filters
      )
        .then((res) => {
          loader.hide();
          this.totalCount = res.data.data.totalCount ? res.data.data.totalCount : 0;
          this.valid = res.data.data.valid;
          this.jobs.alljobs = res.data.data.alljobs.map((m) => {
            return {
              ...m,
              job_end_date: moment(m.job_end_date).format("DD/MM/YY"),
              job_active: moment().isBefore(m.job_end_date, "day") || moment().isSame(m.job_end_date, "day"),
            };
          });

          try {
            this.jobs.alljobs.map((job, index) => {
              job.applicants.map((app, i) => {
                if (app.shortlisted === "Y") {
                  this.jobs.alljobs[index].shortlist.push(app);
                }
              });
            });
            this.loading = false;
            this.$forceUpdate();
          } catch (error) {
            this.loading = false;
          }
        })
        .catch((err) => {
          loader.hide();
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.isMobileFilter {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 6;
  transition: all 1s;
}

.show-filter {
  right: 0 !important;
}

.customized-nav .nav-item a {
  color: white !important;
}

.red-btn {
  background: #e0f1e7 !important;
  color: red;
  letter-spacing: 1.5px;
  font-family: pn-bold;
  font-size: 13px;
  padding: 05px 10px;
  width: 90px;
  border-radius: 04px;
}

.search-setting {
  display: none;
}

.my-mr-3 {
  margin-right: 1rem !important;
}

.my-d-none {
  display: block;
}

@media (max-width: 768px) {

  .search-strip .selector-wrapper {
    flex: 0 0 100% !important;
  }

  .search-setting {
    display: block !important;
  }

  .my-d-none {
    display: none !important;
  }

  .search-btn-d-block {
    display: block !important;
    /* display: -webkit-inline-box !important; */
  }

  #filter-job-modal___BV_modal_header_,
  #filter-job-modal___BV_modal_footer_ {
    display: none !important;
  }

  .Line29 {
    width: 50px;
    /* height: 0; */
    left: 40%;
    text-align: center;
    /* top: 9px; */
    position: relative;
    border: 2px #E5E5EA solid;
  }

  .lists-table .profile-flex {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .dcc-job-btn {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .my-mr-3 {
    margin-bottom: 10px;
  }

}
</style>

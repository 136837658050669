<template>
  <div class="bg-grey">
    <EmployerHeader />
    <div class="container-fluid profile-user emp-settings customized-form px-1 px-md-3 pt-4 pb-5">
      <!--
      <div class="col-md-12 box ">
        <div class="profile-flex border-bottom py-3">
          <h4 class="heading">Your Account</h4>
          <button
            class="btn btn-primary btn-crud"
            @click="$router.push('Employer-onboarding-1')"
          >
            <img src="@/assets/app-icons/edit.svg" width="15px" />
            Edit Details
          </button>
        </div>
        <ValidationObserver ref="changePassword" tag="div">
          <div class="border-bottom py-3">
            <b-form class="customized-form">
              <ValidationProvider
                name="email"
                rules="email"
                v-slot="{ errors, classes }"
                tag="div"
              >
                <b-form-input
                  :disabled="true"
                  placeholder="E-mail"
                  class="col-xl-3 input-email"
                  :class="classes"
                  v-model="email"
                >
                </b-form-input>
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <div class="account-grid">
                <ValidationProvider
                  name="password"
                  rules="required"
                  vid="confirmation"
                  tag="div"
                  v-slot="{ errors, classes }"
                >
                  <b-form-input
                    type="password"
                    placeholder="Change Password"
                    v-model="password"
                    :class="classes"
                    class="form-control"
                  >
                  </b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  rules="required|confirmed:confirmation"
                  v-slot="{ errors, classes }"
                  tag="div"
                >
                  <b-form-input
                    :class="classes"
                    placeholder="Confirm Password"
                    type="password"
                    v-model="confirmPassword"
                    class="form-control"
                  >
                  </b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <button
                  class="btn btn-primary btn-submit"
                  @click.prevent.stop="updatePassword"
                >
                  Change Password
                </button>
              </div>
            </b-form>
          </div>
        </ValidationObserver>
         <div v-if="$cookies.get('admin') == 'Y'">
          <h4 class="heading py-3">Your Subscription</h4>
          <b-tabs
            pills
            align="right"
            class="pills-primary pricing-tabs mt-md-n5"
          >
            <b-tab :active="activesub == 'Yearly'" title="YEARLY">
              <slick
                ref="yearlySlick"
                :options="yearlySlider"
                v-if="yearlySubs.length"
                class="pricing-slick"
              >
                <div class="" v-for="(subs, index) in yearlySubs" :key="index">
                  <div class="pricing-card">
                    <div class="pricing-header">
                      <h5 class="pricing-title">
                        {{ subs.subs_pkg_title }}
                      </h5>
                      <div class="pricing-status">
                        <h4
                          class="pricing-cost"
                          v-if="subs.subs_pkg_annually_price"
                        >
                          ${{ (subs.subs_pkg_annually_price / 12).toFixed(2) }}
                          per Month
                        </h4>
                        <h4
                          class="pricing-cost"
                          v-if="
                            subs.subs_pkg_title == 'ENTERPRISE' ||
                            subs.subs_pkg_title == 'Enterprise'
                          "
                        >
                          Contact Us For Pricing
                        </h4>
                        <h6
                          v-if="subs.subs_pkg_annually_price"
                          class="pricing-plan"
                        >
                          paid Annually
                        </h6>
                      </div>
                    </div>
                    <div class="pricing-content">
                      <ul class="pricing-content-list">
                        <li v-if="subs.subs_pkg_monthly_price">
                          ${{ subs.subs_pkg_monthly_price }} paid monthly
                        </li>
                        <li>{{ subs.subs_pkg_no_of_jobs }} Jobs PM</li>
                        <li>
                          {{ subs.subs_pkg_no_of_users }}
                          {{
                            parseInt(subs.subs_pkg_no_of_users) > 1
                              ? "users"
                              : "user"
                          }}
                        </li>
                        <li>{{ subs.subs_pkg_features }}</li>
                        <li>{{ subs.subs_pkg_no_of_applicants }}</li>
                        <li
                          v-for="(desc, i) in subs.subs_pkg_description"
                          :key="i"
                        >
                          {{ desc }}
                        </li>
                      </ul>
                    </div>

                    <div
                      v-if="
                        selectedSub &&
                        selectedSub.subs_pkg_id == subs.subs_pkg_id &&
                        selectedSub.subs_pkg_title != 'Enterprise'
                      "
                      style="color: #b1b1b1"
                      class="pb-3"
                    >
                      Valid till
                      {{
                        dateFormat(selectedSub.subscription_end_date_timestamp)
                      }}
                    </div>
                    <div class="pricing-footer">
                      <button
                        v-if="
                          selectedSub &&
                          selectedSub.subs_pkg_id == subs.subs_pkg_id
                        "
                        :class="{
                          'btn btn-primary pricing-btn':
                            selectedSub &&
                            selectedSub.subs_pkg_id != subs.subs_pkg_id,
                          'btn pricing-active':
                            selectedSub &&
                            selectedSub.subs_pkg_id == subs.subs_pkg_id,
                        }"
                      >
                        ACTIVE
                      </button>
                      <button
                        v-if="
                          selectedSub &&
                          selectedSub.subs_pkg_id == subs.subs_pkg_id
                        "
                        class="btn btn-danger mt-2"
                        @click="deactivePlan(subs.subs_pkg_id)"
                      >
                        DE-ACTIVATE
                      </button>

                      <button
                        v-else
                        :class="{
                          'btn btn-primary pricing-btn':
                            (selectedSub &&
                              selectedSub.subs_pkg_id != subs.subs_pkg_id) ||
                            !selectedSub,
                          'btn pricing-active':
                            (selectedSub &&
                              selectedSub.subs_pkg_id == subs.subs_pkg_id) ||
                            !selectedSub,
                        }"
                        @click="createOrder(subs)"
                      >
                        {{
                          subs.subs_pkg_title == "FREEE" ||
                          subs.subs_pkg_title == "Free"
                            ? "SELECT"
                            : subs.subs_pkg_title == "ENTERPRISE" ||
                              subs.subs_pkg_title == "Enterprise"
                            ? "CONTACT US"
                            : "PAY NOW"
                        }}
                      </button>
                    </div>
                  </div>
                </div>
              </slick>
            </b-tab>
            <b-tab :active="activesub == 'Monthly'" title="MONTHLY">
              <slick
                ref="monthlySlick"
                :options="monthlySlider"
                v-if="monthlySubs.length"
                class="pricing-slick"
              >
                <div class="" v-for="(subs, index) in monthlySubs" :key="index">
                  <div class="pricing-card">
                    <div class="pricing-header">
                      <h5 class="pricing-title">{{ subs.subs_pkg_title }}</h5>
                      <div class="pricing-status">
                        <h4
                          class="pricing-cost"
                          v-if="subs.subs_pkg_monthly_price"
                        >
                          ${{ subs.subs_pkg_monthly_price }}/month
                        </h4>
                      
                        <h4
                          class="pricing-cost"
                          v-if="
                            subs.subs_pkg_title == 'ENTERPRISE' ||
                            subs.subs_pkg_title == 'Enterprise'
                          "
                        >
                          Contact Us For Pricing
                        </h4>
                        <h6
                          v-if="subs.subs_pkg_monthly_price"
                          class="pricing-plan"
                        >
                          paid monthly
                        </h6>
                      </div>
                    </div>
                    <div class="pricing-content mt-n4">
                      <ul class="pricing-content-list">
                        <li>{{ subs.subs_pkg_no_of_jobs }} Jobs PM</li>
                        <li>
                          {{ subs.subs_pkg_no_of_users }}
                          {{
                            parseInt(subs.subs_pkg_no_of_user) > 1
                              ? "users"
                              : "user"
                          }}
                        </li>
                        <li>{{ subs.subs_pkg_features }}</li>
                        <li>{{ subs.subs_pkg_no_of_applicants }}</li>
                        <li
                          v-for="(desc, i) in subs.subs_pkg_description"
                          :key="i"
                        >
                          {{ desc }}
                        </li>
                        <li
                          v-if="
                            selectedSub &&
                            selectedSub.subs_pkg_id == subs.subs_pkg_id
                          "
                          style="color: #b1b1b1"
                          class="pb-3"
                        >
                          Valid till
                          {{
                            dateFormat(
                              selectedSub.subscription_end_date_timestamp
                            )
                          }}
                        </li>
                      </ul>
                    </div>
                    <div class="pricing-footer mb-n2">
                      <button
                        v-if="
                          selectedSub &&
                          selectedSub.subs_pkg_id == subs.subs_pkg_id
                        "
                        :class="{
                          'btn btn-primary pricing-btn':
                            selectedSub &&
                            selectedSub.subs_pkg_id != subs.subs_pkg_id,
                          'btn pricing-active':
                            selectedSub &&
                            selectedSub.subs_pkg_id == subs.subs_pkg_id,
                        }"
                      >
                        ACTIVE
                      </button>
                      <button
                        v-if="
                          selectedSub &&
                          selectedSub.subs_pkg_id == subs.subs_pkg_id
                        "
                        class="btn btn-danger mt-2"
                        @click="deactivePlan(subs.subs_pkg_id)"
                      >
                        DE-ACTIVATE
                      </button>
                      <button
                        v-else
                        :class="{
                          'btn btn-primary pricing-btn':
                            (selectedSub &&
                              selectedSub.subs_pkg_id != subs.subs_pkg_id) ||
                            !selectedSub,
                          'btn pricing-active':
                            (selectedSub &&
                              selectedSub.subs_pkg_id == subs.subs_pkg_id) ||
                            !selectedSub,
                        }"
                        @click="createOrder(subs)"
                      >
                        {{
                          subs.subs_pkg_title == "FREEE" ||
                          subs.subs_pkg_title == "Free"
                            ? "SELECT"
                            : subs.subs_pkg_title == "ENTERPRISE" ||
                              subs.subs_pkg_title == "Enterprise"
                            ? "CONTACT US"
                            : "PAY NOW"
                        }}
                      </button>
                    </div>
                 
                  </div>
                </div>
              </slick>
            </b-tab>
          </b-tabs>
        </div> -->

      <!-- <div class="py-3 row">
          <div class="col-lg-4 col-md-5">
            <h4 class="heading">Notifications</h4>
            <div class="flex-between py-2">
              <span>New Applicant</span>
              <b-form-checkbox
                class=""
                v-model="alert[0].status"
                @change="addAlert"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="flex-between py-2">
              <span>Deadline Alert</span>
              <b-form-checkbox
                class=""
                v-model="alert[1].status"
                @change="addAlert"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
          <div class="offset-lg-2 col-md-6 col-lg-5">
            <h4 class="heading">Communication Preferences</h4>
            <div class="flex-between py-2">
              <span>3rd party Communication</span>
              <b-form-checkbox
                class=""
                v-model="alert[2].status"
                @change="addAlert"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="flex-between py-2">
              <span>Promotional Offers</span>
              <b-form-checkbox
                class=""
                v-model="alert[3].status"
                @change="addAlert"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
        </div> 
      </div>
      -->
      <div class="col-md-12 box mt-3 py-3">
        <div class="profile-flex">
          <h4 class="heading">Company Details</h4>
          \
        </div>
        <ValidationObserver ref="profile" tag="div">
          <div class="row pb-4">
            <div class="col-xl-3 col-lg-3 col-md-4 mt-3 border-right text-center">
              <label>UPLOAD COMPANY LOGO</label>
              <div class="custom-upload upload-company-img text-center">
                <div v-if="!previewImage && !profile.company_logo_path">
                  <img src="@/assets/app-icons/cloud-upload.svg" class="img-fluid" alt="upload-icon" width="50px" />
                </div>
                <div v-if="previewImage">
                  <img :src="previewImage" alt="" height="59px" width="74px" />
                </div>
                <div v-if="profile.company_logo_path && !previewImage">
                  <img :src="profile.company_logo_path" alt="" class="profile-img" />
                </div>
                <div>
                  <label for="file-upload" class="btn upload-btn"> UPLOAD A PHOTO </label>
                  <div class="upload-max">
                    <span v-if="image_path">
                      <a :href="image_path" target="_blank">View Photo</a>
                    </span>
                    Max 20MB
                  </div>
                  <input id="file-upload" ref="fileInput" type="file" @input="onFileChange" />
                </div>
              </div>
              <!--End Upload Company Logo-->
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 mt-3 border-right text-center">
              <label>UPLOAD COMPANY GALLERY</label>
              <div class="custom-upload upload-company-img">
                <div>
                  <img v-if="!previewImage2" src="@/assets/app-icons/cloud-upload.svg" class="img-fluid" alt="upload-icon" width="50px" />
                  <img v-if="previewImage2" :src="previewImage2" ref="img" class="img-fluid" alt="upload-icon" width="50px" />
                  <!-- <img
                    v-if="!previewImage2 && videoInput"
                    ref="img"
                    class="img-fluid"
                    alt="video-thumbnail"
                    width="50px"
                  /> -->
                  <video ref="video" style="display: none"></video>
                  <canvas ref="canvas" style="display: none"></canvas>
                  <!-- <img ref="img" src="" alt="" /> -->
                </div>
                <div>
                  <label for="file-upload2" class="btn upload-btn">
                    {{ previewImage2 && !videoInput ? "Image Selected" : "UPLOAD PHOTOS/VIDEO" }}
                  </label>
                  <div class="upload-max">Max 100MB</div>
                  <input id="file-upload2" ref="fileInput2" type="file" @input="onFileChange2" />
                </div>
              </div>
            </div>
            <!--End Upload Company Gallery-->

            <div class="col-xl-6 col-lg-6 col-md-12 mt-3">
              <div class="emp-settings-form">
                <ValidationProvider name="companyName" rules="required" v-slot="{ errors, classes }" tag="div">
                  <label>COMPANY NAME</label>
                  <b-form-input v-model="company_name" placeholder="Enter Company Name" :class="classes"></b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="name" rules="required" v-slot="{ errors, classes }" tag="div">
                  <label>ADMIN NAME</label>
                  <b-form-input v-model="admin_name" :disabled="$cookies.get('admin') != 'Y'" placeholder="Enter Name" :class="classes"></b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="useremail" rules="" v-slot="{ errors, classes }" tag="div">
                  <label>ADMIN E-MAIL</label>
                  <b-form-input :disabled="true" v-model="admin_email" placeholder="Enter Email" :class="classes"></b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>

              <ValidationProvider name="description" rules="required" v-slot="{ errors, classes }" tag="div">
                <label>PROFILE</label>
                <b-form-textarea class="" v-model="company_profile" placeholder="Add your company profile in 2 paragraphs or less" :class="classes" rows="3" max-rows="5"> </b-form-textarea>
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <!--End First Row-->
          <div class="border-top row border-bottom py-3">
            <div class="col-12 col-md-12 col-lg-5">
              <div>
                <label>SIZE</label>
                <ValidationProvider name="size" rules="required" v-slot="{ errors, classes }">
                  <b-form-radio-group v-model="employer_size" class="radio-square option-center pt-2 ml-n4" :class="classes">
                    <b-form-radio value="1-100"> 1-100 </b-form-radio>
                    <b-form-radio value="100-500">100-500</b-form-radio>
                    <b-form-radio value="501-1000">501-1000</b-form-radio>
                    <b-form-radio value="1001-5000">1001-5000</b-form-radio>
                    <b-form-radio value="5000">>5000</b-form-radio>
                  </b-form-radio-group>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="pt-4">
                <label>INDUSTRY</label>
                <ValidationProvider name="industry" rules="required" v-slot="{ errors, classes }">
                  <!-- <multiselect
                    class="form-control customized-form w-50"
                    placeholder="Select industry"
                    :multiple="true"
                    v-model="industry_id"
                    track-by="industry_id"
                    :reduce="(r) => r.industry_id"
                    :class="classes"
                    :options="industry"
                  >
                  </multiselect> -->
                  <multiselect style="" class="multis" :class="classes" :limit="4" :max="10" v-model="industry_id" placeholder="Select Industry" label="label" track-by="industry_id" :options="industry" :multiple="true" @remove="removeIndustry" />
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 border-left pt-3 pt-lg-0">
              <div>
                <label>CONTACT & LOCATION</label>
                <div class="pt-2">
                  <ValidationProvider name="phone" rules="required|min:8|max:25" v-slot="{ errors, classes }">
                    <b-form-input class="form-control" placeholder="Contact Number" v-model="company_official_phone" :class="classes"> </b-form-input>
                    <div class="error-msg" :class="classes">
                      <span> {{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider name="email" rules="" v-slot="{ errors, classes }">
                    <b-form-input class="form-control" :disabled="true" placeholder="Official E-mail" :class="classes" v-model="company_official_email"> </b-form-input>
                    <div class="error-msg" :class="classes">
                      <span> {{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider name="url" rules="required" v-slot="{ errors, classes }">
                    <b-form-input class="form-control" :class="classes" placeholder="Website URL" v-model="company_website_url"> </b-form-input>
                    <div class="error-msg" :class="classes">
                      <span> {{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-4 mt-md-5">
              <div class="row mb-3">
                <div class="col-md-6">
                  <ValidationProvider name="count" rules="required|max:150" v-slot="{ errors, classes }">
                    <multiselect style="" class="multis" :class="classes" :limit="4" :max="10" v-model="selectedCountry" placeholder="Select Country" label="country_name" track-by="country_id" :options="countryOptions" :multiple="false" @input="onChangeCountry" />
                    <!-- <multiselect
                      style=""
                      class="multis"
                      :class="classes"
                      v-model="selectedCountry"
                      placeholder="Select Country"
                      label="country_name"
                      track-by="country_id"
                      :options="countryOptions"
                      :multiple="false"
                      @input="onChangeCountry"
                    /> -->
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider name="city" rules="required|max:150" v-slot="{ errors, classes }">
                    <multiselect style="" class="multis" :class="classes" v-model="selectedCity" placeholder="Select City" label="city_name" track-by="city_id" :options="cityOptions" :multiple="false" />
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <ValidationProvider name="address" rules="required|max:150" v-slot="{ errors, classes }">
                <b-form-textarea class="w-100 overflow-auto" :class="classes" v-model="company_office_address" placeholder="Add your company address" rows="6" max-rows="10"> </b-form-textarea>
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <!--End Second Row-->

          <div class="row pt-3">
            <div class="col-md-12 customized-form">
              <label>ADD SOCIAL LINKS</label>
              <div class="social-profile">
                <div class="social-profile-grid">
                  <div class="d-flex">
                    <div class="icon">
                      <i class="fa fa-linkedin"></i>
                    </div>
                    <b-form-input v-model="social_link.linked_in" placeholder="Enter Linkedin profile URL"> </b-form-input>
                  </div>
                  <div class="d-flex">
                    <div class="icon">
                      <i class="fa fa-facebook"></i>
                    </div>
                    <b-form-input v-model="social_link.facebook" placeholder="Enter Facebook ID URL"> </b-form-input>
                  </div>
                  <div class="d-flex">
                    <div class="icon">
                      <i class="fa fa-instagram"></i>
                    </div>
                    <b-form-input v-model="social_link.instagram" placeholder="Enter Instagram profile URL"> </b-form-input>
                  </div>
                  <div class="d-flex">
                    <div class="icon">
                      <i class="fa fa-twitter"></i>
                    </div>
                    <b-form-input v-model="social_link.twitter" placeholder="Enter Twitter Handle"> </b-form-input>
                  </div>
                  <div>
                    <b-form-input v-model="social_link.other_link1" placeholder="Enter Other Link"> </b-form-input>
                  </div>
                  <div>
                    <b-form-input v-model="social_link.other_link2" placeholder="Enter Other Link"> </b-form-input>
                  </div>
                </div>
              </div>
            </div>
            <b-col>
              <RadialProgressBar v-show="showRadial" :diameter="90" :stroke-width="8" :inner-stroke-width="10" inner-stroke-color="#fff" start-color="#6244e5" stop-color="#6244e5" :completed-steps="completedSteps" :total-steps="totalSteps"> {{ completedSteps }} % </RadialProgressBar>
            </b-col>
          </div>
          <!--End Third Row-->
          <div class="text-center pt-3">
            <button class="btn btn-primary btn-submit" @click="ValidateProfile">SAVE CHANGES</button>
          </div>
        </ValidationObserver>
      </div>

      <!--
      <div class="col-md-12 box mt-3 pb-3" v-if="$cookies.get('admin') !== 'N'">
        <div class="profile-flex border-bottom py-3">
          <h4 class="heading">Create Users</h4>
          <button
            class="btn btn-primary btn-submit px-5"
            @click="addMoreEmployee"
          >
            ADD USER+
          </button>
        </div>
        <ValidationObserver ref="addEmployee" tag="div">
          <div class="row" v-for="(e, counter) in employee" :key="counter">
            <div class="col-md-12 user-add-col">
              <div>
                
                <label>NAME </label>
                <b-form-input
                  placeholder="Enter Name"
                  :disabled="e.addedEmployee"
                  v-model="e.user_username"
                ></b-form-input>
                <div
                  class="error-msg"
                  v-if="
                    submit &&
                      (!e.user_username || String(e.user_username).length == 0)
                  "
                >
                  <small>*Username is required</small>
                </div>

            
              </div>
              <div>
                <ValidationProvider
                  name="uemail"
                  rules="email"
                  v-slot="{ errors, classes }"
                  tag="div"
                >
                  <label>EMAIL</label>
                  <b-form-input
                    :disabled="e.addedEmployee"
                    placeholder="Enter E-mail Id"
                    v-model="e.user_email"
                    :class="classes"
                  ></b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                    <small
                      v-if="
                        submit &&
                          (!e.user_email || String(e.user_email).length == 0)
                      "
                      >*Email is required</small
                    >
                  </div>
                </ValidationProvider>
              </div>
              <div>
                <ValidationProvider
                  name="pass"
                  rules="max:15|min:8"
                  v-slot="{ errors, classes }"
                  tag="div"
                >
                  <label>PASSWORD</label>
                  <b-form-input
                    placeholder="Enter Password"
                    :disabled="e.addedEmployee"
                    type="password"
                    v-model="e.user_password"
                    :class="classes"
                  ></b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                    <small
                      v-if="
                        submit &&
                          (!e.user_password ||
                            String(e.user_password).length == 0)
                      "
                      >*Password is required</small
                    >
                  </div>
                </ValidationProvider>
              </div>
              <div class="btn-action-div">
                <button
                  v-show="!e.addedEmployee"
                  class="btn btn-user-add ml-auto"
                  @click="addEmployeeToStore(counter)"
                >
                  ADD+
                </button>
                <button
                  class="btn btn-user-remove ml-auto"
                  @click="removeEmployee(counter)"
                >
                  REMOVE
                  <img
                    src="@/assets/app-icons/delete.svg"
                    width="12px"
                    class="mt-n1"
                  />
                </button>
              </div>
              <b-modal id="myModel" hide-header hide-footer>
                <div class="text-center">
                  <b-spinner
                    label="Loading..."
                    style="width: 8rem; height: 8rem"
                  >
                  </b-spinner>
                  <h2>Uploading...</h2>
                </div>
              </b-modal>
            </div>
          </div>
          <div class="text-center">
            <button
              class="btn btn-primary btn-submit mt-3"
              @click="submitEmployeeDetails"
            >
              Submit Employees
            </button>
          </div>
        </ValidationObserver>
      </div> 

      -->
    </div>
    <b-modal ref="welcome" hide-header hide-footer size="lg" centered>
      <div class="text-center">
        <h2>Welcome</h2>
        <h2>{{ $gettext("Employers") }}</h2>
      </div>
    </b-modal>
  </div>
</template>

<script>
import EmployerHeader from "@/views/Employer-Profile-Screens/Employer.vue";
import GetVideoUploadUrl from "@/apiServices/GetVideoUploadUrl";
import axios from "axios";
import RadialProgressBar from "vue-radial-progress";

import Multiselect from "vue-multiselect";
import CompanyServices from "@/apiServices/CompanyServices";
import LocationServices from "@/apiServices/LocationServices";
import CompanyService from "@/apiServices/CompanyServices";
import UserService from "@/apiServices/UserService";
import AlertSetting from "@/apiServices/AlertSetting";

import RecruiterJobService from "@/apiServices/RecruiterJobService";
import CheckoutServices from "@/apiServices/CheckoutServices";

import moment from "moment";

export default {
  components: { EmployerHeader, RadialProgressBar, Multiselect },
  data() {
    return {
      loader: null,
      loader2: null,
      alertLoader: null,
      uploadUrlLoader: null,
      selectSubLoader: null,
      employerDetailsloader: null,
      activesub: null,
      selectedCountry: null,
      selectedCity: null,
      completedSteps: 0,
      totalSteps: 100,
      submit: null,
      email: null,
      extraJobs: null,
      remainingEmployee: null,
      showRadial: false,
      previewImage: null,
      previewImage2: null,
      alert: [
        { user_alert_name: "New Applicant", status: false },
        { user_alert_name: "Deadline Alert", status: false },

        {
          user_alert_name: "3rd Party Communication",
          status: false,
        },
        {
          user_alert_name: "Promotional Offers",
          status: false,
        },
      ],

      yearlySlider: {
        arrows: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ],
      },
      monthlySlider: {
        arrows: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ],
      },
      filters: {
        job_title: "",
        start_date: null,
        end_date: null,
        applicants: null,
        status: null,
      },
      password: null,
      loading: false,

      videoUploadUrl: null,
      videoInput: null,
      confirmPassword: null,
      companyImage: null,
      subscriptions: [],
      monthlySubs: [],
      yearlySubs: [],
      selectedSub: null,
      payload: [],
      createdEmployee: [],
      // employee: [
      //   {
      //     user_username: null,
      //     user_email: null,
      //     user_password: null,
      //     company_id: null,
      //     role_id: null,
      //     addedEmployee: false,
      //     admin: "N",
      //   },
      // ],

      industry: [],
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: "",
        feature: "Y",
      },
      countryOptions: [],
      cityOptions: [],
      cities: [],
      profile: {},
      industry_id: null,
      removedIndustry: [],
      company_id: null,
      image_path: null,
      image: null,
      company_name: null,
      admin_name: null,
      admin_email: null,
      company_profile: null,
      employer_size: null,
      company_official_phone: null,
      company_official_email: null,
      company_website_url: null,
      company_office_address: null,
      city: null,
      social_link: {
        linked_in: "",
        facebook: "",
        instagram: "",
        twitter: "",
        other_link1: "",
        other_link2: "",
      },
    };
  },

  beforeMount() {
    this.getAllIndustry();
    this.getAllCountry();
    // this.getSubscription();
    // this.getSelectedSubscription();
    this.getAlert();
    // this.getUser();
    // this.getJobs();
  },
  mounted: function () {
    // if (this.$route.query.login && this.$route.query.login == 1) {
    //   this.showModal();
    //   setTimeout(() => {
    //     this.hideModal();
    //   }, 5000);
    // }
    if (this.$route.query.payment_successful && this.$route.query.payment_successful == "true") {
      this.$toasted.show("Payment was successful! Subscription is active", {
        duration: 6000,
        icon: "check",
      });
    } else if (this.$route.query.payment_successful && this.$route.query.payment_successful == "false") {
      this.$toasted.show("Payment failed! Try again later", {
        duration: 3000,
        icon: "clear",
      });
    } else if (this.$route.query.failed && this.$route.query.failed == "false") {
      this.$toasted.show("Payment successful! activation failed, contact support", {
        duration: 5000,
        icon: "clear",
      });
    }
    this.getEmployerDetails();
  },
  methods: {
    dateFormat(date) {
      return moment.unix(date).format("YYYY-MM-DD");
    },
    getUser() {
      console.log("hitting here");
      UserService.getRegisteredUser()
        .then((res) => {
          this.email = res.data.data.user_email;
          this.getJobs();
        })
        .catch((err) => {
          this.loader2.hide();
        });
    },
    ValidateSubscription() {
      console.log("hitting validate subs====");
      // this.showModal();

      // this.boxOne = ''
      let del = this.createdEmployee.length - +this.selectedSub.subs_pkg_no_of_users;
      console.log("del :>> ", del);
      this.$bvModal
        .msgBoxOk(`You have more than max number of users of the selected plan. Delete ${del} users`)
        .then((value) => {
          console.log("hitting");
          // this.$router.push({ name: "Employer-Profile" });
        })
        .catch((err) => {
          // An error occurred
        });
    },
    getAllCountry() {
      LocationServices.GetAllCountry({}).then((res) => {
        this.countryOptions = res.data.data;
      });
    },
    onChangeCountry(e, id, city_reset = true) {
      console.log("e :>> ", e);
      console.log(`city_reset`, city_reset);
      if (e) {
        const country_id = [e.country_id];
        if (city_reset) this.selectedCity = null;
        LocationServices.GetAllCityOfCountry({ country_id, all: true }).then((res) => {
          this.cityOptions = res.data.data;
        });
      } else {
        this.cityOptions = [];
        if (city_reset) this.selectedCity = null;
      }
    },
    showMsgBoxTwo() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxOk("Data was submitted successfully", {
          title: "Confirmation",
          size: "lg",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          console.log("value :::::>> ", value);
        })
        .catch((err) => {
          // An error occurred
        });
    },
    getJobs(paginate = {}) {
      if (Object.keys(paginate).length === 0 && paginate.constructor === Object) {
        this.pagination.current = 1;
        this.pagination.pageSize = 10;
      }
      // payload.company_id = this.$cookies.get("company_id");
      // payload.admin = this.$cookies.get("admin");

      RecruiterJobService.GetJobs(
        {
          current: 1,
          pageSize: 10,

          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          searchText: "",
          feature: "Y",
          ...paginate,
          company_id: this.$cookies.get("company_id"),
          admin: this.$cookies.get("admin"),
        },
        this.filters
      )
        .then((res) => {
          this.loader2.hide();
          this.totalCount = res.data.data.totalCount;
          this.valid = res.data.data.valid;

          console.log("this.valid :>> ", this.valid);
          this.extraJobs = res.data.data.jobLength;
        })
        .catch((err) => {
          this.loader2.hide();
          this.loading = false;
        });
    },
    async createOrder(subs) {
      console.log("subs :>> ", subs);
      if (subs.enterprise_package == "Y") {
        this.$toasted.show("Thank you", { duration: 6000 });
        return;
      }

      let [selectSubs] = this.yearlySubs.filter((f) => {
        if (f.subs_pkg_id == subs.subs_pkg_id) {
          return true;
        }
      });
      if (selectSubs == undefined) {
        [selectSubs] = this.monthlySubs.filter((f) => {
          if (f.subs_pkg_id == subs.subs_pkg_id) {
            return true;
          }
        });
      }

      console.log("selectSubs :>> ", selectSubs);
      if (this.createdEmployee.length > +selectSubs.subs_pkg_no_of_users) {
        let extraUser = this.createdEmployee.length - +selectSubs.subs_pkg_no_of_users;
        this.$toasted.show(`You have ${extraUser} extra users. Delete them to select this plan`, { duration: 6000, icon: "clear" });
      }
      if (this.extraJobs > +selectSubs.subs_pkg_no_of_jobs) {
        let extra = this.extraJobs - +selectSubs.subs_pkg_no_of_jobs;
        this.$toasted.show(`You have ${extra} jobs. Delete them to select this plan`, { duration: 6000, icon: "clear" });
      }

      let valid = !(this.createdEmployee.length > +selectSubs.subs_pkg_no_of_users) && !(this.extraJobs > +selectSubs.subs_pkg_no_of_jobs);
      console.log("valid :>> ", valid);
      if (valid) {
        let loader = this.$loading.show();
        try {
          let response = await CheckoutServices.CreateOrder({
            subs_pkg_id: subs.subs_pkg_id,
            redirect_flag: 3,
          });
          // loader.hide();
          let { data } = response;
          console.log("response ===:>> ", data);
          if (!data.data.payment) {
            this.$toasted.show("Invalid Subscription. please Delete users and job", { duration: 6000, icon: "clear" });
            loader.hide();
            return;
          }

          if (data.data.type == "paid") {
            window.location.href = data.data.payment.links[1].href;
            loader.hide();
          } else if (data.data.type == "free") {
            loader.hide();
            this.getSelectedSubscription();
            this.$toasted.show("Subscription Updated!", {
              duration: 6000,
              icon: "check",
            });
          } else {
            loader.hide();

            this.$toasted.show("Thank you! We will get back to you", {
              duration: 5000,
            });
            this.getSelectedSubscription();
          }
        } catch (error) {
          loader.hide();

          this.$toasted.show("Payment Failed, try again later", {
            duration: 6000,
            icon: "clear",
          });
        }
      }
    },
    getAllIndustry() {
      this.loader = this.$loading.show();
      CompanyService.getAllIndustry()
        .then((res) => {
          this.industry = res.data.data.map((r) => {
            return {
              label: r.industry_name,
              industry_id: r.industry_id,
            };
          });
          this.getSubscription();
          // loader.hide();
        })
        .catch((err) => {
          if (this.loader) {
            this.loader.hide();
          }
        });
    },
    showModal() {
      this.$refs["welcome"].show();
    },
    hideModal() {
      this.$refs["welcome"] ? this.$refs["welcome"].hide() : null;
    },
    async getCreatedEmployee(payload) {
      const company_id = this.$cookies.get("company_id");
      try {
        const createdUser = await CompanyServices.getCreatedEmployee(payload, company_id);

        console.log("this.selectedSub:>>", this.selectedSub);
        this.createdEmployee = createdUser.data.data;
        if (this.loader) {
          this.loader.hide();
        }

        if (this.selectedSub) {
          console.log("=============hitting===========");
          this.remainingEmployee = +this.selectedSub.subs_pkg_no_of_users - this.createdEmployee.length;
          console.log("this.remainingEmployee :>> ", this.remainingEmployee);
        }

        // if (
        //   this.createdEmployee.length > +this.selectedSub.subs_pkg_no_of_users
        // ) {
        //   this.$toasted.show(
        //     "You have more users than max limit of your subs.",
        //     { duration: 6000 }
        //   );
        //   this.ValidateSubscription();
        // }
      } catch (error) {
        console.log("error ------ :>> ", error);
        if (this.loader) {
          this.loader.hide();
        }
      }
    },
    openModel() {
      this.$bvModal.show("myModel");
      setTimeout(() => {
        this.$bvModal.hide("myModel");
      }, 1000);
    },
    closeModel() {
      this.$bvModal.hide("myModel");
    },
    removeIndustry(e) {
      console.log("e :>> ", e);
      this.removedIndustry.push(e.industry_id);
    },
    getAlert() {
      const user_id = this.$cookies.get("user_id");
      const role_id = this.$cookies.get("role_id");
      this.loader2 = this.$loading.show();

      AlertSetting.GetAlertSetting({ user_id, role_id })
        .then((res) => {
          if (res.data.data.length > 0) {
            this.alert = res.data.data.map((m) => {
              return {
                user_alert_name: m.user_alert_name,
                status: m.user_alert_status == "Y" ? true : false,
              };
            });
          }
          this.getUser();
        })
        .catch((err) => {
          this.loader2.hide();
        });
    },
    addAlert() {
      const user_id = this.$cookies.get("user_id");
      const role_id = this.$cookies.get("role_id");
      const payload = { user_id, role_id, alert: this.alert };
      this.alertLoader = this.$loading.show();
      AlertSetting.AddAlertSetting(payload)
        .then((res) => {
          this.alertLoader.hide();
        })
        .catch((err) => {
          this.alertLoader.hide();
        });
    },
    async getUploadUrl() {
      return new Promise(async (resolve, reject) => {
        try {
          this.uploadUrlLoader = this.$loading.show();
          const res = await GetVideoUploadUrl.GetVideoUploadUrl();
          this.videoUploadUrl = res.data.data.result.uploadURL;
          //  this.dropzoneOptions.url = this.url;
          resolve();
          this.uploadUrlLoader.hide();
        } catch (error) {
          reject();

          this.$toasted.show(
            `Internal Server Error.
            Video May not upload.
            please Reaload this page`,
            {
              duration: 5000,
              icon: "clear",
            }
          );
          this.uploadUrlLoader.hide();
        }
      });
    },
    getSelectedSubscription() {
      const user_id = this.$cookies.get("user_id");
      // let loader = this.$loading.show();
      CompanyServices.getUserSubscription(user_id)
        .then((res) => {
          this.selectedSub = res.data.data[0];
          this.selectedSub.subscription_type_id == 1 ? (this.activesub = "Monthly") : (this.activesub = "Yearly");
          this.getCreatedEmployee(this.pagination);

          // loader.hide();

          // this.getCreatedEmployee(this.pagination);
          // console.log("this.createdEmployee :>> ", this.createdEmployee);
          // console.log("this.selectedSub :>> ", this.selectedSub);
          // if (
          //   this.createdEmployee.length > +this.selectedSub.subs_pkg_no_of_users
          // ) {
          //   this.$toasted.show(
          //     `You have more than ${this.selectedSub.subs_pkg_no_of_user} users. Delete them`,
          //     { duration: 6000 }
          //   );
          //   this.ValidateSubscription();
          // }
        })
        .catch((err) => {
          if (this.loader) {
            this.loader.hide();
          }
        });
    },
    selectSubscription(sub_id) {
      const payload = {
        user_id: this.$cookies.get("user_id"),
        subs_pkg_id: sub_id,
      };
      this.selectSubLoader = this.$loading.show();
      CompanyService.updateUserSubscription(payload)
        .then((res) => {
          this.getSelectedSubscription();
          this.$toasted.show("Subscription Updated!", {
            duration: 6000,
            icon: "check",
          });
          this.selectSubLoader.hide();
        })
        .catch((err) => {
          this.selectSubLoader.hide();
          this.$toasted.show("Subscription not updated!", {
            duration: 6000,
            icon: "clear",
          });
        });
    },

    ValidatePassword() {
      let password = this.password;
      let confirmPass = this.confirmPassword;
      validate(changePassword, "required|confirmed:@confirmation", {
        name: "Password",
        values: {
          confirmPass,
        },
      })
        .then((result) => {
          if (result.valid) {
            return true;
          } else {
            return false;
          }
        })
        .catch((err) => {});
    },
    updatePassword() {
      const payload = {
        user_id: this.$cookies.get("user_id"),
        email: this.email,
        password: this.password,
      };
      this.$refs.changePassword.validate().then((result) => {
        if (result) {
          UserService.updatePassword(payload)
            .then((res) => {
              this.$toasted.show("Changed Successfully!", {
                position: "top-right",
                duration: 6000,
                icon: "check",
              });
            })
            .catch((err) => {
              this.$toasted.show("Something Missing!", {
                position: "top-right",
                duration: 6000,
                icon: "clear",
              });
            });
        } else {
          this.$toasted.show("Fill the correct fields!", {
            position: "top-right",
            duration: 6000,
            icon: "clear",
          });
        }
      });
    },
    onFileChange(e) {
      let input = this.$refs.fileInput;
      this.image = e.target.files[0];
      let file = input.files;

      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    async onFileChange2(e) {
      if (e.target.files[0].type == "video/mp4") {
        // let cFResponse = await this.getUploadUrl();
        this.videoInput = e.target.files[0];
        this.previewImage2 = null;
        this.companyImage = null;

        //mycode
        var file = e.target.files[0];
        var fileReader = new FileReader();
        var x = this;
        fileReader.onload = function () {
          var blob = new Blob([fileReader.result], { type: file.type });
          var url = URL.createObjectURL(blob);
          var video = x.$refs["video"];
          console.log("video :>> ", video);
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
              video.pause();
            }
          };
          video.addEventListener("loadeddata", function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
            }
          });
          var snapImage = function () {
            var canvas = x.$refs["canvas"];
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
            var image = canvas.toDataURL();
            x.previewImage2 = canvas.toDataURL();
            console.log(" x.previewImage2 :>> ", x.previewImage2);
            var success = image.length > 100000;
            if (success) {
              // var img = x.$refs["img"];
              // img.src = image;
              // x.$refs["video-thumbnail"].appendChild(img);
              URL.revokeObjectURL(url);
            }
            return success;
          };
          video.addEventListener("timeupdate", timeupdate);
          video.preload = "metadata";
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        };
        fileReader.readAsArrayBuffer(file);
        //mycode
      }
      if (e.target.files[0].type == "image/jpg" || e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpeg") {
        this.videoInput = null;
        let input = this.$refs.fileInput2;
        this.companyImage = e.target.files[0];
        let file = input.files;

        if (file && file[0]) {
          let reader = new FileReader();
          reader.onload = (e) => {
            this.previewImage2 = e.target.result;
            this.videoInput = null;
          };
          reader.readAsDataURL(file[0]);
          this.$emit("input", file[0]);
        }
      }
    },
    getSubscription() {
      // let loader = this.$loading.show();
      CompanyServices.getSubscription()
        .then((res) => {
          res.data.data.forEach((s) => {
            if (s.subs_pkg_type === "Employer" || s.subs_pkg_type === "Employer No Limit") {
              if (s.subscription_type_id == 1) {
                this.monthlySubs.push(s);
              } else {
                this.yearlySubs.push(s);
              }
            }
          });
          // loader.hide();
          this.getSelectedSubscription();
        })
        .catch((err) => {
          if (this.loader) {
            this.loader.hide();
          }
        });
    },

    getEmployerDetails() {
      const payload = { company_id: this.$cookies.get("company_id") };
      this.employerDetailsloader = this.$loading.show();
      CompanyServices.GetSingleCompany(payload)
        .then((result) => {
          console.log("result.data.data[0] :>> ", result.data.data[0]);
          this.profile = { ...result.data.data[0] };
          this.company_name = this.profile.company_long_name;
          if (this.profile.country_id) {
            this.selectedCountry = {
              country_id: this.profile.country_id,
              country_name: this.profile.country_name,
            };
          } else {
            this.selectedCountry = null;
          }
          if (this.profile.city_id) {
            this.selectedCity = {
              city_id: this.profile.city_id,
              city_name: this.profile.city_name,
            };
          } else {
            this.selectedCity = null;
          }
          this.onChangeCountry(this.selectedCountry, null, false);

          const pay = { image: this.profile.company_logo_path };
          const p = {
            userName: this.profile.company_long_name,
          };
          this.$store.commit("ON_SET_USER_NAME", p);
          this.$store.dispatch("onSetProfileImage", pay);
          const country = {
            country: this.selectedCountry ? this.selectedCountry.country_name : "",
          };
          this.$store.commit("ON_SET_COUNTRY", country);
          const city = {
            city: this.selectedCity ? this.selectedCity.city_name : "",
          };
          this.$store.commit("ON_SET_CITY", city);
          this.company_id = this.profile.company_id;
          if (this.profile.industry.length) {
            this.industry_id = this.profile.industry.map((m) => {
              return {
                label: m.industry_name,
                industry_id: m.industry_id,
              };
            });
          }
          this.image_path = this.profile.company_employer_image_path;
          (this.admin_name = this.profile.company_admin_name), (this.admin_email = this.profile.company_admin_email);
          this.company_official_email = this.admin_email;
          (this.company_profile = this.profile.company_profile),
            (this.company_website_url = this.profile.company_website_url),
            (this.employer_size = this.profile.company_employer_size),
            (this.company_official_phone = this.profile.company_official_phone),
            (this.company_office_address = this.profile.company_office_address),
            (this.social_link.linked_in = this.profile.linked_in),
            (this.social_link.instagram = this.profile.instagram),
            (this.social_link.facebook = this.profile.facebook),
            (this.social_link.twitter = this.profile.twitter);
          this.social_link.other_link1 = this.profile.other_link1;
          this.social_link.other_link2 = this.profile.other_link2;
          this.employerDetailsloader.hide();
        })
        .catch((err) => {
          this.employerDetailsloader.hide();
        });
    },

    ValidateProfile() {
      return this.$refs.profile.validate().then((result) => {
        if (result) {
          this.onSubmit();
          return true;
        } else {
          this.$toasted.show("Some Fields are missing in Company Profile", {
            position: "top-right",
            duration: 6000,
            icon: "clear",
          });
        }
      });
    },
    async onSubmit() {
      const postData = new FormData();
      const company_id = this.$cookies.get("company_id");

      if (this.image) {
        postData.append("image", this.image);
        if (this.companyImage) {
          postData.append("image2", this.companyImage);
          postData.append("user_id", this.$cookies.get("user_id"));
          postData.append("company_id", this.$cookies.get("company_id"));
        }
        postData.append("removedIndustry", JSON.stringify(this.removedIndustry));
        postData.append("industry_id", JSON.stringify(this.industry_id));
        postData.append("admin_name", this.admin_name);
        postData.append("admin_email", this.admin_email);
        postData.append("company_profile", this.company_profile);
        postData.append("employer_size", this.employer_size);
        postData.append("social_link", JSON.stringify(this.social_link));
        postData.append("company_official_phone", this.company_official_phone);
        postData.append("company_official_email", this.company_official_email);
        postData.append("company_website_url", this.company_website_url);

        postData.append("company_office_address", this.company_office_address);
        postData.append("country_id", this.selectedCountry ? this.selectedCountry.country_id : null);
        postData.append("city_id", this.selectedCity ? this.selectedCity.city_id : null);
        postData.append("company_name", this.company_name);
      } else {
        postData.append("industry_id", JSON.stringify(this.industry_id));
        postData.append("removedIndustry", JSON.stringify(this.removedIndustry));
        if (this.companyImage) {
          postData.append("image2", this.companyImage);
          postData.append("user_id", this.$cookies.get("user_id"));
          postData.append("company_id", this.$cookies.get("company_id"));
        }
        postData.append("admin_name", this.admin_name);
        postData.append("admin_email", this.admin_email);
        postData.append("company_profile", this.company_profile);
        postData.append("employer_size", this.employer_size);
        postData.append("social_link", JSON.stringify(this.social_link));
        postData.append("company_official_phone", this.company_official_phone);
        postData.append("company_official_email", this.company_official_email);
        postData.append("company_website_url", this.company_website_url);

        postData.append("company_office_address", this.company_office_address);
        postData.append("country_id", this.selectedCountry ? this.selectedCountry.country_id : null);
        postData.append("city_id", this.selectedCity ? this.selectedCity.city_id : null);
        postData.append("company_name", this.company_name);
      }

      try {
        CompanyServices.UpdateCompany(postData, company_id)
          .then((res) => {
            this.companyImage = null;
            this.getEmployerDetails();
          })
          .catch((err) => {
            console.log("err in image upload:>> ", err);
          });
        // this.addAlert();
        //  this.getEmployerDetails();

        if (this.videoInput && this.videoInput.size <= 104857600) {
          GetVideoUploadUrl.GetVideoUploadUrl()
            .then((res) => {
              console.log("get upload url successfully!");
              this.videoUploadUrl = res.data.data.result.uploadURL;
              this.showRadial = true;
              this.loading = true;
              this.$bvModal.show("myModel");
              const formData = new FormData();
              formData.append("file", this.videoInput);

              axios
                .post(this.videoUploadUrl, formData, {
                  onUploadProgress: function (progressEvent) {
                    this.completedSteps = parseInt(Math.round(progressEvent.loaded / progressEvent.total) * 100);
                  }.bind(this),
                })
                .then(async (uploadResult) => {
                  this.showRadial = false;
                  let video_url = uploadResult.config.url;
                  const pay = {
                    user_id: this.$cookies.get("user_id"),
                    company_id: this.$cookies.get("company_id"),
                    role_id: this.$cookies.get("role_id"),
                    video_path: video_url.split("/")[3].split("-")[0],
                  };
                  try {
                    const videoUpload = await CompanyServices.uploadCompanyVideo(pay);

                    this.loading = false;
                    this.$bvModal.hide("myModel");
                    this.$toasted.show("Video Uploaded Successfully!", {
                      duration: 6000,
                      icon: "check",
                    });
                    this.videoInput = null;
                  } catch (error) {
                    console.log("error in db :>> ", error);
                    this.loading = false;
                    this.$bvModal.hide("myModel");
                    this.$toasted.show("Video not uploaded Successfully", {
                      duration: 6000,
                      icon: "clear",
                    });
                    // this.videoInput = null;
                  }
                })
                .catch((err) => {
                  console.log("err ------:>> ", err);
                });
              this.$toasted.show("Company successfully updated!", {
                position: "top-right",
                duration: 6000,
                icon: "check",
              });
            })
            .catch((err) => {
              console.log("err during get upload url :>> ", err);
              this.$toasted.show("Uploaded Url not fetched please try again!", {
                duration: 6000,
                icon: "clear",
              });
            });
        } else {
          if (this.videoInput && this.videoInput.size <= 104857600) {
            this.$toasted.show("video size should be less than 100 mb!", {
              position: "top-right",
              duration: 6000,
              icon: "clear",
            });
          }
          if (!this.videoInput) {
            this.$toasted.show("Company successfully updated!", {
              duration: 6000,
              icon: "check",
            });
          }
        }
      } catch (error) {
        this.$toasted.show("Company not updated!", {
          position: "top-right",
          duration: 6000,
          icon: "clear",
        });
      }
    },

    // ADD EMPLOYER
    // addMoreEmployee() {
    //   if (this.selectedSub) {
    //     console.log("this.remainingEmployee :>> ", this.remainingEmployee);
    //     if (
    //       this.createdEmployee.length <
    //         +this.selectedSub.subs_pkg_no_of_users &&
    //       this.remainingEmployee > 0
    //     ) {
    //       this.submit = false;
    //       this.employee.push({
    //         user_username: null,
    //         user_email: null,
    //         user_password: null,
    //         company_id: null,
    //         role_id: null,
    //         addedEmployee: false,
    //         admin: "N",
    //       });

    //       this.remainingEmployee = this.remainingEmployee - 1;
    //       this.$toasted.show(
    //         `You can create ${this.remainingEmployee} more employee`,
    //         {
    //           duration: 6000,
    //         }
    //       );
    //     } else {
    //       this.$toasted.show(
    //         "You have reached max limit of employee creation",
    //         {
    //           duration: 6000,
    //           icon: "clear",
    //         }
    //       );
    //     }
    //   } else {
    //     this.$toasted.show("You dont have any plan", {
    //       duration: 6000,
    //       icon: "clear",
    //     });
    //   }
    // },
    // removeEmployee(index) {
    //   this.employee.splice(index, 1);
    //   this.payload.splice(index, 1);
    //   this.remainingEmployee = this.remainingEmployee + 1;
    //   this.$store.dispatch("onResetEmployeeDetails", { index });
    //   let emp = this.$store.getters.getCreateEmployee;
    // },

    // addEmployeeToStore(counter) {
    //   if (this.selectedSub) {
    //     if (
    //       this.createdEmployee.length < +this.selectedSub.subs_pkg_no_of_users
    //     ) {
    //       this.$refs.addEmployee.validate().then((result) => {
    //         if (result) {
    //           this.employee[counter].role_id = this.$cookies.get("role_id");
    //           this.employee[counter].addedEmployee = true;
    //           this.employee[counter].company_id = this.$cookies.get(
    //             "company_id"
    //           );

    //           let emp = { ...this.employee[counter] };
    //           delete emp.addedEmployee;
    //           const duplicateEmail = this.payload.filter((f) => {
    //             if (f.user_email == emp.user_email) {
    //               this.employee[counter].addedEmployee = false;
    //               return true;
    //             }
    //           });
    //           if (duplicateEmail.length > 0) {
    //             duplicateEmail.forEach((f) => {
    //               this.$toasted.show(
    //                 `${f.user_email} Email has duplicate Email`,
    //                 { duration: 6000, icon: "clear" }
    //               );
    //             });
    //             return;
    //           }
    //           console.log("duplicateEmail :>> ", duplicateEmail);

    //           this.payload.push(emp);

    //           this.payload = [...new Set(this.payload)];

    //           this.$toasted.show("Employee added!", {
    //             position: "top-right",
    //             duration: 6000,
    //             icon: "check",
    //           });
    //         } else {
    //           return;
    //         }
    //       });
    //     } else {
    //       this.$toasted.show(
    //         "You have reached max limit of employee creation.please Upgrade your plan",
    //         { duration: 6000, icon: "clear" }
    //       );
    //       return;
    //     }
    //   } else {
    //     this.$toasted.show("you dont have any plan", {
    //       duration: 6000,
    //       icon: "clear",
    //     });
    //   }
    // },
    // deactivePlan(subs_pkg_id) {
    //   this.$bvModal
    //     .msgBoxConfirm("Do You Want to De-activate this plan", {
    //       title: "Please Confirm",
    //       size: "sm",
    //       buttonSize: "sm",
    //       okVariant: "danger",
    //       okTitle: "YES",
    //       cancelTitle: "NO",
    //       footerClass: "p-2",
    //       hideHeaderClose: false,
    //       centered: true,
    //     })
    //     .then((value) => {
    //       if (value) {
    //         CheckoutServices.DeActivatePlan({ subs_pkg_id })
    //           .then((res) => {
    //
    //             this.$toasted.show("Plan De-Activated", {
    //               duration: 6000,
    //               icon: "check",
    //             });
    //             this.getSelectedSubscription();
    //           })
    //           .catch((err) => {
    //
    //           });
    //       } else {
    //         return;
    //       }
    //     });
    // },
    // validateEmployee() {
    //   let valid = true;
    //   return new Promise((resolve, reject) => {
    //     this.employee.map((m) => {
    //       let user_email = m.user_email ? true : false;
    //       let user_password = m.user_password ? true : false;
    //       let user_username = m.user_username ? true : false;
    //       console.log(user_email, user_password, user_username);
    //       if (!user_email && !user_password && !user_username) {
    //         valid = false;
    //       }
    //     });
    //     resolve(valid);
    //   });
    // },
    // async submitEmployeeDetails() {
    //   this.submit = true;
    //   let validEmployee = await this.validateEmployee();
    //   console.log(`validEmployee`, validEmployee);

    //   if (this.payload.length > 0 && validEmployee) {
    //     UserService.createEmployee(this.payload)
    //       .then((res) => {
    //         if (res.data.data.user.length > 0) {
    //           this.payload = [];
    //           this.employee = res.data.data.user.map((m) => {
    //             return {
    //               user_username: m.user_username,
    //               user_email: m.user_email,
    //               user_password: null,
    //               company_id: m.company_id,
    //               role_id: 2,
    //               addedEmployee: false,
    //               admin: "N",
    //             };
    //           });
    //           const email = res.data.data.user;
    //           email.forEach((e) => {
    //             this.$toasted.show(`${e.user_email} Email already exists`, {
    //               position: "top-right",
    //               duration: 5000,
    //               icon: "clear",
    //             });
    //           });
    //         } else {
    //           this.payload = [];
    //           (this.employee = []),
    //             this.$toasted.show("Employee added Successfully", {
    //               position: "top-right",
    //               duration: 6000,
    //               icon: "check",
    //             });
    //         }
    //       })
    //       .catch((error) => {
    //
    //         this.$toasted.show(
    //           "This email already registered with other company \n OR \n Internal Server Error!",
    //           {
    //             position: "top-right",
    //             duration: 6000,
    //             icon: "clear",
    //           }
    //         );
    //       });
    //   } else {
    //     this.$toasted.show("Employee Not Added", {
    //       position: "top-right",
    //       duration: 6000,
    //       icon: "clear",
    //     });
    //     return;
    //   }
    // },
  },
};
</script>

<style scoped>
.plan-btn {
  width: 142px;
  font-family: pn-regular;
  letter-spacing: 0.9px;
  /* height: 54px; */
}
.company-details .btn-crud {
  background: #bfbfbf;
  font-family: mp-medium;
  font-size: 13px;
  border-radius: 20px;
  padding: 08px 22px;
  color: white;
  border: 1px solid #6244e5;
  margin-bottom: 0;
}
/* .custom-list-group {
  height: 60%;
} */

.pricing-tabs .tab-content > .tab-pane:not(.active) {
  display: block !important;
  height: 0;
  overflow-y: hidden;
}

.multis >>> .multiselect__tags {
  /* color: orange; */
  background-color: #eaeaea;
}
.multis >>> .multiselect__tag {
  background: #6244e5;
}

.multis >>> .multiselect__tag-icon:after {
  color: white;
}

.multis >>> .multiselect__tag-icon:hover {
  background: #6244e5;
}
.multis >>> .multiselect__option--highlight {
  background: #6244e5;
}

.multis >>> .multiselect__option:after {
  background-color: #6244e5;
  color: white;
}

.multis >>> .multiselect__input {
  background-color: #eaeaea;
}

.emp-settings-page .user-add-col {
  grid-template-columns: 23% 22% 22% 27%;
}

.emp-settings-page .error-msg.is-invalid span {
  padding: 0;
}
</style>

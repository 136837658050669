<template>
  <div class="table-responsive text-center mt-2">
    <table class="table">
      <thead class="thead">
        <tr>
          <th scope="col">{{ $gettext("Title") }}</th>
          <th scope="col">{{ $gettext("Deadlines") }}</th>
          <th scope="col">{{ $gettext("Applicants") }}</th>
          <th scope="col">{{ $gettext("Shortlisted") }}</th>
          <th scope="col">{{ $gettext("Delete") }}</th>
          <th scope="col">{{ $gettext("Update") }}</th>
          <th scope="col">{{ $gettext("Status") }}</th>
          <th scope="col">{{ $gettext("View") }}</th>
        </tr>
      </thead>

      <div class="not-found-div" v-if="!loading && jobs.alljobs && jobs.alljobs.length == 0">
        <h1 v-if="!search">{{ $gettext("You_havent_created_any_job_yet") }}</h1>
        <h1 v-if="search">{{ $gettext("Your_search_produced_no_results") }}</h1>
      </div>
      <!-- <div class="not-found-div text-center" v-if="loading">
        <b-spinner label="Loading.." style="width: 5rem; height: 5rem"></b-spinner>
        <h3>Loading Your Jobs ....</h3>
      </div> -->
      <tbody v-for="(data, index) in jobs.alljobs" :key="index">
        <tr>
          <th scope="row" style="cursor: pointer; color: blue" @click="() => {
            $router.push({ name: 'Job-Details', params: { id: data.job_id } });
          }
            ">
            {{ data.job_title }}
          </th>
          <td>
            {{ data.job_end_date }}
          </td>
          <td v-if="data.applicants" @click="
            $router.push({
              name: 'Employer-Dashboard',
              params: { job_id: data.job_id },
              query: { tab_id: 0 },
            })
            " style="cursor: pointer; color: blue">
            {{ data.applicants.length }}
          </td>
          <td v-if="data.applicants" @click="
            $router.push({
              name: 'Employer-Dashboard',
              params: { job_id: data.job_id },
              query: { tab_id: 1 },
            })
            " style="cursor: pointer; color: blue">
            {{ data.shortlist.length }}
          </td>
          <!-- <td></td> -->
          <td>
            <img src="@/assets/app-icons/delete.svg" width="15px" @click="onDelete(data.job_id)"
              style="cursor: pointer" />
          </td>
          <td style="min-width: 125px;">
            <img id="update" title="Edit Job" src="@/assets/app-icons/blue-edit.svg" width="15px" style="cursor: pointer"
              @click="() => {
                if (data.archived == 'N') {
                  $router.push({
                    name: 'Update-JobCreation-1',
                    params: { job_id: data.job_id },
                  });
                } else {
                  $toasted.show($gettext('Activate_Your_plan'), {
                    duration: 6000,
                  });
                }
              }
                " />
            <img id="update1" title="Edit Interview Questions" src="@/assets/app-icons/calendar-lines-pen.svg"
              width="15px" style="cursor: pointer; margin-left: 10px" @click="() => {
                if (data.archived == 'N') {
                  $router.push({
                    name: 'Update-InterviewQue',
                    params: { job_id: data.job_id },
                  });
                } else {
                  $toasted.show($gettext('Activate_Your_plan'), {
                    duration: 6000,
                  });
                }
              }
                " />
            <div class="share-text" @click="share(data)" style="cursor: pointer; display: inline">
              <!-- <span> SHARE </span> -->
              <img src="@/assets/app-icons/share_desc.svg" width="16px" class="ml-2 mb-0" />
            </div>
          </td>
          <td>
            <!-- :class="
                            data.job_active ? 'label-open' : 'label-closed'
                          " -->
            <label>{{ data.job_active ? "open" : "closed" }}</label>
          </td>
          <!-- <td>
            <router-link
              :to="{
                name: 'Employer-Dashboard',
                params: { job_id: data.job_id },
              }"
              :disabled="selectedSub == undefined"
              class="ml-auto"
            >
              <img
                src="@/assets/app-icons/arrow-thin-right.svg"
                width="10px"
                height="30px"
                class="status-arrow"
              />
            </router-link>
          </td> -->
        </tr>
      </tbody>
    </table>
    <b-modal id="share-modal" ref="modal" @hidden="resetModal" @ok="handleOk" hide-footer>
      <div>
        <h4>{{ $gettext("Share_this_job_on_social_network") }}</h4>
        <div class="social-icons-grid">
          <ShareNetwork network="Whatsapp" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-whatsapp"></i>
            </div>
          </ShareNetwork>
          <ShareNetwork network="Facebook" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-facebook"></i>
            </div>
          </ShareNetwork>
          <ShareNetwork network="Twitter" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-twitter"></i>
            </div>
          </ShareNetwork>
          <!-- <ShareNetwork network="fakeblock" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </div>
          </ShareNetwork> -->

          <!-- <a :href="`https://www.snapchat.com/scan?attachmentUrl=${SERVERURL}job-details/${selectedJob.job_id}`"
            target="_blank">
            <i class="fa fa-snapchat-square" aria-hidden="true"></i>
          </a> -->


          <!-- <a href="https://www.tiktok.com/en/" target="_blank">
            <div style="width:17.15px; margin-top: -2px;">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333335 333336" shape-rendering="geometricPrecision"
                text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd"
                clip-rule="evenodd">
                <path
                  d="M72464 0h188407c39856 0 72464 32609 72464 72465v188407c0 39855-32608 72464-72464 72464H72464C32608 333336 0 300727 0 260872V72465C0 32609 32608 0 72464 0zm127664 70642c337 2877 825 5661 1461 8341l6304 2c1170 9991 4006 19119 8465 26697 7282 6745 16797 10904 28280 11641v9208c2131 444 4350 746 6659 894v29690c-14847 1462-27841-3426-42981-12531l2324 50847c0 16398 61 23892-8738 38977-20546 35222-58194 36677-82176 18323-12269-4256-23069-12466-29881-23612-19875-32516-1959-85512 55687-90966l-94 7835v1970c3105-646 6365-1144 9794-1468v31311c-12484 2057-20412 5890-24119 12980-7424 14197-4049 26526 3716 34309 16276 2796 34401-8481 31673-43351V70644h33628z"
                  fill="#1a121f" />
                <path
                  d="M200128 70642c3093 26406 18915 45038 44510 46681v25046l-165 15v-21275c-25595-1642-40311-17390-43404-43796l-27114-1v111095c3912 50005-35050 51490-49955 32531 17482 10934 45867 3826 42501-39202V70641h33628zm-72854 184165c-15319-3153-29249-12306-37430-25689-19875-32516-1959-85512 55687-90966l-94 7835c-53444 8512-58809 65920-44009 89802 5707 9209 15076 15686 25846 19019z"
                  fill="#26f4ee" />
                <path
                  d="M207892 78985c1761 15036 7293 28119 16454 36903-12866-6655-20630-19315-23062-36905l6609 2zm36580 47511c2181 463 4456 777 6824 929v29690c-14847 1462-27841-3426-42981-12531l2324 50847c0 16398 61 23892-8738 38977-21443 36760-61517 36743-85239 15810 30930 17765 84928 3857 84829-56453v-55496c15141 9105 28134 13993 42981 12530v-24302zm-99036 21460c3105-646 6365-1144 9794-1468v31311c-12484 2057-20412 5890-24119 12980-10441 19964 474 36238 14923 41365-18075-649-36010-19214-23555-43031 3707-7089 10474-10923 22958-12980v-28177z"
                  fill="#fb2c53" />
                <path
                  d="M201068 77313c3093 26406 17809 42154 43404 43796v29689c-14847 1462-27841-3425-42981-12530v55496c119 72433-77802 77945-100063 42025-14800-23881-9435-81290 44009-89802v30147c-12483 2057-19250 5891-22958 12980-22909 43808 56997 69872 51475-706V77313l27114 1z"
                  fill="#fefefe" />
              </svg>
            </div>
          </a> -->
          <ShareNetwork network="Linkedin" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-linkedin"></i>
            </div>
          </ShareNetwork>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SERVERURL } from "@/config";
import { async } from "q";
import { ref } from "vue";
export default {
  name: "EmployerJobs",
  props: ["jobs", "selectedSub", "loading", "search"],
  setup() {
    const selectedJob = ref({ job_id: null, job_title: null, job_description: null });
    function updateAdd(job) {
      console.log("updateAdd", job);
      selectedJob.value.job_id = job.job_id;
      selectedJob.value.job_title = job.job_title;
      selectedJob.value.job_description = job.job_description;
      // this.selectedJob.job_title = job.job_title;
      // this.selectedJob.job_description = job.job_description;
    }

    return { selectedJob, updateAdd };
  },
  data() {
    return {
      // selectedJob:ref({}),
      SERVERURL: SERVERURL,
    };
  },
  methods: {
    onDelete(job_id) {
      this.$emit("deleteJob", job_id);
    },
    resetModal() {
      this.updateAdd({ job_id: null, job_title: null, job_description: null });
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      console.log("at handleOk", this.selectedJob);
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return;
      // }
      // Push the name to submitted names
      // Hide the modal manually

      this.$nextTick(() => {
        this.$bvModal.hide("share-modal");
      });
    },
    share(job) {
      console.log(`job=====`, job);
      // this.selectedJob.job_id = job.job_id;
      // this.selectedJob.job_title = job.job_title;
      // this.selectedJob.job_description = job.job_description;
      this.updateAdd(job);
      // console.log(' this.selectedJob', this.selectedJob);
      this.$bvModal.show("share-modal");
    },
  },
  // watch: {
  //   selectedJob:{
  //     handler(newValue, oldValue) {
  //     console.log(`oldValue=====`, oldValue);
  //       newValue=oldValue;
  //       // Note: `newValue` will be equal to `oldValue` here
  //       // on nested mutations as long as the object itself
  //       // hasn't been replaced.

  //     },
  //     deep: true
  //   },
  //   url(newValue , oldValue){
  //     this.url=`${SERVERURL}job-details/${selectedJob.job_id}`
  //     console.log('newValue',newValue);
  //   }
  // },
};
</script>

<style></style>

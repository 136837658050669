<template>
  <b-col cols="12" class="px-0">
    <nav class="navbar navbar-expand-md shadow-none p-4">
      <!-- logo :: start -->
      <router-link class="navbar-brand" to="/">
        <img width="128" height="38" src="@/assets/logos/Logo-Rectangle-White.svg" class="img-fluid" />
      </router-link>
      <!-- logo :: end -->

      <!-- menu :: start -->
      <div class="d-sm-none mobile-menu">
        <!-- <button class="btn btn-mobile-menu" @click="showMenu">Menu</button> -->
        <button class="btn btn-mobile-menu" @click="showMenu"><span class="material-icons"
            style="color: aliceblue;">menu</span></button>
      </div>
      <!-- menu :: end -->

      <!-- navbar :: start -->
      <div class="navbar-collapse" :class="{ isMobileMenu }">
        <div class="text-right d-sm-none">
          <svg @click="hideMenu" width="3rem" height="3rem" viewBox="0 0 16 16" class="text-body bi bi-x"
            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
        <ul class="navbar-nav mr-auto"></ul>
        <ul class="navbar-nav align-items-center">
          <router-link class="nav-item" to="/download-zaajira-app">
            <b-button class="cta-btn p-3"> {{ $gettext("Looking_for_job") }} </b-button>
          </router-link>
        </ul>
        <div>
          <LanguageSelector />
        </div>
      </div>
      <!-- navbar :: end -->
    </nav>
  </b-col>
</template>
<script>
import LanguageSelector from "../../views/LanguageSelector.vue";
export default {
  name: "NavbarComp",
  components: { LanguageSelector },
  data() {
    return {
      isMobileMenu: window.innerWidth <= 700,
    };
  },
  methods: {
    showMenu: function () {
      document.querySelector(".isMobileMenu").classList.add("show-menu");
    },
    hideMenu: function () {
      document.querySelector(".isMobileMenu").classList.remove("show-menu");
    },
  },
};
</script>
<style scoped>
.navbar {
  background-color: #6244e5;
}

.cta-btn {
  width: 282px;
  background-color: transparent;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 1) !important;
  opacity: 1;
}

@media screen and (max-width:575px) {
  .cta-btn {
    width: 100%;
  }
}
</style>

<template>
  <div class="table-responsive text-center mt-2">
    <table class="table">
      <thead class="thead">
        <tr>
          <th scope="col">{{ $gettext("Title") }}</th>
          <th scope="col">{{ $gettext("Deadlines") }}</th>
          <!-- <th scope="col">{{ $gettext("Applicants") }}</th> -->
          <!-- <th scope="col">{{ $gettext("Shortlisted") }}</th> -->
          <th scope="col">{{ $gettext("Delete") }}</th>
          <th scope="col">{{ $gettext("Update") }}</th>
          <th scope="col">{{ $gettext("Status") }}</th>
          <th scope="col">{{ $gettext("View") }}</th>
        </tr>
      </thead>

      <div class="not-found-div" v-if="jobs.alljobs && jobs.alljobs.length == 0 && !loading">
        <h1>{{ $gettext("No_Draft_job_found") }}</h1>
      </div>
      <!-- <div class="not-found-div text-center" v-if="loading">
        <b-spinner label="Loading.." style="width: 5rem; height: 5rem"></b-spinner>
        <h3>Loading Your Jobs ....</h3>
      </div> -->
      <tbody v-for="(data, index) in jobs.alljobs" :key="index">
        <tr>
          <th scope="row" style="cursor: pointer; color: blue" @click="() => {
            $router.push({ name: 'Job-Details', params: { id: data.job_id } });
          }
            ">
            {{ data.job_title }}
          </th>
          <td>
            {{ data.job_end_date }}
          </td>
          <!-- <td v-if="data.applicants">
            {{ data.applicants.length }}
          </td> -->
          <!-- <td v-if="data.applicants">
            {{ data.shortlist.length }}
          </td> -->
          <!-- <td></td> -->
          <td>
            <img src="@/assets/app-icons/delete.svg" width="15px" style="cursor: pointer"
              @click="onDelete(data.job_id)" />
          </td>
          <td>
            <!-- {{ data.job_id }} -->
            <img src="@/assets/app-icons/blue-edit.svg" width="15px" style="cursor: pointer"
              @click="getDraftJob(data.job_id)" />
            <!-- @click="
                () => {
                  if (data.archived == 'N') {
                    $router.push({
                      name: 'Update-JobCreation-1',
                      params: { job_id: data.job_id },
                    });
                  } else {
                    $toasted.show('Activate Your plan', {
                      duration: 6000,
                    });
                  }
                }
              " -->
          </td>
          <td>
            <!-- :class="
                            data.job_active ? 'label-open' : 'label-closed'
                          " -->
            <label>{{ data.job_active ? "open" : "closed" }}</label>
          </td>
          <!-- <td>
            <router-link
              :to="{
                name: 'Employer-Dashboard',
                params: { job_id: data.job_id },
              }"
              :disabled="selectedSub == undefined"
              class="ml-auto"
            >
              <img
                src="@/assets/app-icons/arrow-thin-right.svg"
                width="10px"
                height="30px"
                class="status-arrow"
              />
            </router-link>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import RecruiterJobService from "@/apiServices/RecruiterJobService";
export default {
  name: "DraftJobs",
  props: ["jobs", "selectedSub", "loading"],
  methods: {
    onDelete(job_id) {
      console.log("job_id :>> ", job_id);
      this.$emit("deleteJob", job_id);
    },
    getDraftJob(d_job_id) {
      console.log(d_job_id);
      // let loader = this.$loading.show();
      RecruiterJobService.GetSingleDraftJob({ d_job_id })
        .then((res) => {
          const { job_experiance, job_id, responsibility } = res.data.data[0]
          console.log("res.data.data", res.data.data);
          console.log("job_experiance", job_experiance);
          console.log("responsibility", responsibility);

          if (responsibility.length) {
            this.$router.push({
              name: 'Employer-JobCreation-1',
              query: { d_job_id: job_id, virtualInterviewPage: true },
            })
          }
          else if (job_experiance !== null) {
            this.$router.push({
              name: 'Employer-JobCreation',
              query: { d_job_id: job_id, candidatePage: true },
            })
          }
          else {
            this.$router.push({
              name: 'Employer-JobCreation',
              query: { d_job_id: job_id, basicDetailPage: true },
            })
          }
        })
    },

  },
};
</script>

<style></style>

import numeral from 'numeral';
import { convertTime } from "@/helpers/HelperFunctions.js";
import moment from 'moment/moment';


export const formattedNumber = (sal) => {
    //console.log(sal);
    return numeral(sal).format('0,0');
}

export const convertToTimeSince = (created_at) => {
    const now = moment();
    const created = moment(created_at).format("YYYY-MM-DD HH:mm:ss");
    let diff = now.diff(created, "minutes");
    const timeSince = convertTime(diff)
    return timeSince
}
<template>
  <b-container fluid class="user-header">
    <div class="pt-5 mt-5">
      <!-- <img src="@/assets/app-icons/left-arrow.svg" width="10px" height="15px" />
      <a @click="$router.go(-1)"> Back</a> -->
    </div>
    <b-row>
      <b-col class="col-md-7 col-12 user-about pt-3 pb-5">
        <div class="user-img">
          <a
            style="cursor: pointer"
            @click="
              () => {
                if ($route.name != 'Employer-Profile') $router.push({ name: 'Employer-Profile' });
              }
            "
          >
            <img :src="`${image_url}${this.$store.getters.getProfileImage}`" class="rounded img-center" width="100" />
            <img v-if="!this.$store.getters.getProfileImage" src="../../assets/unknown.jpg" class="rounded img-center" width="100" />
          </a>
        </div>

        <div class="user-content ml-3">
          <a
            style="cursor: pointer"
            @click="
              () => {
                if ($route.name != 'Employer-Profile') $router.push({ name: 'Employer-Profile' });
              }
            "
          >
            <h3 class="user-name">
              {{ $store.getters.getLoggedUser.userName }}
            </h3>
            <p class="user-loc">{{ $store.getters.getCountry }}<br />{{ $store.getters.getCity }}</p>
          </a>
        </div> </b-col
      ><!--End User About-->

      <b-col class="col-md-5 align-self-center" v-if="$route.fullPath === '/applicant-profile-emp'">
        <div class="app-emp-header">
          <div>
            <img src="@/assets/app-icons/forward.svg" width="15px" />
            <h6>Shortlist</h6>
          </div>
          <div>
            <img src="@/assets/app-icons/forward.svg" width="15px" />
            <h6>Message</h6>
          </div>
          <div>
            <img src="@/assets/app-icons/forward.svg" width="15px" />
            <h6>Forward</h6>
          </div>
        </div>
      </b-col>
      <b-col class="col-md-12 col-xl-5 user-header-nav" v-if="$route.fullPath !== '/applicant-profile-emp'">
        <ul class="user-nav-list">
          <li class="user-nav-item">
            <router-link class="user-nav-link" :to="{ name: 'Employer-Profile' }">Profile</router-link>
          </li>
          <li class="user-nav-item">
            <!-- <router-link
                    class="user-nav-link"
                    :to="{ name: 'Employer-jobs' }"
                    >Jobs</router-link
                  > -->
            <router-link
              class="user-nav-link"
              :class="{
                'router-link-active': $route.fullPath === '/employer-jobs' || $route.fullPath === '/employer-jobcreation-1',
              }"
              :to="{ name: 'Employer-jobs' }"
              >JOBS</router-link
            >
          </li>
          <li class="user-nav-item">
            <router-link class="user-nav-link" :to="{ name: 'Employer-settings' }">Settings</router-link>
          </li>
          <li class="user-nav-item">
            <router-link class="user-nav-link" :to="{ name: 'Employer-Messages' }">Messages</router-link>
          </li>
          <!-- <li class="user-nav-item">
            <router-link class="user-nav-link" to="/employer-message"
              >Messages</router-link
            >
          </li> -->
        </ul> </b-col
      ><!--End User Header Nav-->
    </b-row>
  </b-container>
</template>
<script>
import { IMAGEURL } from "@/config";
export default {
  data() {
    return {
      image_url: IMAGEURL,
    };
  },
};
</script>
<style></style>

import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import Forgot_Password from "@/views/ForgotPassword.vue";
import DownloadZaajiraApp from "@/views/Candidate-Page/DownloadZaajiraApp";
import { validateLoginStatus } from "../store";

export default [
  {
    path: "/home-new",
    name: "HomeNew",
    component: () => import("../views/Home.vue"),
    beforeEnter: validateLoginStatus,
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeNew.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: validateLoginStatus,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    beforeEnter: validateLoginStatus,
  },
  {
    path: "/forgot-password",
    name: "Forgot_Password",
    component: Forgot_Password,
    beforeEnter: validateLoginStatus,
  },
  {
    path: "/download-zaajira-app",
    name: "Download-Zaajira-App",
    component: DownloadZaajiraApp,
  },


  // candidates terms-and-conditions
  {
    path: "/terms-and-conditions",
    name: "Terms-And-Conditions",
    component: () => import("@/views/Terms-And-Conditions.vue"),
  },
  // candidates privacy policy
  {
    path: "/privacy-policy",
    name: "Privacy-Policy",
    component: () => import("@/views/Privacy-Policy.vue"),
  },
  // candidates support
  {
    path: "/support",
    name: "Support",
    component: () => import("@/views/Support.vue"),
  },
  // candidates technology
  {
    path: "/technology",
    name: "Technology",
    component: () => import("@/views/Technology.vue"),
  },
  // candidates
  {
    path: "/applicants",
    name: "Applicants",
    component: () => import("@/views/Applicants.vue"),
  },
  // employers
  {
    path: "/employers",
    name: "Employers",
    component: () => import("@/views/Employers.vue"),
  },
  // refund policy
  // {
  //   path: "/refund-policy",
  //   name: "Refund Policy",
  //   component: () => import("@/views/RefundPolicy.vue"),
  // },

  // hiring candidates
  {
    path: "/hiring",
    name: "Hiring",
    component: () => import("@/views/Hiring.vue"),
  },
];

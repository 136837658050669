import axios from "axios";
import { BASEURL } from "@/config";
import Vue from "vue";
import store from "@/store";

export default () => {
  const instance = axios.create({
    baseURL: BASEURL,
  });
  Vue.$cookies.get("AIAPADHASHID") ? (instance.defaults.headers.common["Authorization"] = Vue.$cookies.get("AIAPADHASHID")) : undefined;

  instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

  instance.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      if (!error["response"]) {
        //hanlde error
      } else if (error.response.status == 403) {
        //hanlde error
        store.dispatch("onLogout");
        //TODO : call store logout function
      } else if (error.response.status == 401) {
        store.dispatch("onLogout");
        //hanlde error
        //TODO : call store logout function
      } else {
        //handle error
        //TODO : call store logout function
      }
      // return Promise.reject(error);
      return Promise.reject("Server error");
    }
  );
  return instance;
};

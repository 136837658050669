import Vue from "vue";
import VuexPersistence from "vuex-persist";
import Vuex from "vuex";
import Cookies from "js-cookie";
import modules from "./modules";
import router from "@/router";
Vue.use(Vuex);

// import VueCookie from 'vue-cookie';
// import createPersistedState from 'vuex-persistedstate'

const store = new Vuex.Store({
  modules,
  plugins: [
    new VuexPersistence({
      restoreState: (key, storage) => Cookies.getJSON(key),
      saveState: (key, state, storage) =>
        Cookies.set(key, state, {
          expires: 30,
        }),
      modules: ["CandidateStorePersist"], //only save user module
      // filter: (mutation) => mutation.type == 'logIn' || mutation.type == 'logOut'
    }).plugin,
  ],
  strict: process.env.NODE_ENV !== "production",
});

export function validateLoginStatus(to, from, next) {
  console.log("validation");
  console.log({ to, from, next });
  store.watch(function () {
    const { getLoggedUser } = store.getters;
    console.log(getLoggedUser);
    const { userEmail, userToken, userRole, userName, isLoggedIn } = getLoggedUser;

    if (isLoggedIn) {
      if (from?.path === "/") {
        // console.log('if::from', from);
        next({ path: "candidate/home" });
      } else {
        // console.log('else::from', from);
        next({ path: from.path });
      }
      return;
    }
    // if ((userEmail && userToken) || (userRole && userName)) {
    //   if (from?.path === "/") {
    //     next({ path: "candidate/home" });
    //   } else {
    //     next({ path: from.path });
    //   }
    //   return;
    // }
    next();
  });
}

export default store;

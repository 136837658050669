// export const BASEURL = "https://stagebackend.zajeera.com/api/";

// export const SERVERURL = "https://zajeera.com/#/";
// export const API_VERSION = "v1.0";
// export const S3BASEURL = "";

// export const socialLoginClientId =
//     "179351463090-udiuuc4bc9cr6qjenkpischuf42s8p26.apps.googleusercontent.com";

// export const reCaptchaSiteKey = "6LczdsofAAAAAD4IIGEBPucKLkqczksvHwvXXQNj";

// export const BASEURL = "https://zaajira.com/apiapi/";   //this url is back end and use apiapi
// export const SERVERURL = "https://zaajira.com/#/";
// // export const SERVERURL = "https://zaajira.com/ap/#/";
// // export const SERVERURL = "https://apply.zaajira.com/#/";
// export const IMAGEURL = "https://zaajira.com/api";
// export const apipublic = "https://zaajira.com/apiapipublic";
// export const userProfile = "https://www.zaajira.com/#/applicant-profile-employer/";

// // local server
// export const FRONTEND_BASE_URL = "http://localhost:8080/#/";
// export const BASEURL = "http://localhost:6002/api/";
// export const SERVERURL = "http://localhost:6002/#/";
// export const EMPLOYER_SERVERURL = "http://localhost:8081/emp/#/";
// export const userProfile = "http://localhost:8080/#/applicant-profile-employer/";
// export const IMAGEURL = "http://localhost:6002/";
// export const apipublic = "http://localhost:6002/apipublic";


// New URL Config @start
export const BASEURL = "https://zaajira.com/apiapi/";   //this url is back end and use apiapi
export const IMAGEURL = "https://zaajira.com/api";
export const SERVERURL = "https://zaajira.com/#/";
export const EMPLOYER_SERVERURL = "https://zaajira.com/emp/#/";
// export const SERVERURL = "https://zaajira.com/ap/#/";
// export const SERVERURL = "https://apply.zaajira.com/#/";
export const apipublic = "https://zaajira.com/apiapipublic";
export const userProfile = "https://www.zaajira.com/#/applicant-profile-employer/";
// New URL Config @end

export const API_VERSION = "v1.0";
export const S3BASEURL = "";

export const socialLoginClientId =
    "179351463090-udiuuc4bc9cr6qjenkpischuf42s8p26.apps.googleusercontent.com";

export const reCaptchaSiteKey = "6LczdsofAAAAAD4IIGEBPucKLkqczksvHwvXXQNj";

<template>
  <div class="bg-grey">
    <b-container fluid class="user-profile-screen emp-profile-screen py-4 mt-5">
      <ValidationObserver ref="job_creation" tag="div"
        class="container-fluid profile-user job-create customized-form px-1 px-md-3 pt-4 pb-3">
        <div class="col-md-12 py-3 my-3 box">
          <div class="profile-flex border-bottom py-3">
            <h4 class="heading">{{ $gettext("Basic_details") }}</h4>
          </div>
          <div class="pt-2 emp-basic-details apply-job-card">
            <!-- firstRow :: start -->
            <div class="row">
              <!-- jobTitle :: start  -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Job_title") }}</h6>
                  <vue-simple-suggest :placeholder="$gettext('Job_title')" class="suggest suggest-input form-control"
                    v-model="job_title" :list="job_titles_list" :min-length="1" :filter-by-query="true"
                    value-attribute="job_title_id" display-attribute="job_title" @select="setDescription"
                    :class="submitted && (!job_title || String(job_title).length == 0) ? 'suggest-input-error' : ''">
                  </vue-simple-suggest>
                  <div v-if="submitted && (!job_title || String(job_title).length == 0)" class="validate-error">
                    <span>{{ "This field is required" }}</span>
                  </div>
                </div>
              </div>
              <!-- jobTitle :: end -->

              <!-- jobCategory :: start -->
              <div class="col-md-4 mt-3">
                <div class="multiselect-dropdown">
                  <h6 class="fpn-b">{{ $gettext("Job_Category") }}</h6>
                  <multiselect class="multis" v-model="speciality" :placeholder="$gettext('Job_Category')"
                    @remove="remspecialityHandle" @select="onSelect" label="checkbox" track-by="checkbox_id"
                    :options="specialityOpt" :multiple="false" />
                </div>
              </div>
              <!-- jobCategory :: end -->

              <!-- jobDescription :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Job_Description") }}</h6>
                  <ValidationProvider name="description" rules="required" v-slot="{ errors, classes }">
                    <b-form-textarea :placeholder="$gettext('Write_Job_Description')" v-model="job_description"
                      :class="classes" rows="3" class="h-50"></b-form-textarea>
                    <div class="error-msg" :class="classes">
                      <!-- <span>{{ errors[0] }}</span> -->
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- jobDescription :: end -->
            </div>
            <!-- firstRow :: end -->

            <!-- secondRow :: start -->
            <div class="row">
              <!-- location :: start -->
              <div class="col-md-4 mt-3">
                <div class="multiselect-dropdown">
                  <h6 class="fpn-b">{{ $gettext("Location") }} *</h6>
                  <div class="grid-half">
                    <ValidationProvider name="country" rules="required" class="grid-item" tag="div"
                      v-slot="{ errors, classes }">
                      <multiselect style="" class="multis" :class="classes" v-model="country"
                        :placeholder="$gettext('Select_country')" @input="onChangeCountry" @remove="removeCountry"
                        label="label" track-by="country_id" :options="countries" :multiple="true" :limit="1"
                        :limit-text="limitText" :max="10" />
                      <div class="error-msg" :class="classes">
                        <!-- <span>{{ errors[0] }}</span> -->
                        <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider name="city" rules="required" class="grid-item" tag="div"
                      v-slot="{ errors, classes }">
                      <multiselect style="" class="multis" :class="classes" v-model="city"
                        :placeholder="$gettext('Select_city')" label="label" :max="10" track-by="city_id"
                        :options="cities" @input="onChangeCity" @remove="removeCity" :multiple="true" :limit="1"
                        :limit-text="limitTextCity" />
                      <div class="error-msg" :class="classes">
                        <!-- <span>{{ errors[0] }}</span> -->
                        <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <!-- location :: end -->

              <!-- vacancies :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("No_Of_Vacancies") }}*</h6>
                  <ValidationProvider name="vacancy" rules="required" v-slot="{ errors, classes }">
                    <b-form-input type="number" :disabled="!basicDetails" :placeholder="$gettext('Vacancies')"
                      :class="classes" v-model="vacancy"> </b-form-input>
                    <div class="error-msg" :class="classes">
                      <!-- <span>{{ errors[0] }}</span> -->
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- vacancies :: end -->

              <!-- jobType :: start -->
              <div class="col-md-4 mt-3">
                <div class="multiselect-dropdown">
                  <h6 class="fpn-b">{{ $gettext("Type_of_Job") }}</h6>
                  <ValidationProvider name="job_type" rules="required" v-slot="{ errors, classes }">
                    <v-select :multiple="false" :placeholder="$gettext('Select_Job_Type')" class="form-control"
                      :class="classes"
                      :options="['Any', 'Contract', 'Full Time', 'Internship', 'Part Time', 'Remote', 'Work From Home']"
                      v-model="job_type" />
                    <div class="error-msg" :class="classes">
                      <!-- <span>{{ errors[0] }}</span> -->
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- jobType :: end -->
            </div>
            <!-- secondRow :: end -->

            <!-- thirdRow :: start -->
            <div class="row">
              <!-- minSalary :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Min_Salary_Month") }}*</h6>
                  <ValidationProvider name="from" rules="required" v-slot="{ errors, classes }" tag="div">
                    <b-form-input type="text" :placeholder="$gettext('Min_Salary_Month')" :class="classes"
                      v-model="salary_from"> </b-form-input>
                    <div class="error-msg" :class="classes">
                      <!-- <span>{{ errors[0] }}</span> -->
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- minSalary :: end -->

              <!-- maxSalary :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Max_Salary_Month") }}</h6>
                  <ValidationProvider name="to" rules="required" v-slot="{ errors, classes }" tag="div">
                    <b-form-input type="text" :placeholder="$gettext('Max_Salary_Month')" :class="classes"
                      v-model="salary_to"> </b-form-input>
                    <div class="error-msg" :class="classes">
                      <!-- <span>{{ errors[0] }}</span> -->
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- maxSalary :: end -->

              <!-- currency :: start -->
              <div class="col-md-4 mt-3">
                <div class="multiselect-dropdown">
                  <h6 class="fpn-b">{{ $gettext("Currency") }}</h6>
                  <ValidationProvider name="currency_name" rules="required" tag="div" v-slot="{ errors, classes }">
                    <multiselect class="multis" :class="classes" v-model="currency"
                      :placeholder="$gettext('Select_Currency')" label="currency_name" track-by="currency_id"
                      :options="currencies" :multiple="false" :limit="1" :limit-text="limitTextCity" />
                    <div class="error-msg" :class="classes">
                      <!-- <span>{{ errors[0] }}</span> -->
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- currency :: end -->
            </div>
            <!-- thirdRow :: end -->

            <!-- fourthRow :: start -->
            <div class="row">
              <!-- jobDeadline :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Job_Deadline") }}</h6>
                  <ValidationProvider name="deadline" rules="required" v-slot="{ errors, classes }" tag="div">
                    <!-- <datePicker :disabled-dates="disabled_dates" :disabled="!basicDetails" :value="state.date" v-model="job_end_date" placeholder="Select Deadline" class="custom-date width-full" :class="classes"></datePicker>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div> -->
                    <b-form-input class="custom-date width-full" :class="classes" type="date" :value="job_end_date"
                      @input="onChangeDate" />
                    <div class="error-msg" :class="classes">
                      <!-- <span>{{ errors[0] }}</span> -->
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- jobDeadline :: end -->
            </div>
            <!-- fourthRow :: end -->
          </div>
        </div>

        <div class="col-md-12 py-3 my-3 box">
          <div class="profile-flex border-bottom py-3">
            <h4 class="heading">{{ $gettext("Candidate_details") }}</h4>
          </div>
          <div class="pt-3">
            <!-- firstRow :: start -->
            <div class="row">
              <!-- experience :: start  -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Experience") }} *</h6>
                  <ValidationProvider name="experince" rules="required" v-slot="{ errors, classes }">
                    <v-select type="number" :placeholder="$gettext('Experience_in_Years')" class="form-control"
                      :class="classes" v-model="experiance" :options="['0-1', '2-3', '4-5', '5-6', '7-8', '10+']">
                    </v-select>
                    <div class="error-msg" :class="classes">
                      <!-- <span>{{ errors[0] }}</span> -->
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- experience :: end -->

              <!-- education :: start -->
              <!-- <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">Education </h6>
                  <ValidationProvider name="education" v-slot="{ errors, classes }">
                    <multiselect class="multis" :class="classes" v-model="education" placeholder="Education" label="edu_title" track-by="edu_id" :options="eduOptions" :max="10" :multiple="true" />
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div> -->
              <!-- education :: end -->
              <!-- education :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Education") }}</h6>
                  <ValidationProvider name="education" v-slot="{ errors, classes }">
                    <multiselect class="multis" :class="classes" v-model="education" :placeholder="$gettext('Education')"
                      label="ms_edu_degree" track-by="ms_edu_id" :options="eduOptions" :max="10" :multiple="true" />
                    <div class="error-msg" :class="classes">
                      <!-- <span>{{ errors[0] }}</span> -->
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- education :: end -->

              <!-- skills :: start -->
              <div class="col-md-4 mt-3">
                <div class="multiselect-dropdown">
                  <h6 class="fpn-b">{{ $gettext("Skills") }} *</h6>
                  <ValidationProvider name="skills" rules="required" v-slot="{ errors, classes }" tag="div">
                    <multiselect class="multis" :class="classes" v-model="skills" :placeholder="$gettext('Select_Skills')"
                      label="skill_name" track-by="skill_id" :options="skillOptions" @input="addSkill"
                      @remove="removeSkill" :max="10" :multiple="true" />
                    <div class="error-msg" :class="classes">
                      <!-- <span>{{ errors[0] }}</span> -->
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- skills :: end -->
            </div>
            <!-- firstRow :: end -->

            <!-- secondRow :: start -->
            <div class="row">
              <!-- nationality :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Nationality") }}</h6>
                  <ValidationProvider name="nationality" tag="div" v-slot="{ errors, classes }">
                    <multiselect class="multis" :class="classes" v-model="nationality"
                      :placeholder="$gettext('Select_Nationality')" label="nationality" track-by="nationality_id"
                      :options="nationalities" @input="addNationality" @remove="removeNationality" :multiple="true" />
                    <div class="error-msg" :class="classes">
                      <!-- <span>{{ errors[0] }}</span> -->
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- nationality :: end -->

              <!-- gender :: start -->
              <div class="col-md-4 mt-3">
                <div>
                  <h6 class="fpn-b">{{ $gettext("Gender") }}</h6>
                  <ValidationProvider name="gender" v-slot="{ errors, classes }">
                    <v-select :placeholder="$gettext('Select_Gender')" class="form-control" :class="classes"
                      v-model="gender" :options="['Male', 'Female', 'Others']"> </v-select>
                    <div class="error-msg" :class="classes">
                      <!-- <span>{{ errors[0] }}</span> -->
                      <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- gender :: end -->
            </div>
            <!-- secondRow :: end -->
          </div>
          <div class="py-5 d-flex justify-content-center">
            <b-button class="mx-4" variant="primary" size="lg" @click.prevent.stop="ValidateInput">{{ $gettext("Submit")
            }}</b-button>
          </div>
        </div>
      </ValidationObserver>
    </b-container>
  </div>
</template>

<script>
import DatePicker from "vuejs-datepicker";
import RecruiterJobService from "@/apiServices/RecruiterJobService";
import LocationServices from "@/apiServices/LocationServices";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  components: { DatePicker, Multiselect },
  data() {
    return {
      submitted: null,
      basicDetail: true,
      candidateDetails: false,
      selectJobType: null,
      select_job_types: [],
      education: null,
      eduOptions: [],
      disabled_dates: {
        to: moment().subtract(1, "days").toDate(),
      },
      state: {
        date: new Date(),
      },
      wfh: false,
      basic: false,
      country: [],
      countries: [],
      delCountry: [],
      delCity: [],
      cities: [],
      currencies: [],
      gender: [],
      delGender: [],
      vacancy: null,
      nationality: null,
      nationalities: [],
      currency: null,
      city: [],
      delNationality: [],
      jobDetails: {},
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: "",
        feature: "Y",
      },
      workflow: [
        { wfc_id: "1", wfc_name: "Basic Details", disabled: true },
        { wfc_id: "2", wfc_name: "Virtual Interview", disabled: true },
        { wfc_id: "3", wfc_name: "Virtual Video Interview" },
        { wfc_id: "4", wfc_name: "EI Assessment" },
      ],
      deselectedWfc: [],
      delSkills: [],
      wfc: [],
      wfc2: [],
      skillOptions: [
        // {
        //   "skill_id": 3,
        //   "skill_name": "37Signals Basecamp",
        //   "skill_is_active": "Y",
        //   "created_by": "-1",
        //   "updated_by": null,
        //   "created_at": "2021-03-26T06:00:48.000Z",
        //   "updated_at": null
        // }
      ],
      job_title: null,
      job_description: null,
      salary_from: null,
      salary_to: null,
      job_type: null,
      job_end_date: null,
      experiance: null,
      skills: [],
      removedSkills: [],
      job_titles_list: [],
      speciality: null,
      specialityOpt: [],
      isLoading: false,
    };
  },
  // beforeCreate: function () {
  //   document.body.className = "bg-grey";
  // },
  async beforeMount() {
    // this.getAllSkills();
    this.getAllJobTitles();
    this.getAllCountries();
    this.getAllSpeciality();
    await this.getAllCities(this.pagination);
    this.getSingleJob();
    this.getAllCurrency();
    this.getEducationList();
    this.getAllNationality();
  },

  computed: {
    basicDetails() {
      if (this.wfc?.includes("1")) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    setDescription(e) {
      this.job_description = e.job_title_description;
    },

    setJobType(e) {
      this.speciality = e.speciality_id;
    },

    getAllSpeciality() {
      RecruiterJobService.GetAllSpeciality().then((res) => {
        const sortedData = res.data.data.sort((a, b) => a.checkbox.localeCompare(b.checkbox));
        console.log("sortedData", sortedData);
        this.specialityOpt = sortedData;
      });
    },

    getAllNationality() {
      RecruiterJobService.GetAllNationality().then((res) => {
        this.nationalities = res.data.data;
      });
    },

    selectGender(e) {
      const addedGender = e;
      this.delGender = this.delGender.filter((f) => {
        if (!addedGender.includes(f)) {
          return true;
        }
      });
    },

    removeGender(e) {
      console.log("e :>> ", e);
      this.delGender.push(e);
    },

    onChangeDate(e) {
      this.job_end_date = e;
    },

    onChangeCity(e) {
      const addedCity = e.map((m) => {
        return m.city_id;
      });
      this.delCity = this.delCity.filter((f) => {
        if (!addedCity.includes(f.city_id)) {
          return true;
        }
      });
    },

    async getAllJobTitles() {
      try {
        let response = await RecruiterJobService.GetAllJobTitles();
        let { data } = response;
        console.log("data", data);

        if (data && data.status) {
          this.job_titles_list = data.data;
        }
      } catch (error) { }
    },

    limitText(count) {
      let text = null;
      count == 1 ? (text = `and ${count} other country`) : (text = `and ${count} other countries`);
      return text;
    },

    limitTextCity(count) {
      let text = null;
      count == 1 ? (text = `and ${count} other city`) : (text = `and ${count} other cities`);
      return text;
    },

    addSkill(e) {
      console.log(e);
      const addedSkill = e.map((m) => {
        return m.skill_id;
      });
      this.removedSkills = this.removedSkills.filter((f) => {
        if (!addedSkill.includes(f.skill_id)) {
          return true;
        }
      });
    },

    getAllCurrency() {
      RecruiterJobService.GetCurrency().then((res) => {
        this.currencies = res.data.data;
      });
    },

    removeSkill(e) {
      console.log("e :>> ", e);
      this.removedSkills.push(e);
      console.log("this.skills", this.skills);
      console.log("this.removedSkills", this.removedSkills);
    },

    onDeselect(e) {
      console.log("e :>> ", e);
      this.wfc2.forEach((f) => {
        console.log("e.includes(f) :>> ", e.includes(f));
        this.deselectedWfc = this.wfc2.filter((f) => {
          if (!e.includes(f)) {
            return true;
          }
        });
      });
    },

    getAllCountries() {
      const role_id = this.$cookies.get("role_id");
      LocationServices.GetAllCountry({ role_id, job_create: "Y" }).then((res) => {
        // this.nationalities = res.data.data;
        this.countries = res.data.data.map((c) => {
          return {
            label: c.country_name,
            country_id: c.country_id,
          };
        });
      });
    },

    async getAllCities(payload) {
      console.log("update job city payload ", payload);
      let loader = this.$loading.show();
      var res = await LocationServices.GetAllCities(payload);

      this.cities = res.data.data.map((city) => {
        return {
          label: city.city_name,
          city_id: city.city_id,
        };
      });
      // this.$forceUpdate();

      loader.hide();
    },
    onChangeCountry(e) {
      if (e) {
        // const country_id = this.country.map((m) => {
        //   return m.country_id;
        // });
        const country_id = e.map((m) => {
          return m.country_id;
        });
        this.delCountry = this.delCountry.filter((f) => {
          if (!country_id.includes(f.country_id)) {
            return true;
          }
        });
        LocationServices.GetAllCityOfCountry({ country_id: `${country_id}` }).then((res) => {
          this.cities = res.data.data.map((m) => {
            return {
              label: m.city_name,
              city_id: m.city_id,
            };
          });
        });
      }
    },
    addCountry(e) {
      const addedCountry = e.map((m) => {
        return m.country_id;
      });
      this.delCountry = this.delCountry.filter((f) => {
        if (!addedCountry.includes(f.country_id)) {
          return true;
        }
      });
      this.getAllCities(addedCountry);
    },
    addNationality(e) {
      this.delNationality = this.delNationality.filter((f) => {
        if (f.nationality_id == e.nationality_id) {
          return false;
        } else {
          return true;
        }
      });
    },
    getEducationList() {
      RecruiterJobService.GetEducationStreams().then((res) => {
        const { data: education } = res.data;
        const sortData = () => {
          return new Promise((resolve, reject) => {
            education.sort((a, b) => a.ms_edu_degree.localeCompare(b.ms_edu_degree));
            resolve(education);
          });
        };

        sortData()
          .then(sortedData => {
            // console.log("EdusortedData", sortedData);
            this.eduOptions = sortedData.map((edu) => {
              return {
                ms_edu_id: edu.ms_edu_id,
                ms_edu_degree: edu.ms_edu_degree,
              };
            });
            // console.log(this.eduOptions);

          })
          .catch(error => {
            console.error(error);
          });
      });
    },
    removeNationality(e) {
      this.delNationality.push(e);
      this.nationality = this.nationality.filter((f) => {
        if (!this.delNationality.includes(f.country_id)) {
          return true;
        }
      });
    },
    removeCountry(e) {
      console.log("e :>> ", e);
      this.delCountry.push(e);

      const country_id = [e.country_id];
      console.log("country_id :>> ", country_id);
      LocationServices.GetAllCityOfCountry(country_id).then((res) => {
        console.log("res.data.data ::::>> ", res.data.data);
        const city = res.data.data.map((m) => {
          return m.city_id;
        });
        let delCity = this.city.filter((f) => {
          if (city.includes(f.city_id)) {
            return true;
          }
        });
        this.delCity = [...delCity];
        console.log("city :>> ", city);
        this.city = this.city.filter((f) => {
          console.log("city.includes(f.city_id) :>> ", city.includes(f.city_id));
          if (!city.includes(f.city_id)) {
            return true;
          }
        });
      });
    },
    addCity(e) {
      const addedCity = e.map((m) => {
        return m.city_id;
      });
      this.delCity = this.delCity.filter((f) => {
        if (!addedCity.includes(f.city_id)) {
          return true;
        }
      });
    },
    removeCity(e) {
      this.delCity.push(e);
    },
    getSingleJob() {
      const job_id = this.$route.params.job_id;

      console.log("job_id :>> ", job_id);
      RecruiterJobService.GetSingleJob({ job_id })
        .then((job) => {
          console.log("job :>> ", job);
          this.jobDetails = job.data.data[0];
          console.log("this.jobDetails", this.jobDetails);
          let country = this.jobDetails.country;
          country = country.map((m) => {
            return m.country_id;
          });

          this.getAllCities(country);

          this.getAllIndustrySkills(job.data.data[0].speciality_id)
          this.prefilledData();
          // loader.hide();
        })
        .catch((err) => {
          loader.hide();
        });
    },
    searchSkills(search) {
      console.log("search :>> ", search);
      if (search && String(search.length) > 0) {
        this.isLoading = true;
        RecruiterJobService.SearchSkills({ search }).then((skills) => {
          this.isLoading = false;

          this.skillOptions = skills.data.data;
        });
      }
    },
    getAllSkills() {
      RecruiterJobService.getAllSkills().then((skills) => {
        this.skillOptions = skills.data.data.map((s) => {
          return {
            label: s.skill_name,
            skill_id: s.skill_id,
          };
        });

        this.skillOptions = skills.data.data;
      });
    },
    getAllIndustrySkills(payload) {
      console.log(this.$route.params.job_id,);
      const payloadData = [];
      payloadData[0] = payload
      RecruiterJobService.getAllIndustrySkills(payloadData, this.$route.params.job_id).then((skills) => {
        console.log("skills", skills);
        console.log("skills==", skills.data.message);
        // this.skillOptions = skills.data.data.map((s) => {
        //   return {
        //     "skill_id": s.skill_id,
        //     "skill_name": s.skill_name,
        //   };
        // });

        this.skillOptions = skills.data.data;
        // this.skills = skills.data.message.length ? this.jobDetails.skills : [];
        if (skills.data.message.length) {
          this.jobDetails.skills
        }
        else {
          this.removedSkills = this.skills
          this.skills = []
        }
        console.log("this.skills", this.skills);
        console.log("this.removedSkills", this.removedSkills);
      });
    },

    prefilledData() {
      console.log("page 1");
      console.log(this.$store.getters.getJobDetails);
      if (this.$store.getters.getPage == 1) {
        const { edu, job_title, job_experiance, job_salary_range_from, job_salary_range_to, job_description, job_type, job_skills, removed_skills, city, country, currency, job_end_date, vacancy, nationality, delNationality, speciality_id, gender, delGender } = this.$store.getters.getJobDetails;
        console.log("IF", { edu, job_title, job_experiance, job_salary_range_from, job_salary_range_to, job_description, job_type, job_skills, removed_skills, city, country, currency, job_end_date, vacancy, nationality, delNationality, speciality_id, gender, delGender });
        this.education = edu;
        this.job_title = job_title;
        this.city = city;
        this.job_description = job_description;
        this.skills = job_skills;
        this.removedSkills = removed_skills;
        this.experiance = job_experiance;
        this.salary_from = job_salary_range_from;
        this.salary_to = job_salary_range_to;
        this.vacancy = vacancy;
        this.country = country;
        this.currency = currency;
        this.nationality = nationality;
        this.delNationality = delNationality;

        console.log("f.checkbox_id, speciality_id", f.checkbox_id, speciality_id);
        const selectedSpeciality = this.specialityOpt.filter((f) => {
          if (f.checkbox_id == speciality_id) {
            return true;
          }
        });
        this.speciality = selectedSpeciality.length > 0 ? selectedSpeciality[0] : null;
        // this.speciality = selectedSpeciality.length > 0 ? selectedSpeciality[0] : null;
        this.wfh = wfh;
        this.gender = gender;
        this.delGender = delGender;
        // this.city = city;
        this.job_type = job_type;
        this.job_end_date = moment(job_end_date).format("YYYY-MM-DD");
        console.log("this.$store.getters.getWfc :>> ", this.$store.getters.getWfc);
        this.wfc = this.$store.getters.getWfc.wfc;
        this.wfc2 = this.wfc;
      } else {
        console.log("else");
        const jobDetails = this.jobDetails;
        console.log("this.jobDetails :>> ", this.jobDetails);
        const wfc = this.jobDetails.wfc;
        console.log("wfc", wfc);
        console.log("jobDetails :>> ", jobDetails);
        //console.log("wfc :>> ", wfc);
        this.wfh = this.jobDetails.wfh_is_active == "Y" ? true : false;
        this.job_title = this.jobDetails.job_title;
        const selectedSpeciality = this.specialityOpt.filter((f) => {
          console.log("f", f);
          if (f.checkbox_id == this.jobDetails.speciality_id) {
            return true;
          }
        });
        console.log("selectedSpeciality :>> ", selectedSpeciality[0]);
        this.speciality = selectedSpeciality.length > 0 ? selectedSpeciality[0] : null;
        console.log("this.speciality", this.speciality);
        this.job_description = this.jobDetails.job_description;
        const resCountry = this.countries.filter((f) => {
          if (f.country_id == this.jobDetails.country_id) {
            return true;
          }
        });
        this.country = resCountry;
        console.log("this.country", this.country);

        const resCity = this.cities.filter((f) => {
          if (f.city_id == this.jobDetails.city[0].city_id) {
            console.log("f.city_id", f.city_id);
            return true;
          }
        });
        this.city = resCity;
        console.log("resCity", resCity);

        this.salary_from = this.jobDetails.job_salary_range_from;
        this.salary_to = this.jobDetails.job_salary_range_to;
        this.job_type = this.jobDetails.job_type;
        this.job_end_date = moment(this.jobDetails.job_end_date).format("YYYY-MM-DD");
        this.experiance = this.jobDetails.job_experiance;
        this.skills = this.jobDetails.skills;
        this.vacancy = this.jobDetails.vacancy;
        this.nationality = this.jobDetails.nationality;
        this.currency = {
          currency_name: this.jobDetails.currency_name,
          currency_code: this.jobDetails.currency_code,
          currency_id: this.jobDetails.currency_id,
        };
        this.gender = this.jobDetails.gender;
        this.wfc = wfc.map(({ wfc_id }) => {
          return `${wfc_id}`;
        });
        this.wfc2 = this.wfc;
        this.education =
          this.jobDetails.education[0].job_education !== null
            ? jobDetails.education.map((edu) => {
              return {
                ms_edu_id: edu.jem_id,
                ms_edu_degree: edu.job_education,
              };
            })
            : null;

        console.log("this.wfc :>> ", this.wfc);
        console.log("City Data", this.cities);

        // this.speciality = [{
        //   checkbox: jobDetails.speciality_title,
        //   checkbox_id: jobDetailsspeciality_id,
        //   title: "speciality"
        // }]

        // this.city = {
        //   label: jobDetails.city_name,
        //   city_id: jobDetails.city_id,
        // };
        // this.country = jobDetails.country.map((m) => {
        //   return {
        //     label: m.country_name,
        //     country_id: m.country_id,
        //   };
        // });

        //this.onChangeCountry(this.country);
        // this.city = jobDetails.city.map((m) => {
        //   return {
        //     label: m.city_name,
        //     city_id: m.city_id,
        //   };
        // });
        // this.skills = jobDetails.skills.map((m) => {
        //   return {
        //     label: m.skill_name,
        //     skill_id: m.skill_id,
        //   };
        // });
      }
    },
    ValidateInput() {
      return this.$refs.job_creation.validate().then((result) => {
        if (result) {
          console.log("this.skills", this.skills);
          console.log("this.skills", this.removedSkills);
          this.submit();
        } else {
          this.$toasted.show("Some Fields Are missing", {
            position: "top-right",
            duration: 6000,
          });
        }
      });
    },
    onSelect(e) {
      console.log("=====e", e.checkbox_id);
      if (e.checkbox.length > 0) {
        this.err = false;
        this.getAllIndustrySkills(e.checkbox_id)
      }
    },
    remspecialityHandle(e) {
      console.log(e);
      this.speciality = null;
    },
    onChangeSkills(e) {
      console.log("e :>> ", e);
      this.skills.forEach((f) => {
        console.log("e.includes(f) :>> ", e.includes(f));
        this.delSkills = this.skills.filter((f) => {
          if (!e.includes(f)) {
            return true;
          }
        });
      });
    },

    submit() {
      const company_id = this.$cookies.get("company_id");
      const delCity = this.delCity;
      const delCountry = this.delCountry;
      // const old_payload = {
      //   company_id: this.$cookies.get("company_id"),
      //   job_title: this.job_title,
      //   job_experiance: this.experiance,
      //   job_salary_range_from: this.salary_from,
      //   job_salary_range_to: this.salary_to,
      //   job_type: this.job_type,
      //   job_skills: this.skills,
      //   removed_skills: this.removedSkills,
      //   job_end_date: this.job_end_date,
      //   job_description: this.job_description,
      //   city: this.city,
      //   currency: this.currency,
      //   country: this.country,
      //   company_id: company_id,
      //   vacancy: this.vacancy,
      //   nationality: this.nationality,
      //   delNationality: this.delNationality,
      //   wfh: this.wfh,
      //   gender: this.gender,
      //   delGender: this.delGender,
      //   speciality_id: this.speciality ? this.speciality.checkbox_id : null,
      //   delCity,
      //   delCountry,
      // };
      const payload = {
        job_id: this.$route.params.job_id,
        title: this.job_title,
        description: this.job_description,
        title_id: null,
        speciality_id: this.speciality ? this.speciality.checkbox_id : null,
        salary_from: this.salary_from,
        salary_to: this.salary_to,
        currency_id: this.currency,
        vacancies: this.vacancy,
        experience: this.experiance,
        gender: this.gender,
        type: this.job_type,
        job_end_date: this.job_end_date,
        country: this.country,
        city: this.city,
        education: this.education,
        nationality: this.nationality,
        skills: this.skills,

        // delNationality,
        // delEducation,
        // delCountry,
        // delCity,
        delSkills: this.removedSkills,
      };
      console.log("job service update paylaod", payload);
      RecruiterJobService.UpdateJobNew(payload)
        .then((res) => {
          console.log("res", res);

          if (res.status === 200) {
            this.$toasted.show("Job Updated Successfully", {
              position: "top-right",
              duration: 6000,
              icon: "check",
            });
            this.$router.push({ name: "Employer-jobs" });
          }
        })
        .catch((e) => {
          console.log("update job error", e.message);
          this.$toasted.show("Failed to Updated Job", {
            position: "top-right",
            duration: 6000,
            icon: "clear",
          });
        });

      // const deselectedWfc = this.deselectedWfc;
      // this.wfc = [...new Set(this.wfc)];
      // const p = { wfc: [...this.wfc] };
      // this.$store.commit("ON_SET_PAGE", 1);
      // this.$store.dispatch("onSetJob_Details", payload);
      // this.$store.dispatch("onSetWfc", p);
      // this.$store.commit("ON_SET_DESELECTED_WFC", deselectedWfc);
      // this.$router.push({
      //   name: "Update-JobCreation-2",
      //   params: { job_id: this.$route.params.job_id },
      // });
    },
  },
};
</script>

<style lang="css" scoped>
/* .vue-simple-suggest.designed >>> .input-wrapper input {
  background: #eaeaea;
}

.vue-simple-suggest.designed >>> .suggestions .suggest-item.selected {
  background-color: #6244e5;
  color: #fff;
}

.multis >>> .multiselect__tags {
  background-color: #eaeaea;
}
.multis >>> .multiselect__tag {
  background: #6244e5;
}

.multis >>> .multiselect__tag-icon:after {
  color: white;
}

.multis >>> .multiselect__tag-icon:hover {
  background: #6244e5;
}
.multis >>> .multiselect__option--highlight {
  background: #6244e5;
}

.multis >>> .multiselect__option:after {
  background-color: #6244e5;
  color: white;
}

.multis >>> .multiselect__input {
  background-color: #eaeaea;
}

.multiselect-dropdown span {
  margin-right: 10px;
}

.grid-half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;
}

.detail-one {
  display: grid;
  grid-template-columns: 43% 57% 35%;
  grid-column-gap: 30px;
}

.grid-three {
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .detail-one {
    grid-template-columns: auto;
  }
  .grid-three {
    display: block;
    grid-template-columns: auto;
    flex: 0 0 100%;
    max-width: 100%;
  }
} */

/* new css */
.vs__dropdown-toggle {
  background: none !important;
  border: none !important;
}

.validate-error {
  color: #ff4b4b;
  font-size: 13px;
  padding: 4px;
  font-family: "pn-regular";
  margin-top: -15px;
}

.vue-simple-suggest.designed>>>.input-wrapper input {
  background: #eaeaea;
}

.multis>>>.multiselect__tags {
  /* color: orange; */
  background-color: #eaeaea;
}

.multis>>>.multiselect__tag {
  background: #6244e5;
}

.multis>>>.multiselect__tag-icon:after {
  color: white;
}

.multis>>>.multiselect__tag-icon:hover {
  background: #6244e5;
}

.multis>>>.multiselect__option--highlight {
  background: #6244e5;
}

.multis>>>.multiselect__option:after {
  background-color: #6244e5;
  color: white;
}

.multis>>>.multiselect__input {
  background-color: #eaeaea;
}

.multis>>>.multiselect__spinner:after {
  background: #eaeaea;
}

.multis>>>.multiselect__spinner {
  background: #eaeaea;
}

.multiselect-dropdown span {
  margin-right: 10px;
}

.suggest-input.error-msg {
  margin-top: 10px;
}

.suggest-input.is-invalid {
  border: 2px solid #ff4b4b !important;
}

.suggest-input-error {
  border: 2px solid #ff4b4b !important;
}

.vue-simple-suggest.designed .input-wrapper input {
  border: 1px solid #eaeaea !important;
}

.grid-half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;
}

.detail-one {
  display: grid;
  grid-template-columns: 43% 57% 35%;
  grid-column-gap: 30px;
}

.grid-three {
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .detail-one {
    grid-template-columns: auto;
  }

  .grid-three {
    display: block;
    grid-template-columns: auto;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* tab styles */
.myTab {
  width: 300px;
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(57, 39, 142, 1);
}

.myTabText {
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}
</style>

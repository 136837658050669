export default
    {
        Enabling_jobs_for_millions: "         Kuwezesha ajira kwa mamilioni ya wafanyakazi wenye vipaji barani Afrika",
        Employers: "Waajiri",
        Employers_Login: "Kuingia kwa Mwajiri",
        How_it_works: "Inavyofanya kazi",
        Pricing: "Bei",
        Find_Jobs: "Tafuta Ajira",
        Search_by_title_company_or_keywords: "Tafuta kwa kichwa, kampuni au maneno muhimu..",
        Saved_Jobs: "Kazi Zilizohifadhiwa",
        Hire_a_Candidate: "#Ajiri Mhitaji",
        Employer: "Mwajiri",
        Find_a_Job: "Tafuta Kazi",
        Chat: "Soga",
        Candidates: "Wahitaji",
        Candidate: "Mhitaji",
        Why_Apply_On_Zaajira: "Kwa nini Uombe Kwenye Zaajira?",
        Feature_that_supercharge_your_job_search_and_profile: "Kipengele kinachotoza zaidi utafutaji wako wa kazi na wasifu",
        Connect_with_top_employers_in_minutes: "Ungana na waajiri wakuu baada ya dakika chache",
        Increase_your_chances_with_custom_cards: "Ongeza nafasi zako kwa kutumia kadi maalum",
        Browse_specific_jobs_with_detailed_requirements: "Sakuru kazi mahususi zilizo na mahitaji ya kina",
        Zaajira_Success_Stories: "Hadithi Za Mafanikio Zaajira",
        Showroom_Manager: "Meneja wa Chumba cha Maonyesho",
        Trusted_by_leading_companies_of_the_world: "Kuaminiwa na makampuni makubwa duniani!",
        An_integrated_AI_talent_acquisition_platform: "Jukwaa la kupata talanta za kiakili, linalosaidia kuchunguza mwombaji na kuunganisha talanta inayofaa.",
        Support: "Msaada",
        Terms_and_Conditions: "Sheria na Masharti",
        Privacy_Policy: "Sera ya Faragha",
        Contact: "Wasiliana",
        OTP_sent_to_email: "OTP imetumwa kwa Barua pepe yako",
        Login_Register: "Ingia/Jisajili",
        Looking_for_job: "Je, unatafuta Kazi?",
        Login_signup_code: "Ingia/Jisajili na Msimbo",
        Email_phone_number: "Barua pepe au nambari ya simu",
        Field_required: "Sehemu hii inahitajika",
        Not_robot: "Mimi si roboti",
        Remember_me: "Nikumbuke",
        Password: "Nywila",
        Register: "Sajili",
        Login: "Ingia",
        No_account: "Je, huna akaunti?",
        Forgot_password: "Umesahau nywila?",
        Company_name: "jina la kampuni",
        Already_have_account: "Je, tayari una akaunti?",
        Reset_password: "WEKA UPYA NYWILA",
        Enter_OTP: "Ingiza OTP",
        Confirm_password: "Thibitisha Nywila",
        Change_password: "Badilisha Nywila",
        Register_for_free: "Jisajili sasa, BILA MALIPO",
        Download_mobile_app: "Pakua The Zaajira Mobile App!",
        Apply_now_mobile_app: "Omba kazi ya ndoto yako SASA, kwa kutumia programu yetu ya rununu!",
        Jobs: "KAZI",
        Jobs1: "Kazi",
        Create: "UNDA",
        View: "TAZAMA",
        Messages: "UJUMBE",
        Profile: "Wasifu",
        Edit: "BADILISHA",
        New_user: "MTUMIAJI MPYA",
        View_users: "ANGALIA WATUMIAJI",
        Settings: "Mipangilio",
        Logout: "Ondoka",
        Search_candidates: "Tafuta wahitaji",
        Select_country: "Chagua Nchi",
        Loading: "Inapakia",
        Select_city: "Chagua jiji",
        Select_any: "Chagua Yoyote",
        Press_enter_to_select: "Bonyeza ingia ili kuchagua",
        Press_enter_to_remove: "Bonyeza ingia ili kuondoa",
        Date: "TAREHE",
        Name: "JINA",
        Back: "Rudi",
        Full_name: "Jina kamili",
        Job_title: "Jina la kazi",
        Age: "Umri",
        Years_of_experience: "Miaka ya Uzoefu",
        Country_of_residence: "Nchi ya Makazi",
        Nationality: "Utaifa",
        Current_salary_monthly: "Mshahara wa Sasa (Kila mwezi)",
        Education: "Elimu",
        Skills: "Ujuzi",
        Industry: "Sekta",
        Shortlist: "Orodha fupi",
        Job_detail: "Maelezo ya kazi",
        Search: "Tafuta",
        Search_result: "Matokeo ya utafutaji",
        Filters: "Vichujio",
        Apply_Filter: "Weka Vichujio",
        Clear_all: "Futa zote",
        Reset: "WEKA UPYA",
        Apply: "TUMA MAOMBI",
        View_candidate_details: "TAZAMA MAELEZO YA MHITAJI",
        Sort_by: "Panga kwa",
        Selected: "Imechaguliwa",
        Country: "Nchi",
        City: "Jiji",
        Salary_range: "Kiwango cha mishahara",
        Gender: "Jinsia",
        Date_modified: "Tarehe Iliyorekebishwa",
        Experience: "Uzoefu",
        Years: "Miaka",
        Location: "Eneo",
        day_ago: "siku iliyopita",
        Admin: "Msimamizi",
        Experience: "Uzoefu",
        Years: "Miaka",
        Location: "Eneo",
        Male: "Mwanaume",
        Female: "Mwanamke",
        Any: "Yoyote",
        Share: "SHIRIKI",
        First: "Kwanza",
        Prev: "Iliyotangulia",
        Next: "Inayofuata",
        Last: "Mwisho",
        Filter_Section: " #Chuja Sehemu",
        Industry_name: "Jina la Sekta",
        Basic_details: "Maelezo ya Msingi",
        Candidate_details: "Maelezo ya Mhitaji",
        Interview_Questions: "Maswali ya Mahojiano",
        Review_Suggestions: "Mapitio/Mapendekezo",
        Job_Category: "Kitengo cha Kazi",
        Select_Currency: "Chagua Sarafu",
        Min_Salary_Month: "Mshahara/Mwezi* kima ya chini",
        Max_Salary_Month: "Mshahara/Mwezi* kima ya juu",
        Type_of_Job: "Aina ya Kazi",
        Select_Job_Type: "Chagua Aina ya Kazi",
        Job_Deadline: "Makataa ya Kazi*",
        SAVE_DRAFT: "HIFADHI RASIMU",
        SELECT_CITY: "#CHAGUA-JIJI",
        City_Name: "Jina la Mji",
        TYPE_OF_JOB: "AINA YA KAZI",
        FULL_TIME: "WAKATI WOTE",
        PART_TIME: "WAKATI NUSU",
        Remote: "Mbali",
        Internship: "Mazoezi ya kazi",
        Contract: "Mkataba",
        Work_from_home: "Kazi kutoka nyumbani",
        Some_fields_missing: "Baadhi ya taarifa Hazipo",
        Connect_with_best_candidates: "Ungana na wahitaji bora zaidi moja kwa moja, kwa urahisi!",
        SUPPORT_TICKET_REQUEST: "SAIDIA OMBI LA TIKETI",
        Please_let_us_know_how_we_can_assist: "Tafadhali tujulishe jinsi tunavyoweza kukusaidia na tutakujibu hivi punde",
        Email: "Barua pepe",
        Im_an: "mimi ni…",
        Message: "Ujumbe",
        Submit: "Wasilisha",
        Invalid_Password_3_attempts: "Nywila batili. Majaribio 3 yamesalia",
        No_new_messages_found: "Hakuna ujumbe mpya uliopatikana",
        DISABLE_CHAT: "FUNGA CHATI",
        VIEW_PROFILE: "TAZAMA WASIFU",
        Type_your_message: "Andika ujumbe wako",
        ENABLE_CHAT: "FUNGUA CHATI",
        d_ago_6: "siku 6 zilizopita",
        Create_Users: "Unda Watumiaji",
        ADD_USER: "ONGEZA MTUMIAJI+",
        ADD: "ONGEZA+",
        REMOVE: "ONDOA",
        Enter_Name: "Ingiza Jina",
        SUBMIT_EMPLOYEES: "WASILISHA WAFANYAKAZI",
        Action: "Kitendo",
        Enter_company_details: "Ingiza maelezo ya kampuni",
        COMPANY_LOGO: "NEMBO YA KAMPUNI",
        COMPANY_GALLERY: "PICHA ZA KAMPUNI",
        UPLOAD_LOGO: "PAKIA NEMBO",
        UPLOAD_PHOTOS_VIDEO: "PAKIA PICHA/VIDEO",
        ADMIN_NAME: "JINA LA ADMIN",
        ADMIN_EMAIL: "BARUA PEPE YA ADMIN",
        Enter_Email: "Weka Barua Pepe",
        SIZE: "Size",
        CONTACT_LOCATION: "MAWASILIANO & ENEO",
        ADD_SOCIAL_LINKS: "ONGEZA VIUNGO VYA KIJAMII",
        ADD_SOCIAL_PROFILE_URL: "ONGEZA URL YA WASIFU WA KIJAMII",
        ENTER_LINKEDIN_PROFILE_URL: "INGIZA URL YA WASIFU YA LINKEDIN",
        ENTER_FACEBOOK_PROFILE_URL: "INGIZA URL YA WASIFU YA FACEBOOK",
        ENTER_TWITTER_HANDLE: "INGIZA TWITTER",
        ENTER_INSTAGRAM_PROFILE_URL: "INGIZA URL YA WASIFU YA INSTAGRAM",
        CONTINUE: "ENDELEA",
        List_is_empty: "Orodha ni tupu",
        Add_your_company_address: "Ongeza anwani ya kampuni yako",
        Contact_Number: "Namba ya mawasiliano",
        Website_URL: "URL ya tovuti",
        Select_Industry: "Chagua Sekta",
        Add_your_company_profile: "Ongeza wasifu ya kampuni yako katika aya 2 au chini",
        ADD_MORE_USERS: "ONGEZA WATUMIAJI ZAIDI",
        SKIP: "RUKA",
        CONFIRM_SAVE: "THIBITISHA NA UHIFADHI",
        Employer_Legal_Name: "Jina la Kisheria la Mwajiri",
        Social_Links: "Viungo vya Kijamii",
        Gallery: "Picha",
        Delete: "Futa",
        Connect_with_top_employers: "Connect with top employers in minutes",
        Increase_chances_with_custom_cards: "Increase your chances with custom cards",
        Browse_specific_jobs: "Browse specific jobs with detailed requirements",
        Zaajira_Success_Stories: "Zaajira Success Stories",
        Share_this_job_on_social_network: "Shiriki kazi hii kwenye mtandao wa kijamii",
        Your_search_produced_no_results: "Utafutaji wako haukuzaa matokeo",
        Try_adjusting_to_display_better_results: "Jaribu kurekebisha ili kuonyesha matokeo bora",
        Please_Wait_Job_Is_Loading: "Tafadhali Subiri Kazi Inapakia...",
        Job_Description: "Maelezo ya Kazi",
        Write_Job_Description: "Andika Maelezo ya Kazi",
        No_Of_Vacancies: "Nambari ya Nafasi za Kazi",
        Vacancies: "Nafasi za kazi",
        Currency: "Sarafu",
        AI_Suggestions: 'Mapendekezo ya AI',
        Choose_Description: 'Chagua Maelezo',
        Enter_New_Description: 'Ingiza Maelezo Mapya',
        Salary_must_be_greater: 'Mshahara lazima uwe mkubwa kuliko kiwango cha chini cha mshahara.',
        Experience_in_Years: 'Uzoefu katika Miaka',
        Select_Skills: 'Chagua Ujuzi',
        Select_Nationality: 'Chagua Utaifa',
        Select_Gender: 'Chagua Jinsia',
        Responsibilities: 'Majukumu',
        Add_Responsibilities: "Ongeza Majukumu",
        Add_Questions: "ONGEZA MASWALI",
        Virtual_Interview: "Mahojiano ya Mtandaoni",
        Suggested_Virtual_Interview_Questions: "Maswali ya Mahojiano ya Mtandaoni Yanayopendekezwa",
        Multiple_Choice: "Chaguo Nyingi",
        Sample_MCQ_Question_goes_here: "Q1. Sampuli ya Swali la MCQ huenda hapa?",
        Sample_Text_Based_Question_goes_here: "Q1. Sampuli ya Swali Kulingana na Maandishi huenda hapa?",
        Enter_Option: 'Weka Chaguo',
        Mark_As_Correct: "Weka alama kama Sahihi",
        ADD_OPTIONS: "ONGEZA CHAGUO",
        Score: "Alama",
        ADD_QUESTIONS: "ONGEZA MASWALI",
        upto: "Hadi",
        Confirm: "Thibitisha",
        Job_successfully_Posted: "Kazi Imetumwa kwa mafanikio",
        Candidates_on_our_site: "Wahitaji kwenye tovuti yetu wanaolingana kwa karibu na kazi yako",
        Recommended_Candidates: "Wahitaji Waliopendekezwa",
        Basic_Score: "Alama ya Msingi",
        Virtual_Interview: "Mahojiano ya Mtandaoni",
        Total_Score: "Jumla ya Alama",
        Actions: "Vitendo",
        Oops_Candidates_Not_Found: "Lo, Wagombea Hawajapatikana!",
        Search_Jobs: 'Tafuta Ajira',
        Jobs_found: "Ajira zimepatikana",
        By_Title: "Kwa Kichwa",
        Enter_Title: "Ingiza Kichwa",
        By_Date: 'Kwa Tarehe',
        Start_Date: "Tarehe ya Kuanza",
        End_Date: "Tarehe ya mwisho",
        By_No_of_Candidates: "Kwa Idadi ya Wagombea",
        More_Than: "Zaidi ya",

        By_Status: "Kwa Hali",
        Select_Status: "Chagua Hali",
        Open: "Fungua",
        Closed: "Imefungwa",
        My_Lists: "Orodha Zangu",
        Title: "Kichwa",
        Deadlines: "Makataa",
        Shortlisted: "Imeorodheshwa",
        Update: "Sasisha",
        Status: "Hali",
        Draft_Jobs: "Rasimu ya Ajira",
        Created_Jobs: "Ajira Zilizoundwa",
        Create_Job: "Tengeneza Kazi",
        Edit_Job: "Hariri Kazi",
        Edit_Interview_Questions: "Hariri Maswali ya Mahojiano",
        No_Candidates_Found_in_Applied_section: "Hakuna Wahitaji Waliopatikana katika sehemu Iliyotumika",
        All_Candidates: "Wahitaji Wote",
        Shortlisted_Candidates: "Wahitaji Walioorodheshwa",
        No_Candidates_Found_in_Shortlisted_Candidates: "Hakuna Wagombea Waliopatikana katika sehemu ya Orodha fupi",
        Rejected_Candidates: "Wahitaji Waliokataliwa",
        No_Candidates_Found_in_Rejected_section: "Hakuna Wagombea Waliopatikana katika sehemu iliyokataliwa",
        You_havent_created_any_job_yet: "Bado hujaunda kazi yoyote.",
        Your_search_produced_no_results: "Utafutaji wako haukuzaa matokeo.",
        Activate_Your_plan: "Amilisha mpango wako",
        No_Draft_job_found: "Hakuna Rasimu ya kazi iliyopatikana",
        Uploads: 'Vipakiwa',
        View_Photo: "Tazama Picha",
        Official_Email: "Barua pepe Rasmi",
        Uploading: "Inapakia",
        Username_is_required: "Jina la mtumiaji linahitajika",
        Email_is_required: "Barua pepe inahitajika",
        Password_is_required: "Nenosiri linahitajika",
        Enter_Email_ld: "Weka kitambulisho cha Barua pepe",
        Enter_Password: "Weka Nenosiri",
        Users: "Watumiaji",
        User_Name: "Jina la mtumiaji",
        User_Email: "Barua pepe ya Mtumiaji",
        New_Password: "Nenosiri Mpya",
        Fill_the_correct_fields: "Jaza sehemu sahihi!",
        Select_To_Shortlist: "Chagua kwa Orodha fupi",
        Mobile_No: "Simu No.",
        Salary_Monthly: "Mshahara (kila mwezi)",
        Job_Type: "Aina ya Kazi",
        Available: "Inapatikana",
        Not_available: "Haipatikani",
        Experience_Required: "Uzoefu Unahitajika",
        Description: "Maelezo",
        Other_jobs_with_high_match_rate: "Kazi zingine zilizo na kiwango cha juu cha mechi",
        Salary_Annually: "Mshahara (kila mwaka)",
        Your_match_rate_of: "Kiwango chako cha mechi",
        is_lower_than: "iko chini kuliko",
        other_applications_recieved: "maombi mengine yamepokelewa",
        You_can_however_still_make_a_difference_by_adding_a: "Hata hivyo, bado unaweza kuleta mabadiliko kwa kuongeza a",
        video_profile_with_a_relevant_and_impactful_message_to: "wasifu wa video wenye ujumbe unaofaa na wenye athari kwa",
        the_employer: "mwajiri",
        I_understand: "Naelewa,",
        continue_to_apply_now: 'endelea kutuma maombi sasa',
        ADD_VIDEO_PROFILE: "ONGEZA WASIFU WA VIDEO",
        Applicants: "Waombaji",
        By_Location: "Kwa Mahali",
        Applied_Candidates: "Wagombea Waliotumika",
        Interview_Score: "Alama ya Mahojiano",
        Forward: "Mbele",
        Remove_Shortlist: "Ondoa Orodha fupi",
        Recover: "Pata nafuu",
        Mandatory: "Lazima",
        No_Suggested_Questions_Found: "Hakuna Maswali Yanayopendekezwa Yaliyopatikana",
        CANDIDATE: "MGOMBEA",
        Related_jobs: "Kazi zinazohusiana",
        Posted: "Iliyotumwa",
        applicant: "mwombaji",
        Opening: "Ufunguzi",
        Address: "Anwani",
        Apply: "Omba",
        Applied: "Imetumika",
        Save_Job: "Okoa Kazi",
        Saved: "Imehifadhiwa",
        Share_Job: "Shiriki Kazi",
        Other_information: "Taarifa nyingine",
        Openings: "Ufunguzi",
        Minimum_Education: "Kima cha chini cha Elimu",
        Salary: "Mshahara",
        Welcome_to_Zaajira: "Karibu Zaajira",
        New_User: "Mtumiaji Mpya?",
        APPLIED_JOBS: "KAZI ZINAZOTUMWA",
        Edit_Profile: "Badilisha Wasifu",
        Share_Profile: "Shiriki Wasifu",
        Share_this_profile: "Shiriki wasifu huu",
        Personal_detail: "Maelezo ya kibinafsi",
        Personal_details: "Maelezo ya Kibinafsi",
        Upload_Photo: "Pakia Picha",
        Industries: "Viwanda",
        Professional_detail: "Maelezo ya kitaaluma",
        Change_language: "Badilisha lugha",
        Delete_account: "Futa akaunti",
        only_PNG_JPG_Allowed_up_To_10MB: "PNG na JPG pekee Zinazoruhusiwa hadi 10MB",
        Are_you_sure_you_want_to_delete_your_account: "Je, una uhakika unataka kufuta akaunti yako?",
        No: "Hapana",
        Yes: "Ndiyo",
        Yes_delete: "Ndiyo, futa",
        Logging_out: "Inatoka...",
        Are_you_sure_you_want_to_log_out: "Je, una uhakika unataka kutoka?",
        Save_change: "Hifadhi mabadiliko",
        Professional_details: "Maelezo ya kitaaluma",
        Select_education: "Chagua elimu",
        Upload_certificate: "Pakia cheti",
        Work_experience: "Uzoefu wa kazi",
        View_Certificate: "Angalia Cheti",
        Change_your_password: "Badilisha nenosiri lako",
        Old_Password: "namba ya siri ya zamani",
        Input_your_old_password: "Ingiza nenosiri lako la zamani",
        Input_your_New_Password: "Ingiza Nenosiri lako Jipya",
        Retype_New_Password: "Andika tena Nenosiri Jipya",
        Change_your_language: "Badilisha lugha yako",
        Select_Language: "Chagua Lugha",
        Mobile_number: "Namba ya simu ya mkononi",
        Input_your_mobile_number: "Ingiza nambari yako ya simu",
        Enter_something: "Weka kitu...",
        There_are_no_saved_jobs: "Hakuna kazi zilizohifadhiwa",
        CreateVisitingCard_paragraph: "Anza kwa kutengeneza kadi yako ya kutembelea, ili uweze kujitambulisha kwa waajiri na kutuma maombi kwa kazi bora zaidi inayopatikana.",
        Create_visiting_card: "Unda kadi ya kutembelea",
        Creating_visiting_card: "Kuunda kadi ya kutembelea",
        Upload_profile_image: "Pakia picha ya wasifu",
        Select_up_to_3_industry: "Chagua hadi sekta 3",
        Select_up_to_10_skills: "Chagua hadi ujuzi 10",
        All: "Wote",
        Unread: "Haijasomwa",
        Search_name_chat: "Tafuta jina/soga",
        Send_your_message: "Tuma ujumbe wako...",






























    }
import EmployerOnboarding1 from "@/views/Employer-Onboarding/Employer-Onboarding-One.vue";
import EmployerOnboarding2 from "@/views/Employer-Onboarding/Employer-Onboarding-Two.vue";
import EmployerOnboarding3 from "@/views/Employer-Onboarding/Employer-Onboarding-Three.vue";
import EmployerPayment from "@/views/Employer-Onboarding/Employer-Payment-Success";
import CreateUser from "@/views/Create-User.vue";
import UsersList from "@/views/Users-List.vue";
import ChangePassword from "@/views/Change-Password.vue";

export default [
  {
    path: "/employer-onboarding-1",
    name: "Employer-onboarding-1",
    component: EmployerOnboarding1,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/employer-onboarding-2",
    name: "Employer-onboarding-2",
    component: EmployerOnboarding2,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/employer-onboarding-3",
    name: "Employer-onboarding-3",
    component: EmployerOnboarding3,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/employer-payment",
    name: "Employer-Payment",
    component: EmployerPayment,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/create-user",
    name: "Create-User",
    component: CreateUser,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/users-list",
    name: "Users-List",
    component: UsersList,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
  {
    path: "/change-password",
    name: "Change-Password",
    component: ChangePassword,
    meta: {
      applicantAuth: false,
      employerAuth: true,
    },
  },
];

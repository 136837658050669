<template>
  <div class="bg-grey">
    <div class="container-fluid profile-user px-1 px-md-3 mt-5 py-5">
      <div class="col-md-12 box pb-5">
        <div class="profile-flex border-bottom py-3">
          <h4 class="heading">Profile</h4>
          <button class="btn btn-primary btn-crud" @click="() => {
            $router.push({ name: 'Employer-onboarding-1' });
          }
            ">
            <img src="@/assets/app-icons/edit.svg" width="15px" /> Edit Profile
          </button>
        </div>
        <div class="row pt-4">
          <div class="col-md-3 col-lg-3 col-xl-2">
            <h6 class="profile-label">Company Logo</h6>
            <div>
              <div class="position-relative profile-photo">
                <img v-if="$store.getters.getProfileImage" :src="`${image_url}${this.$store.getters.getProfileImage}`"
                  class="rounded img-fluid" />
                <img v-if="!$store.getters.getProfileImage" src="@/assets/unknown.jpg" class="rounded" />
              </div>
              <div class="emp-profile-overlay">
                <div @click="onDeleteImage">
                  <img src="@/assets/app-icons/delete-orange.svg" width="60px" style="cursor: pointer" />
                </div>
                <div>
                  <label for="file-upload" class="mb-0">
                    <img for="file-upload" src="@/assets/app-icons/re-upload.svg" width="60px" style="cursor: pointer" />
                  </label>
                  <input id="file-upload" type="file" ref="fileInput" style="display: none" @input="onFileChange" />
                </div>
                <!-- <input
                  id="file-upload"
                  ref="fileInput"
                  type="file"
                  @input="onFileChange"
                /> -->
              </div>
            </div>
          </div>
          <div class="col-md-7 col-lg-8 col-xl-7 col-12 emp-details ml-xl-n5 pt-4 pt-md-0">
            <div>
              <h6 class="profile-label">Employer Legal Name</h6>
              <h5 class="f-bold">{{ profile.company_long_name }}</h5>
            </div>
            <div class="b-bottom">
              <h6 class="profile-label">Industry</h6>
              <span class="f-bold" v-for="(ind, i) in profile.industry" :key="i"> {{ ind }}, </span>
            </div>
            <div class="b-bottom">
              <h6 class="profile-label">Size</h6>
              <h5 class="f-bold">{{ profile.company_employer_size }}</h5>
              <!-- <h5>{{ formatCurrency(profile.company_employer_size) }}</h5> -->
            </div>
            <div class="b-bottom">
              <h6 class="profile-label">Website</h6>
              <h5 class="f-bold">{{ profile.company_website_url }}</h5>
            </div>
            <div class="intro-div b-bottom">
              <h6 class="profile-label">Company Profile</h6>
              <h5 class="f-bold">{{ profile.company_profile }}</h5>
            </div>
          </div>
          <div class="col-md-9 emp-details pt-md-5 pt-2">
            <div class="b-bottom">
              <h6 class="profile-label">Contact No.</h6>
              <h5 class="f-bold">{{ profile.company_official_phone }}</h5>
            </div>
            <div class="b-bottom">
              <h6 class="profile-label">Email</h6>
              <h5 class="f-bold">{{ profile.company_official_email }}</h5>
            </div>
            <div class="b-bottom">
              <h6 class="profile-label">Country</h6>
              <h5 class="f-bold">{{ profile.country_name }}</h5>
            </div>
            <div class="b-bottom">
              <h6 class="profile-label">City</h6>
              <h5 class="f-bold">{{ profile.city_name }}</h5>
            </div>
            <div class="b-bottom">
              <h6 class="profile-label">Address</h6>
              <h5 class="f-bold">{{ profile.company_office_address }}</h5>
            </div>

            <div class="b-bottom">
              <h6 class="profile-label">Social Links</h6>
              <div class="d-flex flex-wrap">
                <h6 v-if="profile.facebook" class="fs-25 social-icon-box mr-4" @click="openUrl(profile.facebook)">
                  <i class="fa fa-facebook text-white" aria-hidden="true"></i>
                </h6>
                <h6 v-if="profile.linked_in" class="fs-25 social-icon-box mr-4" @click="openUrl(profile.linked_in)">
                  <i class="fa fa-linkedin text-white"></i>
                </h6>
                <h6 v-if="profile.instagram" class="fs-25 social-icon-box mr-4" @click="openUrl(profile.instagram)">
                  <i class="fa fa-instagram text-white"></i>
                </h6>
                <h6 v-if="profile.twitter" class="fs-25 social-icon-box mr-4" @click="openUrl(profile.twitter)">
                  <i class="fa fa-twitter text-white"></i>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="profile-flex pt-4 pb-2 border-bottom">
              <h4 class="heading">Gallery</h4>
              <ul class="profile-slider gallery-slides slides-arrow d-flex">
                <li class="prev"><i class="fa fa-angle-left"></i></li>
                <li class="next"><i class="fa fa-angle-right"></i></li>
              </ul>
            </div>
            <slick ref="slick" :options="galleryOptions" v-if="companyMedia && companyMedia.length > 0">
              <div v-for="(data, index) in companyMedia" :key="index" class="gallery-slider">
                <div class="pt-2 px-2">
                  <div v-if="data.company_image_path" v-b-modal.myModal @click="openModal(data)">
                    <img v-if="data.company_image_path" :src="`${image_url}${data.company_image_path}`"
                      class="img-fluid" />
                    <button type="button">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                  <!-- <CloudflareVideoPlayer
                    v-if="data.user_job_video_path"
                    :video-id="data.user_job_video_path"
                    :autoplay="false"
                  /> -->
                  <div class="position-relative" v-if="data.user_job_video_path" v-b-modal.myModal
                    @click="openModal(data)">
                    <img v-if="data.user_job_video_path"
                      :src="`https://videodelivery.net/${data.user_job_video_path}/thumbnails/thumbnail.jpg?time=1s&height=300`"
                      style="border: none" height="300" width="500" />
                    <img src="@/assets/app-icons/orange-video.svg" class="img-center video-play-img" />
                  </div>
                </div>
              </div>
            </slick>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-12">
            <div class="profile-flex border-bottom py-2">
              <h4 class="heading">Users</h4>
              <button
                class="btn btn-primary btn-crud"
                @click="
                  () => {
                    $router.push({ name: 'Employer-onboarding-1' });
                  }
                "
              >
                <img src="@/assets/app-icons/edit.svg" width="15px" /> Edit
                Profile
              </button>
            </div>
            <div class="col-md-4 px-0 py-3">
              <h6 class="fmp-b profile-label">Admin</h6>
              <div class="user-detail">
                <div>
                  <h6 class="profile-label">Admin Name</h6>
                  <h6>{{ profile.company_admin_name }}</h6>
                </div>
                <div>
                  <h6 class="profile-label">Admin E-mail</h6>
                  <h6>{{ profile.company_admin_email }}</h6>
                </div>
              </div>
            </div>
          </div>
         
        </div> -->
      </div>
      <b-modal ref="myModal" id="myModal" size="lg" ok-only hide-header>
        <img v-if="selectedImg" :src="`${image_url}${selectedImg.image_path}`" class="modal-img" />
        <iframe v-if="selectedVideo"
          :src="`https://iframe.videodelivery.net/${selectedVideo.video_path}/thumbnails/thumbnail.jpg?time=1s&height=500`"
          style="border: none" height="500" width="750"
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowfullscreen="true"></iframe>
        <div class="text-right">
          <button v-if="selectedVideo" class="btn btn-delete" @click="onDelete(selectedVideo)">Delete</button>
          <button v-if="selectedImg" class="btn btn-delete" @click="onDelete(selectedImg)">Delete</button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import CompanyServices from "@/apiServices/CompanyServices";
import CloudflareVideoPlayer from "vue-cloudflare-video-player";
import UserService from "@/apiServices/UserService";
import { IMAGEURL } from '@/config'
import numeral from 'numeral';
export default {
  components: {
    CloudflareVideoPlayer, numeral
  },
  data() {
    return {
      image_url: IMAGEURL,
      profile: {},
      // createdEmployee: [],
      companyMedia: [],
      selectedImg: null,
      selectedVideo: null,
      pagination: {
        company_id: null,
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: "",
        feature: "Y",
      },
      galleryOptions: {
        arrows: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 3,
        prevArrow: ".gallery-slides .prev",
        nextArrow: ".gallery-slides .next",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ],
      },
      textOptions: {
        arrows: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 4,
        prevArrow: ".user-slides .prev",
        nextArrow: ".user-slides .next",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ],
      },
    };
  },
  beforeMount() {
    // this.getCreatedEmployee();
    this.getEmployerDetails();
    this.getSingleCompany();
  },
  mounted: function () {
    this.$store.commit("RESET_EMAIL");
    this.$store.commit("RESET_NAME");
    this.$nextTick(function () { });
  },
  methods: {
    formatCurrency(size) {
      const [start, end] = size.split("-").map(Number);
      return numeral(start).format('0,0') + " - " + numeral(end).format('0,0')
    },
    getSingleCompany() {
      const company_id = this.$cookies.get("company_id");
      let loader = this.$loading.show();
      CompanyServices.GetSingleCompany({ company_id }).then((res) => {
        this.companyMedia = res.data.data[0].images;
        const profile = res.data.data[0];
        const country = { country: profile.country_name };
        this.$store.commit("ON_SET_COUNTRY", country);
        const city = { city: profile.city_name };
        this.$store.commit("ON_SET_CITY", city);
        res.data.data[0].videos.forEach((v) => {
          this.companyMedia.push(v);
        });
        // const p = {
        //   userName: profile.company_long_name,
        // };
        // this.$store.commit("ON_SET_USER_NAME", p);

        loader.hide();
      });
    },
    onFileChange(e) {
      let input = this.$refs.fileInput;
      let image = e.target.files[0];
      let file = input.files;
      const company_id = this.$cookies.get("company_id");
      const postData = new FormData();
      postData.append("image", image);
      let loader = this.$loading.show();
      CompanyServices.ReUploadImage(postData, company_id).then((res) => {
        const pay = { image: res.data.data.path };
        this.$store.dispatch("onSetProfileImage", pay);
        loader.hide();
        this.getSingleCompany();
      });
      // if (file && file[0]) {
      //   let reader = new FileReader();
      //   reader.onload = (e) => {
      //     this.previewImage = e.target.result;
      //   };
      //   reader.readAsDataURL(file[0]);
      //   this.$emit("input", file[0]);
      // }
    },
    onDelete(data) {
      if (data.image_id) {
        this.$bvModal
          .msgBoxConfirm("Do You Want to delete this Image", {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              CompanyServices.deleteCompanyImage(data.image_id).then((res) => {
                this.getSingleCompany();
              });
              this.$toasted.show("Image Deleted successfully!", {
                duration: 6000,
                icon: "check",
              });
              this.$refs.myModal.hide();
            } else {
              return;
            }
          });
      }
      if (data.video_id) {
        this.$bvModal
          .msgBoxConfirm("Do You Want to delete this Video", {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              CompanyServices.deleteCompanyVideo(data.video_id).then((res) => {
                this.getSingleCompany();
                this.$toasted.show("Video Deleted successfully!", {
                  duration: 6000,
                  icon: "check",
                });
                this.$refs.myModal.hide();
              });
            } else {
              return;
            }
          });
      }
    },
    onDeleteEmployee(user_id) {
      this.$bvModal
        .msgBoxConfirm("Do You Want to delete this Employee", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            CompanyServices.deleteEmployee({ user_id })
              .then((res) => {
                this.$toasted.show("Employee deleted successfully", {
                  duration: 6000,
                  icon: "check",
                });
                this.getCreatedEmployee();
              })
              .catch((err) => {
                this.$toasted.show("Employee not deleted", {
                  duration: 6000,
                  icon: "clear",
                });
              });
            this.$refs.myModal.hide();
          } else {
            return;
          }
        });
    },
    getEmployerDetails() {
      const payload = { company_id: this.$cookies.get("company_id") };
      let loader = this.$loading.show();
      CompanyServices.GetSingleCompany(payload).then((result) => {
        this.profile = { ...result.data.data[0] };
        console.log("this.profile to debug", this.profile);
        let admin = {
          a: true,
          email: this.profile.company_admin_email,
          username: this.profile.company_admin_name,
        };
        // this.createdEmployee.unshift(admin);
        this.profile.industry = this.profile.industry.map((m) => {
          return m.industry_name;
        });

        const pay = { image: this.profile.company_logo_path };
        if (this.$refs.slick) {
          this.$refs.slick.destroy();
          this.$nextTick(() => {
            this.$refs.slick.create();
            this.$refs.slick.goTo(0, true);
          });
        }

        console.log("image to debug", pay);
        this.$store.dispatch("onSetProfileImage", pay);
        loader.hide();
      });
    },
    onDeleteImage() {
      const company_id = this.$cookies.get("company_id");
      let loader = this.$loading.show();
      CompanyServices.DeleteProfileImage(company_id).then((res) => {
        const pay = { image: res.data.data.path };
        this.$store.dispatch("onSetProfileImage", pay);
        loader.hide();
      });
    },
    openModal(data) {
      if (data.company_image_path) {
        this.selectedVideo = null;
        this.selectedImg = {
          image_path: data.company_image_path,
          image_id: data.company_image_id,
        };
      }
      if (data.user_job_video_path) {
        this.selectedImg = null;
        this.selectedVideo = {
          video_path: data.user_job_video_path,
          video_id: data.user_job_video_id,
        };
      }
      this.$bvModal.show("myModal");
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    // async getCreatedEmployee() {
    //   const company_id = this.$cookies.get("company_id");
    //   let loader = this.$loading.show();
    //   const createdUser = await CompanyServices.getCreatedEmployee(company_id);

    //   this.createdEmployee = createdUser.data.data.map((e) => {
    //     return {
    //       username: e.user_username,
    //       email: e.user_email,
    //       admin: e.admin,
    //       user_id: e.user_id,
    //     };
    //   });
    //   // this.createdEmployee = this.createdEmployee.filter((f) => {
    //   //   if (f.admin == "N") {
    //   //     return true;
    //   //   }
    //   // });
    //   this.getEmployerDetails();
    //   loader.hide();
    // },
  },
};
</script>

<style>
.slides-arrow li {
  list-style-type: none;
}

.slides-arrow li .fa {
  font-size: 30px;
  color: black;
  cursor: pointer;
  padding-right: 30px;
}

.gallery-slider img {
  height: 300px;
  width: 95%;
  -o-object-fit: cover;
  object-fit: cover;
  /* margin: 0 10%; */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.emp-profile-overlay {
  background: rgba(0, 0, 0, 0.58);
  border-radius: 08px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 30px;
  height: 160px;
  width: 160px;
  align-items: center;
}

.emp-profile-page .profile-img img {
  height: 160px;
  width: 160px;
  object-fit: cover;
}

.emp-profile-page .slick-track {
  margin-left: revert !important;
}

.user-slick .slick-list {
  overflow-x: scroll !important;
}

/* SCROLLBAR CUSTOMIZATION */

.user-slick .slick-list::-webkit-scrollbar {
  width: 0px;
  height: 13px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: #ff744b !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: #ff744b !important;
}

.table-vertical-scroll {
  height: 209px;
  overflow-y: auto;
}

.f-bold {
  color: #000;
  font-weight: bold;
}

@media only screen and (max-width:768px) {
  .b-bottom {
    border-bottom: 1px solid #0000002b;
  }

  .f-bold {
    color: #000;
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    line-height: 19.392px;
  }

  .profile-user .emp-details {
    display: grid !important;
    grid-template-columns: auto !important;
    grid-column-gap: 10px !important;
    grid-row-gap: 10px !important;
  }
}
</style>

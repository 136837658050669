import { validateLoginStatus } from "../store";
import { EMPLOYER_SERVERURL } from "@/config";


const routes = [
    {
        path: "/candidate/candidate-login",
        name: "Candidate-login",
        component: () => import("@/views/Web-Candidate/Login-Register/Login.vue"),
        meta: {
            applicantAuth: false,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/candidate-register",
        name: "Candidate-register",
        component: () => import("@/views/Web-Candidate/Login-Register/Register.vue"),
        meta: {
            applicantAuth: false,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/candidate-otp",
        name: "Candidate-Otp-Screen",
        component: () => import("@/views/Web-Candidate/Otp-Screen/Otp-screen.vue"),
        meta: {
            applicantAuth: false,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/update-password",
        name: "Update-Password",
        component: () => import("@/views/Web-Candidate/Update-Password/Update-Password.vue"),
        meta: {
            applicantAuth: true,
            employerAuth: false,
        },
        // beforeEnter: validateLoginStatus,
    },
    {
        path: "",
        name: "Home-Page",
        component: () => import("@/views/Web-Candidate/Home/Home-Layout.vue"),
        meta: {
            applicantAuth: false,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/home",
        name: "Home-Page",
        component: () => import("@/views/Web-Candidate/Home/Home-Layout.vue"),
        meta: {
            applicantAuth: false,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/notification",
        name: "Notification",
        component: () => import("@/views/Web-Candidate/Notification/Notification.vue"),
        // beforeEnter: validateLoginStatus,
        meta: {
            applicantAuth: true,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/create-visiting-card",
        name: "Create-Visiting-Card",
        component: () => import("@/views/Web-Candidate/Visiting-Card/Create-Visiting-Card.vue"),
        // beforeEnter: validateLoginStatus,
        meta: {
            applicantAuth: true,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/personal-details",
        name: "Personal-Details",
        component: () => import("@/views/Web-Candidate/Visiting-Card/Personal-details.vue"),
        // beforeEnter: validateLoginStatus,
        meta: {
            applicantAuth: true,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/industry",
        name: "Industry",
        component: () => import("@/views/Web-Candidate/Visiting-Card/Industry.vue"),
        // beforeEnter: validateLoginStatus,
        meta: {
            applicantAuth: true,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/skill-details",
        name: "Skill-Details",
        component: () => import("@/views/Web-Candidate/Visiting-Card/Skills.vue"),
        // beforeEnter: validateLoginStatus,
        meta: {
            applicantAuth: true,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/education-details",
        name: "education-Details",
        component: () => import("@/views/Web-Candidate/Visiting-Card/Education-Expiriance.vue"),
        // beforeEnter: validateLoginStatus,
        meta: {
            applicantAuth: true,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/job-deatils/:job_id",
        name: "Job-Deatils",
        component: () => import("@/views/Web-Candidate/Job-Details/Job-Details.vue"),
        meta: {
            applicantAuth: false,
            employerAuth: false,
        },
        // beforeEnter: validateLoginStatus,
    },
    {
        path: "/candidate/interview-questions/:job_id",
        name: "Interview-Questions",
        component: () => import("@/views/Web-Candidate/Interview-Questions/Interview-Questions.vue"),
        // beforeEnter: validateLoginStatus,
        meta: {
            applicantAuth: true,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/save-job",
        name: "Save-Job",
        component: () => import("@/views/Web-Candidate/Save-Job/Save-Job.vue"),
        // beforeEnter: validateLoginStatus,
        meta: {
            applicantAuth: true,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/user-profile",
        name: "User-Profile",
        component: () => import("@/views/Web-Candidate/Candiate-Profile/Candiate-Profile.vue"),
        // beforeEnter: validateLoginStatus,
        meta: {
            applicantAuth: true,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/chat",
        name: "User-Chat",
        component: () => import("@/views/Web-Candidate/Chat/Chat.vue"),
        // beforeEnter: validateLoginStatus,
        meta: {
            applicantAuth: true,
            employerAuth: false,
        },
    },
    {
        path: "/candidate/settings",
        name: "User-Settings",
        component: () => import("@/views/Web-Candidate/Settings/Settings.vue"),
        // beforeEnter: validateLoginStatus,
        meta: {
            applicantAuth: true,
            employerAuth: false,
        },
        children: [
            {
                path: '',
                name: "Personal-Detail",
                component: () => import("@/views/Web-Candidate/Profile-Settings/Personal-Detail.vue"),
            },
            {
                path: 'personal-detail',
                name: "Personal-Detail",
                component: () => import("@/views/Web-Candidate/Profile-Settings/Personal-Detail.vue"),
            },
            {

                path: 'edit-industry',
                name: "Edit-Industry",
                component: () => import("@/views/Web-Candidate/Profile-Settings/Edit-Industry.vue"),
            },
            {
                path: 'edit-skill',
                name: "Edit-Skill",
                component: () => import("@/views/Web-Candidate/Profile-Settings/Edit-Skill.vue"),
            },
            {
                path: 'professional-details',
                name: "Professional-Details",
                component: () => import("@/views/Web-Candidate/Profile-Settings/Professional-Details.vue"),
            },
            {
                path: 'change-password',
                name: "change-password",
                component: () => import("@/views/Web-Candidate/Settings/Change-Password.vue"),
            },
            {

                path: 'change-language',
                name: "change-language",
                component: () => import("@/views/Web-Candidate/Settings/Change-Language.vue"),
            },
            {
                path: 'support',
                name: "support",
                component: () => import("@/views/Web-Candidate/Settings/Support.vue"),
            },
            // {
            //     path: 'logout',
            //     name: "logout",
            //     component: () => import("@/views/Web-Candidate/Settings/Logout.vue"),
            // },
            // {

            //     path: 'delete-account',
            //     name: "",
            //     component: () => import("@/views/Web-Candidate/Settings/Change-Password.vue"),
            // },
        ],
    },
    // {
    //     path: "/candidate/edit-profile",
    //     name: "User-Settings",
    //     component: () => import("@/views/Web-Candidate/Profile-Settings/Profile-Settings.vue"),
    //     // beforeEnter: validateLoginStatus,
    //     meta: {
    //         applicantAuth: true,
    //         employerAuth: false,
    //     },
    //     children: [
    //         {
    //             path: '',
    //             name: "Personal-Detail",
    //             component: () => import("@/views/Web-Candidate/Profile-Settings/Personal-Detail.vue"),
    //         },
    //         {
    //             path: 'personal-detail',
    //             name: "Personal-Detail",
    //             component: () => import("@/views/Web-Candidate/Profile-Settings/Personal-Detail.vue"),
    //         },
    //         {

    //             path: 'edit-industry',
    //             name: "Edit-Industry",
    //             component: () => import("@/views/Web-Candidate/Profile-Settings/Edit-Industry.vue"),
    //         },
    //         {
    //             path: 'edit-skill',
    //             name: "Edit-Skill",
    //             component: () => import("@/views/Web-Candidate/Profile-Settings/Edit-Skill.vue"),
    //         },
    //         {
    //             path: 'professional-details',
    //             name: "Professional-Details",
    //             component: () => import("@/views/Web-Candidate/Profile-Settings/Professional-Details.vue"),
    //         },
    //     ],
    // },

    {
        path: '/employer-login',
        beforeEnter(to, from, next) {
            //console.log("To", to);
            //console.log("From", from);
            // Put the full page URL including the protocol http(s) below
            window.history.pushState({ prevUrl: window.location.href }, null, from.fullPath);
            window.location.replace(`${EMPLOYER_SERVERURL}login`)
            // window.location.replace("https://www.zaajira.com/#/login")
        }
    },
    {
        path: '/employer-register',
        beforeEnter(to, from, next) {
            //console.log("To", to);
            //console.log("From", from);
            window.history.pushState({ prevUrl: window.location.href }, null, from.fullPath);
            window.location.replace(`${EMPLOYER_SERVERURL}register`)
            // window.location.replace("https://www.zaajira.com/#/register")
        }
    },
    {
        path: '/how-it-works',
        beforeEnter(to, from, next) {
            //console.log("To", to);
            //console.log("From", from);
            window.history.pushState({ prevUrl: window.location.href }, null, from.fullPath);
            window.location.replace(`${EMPLOYER_SERVERURL}employers`)
            // window.location.replace("https://www.zaajira.com/#/employers")
        }
    },
    {
        path: '/pricing',
        beforeEnter(to, from, next) {
            //console.log("To", to);
            //console.log("From", from);
            window.history.pushState({ prevUrl: window.location.href }, null, from.fullPath);
            window.location.replace(`${EMPLOYER_SERVERURL}employers/?Pricing=true`)
            // window.location.replace("https://www.zaajira.com/#/employers")
        }
    },
    {
        path: '/download-zaajira-app',
        beforeEnter(to, from, next) {
            //console.log("To", to);
            //console.log("From", from);
            window.history.pushState({ prevUrl: window.location.href }, null, from.fullPath);
            window.location.replace(`${EMPLOYER_SERVERURL}download-zaajira-app`)
            // window.location.replace("https://www.zaajira.com/#/download-zaajira-app")
        }
    },
    {
        path: '/support',
        beforeEnter(to, from, next) {
            //console.log("To", to);
            //console.log("From", from);
            window.history.pushState({ prevUrl: window.location.href }, null, from.fullPath);
            window.location.replace(`${EMPLOYER_SERVERURL}support`)
            // window.location.replace("https://www.zaajira.com/#/support")
        }
    },
    {
        path: '/terms-and-conditions',
        beforeEnter(to, from, next) {
            //console.log("To", to);
            //console.log("From", from);
            window.history.pushState({ prevUrl: window.location.href }, null, from.fullPath);
            window.location.replace(`${EMPLOYER_SERVERURL}terms-and-conditions`)
            // window.location.replace("https://www.zaajira.com/#/terms-and-conditions")
        }
    },
    {
        path: '/privacy-policy',
        beforeEnter(to, from, next) {
            //console.log("To", to);
            //console.log("From", from);
            window.history.pushState({ prevUrl: window.location.href }, null, from.fullPath);
            window.location.replace(`${EMPLOYER_SERVERURL}privacy-policy`)
            // window.location.replace("https://www.zaajira.com/#/privacy-policy")
        }
    },
    {
        path: '/apple',
        beforeEnter(to, from, next) {
            //console.log("To", to);
            //console.log("From", from);
            window.history.pushState({ prevUrl: window.location.href }, null, from.fullPath);
            window.location.replace("https://apps.apple.com/app/zaajira/id6449016993?platform=iphone", '_blank');
            next(false);
        }
    },
    {
        path: '/android',
        beforeEnter(to, from, next) {
            //console.log("To", to);
            //console.log("From", from);
            window.history.pushState({ prevUrl: window.location.href }, null, from.fullPath);
            window.location.replace("https://play.google.com/store/apps/details?id=com.zaajira", '_blank');
            next(false);
        }
    },

]



export default routes
<template>
  <div class="bg-grey">
    <b-container fluid class="user-header">
      <div class="pt-5 mt-5">
        <div class="back-header mt-3">
          <img @click="$router.go(-1)" src="@/assets/app-icons/left-arrow.svg" width="10px" />
          <span @click="$router.go(-1)" class="back-route-text"> &nbsp;{{ $gettext("Back") }}</span>
        </div>
      </div>
      <b-row>
        <b-col :class="jobCount ? 'col-md-11 col-12 user-about pt-3 pb-5' : 'col-md-8 col-12 user-about pt-3 pb-5'">
          <div class="user-img">
            <img :src="`${image_url}${profile.user_job_profile_image}`" class="rounded img-center" width="100" />
            <img v-if="!profile.user_job_profile_image" src="../../assets/unknown.jpg" class="rounded img-center"
              width="100" />
          </div>
          <div class="user-content ml-3">
            <h3 class="user-name">
              {{ profile.user_username }}
            </h3>
            <p class="user-loc">{{ profile.city_name }}, {{ profile.country_name }}</p>
          </div>
        </b-col><!--End User About-->
        <b-col :class="jobCount ? 'col-md-1 align-self-center' : 'col-md-4 align-self-center'">
          <div class="app-emp-header" v-if="jobCount" v-show="!$route.query.shortlist">
            <!-- <div style="cursor: pointer" @click="addShortlist">
              <img src="@/assets/app-icons/rewards.svg" width="15px" />
              <h6>{{ this.shortlisted ? selectedJob.emp_status : "Shortlist" }}</h6>
            </div> -->
            <!-- <div>
              <img src="@/assets/app-icons/chat.svg" width="15px" />
              <h6>Message</h6>
            </div>
            <div>
              <img src="@/assets/app-icons/forward.svg" width="15px" />
              <h6>Forward</h6>
            </div> -->

            <div @click="share" v-b-modal.share-modal>
              <img src="@/assets/app-icons/forward.svg" width="16px" />
              <div>{{ $gettext("Share") }}</div>
            </div>
          </div>
          <div class="app-emp-header" v-if="!jobCount">
            <!-- <h6>Kindly create a job for shortlisting</h6> -->
            <button style="background-color: transparent" title="Kindly create a job for shortlisting"
              class="btn btn-primary font-weight-bold mr-3" @click="onCreateJob">
              <img src="@/assets/app-icons/edit.svg" width="15px" />
              {{ $gettext("Create_Job") }}
            </button>
            <div @click="share" v-b-modal.share-modal>
              <img src="@/assets/app-icons/forward.svg" width="16px" />
              <div>{{ $gettext("Share") }}</div>
            </div>
          </div>
        </b-col>
        <b-col class="col-md-12 mb-3">
          <div class="" v-if="jobCount" v-show="!$route.query.shortlist">
            <multiselect class="multis" v-model="selectedJob" :placeholder="$gettext('Select_To_Shortlist')"
              label="job_title" track-by="job_id" @input="addShortlist" @remove="removeHandle" :options="jobOptions"
              :multiple="true" />
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="py-4">
      <b-col class="col-md-12 user-profile-screen user-profile emp-profile-page user-emp-page py-3">
        <div class="profile-flex border-bottom pb-3">
          <!-- {{ selectedJob }}......... {{ shortlistData }} -->
          <h4 class="heading">{{ $gettext("Profile") }}</h4>
          <!-- <h6>{{ profile.profile_update_at }} {{ $gettext("day_ago") }}</h6> -->
          <h6>{{ created_at }} {{ $gettext("day_ago") }}</h6>
        </div>
        <b-row>
          <!-- <b-col class="col-md-12 py-5">
            <h6>CV</h6>
            <div class="resume-box" style="width: 20%">
              <div class="resume-doc d-flex" v-b-tooltip.hover :title="cvName">
                <img src="@/assets/app-icons/pdf.svg" width="25px" />
                <div v-if="!selectedSub || selectedSub.subs_pkg_title == 'Free' || selectedSub.subs_pkg_title == 'FREE'">
                  <h5
                    class="pl-3"
                    style="cursor: pointer"
                    @click="
                      () => {
                        $toasted.show('You are not allowed to see this', {
                          duration: 6000,
                          icon: 'clear',
                        });
                      }
                    "
                  >
                    {{ cvName != "" ? cvName : "Resume" }}
                  </h5>
                </div>
                <div v-else>
                  <a :href="profile.user_job_cv_path" target="_blank">
                    <h5 class="pl-3">{{ cvName != "" ? cvName : "Resume" }}</h5>
                  </a>
                </div>
              </div>
              <div class="resume-action">
                <div class="resume-view">
                  <img src="@/assets/app-icons/view.svg" width="25px" />
                  <h5>
                    <a :href="profile.user_job_cv_path" target="_blank" class="text-dark pt-2">VIEW</a>
                  </h5>
                </div>
                <div class="resume-download" v-if="profile.user_job_cv_path">
                  <a @click="download">
                    <img src="@/assets/app-icons/save-upload.svg" width="25px" />
                    <h5 class="f-primary">DOWNLOAD</h5>
                  </a>
                </div>
              </div>
            </div>
          </b-col> -->

          <b-col class="col-md-8 pt-3">
            <div class="profile-details-one">
              <div>
                <h6>{{ $gettext("Full_name") }}</h6>
                <h5 class="fw-700">{{ profile.user_username }}</h5>
              </div>
              <div>
                <h6>{{ $gettext("Job_title") }}</h6>
                <!-- <h5>{{ profile.user_job_title }}</h5> -->
                <h5 class="fw-700">{{ profile.exp[0].designation && profile.exp[0].designation !== "null" ?
                  profile.exp[0].designation :
                  "Not Entered" }}</h5>
                <!-- <h5>4</h5> -->
              </div>
              <div>
                <h6>{{ $gettext("Age") }}</h6>
                <h5 class="fw-700">{{ profile.user_age }}</h5>
              </div>
              <div>
                <h6>{{ $gettext("Gender") }}</h6>
                <h5 class="fw-700">{{ profile.user_gender == "M" ? "Male" : "Female" }}</h5>
              </div>
              <div>
                <h6>{{ $gettext("Email") }}</h6>
                <h5 class="fw-700"
                  v-if="!selectedSub || (selectedSub && (selectedSub.subs_pkg_title == 'Free' || selectedSub.subs_pkg_title == 'FREE'))">
                  #########</h5>
                <h5 class="fw-700" v-else>
                  {{ profile.user_email }}
                </h5>
              </div>
              <div>
                <h6>{{ $gettext("Mobile_No") }}</h6>
                <h5 class="fw-700">{{ profile.user_mobile_number }}</h5>
                <!-- <h6>Industry</h6>
                <h5>{{ profile.industry_name }}</h5> -->
              </div>
              <div>
                <h6>{{ $gettext("Years_of_experience") }}</h6>
                <!-- <h5>{{ profile.user_job_experience }}</h5> -->
                <!-- <h5>{{ profile.exp[0].experiance_year }}</h5> -->
                <h5 class="fw-700">
                  {{ profile.exp[0].experiance_year ? profile.exp[0].experiance_year : 0 }}
                  {{ $gettext(" Years") }}
                </h5>
              </div>
              <div>
                <h6>{{ $gettext("Country_of_residence") }}</h6>
                <h5 class="fw-700">{{ profile.country_name }}</h5>
              </div>
              <div>
                <h6>{{ $gettext("Nationality") }}</h6>
                <h5 class="fw-700">{{ profile.nationality }}</h5>
              </div>
              <!-- <div>
                <h6>Current Organisation</h6>
                <h5>{{ profile.user_job_current_org }}</h5>
              </div> -->
              <!-- <div>
                <h6>Preferred Job</h6>
                <h5>{{ profile.user_job_preferred }}</h5>
              </div> -->
              <div>
                <h6>{{ $gettext("Current_salary_monthly") }}</h6>

                <h5 class="fw-700">
                  <!-- {{ profile.currency_code
                  }}{{ profile.user_job_expected_salary }} -->
                  <div class="d-flex">
                    <div class="mr-2 fw-700">{{ profile.exp[0].current_salary == 0 ? "" : profile.currency_code }}</div>
                    <div class="pr-2 fw-700">
                      <!-- {{ profile.exp[0].current_salary }} -->
                      {{ profile.exp[0].current_salary > 0 ?
                        formattedNumber(profile.exp[0].current_salary) : "Not Entered" }}
                    </div>
                  </div>
                </h5>
              </div>
              <!-- <div class="intro-div">
                <h6>Introduction</h6>
                <p>
                  {{ profile.user_job_introduction }}
                </p>
              </div> -->
            </div>
            <div>
              <!-- <h6>Experience</h6>
              <ul class="px-3">
                <li v-for="e of profile.exp" :key="e.experiance_id">
                  <span> {{ e.experiance_working_org }}, {{ e.designation }} </span>
                  <span> {{ e.employee_type }} </span>
                </li>
              </ul> -->
              <h6 class="mt-2">{{ $gettext("Education") }}</h6>
              <ul class="px-3 fw-700">
                <!-- <li v-for="e of profile.edu" :key="e.education_id">
                  <div>{{ e.education_degree }}</div>
                  <div>{{ e.education_university_name }}</div>
                </li> -->
                <li v-for="e of profile.edu" :key="e">
                  <div>{{ e.ms_edu_degree }}</div>
                  <!-- <div>{{ e.education_university_name }}</div> -->
                </li>
              </ul>
              <!-- <h6 class="mt-2">Certificates</h6>
              <ul v-if="profile.certificates && profile.certificates.length" class="px-3">
                <li v-for="c of profile.certificates" :key="c.certificates_id">{{ c.c_name }} - {{ c.c_field }}</li>
              </ul> -->
            </div>
            <div class="profile-details-two">
              <div>
                <h6>{{ $gettext("Skills") }}</h6>
                <label style="color:black;" class="label-grey mr-2 fw-700" v-for="(skill, index) in profile.skills"
                  :key="index">{{ skill }}</label>
              </div>
              <div>
                <h6>{{ $gettext("Industry") }}</h6>
                <label style="color:black;" class="label-grey mr-2 fw-700" v-for="(ind, index) in profile.industry"
                  :key="index">{{ ind }}</label>
              </div>

              <!-- <div>
                <h6>Social Links</h6>
                <div class="d-flex">
                  <h6 v-if="profile.facebook" class="fs-25 social-icon-box mr-3" @click="socialLink(profile.facebook)">
                    <i class="fa fa-facebook text-white" aria-hidden="true"></i>
                  </h6>
                  <h6 v-if="profile.linked_in" class="fs-25 social-icon-box mr-3" @click="socialLink(profile.linked_in)">
                    <i class="fa fa-linkedin text-white"></i>
                  </h6>
                  <h6 v-if="profile.instagram" class="fs-25 social-icon-box mr-3" @click="socialLink(profile.instagram)">
                    <i class="fa fa-instagram text-white"></i>
                  </h6>
                  <h6 v-if="profile.twitter" class="fs-25 social-icon-box" @click="socialLink(profile.twitter)">
                    <i class="fa fa-twitter text-white"></i>
                  </h6>
                </div>
              </div> -->
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-col class="col-md-12 user-profile-screen user-profile mt-3" v-if="videos.length > 0">
        <div class="profile-flex border-bottom">
          <h4 class="heading">Video Profile</h4>
          <ul class="slides-arrow--black d-flex">
            <li class="prev"><i class="fa fa-angle-left"></i></li>
            <li class="next"><i class="fa fa-angle-right"></i></li>
          </ul>
        </div>
        <slick ref="slick" :options="videoSlider" v-if="videos.length > 0">
          <div v-for="(data, index) in videos" :key="index">
            <div class="p-3 position-relative" @click="onClick(data)">
              <!-- <a @click="onSelectVideo(index)" style="cursor: pointer"> -->
              <div style="cursor: pointer w-100">
                <img :id="data.user_job_video_path" :name="data.user_job_video_path"
                  :src="`https://videodelivery.net/${data.user_job_video_path}/thumbnails/thumbnail.jpg?time=1s&height=300`"
                  style="border: none" height="300" width="100%" />
              </div>
              <span>{{ data.user_job_video_title }}</span>
              <img src="@/assets/app-icons/orange-video.svg" class="img-center video-play-img" width="50px" />
              <!-- </a> -->
              <!-- <CloudflareVideoPlayer
                :video-id="data.user_job_video_path"
                :autoplay="false"
              /> -->
            </div>
          </div>
        </slick>
        <b-row>
          <b-col class="col-md-6 col-8">
            <div class="video-player-box">
              <!-- <img
                  src="@/assets/home/man.jpg"
                  class="img-fluid img-center bg-overlay-dark"
                /> -->
              <!-- <CloudflareVideoPlayer :video-id="video_id" :autoplay="false" /> -->
              <iframe v-if="selectedVideo"
                :src="`https://iframe.videodelivery.net/${selectedVideo.user_job_video_path}?poster=https%3A%2F%2Fvideodelivery.net%2F${selectedVideo.user_job_video_path}%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D68s%26height%3D270&time=1s`"
                style="border: none" height="500" width="600"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"></iframe>

              <p v-if="videos.length" class="h5 font-weight-bold">Video Profile #{{ videos.length }}</p>
            </div>
          </b-col>
          <b-col class="col-md-6 col-12">
            <b-form class="customized-form mt-4">
              <b-form-input :disabled="true" placeholder="Video Title" v-model="video_title"> </b-form-input>
              <b-form-textarea :disabled="true" v-model="video_description" size="lg"
                placeholder="Video Description (Max 100 words)" rows="10" max-rows="15"> </b-form-textarea>
            </b-form>
          </b-col>
        </b-row>
      </b-col>
    </b-container>
    <b-modal id="share-modal" ref="modal" @ok="handleOk" hide-footer>
      <div>
        <h4>{{ $gettext("Share_this_job_on_social_network") }}</h4>
        <div class="social-icons-grid">
          <ShareNetwork network="Whatsapp" :url="shareUrl" :title="profile.user_username ? profile.user_username : ''"
            :description="profile.user_job_introduction ? profile.user_job_introduction : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-whatsapp"></i>
            </div>
          </ShareNetwork>
          <ShareNetwork network="Facebook" :url="shareUrl" :title="profile.user_job_title ? profile.user_job_title : ''"
            :description="profile.user_job_introduction ? profile.user_job_introduction : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-facebook"></i>
            </div>
          </ShareNetwork>
          <ShareNetwork network="Twitter" :url="shareUrl" :title="profile.user_job_title ? profile.user_job_title : ''"
            :description="profile.user_job_introduction ? profile.user_job_introduction : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-twitter"></i>
            </div>
          </ShareNetwork>
          <!-- <ShareNetwork network="fakeblock" :url="shareUrl" :title="profile.user_job_title ? profile.user_job_title : ''"
            :description="profile.user_job_introduction ? profile.user_job_introduction : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </div>
          </ShareNetwork> -->

          <!-- <a :href="`https://www.snapchat.com/scan?attachmentUrl=${shareUrl}`" target="_blank">
            <i class="fa fa-snapchat-square" aria-hidden="true"></i>
          </a> -->

          <!-- 
          <a href="https://www.tiktok.com/en/" target="_blank">
            <div style="width:17.15px; margin-top: -2px;">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333335 333336" shape-rendering="geometricPrecision"
                text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd"
                clip-rule="evenodd">
                <path
                  d="M72464 0h188407c39856 0 72464 32609 72464 72465v188407c0 39855-32608 72464-72464 72464H72464C32608 333336 0 300727 0 260872V72465C0 32609 32608 0 72464 0zm127664 70642c337 2877 825 5661 1461 8341l6304 2c1170 9991 4006 19119 8465 26697 7282 6745 16797 10904 28280 11641v9208c2131 444 4350 746 6659 894v29690c-14847 1462-27841-3426-42981-12531l2324 50847c0 16398 61 23892-8738 38977-20546 35222-58194 36677-82176 18323-12269-4256-23069-12466-29881-23612-19875-32516-1959-85512 55687-90966l-94 7835v1970c3105-646 6365-1144 9794-1468v31311c-12484 2057-20412 5890-24119 12980-7424 14197-4049 26526 3716 34309 16276 2796 34401-8481 31673-43351V70644h33628z"
                  fill="#1a121f" />
                <path
                  d="M200128 70642c3093 26406 18915 45038 44510 46681v25046l-165 15v-21275c-25595-1642-40311-17390-43404-43796l-27114-1v111095c3912 50005-35050 51490-49955 32531 17482 10934 45867 3826 42501-39202V70641h33628zm-72854 184165c-15319-3153-29249-12306-37430-25689-19875-32516-1959-85512 55687-90966l-94 7835c-53444 8512-58809 65920-44009 89802 5707 9209 15076 15686 25846 19019z"
                  fill="#26f4ee" />
                <path
                  d="M207892 78985c1761 15036 7293 28119 16454 36903-12866-6655-20630-19315-23062-36905l6609 2zm36580 47511c2181 463 4456 777 6824 929v29690c-14847 1462-27841-3426-42981-12531l2324 50847c0 16398 61 23892-8738 38977-21443 36760-61517 36743-85239 15810 30930 17765 84928 3857 84829-56453v-55496c15141 9105 28134 13993 42981 12530v-24302zm-99036 21460c3105-646 6365-1144 9794-1468v31311c-12484 2057-20412 5890-24119 12980-10441 19964 474 36238 14923 41365-18075-649-36010-19214-23555-43031 3707-7089 10474-10923 22958-12980v-28177z"
                  fill="#fb2c53" />
                <path
                  d="M201068 77313c3093 26406 17809 42154 43404 43796v29689c-14847 1462-27841-3425-42981-12530v55496c119 72433-77802 77945-100063 42025-14800-23881-9435-81290 44009-89802v30147c-12483 2057-19250 5891-22958 12980-22909 43808 56997 69872 51475-706V77313l27114 1z"
                  fill="#fefefe" />
              </svg>
            </div>
          </a> -->

          <ShareNetwork network="Linkedin" :url="shareUrl" :title="profile.user_job_title ? profile.user_job_title : ''"
            :description="profile.user_job_introduction ? profile.user_job_introduction : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-linkedin"></i>
            </div>
          </ShareNetwork>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RecruiterJobService from "@/apiServices/RecruiterJobService.js";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";
import EmployerHeader from "@/views/Employer-Profile-Screens/Employer.vue";
import CompanyServices from "@/apiServices/CompanyServices";
import CloudflareVideoPlayer from "vue-cloudflare-video-player";
import axios from "axios";
import moment from "moment";
import { SERVERURL } from "@/config";
import { IMAGEURL } from "@/config";

import Multiselect from "vue-multiselect";
import numeral from 'numeral';

export default {
  components: {
    EmployerHeader,
    CloudflareVideoPlayer,
    Multiselect,
  },
  data() {
    return {
      created_at: this.$route.query.c,
      jobCount: null,
      selectedJob: null,
      removedJob: null,
      shortlistData: null,
      jobOptions: [],
      image_url: IMAGEURL,
      SERVERURL: SERVERURL,
      completedSteps: 25,
      totalSteps: 4,
      shortlisted: false,
      profile: {},
      archived: null,
      selectedVideo: null,
      video_title: null,
      video_description: null,
      cvName: "",
      job: {},
      applicantDetails: {},
      selectedSub: null,
      shareUrl: "",
      skills: null,
      video_id: "",
      videos: [{}],
      videoSlider: {
        arrows: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 4,
        prevArrow: ".prev",
        nextArrow: ".next",
        videoSlider: {
          arrows: true,
          infinite: true,
          slidesToScroll: 1,
          slidesToShow: 4,
          prevArrow: ".prev",
          nextArrow: ".next",
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
              },
            },
          ],
        },
      },
    };
  },
  async beforeMount() {
    this.getApplicantProfile();
    // this.getShortlisted();
    this.selectedShortlist();
    this.getSelectedSubscription();
    this.getAllVideos();

    console.log("this.$router.query", this.$route.query);
    //  this.getAppliedApplicant();
    if (this.$route.query.job_id && this.$route.query.job_id != "") {
      this.shareUrl = `${this.SERVERURL}applicant-profile-employer/${this.$route.params.user_id}?job_id=${this.$route.query.job_id}`;
    } else {
      this.shareUrl = `${this.SERVERURL}applicant-profile-employer/${this.$route.params.user_id}?shortlist=`;
    }
  },
  methods: {
    formattedNumber(sal) {
      console.log(sal);

      return numeral(sal).format('0,0');
    },
    onCreateJob() {
      this.$router.push({ path: "/employer-jobcreation" });
    },
    async getAllVideos() {
      // let loader = this.$loading.show();
      const payload = { user_id: this.$route.params.user_id };
      try {
        const getVideos = await RecruiterJobService.GetApplicantVideos(payload);
        this.videos = getVideos.data.data;
        let v = this.videos.some((j) => j.archived === "Y");
        this.archived = v;
        if (this.videos.length > 0) {
          this.selectedVideo = this.videos[0];
          this.video_title = this.selectedVideo.user_job_video_title;
          this.video_description = this.selectedVideo.user_job_video_description;
          //loader.hide();
          if (this.$refs.slick) {
            this.$refs.slick.destroy();
            this.$nextTick(() => {
              this.$refs.slick.create();
              this.$refs.slick.goTo(0, true);
            });
          }
        } else {
          console.log("hitting");
          this.video_status = "add";
          //loader.hide();
        }
      } catch (error) {
        //   loader.hide();
      }
    },
    onClick(e) {
      console.log("e :>> ", e);
      this.selectedVideo = e;

      this.video_title = e.user_job_video_title;
      this.video_description = e.user_job_video_description;
    },
    getApplicantProfile() {
      const user_id = this.$route.params.user_id;
      const job_id = this.$route.query.job_id ? this.$route.query.job_id : "";
      console.log("user_id :>> ", user_id);
      RecruiterJobService.GetApplicantProfile({ user_id, job_id }).then((app) => {
        console.log("app.data.data :>> ", app.data.data);
        console.log("app :>> ", app.data.message.jobs.length);
        this.jobCount = app.data.message.jobs.length;
        this.jobOptions = app.data.message.jobs.map((job, index) => ({ job_id: job.job_id, job_title: job.job_title, shortlisted: "" }));
        console.log("jobOptions", this.jobOptions);

        this.profile = app.data.data[0];

        const now = moment();
        const d = moment(this.profile.profile_update_at).format("YYYY-MM-DD HH:mm:ss");
        console.log("this.profile.profile_update_at :>> ", this.profile.profile_update_at);
        const diff = now.diff(d, "days");
        console.log("diff :>> ", diff);
        this.profile = { ...this.profile, profile_update_at: diff };
        this.profile.answer.forEach((ans) => {
          if (ans.video_path) {
            this.video_id = ans.video_path;
          }
        });
        this.cvName = this.profile.user_job_cv_path.split("/")[5].split("_")[1];
        this.profile.skills = this.profile.skills;
        if (this.profile.social_link) {
          this.profile.social_link = {
            ...JSON.parse(JSON.parse(this.profile.social_link)),
          };
        }
      });
    },

    getSelectedSubscription() {
      const user_id = this.$cookies.get("user_id");
      let loader = this.$loading.show();
      CompanyServices.getUserSubscription(user_id)
        .then((res) => {
          this.selectedSub = res.data.data.length ? res.data.data[0] : null;
          // this.selectedSub.subscription_type_id == 1
          //   ? (this.activesub = "Monthly")
          //   : (this.activesub = "Yearly");
          if (this.selectedSub) {
            this.selectedSub.subscription_end_date_timestamp = moment.unix(this.selectedSub.subscription_end_date_timestamp).format("YYYY-MM-DD HH:mm:ss");
          }
          loader.hide();
        })
        .catch((err) => {
          loader.hide();
        });
    },
    getAppliedApplicant() {
      let job_id = +this.$route.query.job_id;
      console.log("this.$route.query :>> ", this.$route.query);
      let searchApplicant = this.searchApplicant;
      let city_id = this.city?.city_id;
      console.log("job_id :>> ", job_id);
      RecruiterJobService.GetAppliedApplicant({
        job_id,
        searchApplicant,
        city_id,
      }).then((res) => {
        this.applicantDetails = res.data.data;
        this.applicantDetails.forEach((app, index) => {
          app.answer.forEach((ans) => {
            if (ans.video_path) {
              console.log("ans :>> ", ans);
              this.video_id = ans;
            }
          });
        });
        console.log("this.applicantDetails :>> ", this.applicantDetails);
      });
    },
    share() {
      this.$bvModal.show("share-modal");
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      //this.handleSubmit();
      this.$nextTick(() => {
        this.$bvModal.hide("share-modal");
      });
    },
    handleSubmit() {
      this.$nextTick(() => {
        this.$bvModal.hide("share-modal");
      });
    },
    selectedShortlist() {
      // if (!this.$route.query.job_id) {
      //   return;
      // }
      const payload = {
        user_id: this.$route.params.user_id,
        // job_id: this.$route.query.job_id,
      };
      RecruiterJobService.selectedShortlist(payload)
        .then((res) => {
          console.log("res.data", res.data.data);
          this.selectedJob = res.data.data;
          // if (res.data.data.length > 0) {
          //   this.shortlisted = res.data.data[0].shortlisted == "Y" ? true : false;
          // }
        })
        .then((err) => {
          console.log(err);
        });
    },

    addShortlist(e) {
      console.log("addShortlist selectedJobs", this.selectedJob);

      const payload = {
        user_id: this.$route.params.user_id,
        // job_id: this.selectedJob.job_id,
        // job_title: this.selectedJob.job_title,
        jobs: this.selectedJob.filter((job) => {
          if (job.shortlisted !== "Y") {
            return job;
          }
          // job.shortlisted = "Y";
        }),
        removedJob: this.removedJob,
      };
      console.log("payload", payload);
      RecruiterJobService.AddShortlist(payload)
        .then((s) => {
          this.selectedShortlist();
          console.log("s :>> ", s);
          this.shortlisted = s.data.data.rQuery > 0 ? true : false;
          this.removedJob = null;
          // this.selectedShortlist();
          if (this.shortlisted) {
            this.$toasted.show("Applicant Rejected!", {
              duration: 6000,
              icon: "clear",
            });
          } else {
            this.$toasted.show("Applicant Shortlisted!", {
              duration: 6000,
              icon: "check",
            });
          }
        })
        .catch((e) => {
          this.$toasted.show("Applicant already shorlisted!", {
            duration: 6000,
            icon: "clear",
          });
        });
    },

    removeHandle(e) {
      this.shortlisted = true;
      console.log("removeHandle", e);
      this.removedJob = {
        job_id: e.job_id,
        job_title: e.job_title,
        shortlisted: "N",
      };
      // this.selectedJob = this.selectedJob.map((job) => (job.shortlisted = "N"));
      console.log("this.removedJob", this.removedJob);
      console.log("this.selectedJob", this.selectedJob);
    },
    socialLink(url) {
      window.open(url, "_blank");
    },
    download() {
      const user_id = this.$route.params.user_id;
      axios({
        url: this.profile.user_job_cv_path,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });

      // RecruiterJobService.GetApplicantProfile({ user_id }).then((response) => {
      //   var fileURL = window.URL.createObjectURL(
      //     new Blob([response.data.data[0].user_job_cv_path])
      //   );
      //   console.log("fileURL :>> ", fileURL);
      //   var fileLink = document.createElement("a");

      //   fileLink.href = fileURL;
      //   fileLink.setAttribute("download", "file.pdf");
      //   document.body.appendChild(fileLink);

      //   fileLink.click();
      // });
    },
  },
};
</script>

<style>
.multis>>>.multiselect__tag {
  background: #6244e5;
}

.multiselect.multis .multiselect__tags .multiselect__tags-wrap .multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #6244e5;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.multiselect.multis .multiselect__tags .multiselect__tags-wrap .multiselect__tag .multiselect__tag-icon:after {
  content: "×";
  color: #fff;
  font-size: 14px;
}

.multiselect__content-wrapper .multiselect__content .multiselect__element .multiselect__option--highlight {
  background: #6244e5;
  outline: none;
  color: white;
}

.user-emp-page .resume-box {
  width: 35%;
  padding-right: 20px;
}

.fw-700 {
  font-weight: 700;
}

@media screen and (max-width:768px) {
  .user-emp-page .profile-details-one {
    display: grid;
    grid-template-columns: 100% !important;
    grid-gap: 10px;
  }

  .user-emp-page .profile-details-two {
    display: grid;
    grid-template-columns: 100% !important;
  }

}
</style>
